import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { TransactionType } from 'types/HistoryTypes';
import { NumberOfTickets } from './NumberOfTickets';
import {
  HiddenSpan,
  TxtMediumBoldDarkResp,
  TxtMediumMediumDarkResp,
  TxtSmallMediumMediumResp,
} from 'elements/new-design/Typography';
import { BtnExpandDark, BtnExpandWhite } from 'elements/buttons/Buttons';
import { ChevronRight } from 'elements/icon/Icon';
import { MarginRight } from 'elements/distance/Margins';

type TicketHeaderProps = {
  ariaLabel: string;
  transaction: TransactionType;
  selected: boolean;
  expired: boolean;
  onToggleTicket: () => void;
};

type StyleProps = {
  selected: boolean;
  useActiveColor: boolean;
};

const TicketHeaderContainer = styled.button`
  ${(_: StyleProps) => ''}
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  border-bottom: ${(props) => (props.selected ? props.theme.constants.borderPrimary : 'none')};

  @media ${(props) => props.theme.devices.large} {
    padding: 2.4rem;
  }

  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }

  ${TxtMediumMediumDarkResp},
  ${TxtMediumBoldDarkResp},
  ${TxtSmallMediumMediumResp} {
    color: ${(props) => (props.useActiveColor ? props.theme.colors.txtColLight : '')};
  }
`;

const PurchaseDate = styled.div`
  width: 100%;
  text-align: left;
  @media ${(props) => props.theme.devices.large} {
    width: 10%;
  }
`;

const Stations = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 1.6rem;
  margin-top: 0.4rem;
  @media ${(props) => props.theme.devices.large} {
    width: 35%;
    margin-top: 0rem;
  }
`;

const Passengers = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  text-align: left;

  @media ${(props) => props.theme.devices.large} {
    width: 30%;
    margin-top: 0;
  }
`;

const Amount = styled.div`
  width: 40%;
  text-align: left;
  margin-top: 0.6rem;

  @media ${(props) => props.theme.devices.large} {
    width: 10%;
    text-align: right;
    margin-top: 0;
  }
`;

const Tickets = styled.div`
  width: 40%;
  text-align: left;
  margin-top: 0.6rem;

  @media ${(props) => props.theme.devices.large} {
    width: 10%;
    text-align: right;
    margin-top: 0;
  }
`;

const Expand = styled.div`
  width: 20%;
  text-align: right;
  margin-top: 0.6rem;
  @media ${(props) => props.theme.devices.large} {
    width: 5%;
    margin-top: 0;
  }
`;

export const TicketHeader = ({ ariaLabel, transaction, selected, expired, onToggleTicket }: TicketHeaderProps) => {
  const { translate } = useI18n();
  const purchaseDate = transaction.purchaseDate.formateDateToNumberString();

  const formattedAriaLabel = `${ariaLabel} ${translate('DATE_OF_PURCHASE')} ${purchaseDate} ${
    transaction.fromStationName
  } ${translate('TO')} ${transaction.toStationName} ${translate('PASSENGERS')} ${transaction.ticketList}`;

  return (
    <TicketHeaderContainer selected={selected} useActiveColor={selected && !expired} onClick={onToggleTicket}>
      <HiddenSpan>{formattedAriaLabel}</HiddenSpan>
      <PurchaseDate>
        <TxtMediumMediumDarkResp aria-hidden={true}>{purchaseDate}</TxtMediumMediumDarkResp>
      </PurchaseDate>
      <Stations>
        {transaction.campaignInfo ? (
          <TxtMediumBoldDarkResp aria-hidden={true}>{transaction.campaignInfo.title}</TxtMediumBoldDarkResp>
        ) : (
          <>
            <MarginRight margin={0.8}>
              <TxtMediumBoldDarkResp aria-hidden={true}>{transaction.fromStationName}</TxtMediumBoldDarkResp>
            </MarginRight>
            <MarginRight margin={0.8}>
              <ChevronRight color={selected && !expired ? 'white' : 'dark'} width={1} height={1} />
            </MarginRight>
            <TxtMediumBoldDarkResp aria-hidden={true}>{transaction.toStationName}</TxtMediumBoldDarkResp>
          </>
        )}
      </Stations>

      <Passengers>
        <TxtSmallMediumMediumResp aria-hidden={true}>{transaction.ticketList}</TxtSmallMediumMediumResp>
      </Passengers>
      <Amount>
        <TxtSmallMediumMediumResp aria-hidden={true}>{`kr. ${Number(transaction.price)},-`}</TxtSmallMediumMediumResp>
      </Amount>
      <Tickets aria-hidden={true}>
        <NumberOfTickets
          transaction={transaction}
          displayCompactVersion={true}
          displaySelectedColor={selected && !expired}
        />
      </Tickets>
      <Expand aria-hidden={true}>
        {(!expired && !selected) || expired ? (
          <BtnExpandDark expanded={selected} />
        ) : (
          <BtnExpandWhite expanded={selected} />
        )}
      </Expand>
    </TicketHeaderContainer>
  );
};
