import { GOADate } from 'utils/date/GOADate';
import { EntitleMentType } from 'types/EntitlementTypes';

export type Consent = {
  consentCode: string;
  approvedByUser: boolean;
  id: string;
};

export type UserType = {
  firstName?: string;
  surname?: string;
  email?: string;
  account?: boolean;
  keep?: boolean;
  tokenGoOps: string;
  token: string;
  refreshToken?: string;
  refreshTokenGoOps: GOADate;
  refreshTokenExpirationGoOps: GOADate;
  expiration: GOADate;
  refreshTokenExpiration?: GOADate;
  countryCode?: string;
  nationality?: string;
  telephoneNumberNoCountryCode?: string;
  consents?: Consent[];
  customerEntitlements?: EntitleMentType[];
};

export const SET_USER = 'SET_USER';

type SetUser = {
  type: typeof SET_USER;
  payload: UserType;
};

export type UserActionTypes = SetUser;
