import { GOADate } from 'utils/date/GOADate';

export type RecurringCardType = {
  recurringPaymentId: number;
  recurringStatus: string;
  nickname: string;
  expiresAt: GOADate;
  maskedPan: string;
  paymentType: string;
  cardExpiresAt: GOADate;
  primary: boolean;
};

// Set Recurring card
// =========================
export const SET_RECURRING_CARD = 'SET_RECURRING_CARD';

type SetRecurringCard = {
  type: typeof SET_RECURRING_CARD;
  payload: RecurringCardType;
};

export type RecurringCardActionTypes = SetRecurringCard;
