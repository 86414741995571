import styled, { css } from 'styled-components';

export const fontXSmallStatic = css`
  font-size: 1.2rem;
`;
export const fontSmallStatic = css`
  font-size: 1.4rem;
`;
export const fontMediumStatic = css`
  font-size: 1.6rem;
`;
export const fontLargeStatic = css`
  font-size: 1.8rem;
`;
export const fontXLargeStatic = css`
  font-size: 2rem;
`;

// FONT-SIZES
export const fontSizeMegaResp = css`
  font-size: 3.6rem;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 5.2rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    font-size: 6rem;
  }
`;

export const fontSizeXLargeResp = css`
  font-size: 2.8rem;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 3rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    font-size: 3.4rem;
  }
`;

export const fontSizeLargeResp = css`
  font-size: 2.2rem;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 2.2rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    font-size: 2.4rem;
  }
`;

export const fontSizeMediumResp = css`
  font-size: 1.8rem;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 1.8rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    font-size: 2rem;
  }
`;

export const fontSizeDefaultResp = css`
  font-size: 1.6rem;
  line-height: 1.4;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 1.6rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    font-size: 1.8rem;
  }
`;

export const fontSizeSmallResp = css`
  font-size: 1.4rem;
  line-height: 1.4;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 1.6rem;
  }
`;

export const fontSizeXSmallResp = css`
  font-size: 1.2rem;

  @media ${(props) => props.theme.breakpoints.large} {
    font-size: 1.4rem;
  }
`;

// FONT-FAMILY
export const fontFamilyRegular = css`
  font-family: ${(props) => props.theme.fonts.fontFamily.regular};
`;

export const fontFamilyMedium = css`
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};
`;

export const fontFamilyBold = css`
  font-family: ${(props) => props.theme.fonts.fontFamily.bold};
`;

export const fontFamilyItalic = css`
  font-family: ${(props) => props.theme.fonts.fontFamily.italic};
`;

// COLORS
export const fontColorDark = css`
  color: ${(props) => props.theme.newcolors.txtColDark};
`;

export const fontColorLight = css`
  color: ${(props) => props.theme.newcolors.txtColLight};
`;

export const fontColorMedium = css`
  color: ${(props) => props.theme.newcolors.txtColMedium};
`;

export const fontColorError = css`
  color: ${(props) => props.theme.newcolors.txtColError};
`;

export const fontColorAccent = css`
  color: ${(props) => props.theme.newcolors.txtColorAccent};
`;

export const fontColorBrown = css`
  color: ${(props) => props.theme.newcolors.txtColBrown};
`;

export const fontColorDarkGrey = css`
  color: ${(props) => props.theme.newcolors.txtColDarkGrey};
`;

export const fontStyleParagraph = css`
  ${fontSizeMediumResp}
  ${fontFamilyRegular}
  ${fontColorDark}
  line-height: 1.6;
`;

export const fontStylePride = css`
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// COMBINED-STYLES RESPONSIVE (size, family, color)
export const TxtXSmallRegularLightResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyRegular}
  ${fontColorLight}
`;

export const TxtXSmallRegularDarkResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyRegular}
  ${fontColorDark}
`;

export const TxtXSmallMediumDarkResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtXSmallBoldLightResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyBold}
  ${fontColorLight}
`;

export const TxtXSmallBoldDarkResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtXSmallMediumMediumResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyMedium}
  ${fontColorMedium}
`;

export const TxtXSmallBoldErrorResp = styled.span`
  ${fontSizeXSmallResp}
  ${fontFamilyMedium}
  ${fontColorError}
`;

export const TxtSmallRegularDarkResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyRegular}
  ${fontColorDark}
`;

export const TxtSmallBoldDarkResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtSmallBoldErrorResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyBold}
  ${fontColorError}
`;

export const TxtSmallBoldAccentResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyBold}
  ${fontColorAccent}
`;

export const TxtSmallMediumDarkResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtSmallMediumLightResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorLight}
`;

export const TxtSmallMediumMediumResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorMedium}
`;

export const TxtSmallMediumErrorResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorError}
`;

export const TxtSmallMediumAccentResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorAccent}
`;

export const TxtSmallBoldLightResp = styled.span`
  ${fontSizeSmallResp}
  ${fontFamilyBold}
  ${fontColorLight}
`;

export const TxtDefaultRegularDarkResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyRegular}
  ${fontColorDark}
`;

export const TxtDefaultRegularErrorResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyRegular}
  ${fontColorError}
`;

export const TxtDefaultMediumDarkResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtDefaultMediumLightResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorLight}
`;

export const TxtDefaultMediumAccentResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorAccent}
`;

export const TxtDefaultMediumMediumResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorMedium}
`;

export const TxtDefaultBoldDarkResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtDefaultBoldAccentResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorAccent}
`;

export const TxtDefaultBoldMediumResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorMedium}
`;

export const TxtDefaultBoldErrorResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorError}
`;

export const TxtDefaultMediumErrorResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorError}
`;

export const TxtDefaultBrownResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyRegular}
  ${fontColorBrown}
`;

export const TxtDefaultBoldBrownResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorBrown}
`;

export const TxtDefaultDarkGreyResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyRegular}
  ${fontColorDarkGrey}
`;

export const TxtMediumRegularLightResp = styled.span`
  ${fontSizeMediumResp}
  ${fontFamilyRegular}
  ${fontColorLight}
`;

export const TxtMediumRegularDarkResp = styled.span`
  ${fontSizeMediumResp}
  ${fontFamilyRegular}
  ${fontColorDark}
`;

export const TxtMediumBoldDarkResp = styled.span`
  ${fontSizeMediumResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtMediumBoldLightResp = styled.span`
  ${fontSizeMediumResp}
  ${fontFamilyBold}
  ${fontColorLight}
`;

export const TxtMediumMediumDarkResp = styled.span`
  ${fontSizeMediumResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtMediumBoldDarkStatic = styled.span`
  ${fontMediumStatic}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtMediumBoldLightStatic = styled.span`
  ${fontMediumStatic}
  ${fontFamilyBold}
  ${fontColorLight}
`;

export const TxtLargeRegularDarkStatic = styled.span`
  ${fontLargeStatic}
  ${fontFamilyRegular}
  ${fontColorDark}
`;

export const TxtLargeMediumDarkStatic = styled.span`
  ${fontLargeStatic}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtLargeBoldDarkResp = styled.span`
  ${fontSizeLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtLargeBoldLightResp = styled.span`
  ${fontSizeLargeResp}
  ${fontFamilyBold}
  ${fontColorLight}
`;

export const TxtLargeMediumDarkResp = styled.span`
  ${fontSizeLargeResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtXLargeBoldDarkResp = styled.span`
  ${fontSizeXLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const TxtXLargeMediumDarkResp = styled.span`
  ${fontSizeXLargeResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtMegaMediumDarkResp = styled.span`
  ${fontSizeMegaResp}
  ${fontFamilyMedium}
  ${fontColorDark}
`;

export const TxtMediumBrownResp = styled.span`
  ${fontSizeDefaultResp}
  ${fontFamilyMedium}
  ${fontColorBrown}
`;

export const visuallyHidden = css`
  width: 0.1rem;
  height: 0.1rem;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -0.1rem;
  padding: 0;
  overflow: hidden;
  position: absolute;
`;

export const HiddenSpan = styled.span`
  ${visuallyHidden};
`;

export const PageTitleLarge = styled.h1`
  ${fontSizeMegaResp}
  ${fontFamilyBold}
  ${fontColorDark}
  margin-bottom: 3.2rem;
`;

export const PageTitleSmall = styled.h1`
  ${fontSizeXLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
  margin-bottom: 3.2rem;
`;

export const SectionTitleLarge = styled.h2`
  ${fontSizeXLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
  margin-bottom: 2.4rem;

  @media ${(props) => props.theme.breakpoints.large} {
    margin-bottom: 3.2rem;
  }
`;

export const SectionTitleSmall = styled.h2`
  ${fontSizeLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
  margin-bottom: 1.6rem;
`;

export const SectionTitleSmallError = styled(SectionTitleSmall)`
  ${fontColorError}
`;

export const DialogTitle = styled.h2`
  ${fontFamilyBold}
  ${fontColorDark}
  font-size: 3rem;
  margin-bottom: 2.4rem;
`;

export const DialogErrorTitle = styled(DialogTitle)`
  ${fontColorError}
`;

export const ParagraphText = styled.p`
  ${fontStyleParagraph}
`;

export const ParagraphTextItalic = styled(ParagraphText)`
  ${fontFamilyItalic}
`;

export const Premable = styled.p`
  ${fontSizeLargeResp}
  ${fontFamilyMedium}
  ${fontColorDark}
  line-height: 1.56;
`;

export const PlannedTime = styled(TxtLargeBoldDarkResp)``;
export const DelayedTime = styled(TxtLargeBoldDarkResp)`
  text-decoration: line-through;
  opacity: 0.5;
`;

export const TxtDiscount = styled(TxtDefaultBoldDarkResp)`
  text-decoration: line-through;
  opacity: 0.5;
`;
