import React from 'react';
import styled from 'styled-components';
import { useStateNavigation } from 'hooks/useStateNavigation';
import {
  fontSizeSmallResp,
  fontSizeXLargeResp,
  fontSizeDefaultResp,
  fontStyleParagraph,
  fontFamilyBold,
  fontColorDark,
} from 'elements/new-design/Typography';
import { IconLink } from 'elements/new-design/links/Links';
import { FlexEnd } from 'elements/containers/Containers';
import { MarginTop } from 'elements/distance/Margins';

type ThreeColumnProps = {
  boxes: any;
};
const ThreeColumnContainer = styled.section`
  width: 100%;
  display: grid;
  grid-gap: 3.6rem;
  grid-template-columns: 100%;
  grid-template-rows: 1fr, 1fr, 1fr;

  @media ${(props) => props.theme.breakpoints.large} {
    grid-gap: 2.4rem;
    grid-template-columns: repeat(3, 1fr);
  }

  article {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 20rem;

    @media ${(props) => props.theme.breakpoints.small} {
      height: 25rem;
    }

    @media ${(props) => props.theme.breakpoints.medium} {
      height: 30rem;
    }

    @media ${(props) => props.theme.breakpoints.large} {
      height: 17rem;
      grid-gap: 2.4rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Title = styled.h2`
  ${fontSizeXLargeResp}
  ${fontFamilyBold}
  ${fontColorDark}
  
  @media ${(props) => props.theme.breakpoints.large} {
    ${fontSizeDefaultResp}
  }
`;

const Text = styled.p`
  flex: 1;
  margin-bottom: 1.6rem;
  margin-top: 0.8rem;
  line-height: 1.6;
  ${fontStyleParagraph}

  @media ${(props) => props.theme.breakpoints.large} {
    ${fontSizeSmallResp}
  }
`;

const getUrl = (box) =>
  box.linkInternal
    ? box.linkInternal.parentLink
      ? `/${box.linkInternal.parentLink.current}/${box.linkInternal.slug.current}`
      : `/${box.linkInternal.slug.current}`
    : box.linkExternal;

export const ThreeColumn = ({ boxes }: ThreeColumnProps) => {
  const { navigateWithState } = useStateNavigation();
  return (
    <ThreeColumnContainer>
      {boxes.map((box, index: number) => {
        return (
          <article
            key={index}
            onClick={() =>
              box.linkExternal ? window.open(box.linkExternal, '_blank') : navigateWithState(getUrl(box))
            }
          >
            <div>
              <img src={`${box.image.url}?w=600`} alt={box.image.alt} />
              {box.title ? (
                <MarginTop margin={0.8}>
                  <Title>{box.title}</Title>
                </MarginTop>
              ) : null}
              <Text>{box.text}</Text>
            </div>

            <FlexEnd>
              <IconLink
                url={box.linkExternal ? box.linkExternal : getUrl(box)}
                external={box.linkExternal}
                color="dark"
              />
            </FlexEnd>
          </article>
        );
      })}
    </ThreeColumnContainer>
  );
};
