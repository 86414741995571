import styled from 'styled-components';
import ExclamationIcon from 'assets/svg/exclamation-mark.svg';

export const ErrorIcon = styled.span`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  left: -0.7rem;
  background: ${(props) => props.theme.colors.error} url(${ExclamationIcon}) center;
  background-size: 1.2rem;
  z-index: ${(props) => props.theme.constants.zIndexLow};
`;
