import React, { useRef } from 'react';
import styled from 'styled-components';
import { MenuLinksType } from 'types/CmsTypes';
import { FloatingMenuContainer } from 'elements/new-design/Containers';
import { LinkList } from 'components/new-design/lists/LinkList';
import { ExpandChevronBtn } from 'elements/new-design/buttons/expand-buttons';

type ExpandableSubMenuProps = {
  link: MenuLinksType | { title: string; slug: null; subRows: { title: string; slug: string | null }[] };
  margin: string;
  active?: boolean;
  expanded: string;
  defaultSelected?: number;
  onToggleMenu: (id: string) => void;
  onRowClick: (parentId: string, id: string) => void;
};

const FloatingMenuOuter = styled.div`
  position: static;

  @media ${(props) => props.theme.breakpoints.medium} {
    position: relative;
  }
`;

export const HeaderFloatingMenu = ({ ...props }: ExpandableSubMenuProps) => {
  const { link, margin, expanded, defaultSelected, onToggleMenu, onRowClick } = props;
  const buttonRef = useRef<HTMLButtonElement>();

  const handleCloseList = () => {
    buttonRef.current?.focus();
    onToggleMenu(null);
  };

  const handleRowClick = (parent: string, name: string) => {
    onRowClick(parent, name);
    handleCloseList();
  };

  return (
    <FloatingMenuOuter>
      <ExpandChevronBtn
        small
        ref={buttonRef}
        text={link.title}
        expanded={expanded === link.title}
        popupType="menu"
        onButtonClick={() => onToggleMenu(link.title)}
      />

      {expanded === link.title && (
        <FloatingMenuContainer role="menu" margin={margin}>
          <LinkList
            link={link}
            expanded={expanded}
            belongsToColumn={0}
            activeColumn={0}
            defaultSelected={defaultSelected}
            onToggleMenu={onToggleMenu}
            onRowClick={handleRowClick}
            onTabKey={handleCloseList}
          />
        </FloatingMenuContainer>
      )}
    </FloatingMenuOuter>
  );
};
