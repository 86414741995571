import React from 'react';
import styled from 'styled-components';
import { FormFieldType } from 'types/FormTypes';
import { ErrorIcon } from './InputErrorIcon';

type FormGroupListProps = {
  field: FormFieldType;
  error: boolean;
  column: boolean;
  children: JSX.Element | JSX.Element[];
};

type StyleProps = {
  error: boolean;
  column: boolean;
};

export const FormGroupList = styled.ul`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: grid;
  grid-gap: 1.2rem;
  position: relative;
  grid-auto-flow: ${(props) => (props.column === true ? 'row' : 'column')};
  justify-content: flex-start;
  align-items: center;
  padding: 1.2rem;
  list-style: none;
  border-radius: 0.8rem;
  background: ${(props) => (props.error ? props.theme.colors.bgError : props.theme.colors.bgSecondary)};
  border: ${(props) => (props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary)};

  li {
    width: fit-content;
  }
`;
export const FormGroupContainer = ({ field, error, column, children }: FormGroupListProps) => (
  <FormGroupList id={field.id} error={error} column={column} aria-labelledby={`${field.id}Label ${field.id}Error`}>
    {Boolean(error) && <ErrorIcon />}
    {children}
  </FormGroupList>
);
