import React, { useEffect, createRef } from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { DialogActionContent } from 'components/new-design/dialogs/DialogActionContent';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { CodeReferenceBox } from 'elements/CodeReferenceBox';
import { MarginBottom } from 'elements/distance/Margins';

type AssistanceSuccessProps = {
  caseNumber?: string;
  onButtonClick: () => void;
};

export const AssistanceSuccess = ({ caseNumber, onButtonClick }: AssistanceSuccessProps) => {
  const { translate } = useI18n();
  const buttonRef = createRef<HTMLButtonElement>();
  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  return (
    <DialogActionContent
      headline={translate('ASSISTANCE_ORDER_SUCCESS_HEADLINE')}
      text={translate('ASSISTANCE_ORDER_SUCCESS_TEXT')}
      loading={false}
    >
      <MarginBottom margin={1.6} />
      <CodeReferenceBox title={translate('ASSISTANCE_ORDER_CASE_NUMBER')} code={caseNumber} />
      <MarginBottom margin={2.4} />
      <BorderButtonDark big ref={buttonRef} onClick={onButtonClick}>
        {translate('LABEL_CLOSE_MODAL')}
      </BorderButtonDark>
    </DialogActionContent>
  );
};
