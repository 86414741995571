import React from 'react';
import styled from 'styled-components';
import { FlexRow } from 'elements/containers/Containers';
import { LogoGoAhead } from 'elements/new-design/Logos';

const HeaderWebviewLogo = styled(LogoGoAhead)`
  width: 12rem;
  height: 3.5rem;
`;

const HeaderWebviewContainer = styled.div`
  width: 100%;
  height: 7.5rem;
  padding: 2rem 2.4rem;
`;

//Header used when mobile apps opens link in webview
export const HeaderWebview = () => {
  return (
    <HeaderWebviewContainer>
      <FlexRow>
        <HeaderWebviewLogo />
      </FlexRow>
    </HeaderWebviewContainer>
  );
};
