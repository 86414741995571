import { useSelector } from 'react-redux';
import { navigate, getQueryParams } from 'hookrouter';
import { AppState } from 'store/index';
import { isProtectedRoute, isKeepCachedOrderRoute, isPurchaseFlowTokenRoute, isEncodedRoute } from 'utils/Navigation';
import { encodeState, decodeState, cleanState } from 'utils/Navigation';
import { usePersistentStorage } from './usePersistentStorage';
import { useUpdateState } from './useUpdateState';
import { Constants } from 'utils/Constants';
//import { useAuthentication } from 'hooks/useAuthentication';

export const useStateNavigation = () => {
  const stations = useSelector((state: AppState) => state.locations.stopPlaces);
  const order = useSelector((state: AppState) => state.order);
  const user = useSelector((state: AppState) => state.user);
  const { setOrderState } = useUpdateState();
  const { cacheItem, getCachedItem, removeCachedItem } = usePersistentStorage();

  const cacheRedirect = (route: string): void => cacheItem(Constants.CACHE_REDIRECT, route);
  const getCachedRedirect = (): string => getCachedItem(Constants.CACHE_REDIRECT);
  const removeRedirectFromCache = (): void => removeCachedItem(Constants.CACHE_REDIRECT);
  const getParams = () => getQueryParams();
  const navigateWithParams = (route: string, params: Record<string, unknown>) => {
    return navigate(route, true, params);
  };

  const handleNavigation = (route: string, fromPreloader: boolean) => {
    const state = decodeState(window.location, stations);
    const cleaned = fromPreloader ? Object.assign(order, state) : cleanState(route, Object.assign(order));
    setOrderState(cleaned);
    if (isEncodedRoute(route)) {
      navigate(route, false, { state: encodeState(cleaned) });
    } else {
      //Detecting back button click
      window.onpopstate = () => {
        if (window.location.pathname === Constants.ROUTE_JOURNEY) {
          window.location.reload();
        } else {
          navigateWithState(window.location.pathname, false);
        }
      };

      //Prevent safari mem-cache
      window.onpageshow = (e: PageTransitionEvent): void => {
        if (e.persisted) window.location.reload();
      };

      navigate(route);
    }
  };

  const cleanCachedData = (route: string) => {
    if (route === Constants.ROUTE_BASE) removeRedirectFromCache();
    if (!isKeepCachedOrderRoute(route)) removeCachedItem(Constants.CACHE_ORDER);
  };

  const navigateWithState = (route: string, fromPreloader = null): void => {
    if (isProtectedRoute(route) && !user?.account) return navigate(Constants.ROUTE_BASE);
    if (fromPreloader && route === Constants.ROUTE_RETURN_JOURNEY) return navigate(Constants.ROUTE_JOURNEY);
    if (fromPreloader && isPurchaseFlowTokenRoute(route) && !order.orderID) return navigate(Constants.ROUTE_BASE);

    cleanCachedData(route);
    return handleNavigation(route, fromPreloader);
  };

  return {
    navigateWithParams,
    getParams,
    navigateWithState,
    cacheRedirect,
    getCachedRedirect,
    removeRedirectFromCache,
  };
};
