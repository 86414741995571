export type Message = {
  type: string;
  title: string;
  message?: string;
  closable: boolean;
  icon?: string;
};

export type Messages = Message[];
export type MessagesState = Messages;

// Set Messages
// =========================
export const SET_MESSAGES = 'SET_MESSAGES';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const REMOVE_ALL_MESSAGES = 'REMOVE_ALL_MESSAGES';

type SetMessagesAction = {
  type: typeof SET_MESSAGES;
  payload: Message;
};

type RemoveMessageAction = {
  type: typeof REMOVE_MESSAGE;
  payload: number;
};

type RemoveAllMessagesAction = {
  type: typeof REMOVE_ALL_MESSAGES;
};

export type MessagesActionTypes = SetMessagesAction | RemoveMessageAction | RemoveAllMessagesAction;
