import React from 'react';
import { CONFIRMATION_EMAIL } from './services/AssistanceService';
import { fieldNames } from './services/AssistanceFormData';
import { FormType, FormFieldType } from 'types/FormTypes';
import { DropDown } from 'elements/forms/DropDown';
import { FormGroup } from 'elements/forms/FormGroup';
import { TextArea } from 'elements/forms/TextArea';
import { PhoneInputCountryNorway } from 'elements/forms/TextInput';
import { LuggageCount } from './LuggageCount';

type AssistanceFormProps = {
  form: FormType;
  expanded: string;
  fromStation: any;
  toStation: any;
  onInputChange: (field: FormFieldType, value: string | number) => void;
  onInputBlur: (field: FormFieldType) => void;
  onToggleDropdown: (fieldId: string) => void;
};

export const AssistanceForm = ({ ...props }: AssistanceFormProps) => {
  const { form, expanded, fromStation, toStation, onInputChange, onInputBlur, onToggleDropdown } = props;
  return (
    <form noValidate>
      {Array.from(form.values()).map((field: FormFieldType, index: number) => (
        <React.Fragment key={index}>
          {field.type === 'dropdown' && (
            <DropDown
              field={field}
              expanded={expanded === field.id}
              required={field.validationRules.includes('isRequired')}
              onInputChange={onInputChange}
              onToggle={onToggleDropdown}
            />
          )}
          {field.element === 'formGroup' && <FormGroup field={field} onInputChange={onInputChange} />}
          {field.type === 'textarea' &&
            field.id !== fieldNames.fromStationInfo &&
            field.id !== fieldNames.toStationInfo && (
              <TextArea field={field} onInputChange={onInputChange} onInputBlur={onInputBlur} />
            )}
          {field.type === 'textarea' &&
            field.id === fieldNames.fromStationInfo &&
            form.get(fieldNames.assistanceLocation).value.includes(fromStation.id) && (
              <TextArea field={field} onInputChange={onInputChange} onInputBlur={onInputBlur} />
            )}
          {field.type === 'textarea' &&
            field.id === fieldNames.toStationInfo &&
            form.get(fieldNames.assistanceLocation).value.includes(toStation.id) && (
              <TextArea field={field} onInputChange={onInputChange} onInputBlur={onInputBlur} />
            )}
          {field.type === 'counter' && <LuggageCount field={field} onInputChange={onInputChange} />}
          {field.type === 'tel' && form.get(fieldNames.confirmationMethod).value !== CONFIRMATION_EMAIL && (
            <PhoneInputCountryNorway field={field} onInputChange={onInputChange} onInputBlur={onInputBlur} />
          )}
        </React.Fragment>
      ))}
    </form>
  );
};
