import React from 'react';
import styled from 'styled-components';
import { CmsPageType } from 'types/CmsTypes';
import { PortableText } from '@portabletext/react';
import { BodyPortableText } from 'components/new-design/portable-texts/BodyPortableText';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { CampaignBox } from 'components/new-design/cms/campaigns/CampaignBox';
import { SmallContainer, LargeTopContainer, LargeWidthLargeTop } from 'components/new-design/layouts/LayoutContainers';
import { Premable } from 'elements/new-design/Typography';
import { StandaloneImage } from 'components/new-design/cms/StandaloneImage';

type ArticlePageProps = {
  pageData: CmsPageType;
};

const MainImageContainer = styled(LargeWidthLargeTop)`
  padding: 0;
`;

export const ArticlePage = ({ pageData }: ArticlePageProps): JSX.Element => {
  return (
    <BreadcrumbsPage title={pageData.title} loading={false}>
      <SmallContainer>
        <Premable>{pageData.preamble}</Premable>
      </SmallContainer>

      {pageData.referencedCampaign && (
        <LargeTopContainer>
          <SmallContainer>
            <CampaignBox layout="row" position="center" boxData={pageData.referencedCampaign} />
          </SmallContainer>
        </LargeTopContainer>
      )}

      {pageData.mainImage && pageData.mainImage.displayOnPage != false && (
        <MainImageContainer>
          <StandaloneImage imageData={pageData.mainImage} />
        </MainImageContainer>
      )}
      <PortableText value={pageData.body} components={BodyPortableText} />
    </BreadcrumbsPage>
  );
};
