import { Config } from 'utils/Config';

export const Constants = {
  ERROR_MESSAGE: 'error',
  WARNING_MESSAGE: 'warning',
  DEFAULT_MESSAGE: 'default',
  INFO_MESSAGE: 'info',
  DEVIATION_MESSAGE: 'deviation',
  ADULT: 'ADULT',
  INFANT: 'INFANT',
  CHILD: 'CHILD',
  SENIOR: 'SENIOR',
  STUDENT: 'STUDENT',
  MILITARY: 'MILITARY',
  ATTENDANT: 'ATTENDANT',
  BICYCLE: 'BICYCLE',
  BICYCLE_UPPERCASE_START: 'Bicycle',
  SMALL_ANIMAL: 'SMALL_ANIMAL',
  ANIMAL: 'ANIMAL',
  FAMILY: 'FAMILY',
  SERVICEDOG: 'SERVICEDOG',
  NOPETSALLOWED: 'nopetsallowed',
  GOA_NOPETSALLOWED: 'GOA:SupplementProduct:NoPetsAllowed',
  GOA_FAMILY: 'GOA:SupplementProduct:FamilyArea',
  GOA_SMALL_ANIMAL: 'GOA:SupplementProduct:SmallPet',
  GOA_ANIMAL: 'GOA:SupplementProduct:LargePet',
  GOA_PUSH_CHAIR: 'GOA:SupplementProduct:Stroller',
  GOA_BICYCLE: 'GOA:SupplementProduct:BikeReservation',
  GOA_BICYCLE_NON_RESERVABLE: 'GOA:SupplementProduct:NonReservableBicycle',
  GOA_SLEEPER: 'GOA:SupplementProduct:Sleeper',
  GOA_SLEEPER_BERTH: 'GOA:SupplementProduct:SleeperBerth',
  GOA_SLEEPER_CHILD: 'GOA:SupplementProduct:AddChild',
  GOA_USERPROFILE_INFANT: 'GOA:UserProfile:Infant',
  GOA_USERPROFILE_ADULT: 'GOA:UserProfile:Adult',
  GOA_USERPROFILE_STUDENT: 'GOA:UserProfile:Student',
  GOA_USERPROFILE_CHILD: 'GOA:UserProfile:Child',
  GOA_USERPROFILE_SENIOR: 'GOA:UserProfile:Senior',
  GOA_USERPROFILE_MILITARY: 'GOA:UserProfile:Military',
  KOL_USERPROFILE_INFANT: 'KOL:UserProfile:Infant',
  KOL_USERPROFILE_ADULT: 'KOL:UserProfile:Adult',
  KOL_USERPROFILE_STUDENT: 'KOL:UserProfile:Student',
  KOL_USERPROFILE_CHILD: 'KOL:UserProfile:Child',
  KOL_USERPROFILE_SENIOR: 'KOL:UserProfile:Senior',
  KOL_USERPROFILE_MILITARY: 'KOL:UserProfile:Military',
  GOA_TICKET_SMS: 'GOA_TICKET_SMS',
  GOA_INFO_SMS: 'GOA_INFO_SMS',
  GOA_INFO_EMAIL: 'GOA_INFO_EMAIL',
  GOA_OFFERS: 'GOA_OFFERS',
  KOL_SINGLE_TICKET_ID: 'KOL:PreassignedFareProduct:Enkeltbillett',
  KOL_SINGLE_TICKET_NAME: 'Kolumbus enkeltbillett',
  GOA_SINGLE_TICKET_ID: 'GOA:PreassignedFareProduct:SingleTicket',
  GOA_SINGLE_TICKET_NAME: 'Enkeltbillett',
  GOA_SINGLE_NONFLEX_TICKET_ID: 'GOA:PreassignedFareProduct:StandardNonFlexible',
  GOA_SINGLE_FLEX_TICKET_ID: 'GOA:PreassignedFareProduct:StandardFlexible',
  GOA_SINGLE_NONFLEX_TICKET_NAME: 'Enkeltbillett',
  LINE_ID_ARENDAL: 'GOA:Line:53',
  LINE_ID_KOL: 'GOA:Line:59',
  LINE_ID_REGION: 'GOA:Line:50',
  ORDER_TYPE_DEFAULT: 'orderDefault',
  CONSENT_SMS: 'sms',
  KOL: 'KOL',
  GOA: 'GOA',
  GAN: 'GA',
  GO_AHEAD_ENTUR_WEB: 'goahead-entur Web',
  PRODUCT_STANDARD: 'Standard',
  PRODUCT_COMFORT: 'Komfort',
  PRODUCT_COMFORT_NIGHT: 'Komfort Natt',
  PRODUCT_SLEEP: 'Sove',
  PRODUCT_FAMILY: 'Familie',
  PRODUCT_REST: 'Hvile',
  PRODUCT_NOPETSALLOWED: 'Dyrefri',
  PRODUCT_EXTRA: 'Ekstra',
  OFFER_FLEKS: 'Fleks',
  OFFER_LOW_PRICE: 'Lavpris',
  OFFER_FAST: 'fast',
  OFFER_FULLFLEX: 'fullFleks',
  PERSONAL_INTEGRITY_CMS_SLUG: 'personvern',
  TRANSPORT_CONDITIONS_CMS_SLUG: 'transportvilkar',
  REFUND_INFO_SLUG: 'endring-refusjon-og-gebyrer',
  ROUTE_CMS_TRANSPORT_CONDITIONS: '/transportvilkar',
  ROUTE_CMS_PERSONAL_INTEGRITY: '/personvern',
  ROUTE_CMS_ALL_LINES: '/trafikk-ruter-linjer/vare-linjer',
  ROUTE_CMS_REFUNDINFO: '/reiseinformasjon/endring-refusjon-og-gebyrer',
  ROUTE_CMS_CUSTOMER_SERVICE: '/om-go-ahead-nordic/kundeservice',
  ROUTE_CMS_ABOUT: '/om-oss',
  ROUTE_CMS_ABOUT_SLUG: '/om-oss/:slug',
  ROUTE_CMS_LEGAL: '/vilkar-og-personvern',
  ROUTE_CMS_LEGAL_OLD: '/om-go-ahead-nordic',
  ROUTE_CMS_LEGAL_SLUG_OLD: '/om-go-ahead-nordic/:slug',
  ROUTE_CMS_LEGAL_SLUG_NEW: '/vilkar-og-personvern/:slug',
  ROUTE_CMS_WELCOME_ONBOARD_SLUG: '/velkommen-om-bord/:slug',
  ROUTE_CMS_TRAVEL_INFO_OLD_SLUG: '/reiseinfo/:slug',
  ROUTE_CMS_TRAVEL_INFO_NEW_SLUG: '/reiseinformasjon/:slug',
  ROUTE_CMS_TRAVEL_INFO: '/reiseinformasjon',
  ROUTE_CMS_TRAVEL_INFO_OLD: '/reiseinfo',
  ROUTE_OLD_PASSENGER_CATEGORY: '/reiseinfo/passasjererkategorier',
  ROUTE_CMS_WELCOME_ONBOARD: '/velkommen-om-bord',
  ROUTE_CMS_TRAFFIC_INFO: '/trafikk-ruter-linjer',
  ROUTE_CMS_TRAFFIC_INFO_SLUG: '/trafikk-ruter-linjer/:slug',
  ROUTE_DEVIATIONS: '/trafikk-ruter-linjer/trafikkmeldinger',
  ROUTE_CMS_ARTICLE_SLUG: '/aktuelt/:slug',
  ROUTE_CMS_ARTICLES: '/aktuelt',
  ROUTE_CMS_PREVIEW_SLUG: '/preview/:slug',
  ROUTE_CMS_PREVIEW: '/preview',
  ROUTE_CMS_STRAWBERRY: '/trafikk-ruter-linjer/strawberry',
  ROUTE_SEATMAP: `${Config().seatMap}`,
  ROUTE_BASE: '/',
  ROUTE_PLANNER: '/planner',
  ROUTE_PROFILE_LANDING: '/profile-landing',
  ROUTE_JOURNEY: '/journey',
  ROUTE_RETURN_JOURNEY: '/return-journey',
  ROUTE_REVIEW: '/review',
  ROUTE_PAYMENT: '/payment',
  ROUTE_ACCESSABILITY: '/tilgjengelighet',
  ROUTE_CREATE_PROFILE: '/create-profile',
  ROUTE_EDIT_PROFILE: '/edit-profile',
  ROUTE_CHANGE_PASSWORD: '/change-password',
  ROUTE_PROFILE_DATA: '/profile-data',
  ROUTE_DELETE_PROFILE: '/profile-data/delete-profile',
  ROUTE_MY_JOURNEYS: '/my-journeys',
  ROUTE_LOGIN_PROCEED: '/login-proceed',
  ROUTE_MY_CARDS: '/my-cards',
  ROUTE_RESPONSE_PARAM: '/response/:type',
  ROUTE_RESPONSE: '/response',
  ROUTE_NETS_REDIRECT: '/nets-redirect',
  ROUTE_VIPPS_REDIRECT: '/vipps-redirect',
  ROUTE_MOBILE_APP_INFO: '/om-mobilappene',
  CREATE_PROFILE_SUCCESS: 'create-profile-success',
  CREATE_PROFILE_ERROR: 'create-profile-error',
  EDIT_PROFILE_ERROR: 'edit-profile-error',
  FORGOT_PASSWORD_SUCCESS: 'forgot-password-success',
  CHANGE_PASSWORD_ERROR: 'forgot-password-error',
  DELETE_PROFILE_SUCCESS: 'delete-profile-success',
  DELETE_PROFILE_ERROR: 'delete-profile-error',
  OLD_CACHE_USER: 'GOA:USER',
  OLD_CACHE_TEMP: 'GOA:TEMPTOKEN',
  CACHE_USER: 'GOA:ACC',
  CACHE_REDIRECT: 'GOA:REDIRECT',
  CACHE_ORDER: 'GOA:ORDER',
  CACHE_PAYMENT: 'GOA:PAYMENT',
  CACHE_SETTINGS: 'GOA:SETTINGS',
  CACHE_SEARCHED_STATIONS: 'GOA:SEARCHED_STATIONS',
  TICKET: 'billett',
  COLLECT_ON_TRAIN: 'TRAIN',
  COLLECT_ON_STATION: 'STATION',
  COLLECT_AS_PDF: 'PDF',
  VIPPS: 'VIPPS',
  VISA: 'VISA',
  NICKNAME: 'nickname',
  NORWEGIAN_COUNTRY_CODE: '+47',
  NORWAY_ALPHA3_CODE: 'NOR',
  ISNORWEGIAN: 'NOR',
  DEVICE_XSMALL: 'xsmall',
  DEVICE_SMALL: 'small',
  DEVICE_MEDIUM: 'medium',
  DEVICE_LARGE: 'large',
  DEVICE_XLARGE: 'xlarge',
  DEVICE_LAPTOP: 'laptop',
  DEVICE_MEGA: 'mega',
  BANENOR_PHONE_NUMBER: '47 77 00 98',
  BANENOR_PHONE_LINK: 'tel: +4747770098',
  BANENOR_CUSTOMER_SERVICE_EMAIL: 'kundesenter@banenor.no',
  GO_AHEAD_PHONE_NUMBER: '61 25 80 00',
  GO_AHEAD_PHONE_LINK: 'tel: +4761258000',
  GO_AHEAD_CUSTOMER_SERVICE_EMAIL: 'kundeservice@go-aheadnordic.no',
  GO_AHEAD_GROUP_SERVICE_EMAIL: 'gruppereiser@go-aheadnordic.no',
  GO_AHEAD_WEBPAGE: 'www.go-aheadnordic.no',
  GO_AHEAD_WEBPAGE_URL: 'https://www.go-aheadnordic.no',
  GO_AHEAD_PROD_HOST_1: 'billetter.go-aheadnordic.no',
  GO_AHEAD_PROD_HOST_2: 'go-aheadnordic.no',
  GO_AHEAD_PROD_HOST_3: 'go-ahead.no',
  GO_AHEAD_PROD_HOST_4: 'gan.no',
  GO_AHEAD_QA_HOST: 'goaliveqa.api.go-aheadnordic.no',
  GO_AHEAD_DEV_HOST: 'test.goalive.go-aheadnordic.no',
  GO_AHEAD_LOCAL_HOST: '127.0.0.1:8080',
  ENTUR_WEB: 'https://entur.no',
  LEG_MODE_RAIL: 'rail',
  LEG_MODE_BUS: 'bus',
  LEG_MODE_FOOT: 'foot',
  LEG_SUB_MODE_BUS: 'railReplacementBus',
  LEG_SUB_MODE_NIGHT_TRAIN: 'nightRail',
  JOURNEY_DELAY: 'delay',
  LEG_SITUATION: 'situation',
  LEG_INFORMATION: 'information',
  SEVERITY_NORMAL: 'normal',
  SEVERITY_SEVERE: 'severe',
  REGION_TRAIN: 'Sørtoget Region',
  SELECTABLE_DAYS: 90,
  RETURN_JOURNEY: 'return',
  DEPARTURE_JOURNEY: 'departure',
  REBOOK: 'rebook',
  RECURRING: 'recurring',
  MY_CARDS_ORIGIN: 'myCardsOrigin',
  PAYMENT_CARD: 'PaymentCard',
  NETS_REDIRECT_ORIGIN: 'netsRedirectOrigin',
  GOA_SEARCH_HISTORY: 'searchHistory',
  PAYMENT_STATUS_CANCEL: 'CANCELLED',
  PAYMENT_STATUS_CAPTURED: 'CAPTURED',
  PAYMENT_STATUS_REJECTED: 'REJECTED',
  CANCELLED_BY_USER: 'CANCELLEDBYUSER',
  GA_EVENT_PAYMENT: 'PAYMENT',
  GA_EVENT_STATIONS: 'STATIONS',
  GA_EVENT_STATIONS_SALES: 'STATIONS SALES',
  GA_EVENT_LINK_CLICK: 'LINK CLICK',
  GA_EVENT_FORESIGHT_SEARCH: 'FORESIGHT SEARCH',
  GA_EVENT_FORESIGHT_SALES: 'FORESIGHT SALES',
  GTM_EVENTS: {
    ORDER_SUCCESS: 'Successful Order',
    ORDER_CAMPAIGN_SUCCESS: 'Successful Campaign Order',
    CHANGED_SEAT: 'Changed seat',
  },
  STATION: ' stasjon',
  ERROR: 'ERROR',
  ERROR_BAD_REQUEST: 'ERROR_BAD_REQUEST',
  ERROR_LOAD: 'ERROR_LOAD',
  ERROR_UNAUTH: 'ERROR_UNAUTH',
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADING_REQUEST: 'LOADING_REQUEST',
  EXPIRED: 'EXPIRED',
  EMPTY: 'EMPTY',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
  REBOOK_JOURNEY: 'JOURNEY',
  REBOOK_REVIEW: 'REVIEW',
  REBOOK_PAYMENT: 'PAYMENT',
  REBOOK_JOURNEY_FLOW: 'JOURNEY_FLOW',
  REBOOK_SEAT_FLOW: 'SEAT_FLOW',
  SYSTEM_ANDROID: 'Android',
  SYSTEM_IOS: 'iOS',
  ON: 'ON',
  OFF: 'OFF',
  GROUP_JOURNEYS_OVER_NINE: 'https://om.entur.no/gruppereiser/?gad_source=1',
  SANITY: {
    GOA_DEVIATIONS_PAGE: 'Deviations Page',
    GOA_STANDARD_PAGE: 'GOA_STANDARD_PAGE',
    GOA_ARTICLE_PAGE: 'GOA_ARTICLE_PAGE',
    GOA_ARTICLE_LANDING_PAGE: 'Article Landing Page',
    GOA_INFORMATION_PAGE: 'GOA_INFORMATION_PAGE',
    GOA_INFORMATIONAL_TAG: 'GOA_INFORMATIONAL_TAG',
    GOA_SOCIAL_TAG: 'GOA_SOCIAL_TAG',
    GOA_COMPANY_TAG: 'GOA_COMPANY_TAG',
    GOA_LEGAL_TAG: 'GOA_LEGAL_TAG',
    GOA_COOKIE_INFORMATION: 'GOA_COOKIE_INFORMATION',
    GOA_COOKIE_SETTINGS: 'GOA_COOKIE_SETTINGS',
    GOA_PARTNER_PAGE: 'GOA_PARTNER_PAGE',
    GOA_PARTNER_BONUS_PAGE: 'GOA_PARTNER_BONUS_PAGE',
  },
  POPULAR_STATIONS: ['Stavanger', 'Oslo S', 'Kristiansand', 'Drammen', 'Egersund', 'Arendal'],
  PAYMENT_OPTIONS: [
    {
      text: 'Vipps',
      key: 'VIPPS',
    },
    {
      text: 'Visa',
      key: 'VISA',
    },
    {
      text: 'MasterCard',
      key: 'MASTERCARD',
    },
  ],
  PAYMENT_VIPPS: 'VIPPS',
  MONTHS_OF_YEAR: [
    'JANUARY',
    'FEBRUARY',
    'MARS',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ],
  DIALOGS: {
    TO_STATION: 'toStation',
    FROM_STATION: 'fromStation',
    CRITICAL_DEVIATIONS: 'criticalDeviations',
    COOKIE: 'cookie',
  },
  CAMPAIGNS: {
    FLIPP: 'flipp-1',
  },
  CHAT_EN: '//entur.humany.net/ga-chat-eng',
  CHAT_NO: '//entur.humany.net/go-ahead-chat',
  LANG_EN: 'en-GB',
  LANG_NO: 'no',
  LANGUAGES: [
    { key: 'no', title: 'Norsk' },
    { key: 'en-GB', title: 'English' },
  ],
};
