import React from 'react';
import { useDevice } from 'hooks/useDevice';
import { Constants } from 'utils/Constants';
import { TxtSmallBoldAccentResp, TxtSmallBoldDarkResp } from 'elements/new-design/Typography';

type PhoneProps = {
  number?: string;
  text?: string;
};

export const CustomerServicePhone = ({ number, text }: PhoneProps) => {
  const { device } = useDevice();
  const isSmall = device === Constants.DEVICE_SMALL;

  return (
    <>
      {isSmall ? (
        <a href={number ? number : Constants.GO_AHEAD_PHONE_LINK} data-cy="customerServicePhoneCy">
          <TxtSmallBoldAccentResp>{text ? text : Constants.GO_AHEAD_PHONE_NUMBER}</TxtSmallBoldAccentResp>
        </a>
      ) : (
        <TxtSmallBoldDarkResp>{text ? text : Constants.GO_AHEAD_PHONE_NUMBER}</TxtSmallBoldDarkResp>
      )}
    </>
  );
};
