import React from 'react';
import styled from 'styled-components';
import { DefaultPage } from 'components/new-design/layouts/DefaultPage';
import { SidebarSection } from './SidebarSection';
import { PortableText } from '@portabletext/react';
import { BodyPortableText } from 'components/new-design/portable-texts/BodyPortableText';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { CampaignBox } from 'components/new-design/cms/campaigns/CampaignBox';
import { SmallContainer, LargeTopContainer } from 'components/new-design/layouts/LayoutContainers';
import { Premable } from 'elements/new-design/Typography';
import { StandaloneImage } from 'components/new-design/cms/StandaloneImage';

type SidebarPageProps = {
  pageData: any;
  hideBreadCrumbs?: boolean;
};

export const InnerFlexContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${(props) => props.theme.devices.laptop} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const RelativeParent = styled.div`
  position: relative;
`;

export const SidebarPage = ({ pageData, hideBreadCrumbs }: SidebarPageProps): JSX.Element => {
  return (
    <DefaultPage>
      <InnerFlexContainer>
        <RelativeParent>
          <SidebarSection contentPageData={pageData} />
        </RelativeParent>
        <BreadcrumbsPage title={pageData.title} loading={false} hideBreadCrumbs={hideBreadCrumbs}>
          <SmallContainer>
            <Premable>{pageData.preamble}</Premable>
          </SmallContainer>

          {pageData.referencedCampaign && (
            <LargeTopContainer>
              <SmallContainer>
                <CampaignBox layout="row" position="center" boxData={pageData.referencedCampaign} />
              </SmallContainer>
            </LargeTopContainer>
          )}

          {pageData.mainImage && pageData.mainImage.displayOnPage != false && (
            <StandaloneImage imageData={pageData.mainImage} />
          )}
          <PortableText value={pageData.body} components={BodyPortableText} />
        </BreadcrumbsPage>
      </InnerFlexContainer>
    </DefaultPage>
  );
};
