import { LocationType, ProblemCombinationType } from 'types/LocationTypes';
import { Order } from 'types/OrderTypes';
import { Constants } from 'utils/Constants';

const FROM_MESSAGE = 'fromMessageId';
const TO_MESSAGE = 'toMessageId';
export const FROM_STATION = Constants.DIALOGS.FROM_STATION;
export const TO_STATION = Constants.DIALOGS.TO_STATION;
export type FormType = Map<string, StationFieldType>;
export const lists = {
  shortcuts: 'shortcuts',
  earlierSearches: 'earlierSearches',
  searchResult: 'searchresult',
};

export type StationFieldType = {
  name: string;
  label: string;
  value: string;
  placeholder: string;
  error: string;
  stationExists: boolean;
  isValid: boolean;
  serviceMessage: string;
  left: boolean;
};

const getStationFields = () => [
  {
    name: FROM_STATION,
    label: 'FROM',
    value: '',
    error: undefined,
    stationExists: false,
    isValid: false,
    serviceMessage: undefined,
    left: true,
  },
  {
    name: TO_STATION,
    label: 'TO',
    value: '',
    error: undefined,
    stationExists: false,
    isValid: false,
    serviceMessage: undefined,
    left: false,
  },
];

export const StationsSearchService = {
  getForm: (): FormType => {
    const form = new Map();
    getStationFields().forEach((field) => {
      form.set(field.name, field);
    });
    return form;
  },
  populateForm: (form: FormType, order: Order): FormType => {
    const fromField = form.get(FROM_STATION);
    const toField = form.get(TO_STATION);
    fromField.value = order.from ? order.from.name : '';
    toField.value = order.to ? order.to.name : '';
    return form;
  },
  getNoServiceMessages: (
    fromField: string,
    toField: string,
    stopPlaces: LocationType[],
  ): { [index: string]: ProblemCombinationType } => {
    const fromObject = stopPlaces.find((s) => s.name.toLowerCase() === fromField.toLowerCase());
    const toObject = stopPlaces.find((s) => s.name.toLowerCase() === toField.toLowerCase());
    if (!fromObject || !toObject) return null;
    const messages = {};
    if (fromObject.problemCombinations && fromObject.problemCombinations[toObject.id]) {
      if (fromObject.problemCombinations[toObject.id].toMessageId) {
        messages[TO_MESSAGE] = fromObject.problemCombinations[toObject.id].toMessageId;
      }
    }

    if (toObject.problemCombinations && toObject.problemCombinations[fromObject.id]) {
      if (toObject.problemCombinations[fromObject.id].fromMessageId) {
        messages[FROM_MESSAGE] = toObject.problemCombinations[fromObject.id].fromMessageId;
      }
    }

    return Object.keys(messages).length === 0 ? null : messages;
  },
  setServiceMessages: (
    problemCombo: ProblemCombinationType,
    messages: { [index: number]: string },
    form: FormType,
  ): FormType => {
    const fromField = form.get(FROM_STATION);
    const toField = form.get(TO_STATION);
    const toTextNumber = problemCombo[TO_MESSAGE];
    const fromTextNumber = problemCombo[FROM_MESSAGE];

    if (fromTextNumber && toTextNumber) {
      toField.serviceMessage = messages[toTextNumber];
      toField.isValid = false;
    } else if (toTextNumber) {
      toField.serviceMessage = messages[toTextNumber];
      toField.isValid = false;
      fromField.serviceMessage = undefined;
    } else if (fromTextNumber) {
      fromField.serviceMessage = messages[fromTextNumber];
      fromField.isValid = false;
      toField.serviceMessage = undefined;
    }
    return form;
  },
  isFormValid: (form: FormType): boolean => {
    const fromField = form.get(FROM_STATION);
    const toField = form.get(TO_STATION);
    return fromField.isValid && toField.isValid;
  },
  isSameStation: (from: string, to: string): boolean =>
    from && to ? from.trim().toLowerCase() === to.trim().toLowerCase() : false,
  isValidStation: (updated: string, stopPlaces): boolean =>
    Boolean(stopPlaces.find((s) => s.name.toLowerCase() === updated.toLowerCase())),
};
