import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module SeatMap */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Get seatmap for order journey
 * 
 * @param {string} orderId 
 * @param {string} datedServiceJourneyId 
 * @param {string} originId e.g. NSR:StopPlace:337
 * @param {string} destinationId e.g. NSR:StopPlace:596
 * @param {number} seatTypeId 
 * @param {number} trainNumber 
 * @param {number} imageWidth 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.imageFormat=SVG] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRailcar2>} Success
 */
export function SeatMap_GetAvailableSeats(orderId: string, datedServiceJourneyId: string, originId: string, destinationId: string, seatTypeId: number, trainNumber: number, imageWidth: number, options?: SeatMap_GetAvailableSeatsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRailcar2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      orderId
    },
    query: {
      datedServiceJourneyId,
      originId,
      destinationId,
      seatTypeId,
      trainNumber,
      imageWidth,
      imageFormat: options.imageFormat
    }
  }
  return gateway.request(SeatMap_GetAvailableSeatsOperation, parameters)
}

/**
 * @param {string} path 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultErrorDetails1>} Bad Request
 */
export function SeatMap_GetImages(path: string): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultErrorDetails1>> {
  const parameters: api.OperationParamGroups = {
    path: {
      path
    }
  }
  return gateway.request(SeatMap_GetImagesOperation, parameters)
}

export interface SeatMap_GetAvailableSeatsOptions {
  authorization?: string
  imageFormat?: string
}

const SeatMap_GetAvailableSeatsOperation: api.OperationInfo = {
  path: '/rest/SeatMap/available-seats/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const SeatMap_GetImagesOperation: api.OperationInfo = {
  path: '/rest/SeatMap/resources/{path}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
