import { GOADate } from 'utils/date/GOADate';
import { Constants } from 'utils/Constants';
import { SelectedJourneyType } from 'types/JourneyTypes';
import { Order } from 'types/OrderTypes';
import { useAuthentication } from './useAuthentication';
import { getOfferPrice, getOfferProducts, calculatePassengers, formatDistanceKm, getTrainNumbers } from 'utils/Helpers';
import { useCookieConsent } from './useCookieConsent';

const SALES_CHANNEL = 'GOAWEB';

export const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minuters = Math.floor((seconds % 3600) / 60);
  if (hours === 0) return `${minuters}min`;
  return `${hours}t ${minuters}min`;
};

const getForesight = (date: GOADate): number => {
  const diffObject = date.getDiffInDates(new GOADate(), ['hours']);
  const rounded = Math.round(diffObject.hours);
  if (rounded < 0) return 0;
  else if (rounded === 0) return 1;
  else return rounded;
};

export const useGoogleAnalytics = () => {
  const { hasAnalyticConsent } = useCookieConsent();
  const { isAuthenticated } = useAuthentication();
  const getFormattedOrder = (order: Order, journey: SelectedJourneyType) => {
    return {
      event: Constants.GTM_EVENTS.ORDER_SUCCESS,
      salesChannel: SALES_CHANNEL,
      accountUser: isAuthenticated(),
      totalPrice: getOfferPrice(journey.selectedOffer),
      offer: journey.selectedOffer.name,
      product: getOfferProducts(journey.selectedOffer).join(' + '),
      from: journey.selectedJourney.from,
      to: journey.selectedJourney.to,
      numberOfTravellers: calculatePassengers(order.passengers),
      travellers: order.passengers,
      supplements: order.supplements,
      foreSight: getForesight(journey.selectedJourney.plannedDepartureUtc),
      paymentType: order.paymentMethod,
      duration: formatDuration(journey.selectedJourney.duration),
      distance: formatDistanceKm(journey.selectedJourney),
      trainNumbers: getTrainNumbers(journey.selectedJourney.legs),
    };
  };

  const trackOrder = (order: Order): void => {
    if (hasAnalyticConsent()) {
      window['dataLayer'] && window['dataLayer'].push(getFormattedOrder(order, order.journey));
      if (order.returnJourney)
        window['dataLayer'] && window['dataLayer'].push(getFormattedOrder(order, order.returnJourney));
    }
  };

  const track404 = () => {
    return window['dataLayer']?.push({
      event: '404 page not found',
      path: window?.location?.href,
      referrer: document?.referrer,
    });
  };

  const trackCampaignOrder = (order: any): void => {
    if (hasAnalyticConsent()) {
      const formattedOrder = {
        event: Constants.GTM_EVENTS.ORDER_CAMPAIGN_SUCCESS,
        salesChannel: SALES_CHANNEL,
        accountUser: isAuthenticated(),
        product: order.type,
        reportRef: order.reportRef,
        startDateUtc: order.departureDate.toJSON(),
        travellers: order.passengers,
        paymentMethod: order.paymentMethod,
      };
      window['dataLayer'] && window['dataLayer'].push(formattedOrder);
    }
  };

  const trackGtmEvent = (evt: string): void => {
    if (hasAnalyticConsent()) window['dataLayer'] && window['dataLayer'].push({ event: evt });
  };

  return {
    trackOrder,
    trackCampaignOrder,
    track404,
    trackGtmEvent,
  };
};
