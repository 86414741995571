export type GlobalType = {
  orderValid: boolean;
  hasFormChanged: boolean;
  currentDialog: { name: string; color: string; scroll: boolean; redirectTo?: string };
  language: string;
  promptForLogin: boolean;
  pageTitle: string;
};

export const SET_ORDER_VALID = 'SET_ORDER_VALID';
export const SET_HAS_FORM_CHANGED = 'SET_HAS_FORM_CHANGED';
export const SET_CURRENT_DIALOG = 'SET_CURRENT_DIALOG';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PROMPT_LOGIN = 'SET_PROMPT_LOGIN';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

type SetOrderValid = {
  type: typeof SET_ORDER_VALID;
  payload: boolean;
};

type SetHasFormChanged = {
  type: typeof SET_HAS_FORM_CHANGED;
  payload: boolean;
};

type SetCurrentDialog = {
  type: typeof SET_CURRENT_DIALOG;
  payload: object;
};

type SetLanguage = {
  type: typeof SET_LANGUAGE;
  payload: string;
};

type SetPromptLogin = {
  type: typeof SET_PROMPT_LOGIN;
  payload: boolean;
};

type SetPageTitle = {
  type: typeof SET_PAGE_TITLE;
  payload: string;
};

export type GlobalActionTypes =
  | SetOrderValid
  | SetHasFormChanged
  | SetCurrentDialog
  | SetLanguage
  | SetPromptLogin
  | SetPageTitle;
