import { api } from 'client/types';
import { GOADate } from 'utils/date/GOADate';
/// <reference path="types.ts"/>
/** @module Journey */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Get all journeys with filter
 * 
 * @param {object} options Optional options
 * @param {date} [options.dateTimeUtc] 
 * @param {string[]} [options.feat] 
 * @param {string} [options.fromId] 
 * @param {boolean} [options.includeAdditionalOffers] 
 * @param {boolean} [options.includeDeparted] 
 * @param {boolean} [options.includePlannedCancellations] 
 * @param {boolean} [options.includeSupplementProducts] 
 * @param {boolean} [options.isDepartureTime] 
 * @param {string[]} [options.luggage] 
 * @param {string[]} [options.passenger] 
 * @param {string} [options.toId] 
 * @param {number} [options.searchWindow] 
 * @param {number} [options.numTripPatterns] 
 * @param {number} [options.numCompartments] 
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultJourney2>} Success
 */
export function Journey_Get(options?: Journey_GetOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultJourney2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    query: {
      dateTimeUtc: gateway.formatDate(options.dateTimeUtc, 'date-time'),
      feat: gateway.formatArrayParam(options.feat, 'multi', 'feat'),
      fromId: options.fromId,
      includeAdditionalOffers: options.includeAdditionalOffers,
      includeDeparted: options.includeDeparted,
      includePlannedCancellations: options.includePlannedCancellations,
      includeSupplementProducts: options.includeSupplementProducts,
      isDepartureTime: options.isDepartureTime,
      luggage: gateway.formatArrayParam(options.luggage, 'multi', 'luggage'),
      passenger: gateway.formatArrayParam(options.passenger, 'multi', 'passenger'),
      toId: options.toId,
      searchWindow: options.searchWindow,
      numTripPatterns: options.numTripPatterns,
      numCompartments: options.numCompartments
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    }
  }
  return gateway.request(Journey_GetOperation, parameters)
}

/**
 * API returns the journey plan for a certain date time without any offers or products.
 * 
 * @param {object} options Optional options
 * @param {string} [options.fromId] 
 * @param {string} [options.toId] 
 * @param {date} [options.dateTimeUtc] 
 * @param {boolean} [options.isDepartureTime=true] 
 * @param {number} [options.numTripPatterns=5] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultJourneyPlan2>} Success
 */
export function Journey_GetJourneyPlan(options?: Journey_GetJourneyPlanOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultJourneyPlan2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    query: {
      fromId: options.fromId,
      toId: options.toId,
      dateTimeUtc: gateway.formatDate(options.dateTimeUtc, 'date-time'),
      isDepartureTime: options.isDepartureTime,
      numTripPatterns: options.numTripPatterns
    }
  }
  return gateway.request(Journey_GetJourneyPlanOperation, parameters)
}

/**
 * Get earlier departure alternatives for journey leg
 * 
 * @param {string} tripPatternId Id of trip pattern
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>} Success
 */
export function Journey_GetEarlierDeparturesForLeg(tripPatternId: string, legIndex: number, options?: Journey_GetEarlierDeparturesForLegOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      tripPatternId,
      legIndex
    }
  }
  return gateway.request(Journey_GetEarlierDeparturesForLegOperation, parameters)
}

/**
 * Get later departure alternatives for journey leg
 * 
 * @param {string} tripPatternId Id of trip pattern
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponse1>} Success
 */
export function Journey_GetLaterDeparturesForLeg(tripPatternId: string, legIndex: number, options?: Journey_GetLaterDeparturesForLegOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      tripPatternId,
      legIndex
    }
  }
  return gateway.request(Journey_GetLaterDeparturesForLegOperation, parameters)
}

/**
 * Replace part of journey
 * 
 * @param {string} tripPatternId Id of trip pattern
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.serviceJourneyId] 
 * @param {string} [options.fromId] 
 * @param {string} [options.toId] 
 * @param {date} [options.departureTime] 
 * @param {string[]} [options.passenger] 
 * @param {string[]} [options.luggage] 
 * @param {number} [options.numCompartments] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {boolean} [options.includeSleeperProduct] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultJourney1>} Success
 */
export function Journey_ReplaceLegInJourney(tripPatternId: string, legIndex: number, options?: Journey_ReplaceLegInJourneyOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultJourney1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    path: {
      tripPatternId,
      legIndex
    },
    query: {
      serviceJourneyId: options.serviceJourneyId,
      fromId: options.fromId,
      toId: options.toId,
      departureTime: gateway.formatDate(options.departureTime, 'date-time'),
      passenger: gateway.formatArrayParam(options.passenger, 'multi', 'passenger'),
      luggage: gateway.formatArrayParam(options.luggage, 'multi', 'luggage'),
      numCompartments: options.numCompartments,
      includeSleeperProduct: options.includeSleeperProduct
    }
  }
  return gateway.request(Journey_ReplaceLegInJourneyOperation, parameters)
}

/**
 * Get earlier departure alternatives
 * 
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.AlternateDeparturesRequestV2} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>} Success
 */
export function Journey_GetEarlierDepartures(legIndex: number, options?: Journey_GetEarlierDeparturesOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      legIndex
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Journey_GetEarlierDeparturesOperation, parameters)
}

/**
 * Get later departure alternatives
 * 
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.AlternateDeparturesRequestV2} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>} Success
 */
export function Journey_GetLaterDepartures(legIndex: number, options?: Journey_GetLaterDeparturesOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultChangeLegDepartureResponseV21>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      legIndex
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Journey_GetLaterDeparturesOperation, parameters)
}

/**
 * Replace part of journey
 * 
 * @param {number} legIndex Zero-based index of journey legs
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.ReplaceLegRequestV2} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultJourney1>} Success
 */
export function Journey_ReplaceLeg(legIndex: number, options?: Journey_ReplaceLegOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultJourney1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      legIndex
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Journey_ReplaceLegOperation, parameters)
}

export interface Journey_GetOptions {
  dateTimeUtc?: GOADate
  feat?: string[]
  fromId?: string
  includeAdditionalOffers?: boolean
  includeDeparted?: boolean
  includePlannedCancellations?: boolean
  includeSupplementProducts?: boolean
  isDepartureTime?: boolean
  luggage?: string[]
  passenger?: string[]
  toId?: string
  searchWindow?: number
  numTripPatterns?: number
  numCompartments?: number
  authorization?: string
  clientType?: 'Android'|'iOS'|'Web'
}

export interface Journey_GetJourneyPlanOptions {
  fromId?: string
  toId?: string
  dateTimeUtc?: GOADate
  isDepartureTime?: boolean
  numTripPatterns?: number
}

export interface Journey_GetEarlierDeparturesForLegOptions {
  authorization?: string
}

export interface Journey_GetLaterDeparturesForLegOptions {
  authorization?: string
}

export interface Journey_ReplaceLegInJourneyOptions {
  authorization?: string
  serviceJourneyId?: string
  fromId?: string
  toId?: string
  departureTime?: GOADate
  passenger?: string[]
  luggage?: string[]
  numCompartments?: number
  clientType?: 'Android'|'iOS'|'Web'
  includeSleeperProduct?: boolean
}

export interface Journey_GetEarlierDeparturesOptions {
  body?: api.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.AlternateDeparturesRequestV2
}

export interface Journey_GetLaterDeparturesOptions {
  body?: api.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.AlternateDeparturesRequestV2
}

export interface Journey_ReplaceLegOptions {
  body?: api.GoAliveRestApi.Clients.EnTurJourneyV2.JourneyRequest.ReplaceLegRequestV2
}

const Journey_GetOperation: api.OperationInfo = {
  path: '/rest/v2/Journey',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_GetJourneyPlanOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/journeyplan',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_GetEarlierDeparturesForLegOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/{tripPatternId}/legs/{legIndex}/earlier',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_GetLaterDeparturesForLegOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/{tripPatternId}/legs/{legIndex}/later',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_ReplaceLegInJourneyOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/{tripPatternId}/legs/{legIndex}/replace',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_GetEarlierDeparturesOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/legs/{legIndex}/earlier',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_GetLaterDeparturesOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/legs/{legIndex}/later',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Journey_ReplaceLegOperation: api.OperationInfo = {
  path: '/rest/v2/Journey/legs/{legIndex}/replace',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
