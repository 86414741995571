import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from 'utils/Constants';
import { AppState } from 'store/index';
import { useBreakpoints } from './useBreakpoints';

export type HeaderHeightType = {
  headerH: string;
  skipLinkH: string;
};

const getHeight = (hasDeviations: boolean, device: string): HeaderHeightType => {
  if (device === Constants.DEVICE_XLARGE) {
    return {
      headerH: hasDeviations ? '16.8rem' : '12rem',
      skipLinkH: hasDeviations ? '4.8rem' : '12em',
    };
  }
  return {
    headerH: hasDeviations ? '12.3rem' : '7.5rem',
    skipLinkH: hasDeviations ? '4.8rem' : '7.5rem',
  };
};

export const useHeaderHeight = () => {
  const deviations = useSelector((state: AppState) => state.deviations);
  const critical = deviations.filter((deviation) => deviation.messageAlert);
  const { device } = useBreakpoints();
  const [headerHeight, setHeaderHeight] = useState({
    headerH: undefined,
    skipLinkH: undefined,
  });

  useEffect(() => {
    setHeaderHeight(getHeight(Boolean(critical.length), device));
  }, [deviations]);

  useEffect(() => {
    setHeaderHeight(getHeight(Boolean(critical.length), device));
  }, [device]);

  return {
    headerHeight,
  };
};
