import React from 'react';
import { SmallTopContainer } from 'components/new-design/layouts/LayoutContainers';
import {
  TxtXLargeBoldDarkResp,
  TxtLargeBoldDarkResp,
  TxtMediumBoldDarkResp,
  ParagraphText,
  ParagraphTextItalic,
} from 'elements/new-design/Typography';
import { UnorderedList, NumberedList } from 'elements/new-design/cms/List';

//This overrides styles set in BodyPortableText.tsx
export const BoxBodyPortableText = {
  block: {
    h2: ({ children }: any) => (
      <h2>
        <TxtXLargeBoldDarkResp>{children}</TxtXLargeBoldDarkResp>
      </h2>
    ),
    h3: ({ children }: any) => (
      <SmallTopContainer>
        <h3>
          <TxtLargeBoldDarkResp>{children}</TxtLargeBoldDarkResp>
        </h3>
      </SmallTopContainer>
    ),
    h4: ({ children }: any) => (
      <SmallTopContainer>
        <h4>
          <TxtMediumBoldDarkResp>{children}</TxtMediumBoldDarkResp>
        </h4>
      </SmallTopContainer>
    ),
    normal: ({ children }: any) => (
      <SmallTopContainer>
        <ParagraphText>{children}</ParagraphText>
      </SmallTopContainer>
    ),
    blockquote: ({ children }: any) => (
      <SmallTopContainer>
        <blockquote>
          <ParagraphTextItalic>{children}</ParagraphTextItalic>
        </blockquote>
      </SmallTopContainer>
    ),
  },
  list: {
    bullet: ({ children }) => (
      <SmallTopContainer>
        <UnorderedList>{children}</UnorderedList>
      </SmallTopContainer>
    ),
    number: ({ children }) => (
      <SmallTopContainer>
        <NumberedList>{children}</NumberedList>
      </SmallTopContainer>
    ),
  },
};
