import React, { useState } from 'react';
import styled from 'styled-components';
import { useDevice } from 'hooks/useDevice';
import { useI18n } from 'utils/i18n/usei18n';
import { TxtDefaultMediumDarkResp } from 'elements/new-design/Typography';
import { Sidebar } from './Sidebar';
import { BtnExpandDark, TxTButton } from 'elements/buttons/Buttons';

type SidebarSectionProps = {
  contentPageData: any;
};

type StyleProps = {
  expanded: boolean;
};

const PageNavigationDropButton = styled(TxTButton)`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: 5rem;
  border-top: ${(props) => props.theme.constants.borderPrimary};
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  justify-content: space-between;
  padding: 0 3rem;
`;

const DropUp = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 4rem;
`;

export const SidebarSection = ({ contentPageData }: SidebarSectionProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const { translate } = useI18n();
  const { isSmallerThanLaptop } = useDevice();
  const isSmallDevice = isSmallerThanLaptop();

  return (
    <>
      {!isSmallDevice && (
        <Sidebar
          pageTitle={contentPageData.title}
          underTitles={contentPageData.body.filter((item) => item.style === 'h2')}
        />
      )}
      {expanded && isSmallDevice && (
        <DropUp onClick={() => setExpanded(false)}>
          <Sidebar
            pageTitle={contentPageData.title}
            underTitles={contentPageData.body.filter((item) => item.style === 'h2')}
          />
        </DropUp>
      )}
      {isSmallDevice && (
        <PageNavigationDropButton expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <TxtDefaultMediumDarkResp>
            {expanded ? translate('CLOSE_OVERVIEW') : translate('OPEN_OVERVIEW')}
          </TxtDefaultMediumDarkResp>
          <BtnExpandDark expanded={expanded} />
        </PageNavigationDropButton>
      )}
    </>
  );
};
