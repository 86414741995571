import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { InputErrorLabel } from 'elements/new-design/InputFields';

type CheckboxProps = {
  fieldId: string;
  error: string;
};

type StyleProps = {
  error: boolean;
};

const ErrorLabel = styled.div`
  ${(_: StyleProps) => ''}
  height: 3rem;
  visibility: ${(props) => (props.error ? 'visible' : 'hidden')};
`;

export const InputError = ({ fieldId, error }: CheckboxProps) => {
  const { translate } = useI18n();
  return (
    <ErrorLabel error={Boolean(error)}>
      <InputErrorLabel id={`${fieldId}Error`}>{error ? translate(error) : ''}</InputErrorLabel>
    </ErrorLabel>
  );
};
