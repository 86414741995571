import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Ticket */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Adds a specific consent to the order.
 * 
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCaptureResponse1>} Success
 */
export function Ticket_AddConsent(orderId: string, options?: Ticket_AddConsentOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCaptureResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      orderId
    }
  }
  return gateway.request(Ticket_AddConsentOperation, parameters)
}

/**
 * Cancel payment transaction.
 * 
 * @param {number} paymentId 
 * @param {number} transactionId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCancelPaymentTransactionResponse1>} Success
 */
export function Ticket_CancelPaymentTransaction(paymentId: number, transactionId: number, options?: Ticket_CancelPaymentTransactionOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCancelPaymentTransactionResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      paymentId,
      transactionId
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    }
  }
  return gateway.request(Ticket_CancelPaymentTransactionOperation, parameters)
}

/**
 * Complete the payment.
 * 
 * @param {number} paymentId 
 * @param {number} transactionId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCaptureResponse1>} Success
 */
export function Ticket_Capture(paymentId: number, transactionId: number, options?: Ticket_CaptureOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCaptureResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      paymentId,
      transactionId
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    }
  }
  return gateway.request(Ticket_CaptureOperation, parameters)
}

/**
 * Get payment transaction status.
 * 
 * @param {number} paymentId 
 * @param {number} transactionId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultTransaction1>} Success
 */
export function Ticket_GetPaymentTransactionStatus(paymentId: number, transactionId: number, options?: Ticket_GetPaymentTransactionStatusOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultTransaction1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      paymentId,
      transactionId
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    }
  }
  return gateway.request(Ticket_GetPaymentTransactionStatusOperation, parameters)
}

/**
 * Distribute a paid ticket to NOD
 * 
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultOrderResponseTicketDistributionGroupResponse3>} Success
 */
export function Ticket_Distribute(orderId: string, options?: Ticket_DistributeOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultOrderResponseTicketDistributionGroupResponse3>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    query: {
      returnMockedData: options.returnMockedData
    }
  }
  return gateway.request(Ticket_DistributeOperation, parameters)
}

/**
 * Distribute paid tickets to NOD
 * 
 * @param {string[]} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultOrderResponse12>} Success
 */
export function Ticket_DistributeOrders(body: string[], options?: Ticket_DistributeOrdersOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultOrderResponse12>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    query: {
      returnMockedData: options.returnMockedData
    },
    body: {
      body
    }
  }
  return gateway.request(Ticket_DistributeOrdersOperation, parameters)
}

/**
 * Get Qr codes and animations for order
 * 
 * @param {string} orderId Order id
 * @param {string} clientType Enum: Android, iOS, Web. Client type
 * @param {number} appId App id
 * @param {string} appGuid App guid
 * @param {string} deviceId Device id
 * @param {string} authorization 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultGetQrCodesForOrderResponse1>} Success
 */
export function Ticket_GetQrCodesForOrder(orderId: string, clientType: 'Android'|'iOS'|'Web', appId: number, appGuid: string, deviceId: string, authorization: string): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultGetQrCodesForOrderResponse1>> {
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      clientType,
      appId,
      appGuid,
      deviceId,
      authorization
    }
  }
  return gateway.request(Ticket_GetQrCodesForOrderOperation, parameters)
}

/**
 * If the order is canceled by the user while in the Nets webview, a retry can be made with this same API for the same order.
 * 
 * @param {string} orderId Id of order. Returned in callback.
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.callbackUrl] 
 * @param {string} [options.paymentType] Payment type. I.e. VISA, MASTERCARD or VIPPS
 * @param {string} [options.phoneNumber] Phone number for VIPPS payment.
 * @param {string} [options.clientType=Web] Client type. I.e. Web, Android or iOS.
 * @param {number} [options.recurringPaymentId] Id of saved payment.
 * @param {string} [options.ticketDistributionType] Enum: Pdf, Code, App. Distribution type chosen by user. Returned in callback.
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultPaymentResponse1>} Success
 */
export function Ticket_Pay(orderId: string, options?: Ticket_PayOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultPaymentResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      phoneNumber: options.phoneNumber,
      clientType: options.clientType
    },
    query: {
      callbackUrl: options.callbackUrl,
      paymentType: options.paymentType,
      recurringPaymentId: options.recurringPaymentId,
      ticketDistributionType: options.ticketDistributionType
    },
    path: {
      orderId
    }
  }
  return gateway.request(Ticket_PayOperation, parameters)
}

/**
 * @param {string} orderGroupId 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.PickupRequest} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultPickupResponse1>} Success
 */
export function Ticket_Pickup(orderGroupId: string, body: api.GoAliveRestApi.Controllers.ControllerModels.PickupRequest, options?: Ticket_PickupOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultPickupResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      orderGroupId
    },
    query: {
      returnMockedData: options.returnMockedData
    },
    body: {
      body
    }
  }
  return gateway.request(Ticket_PickupOperation, parameters)
}

/**
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.PickupOrderGroupRequest[]} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultPickupResponse2>} Success
 */
export function Ticket_PickupOrderGroups(body: api.GoAliveRestApi.Controllers.ControllerModels.PickupOrderGroupRequest[], options?: Ticket_PickupOrderGroupsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultPickupResponse2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    query: {
      returnMockedData: options.returnMockedData
    },
    body: {
      body
    }
  }
  return gateway.request(Ticket_PickupOrderGroupsOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.orderLineId] 
 * @param {string[]} [options.orderLines] 
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultListCancelRefundResponse2>} Success
 */
export function Ticket_RefundTicket(orderId: string, options?: Ticket_RefundTicketOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultListCancelRefundResponse2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    query: {
      orderLineId: options.orderLineId,
      orderLines: gateway.formatArrayParam(options.orderLines, 'multi', 'orderLines')
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Ticket_RefundTicketOperation, parameters)
}

/**
 * clientType is an enum on back-end. Values are
 *             Web (default)
 *             Android
 *             iOS
 *             App
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum on backend (see description)
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ReservationRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReservationResponse1>} Success
 */
export function Ticket_Reserve(options?: Ticket_ReserveOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReservationResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_ReserveOperation, parameters)
}

/**
 * Gives a list of change options for an order. Change options are options which either comes with a fee which have to be paid for, or free.
 * 
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultChangeOption1>} Success
 */
export function Ticket_ChangeOptions(orderId: string, options?: Ticket_ChangeOptionsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultChangeOption1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Ticket_ChangeOptionsOperation, parameters)
}

/**
 * Returns all information about the order.
 * 
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReviewResponse1>} Success
 */
export function Ticket_ReviewOrder(orderId: string, options?: Ticket_ReviewOrderOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReviewResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Ticket_ReviewOrderOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.SendPdfRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultSendPdfResponse1>} Success
 */
export function Ticket_SendPdf(orderId: string, options?: Ticket_SendPdfOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultSendPdfResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_SendPdfOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.SendPdfRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultListPdfs1>} Success
 */
export function Ticket_SendPdfWithOutAuth(orderId: string, options?: Ticket_SendPdfWithOutAuthOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultListPdfs1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_SendPdfWithOutAuthOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.SendReceiptRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultSendReceiptTicketResponse1>} Success
 */
export function Ticket_SendReceipt(orderId: string, options?: Ticket_SendReceiptOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultSendReceiptTicketResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_SendReceiptOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Clients.EnTurOrder.Sms} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultString1>} Accepted
 */
export function Ticket_SendSms(orderId: string, options?: Ticket_SendSmsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultString1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_SendSmsOperation, parameters)
}

/**
 * Cancel ticket for small children.
 * 
 * @param {string} orderId 
 * @param {string} orderLineId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCancellTicketResonse1>} Success
 */
export function Ticket_CancellTicket(orderId: string, orderLineId: string, options?: Ticket_CancellTicketOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCancellTicketResonse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId,
      orderLineId
    },
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    }
  }
  return gateway.request(Ticket_CancellTicketOperation, parameters)
}

export interface Ticket_AddConsentOptions {
  authorization?: string
}

export interface Ticket_CancelPaymentTransactionOptions {
  authorization?: string
  clientType?: string
}

export interface Ticket_CaptureOptions {
  authorization?: string
  clientType?: string
}

export interface Ticket_GetPaymentTransactionStatusOptions {
  authorization?: string
  clientType?: string
}

export interface Ticket_DistributeOptions {
  authorization?: string
  clientType?: 'Android'|'iOS'|'Web'
  returnMockedData?: boolean
}

export interface Ticket_DistributeOrdersOptions {
  authorization?: string
  clientType?: 'Android'|'iOS'|'Web'
  returnMockedData?: boolean
}

export interface Ticket_PayOptions {
  authorization?: string
  callbackUrl?: string
  /**
   * Payment type. I.e. VISA, MASTERCARD or VIPPS
   */
  paymentType?: string
  /**
   * Phone number for VIPPS payment.
   */
  phoneNumber?: string
  /**
   * Client type. I.e. Web, Android or iOS.
   */
  clientType?: string
  /**
   * Id of saved payment.
   */
  recurringPaymentId?: number
  /**
   * Distribution type chosen by user. Returned in callback.
   */
  ticketDistributionType?: 'Pdf'|'Code'|'App'
}

export interface Ticket_PickupOptions {
  authorization?: string
  returnMockedData?: boolean
}

export interface Ticket_PickupOrderGroupsOptions {
  authorization?: string
  returnMockedData?: boolean
}

export interface Ticket_RefundTicketOptions {
  orderLineId?: string
  orderLines?: string[]
  authorization?: string
}

export interface Ticket_ReserveOptions {
  authorization?: string
  /**
   * Enum on backend (see description)
   */
  clientType?: string
  body?: api.GoAliveRestApi.Controllers.ControllerModels.ReservationRequest
}

export interface Ticket_ChangeOptionsOptions {
  authorization?: string
}

export interface Ticket_ReviewOrderOptions {
  authorization?: string
}

export interface Ticket_SendPdfOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Controllers.ControllerModels.SendPdfRequest
}

export interface Ticket_SendPdfWithOutAuthOptions {
  body?: api.GoAliveRestApi.Controllers.ControllerModels.SendPdfRequest
}

export interface Ticket_SendReceiptOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Controllers.ControllerModels.SendReceiptRequest
}

export interface Ticket_SendSmsOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Clients.EnTurOrder.Sms
}

export interface Ticket_CancellTicketOptions {
  authorization?: string
  clientType?: string
}

const Ticket_AddConsentOperation: api.OperationInfo = {
  path: '/rest/Ticket/consent/{orderId}',
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_CancelPaymentTransactionOperation: api.OperationInfo = {
  path: '/rest/Ticket/capture/payment/{paymentId}/transaction/{transactionId}/cancel',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_CaptureOperation: api.OperationInfo = {
  path: '/rest/Ticket/capture/payment/{paymentId}/transaction/{transactionId}',
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_GetPaymentTransactionStatusOperation: api.OperationInfo = {
  path: '/rest/Ticket/capture/payment/{paymentId}/transaction/{transactionId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_DistributeOperation: api.OperationInfo = {
  path: '/rest/Ticket/distribute/{orderId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_DistributeOrdersOperation: api.OperationInfo = {
  path: '/rest/Ticket/distribute',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_GetQrCodesForOrderOperation: api.OperationInfo = {
  path: '/rest/Ticket/qrCodes/{orderId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_PayOperation: api.OperationInfo = {
  path: '/rest/Ticket/pay/{orderId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_PickupOperation: api.OperationInfo = {
  path: '/rest/Ticket/pickup/{orderGroupId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_PickupOrderGroupsOperation: api.OperationInfo = {
  path: '/rest/Ticket/pickup',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_RefundTicketOperation: api.OperationInfo = {
  path: '/rest/Ticket/refund/{orderId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_ReserveOperation: api.OperationInfo = {
  path: '/rest/Ticket/reserve',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_ChangeOptionsOperation: api.OperationInfo = {
  path: '/rest/Ticket/ChangeOptions/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_ReviewOrderOperation: api.OperationInfo = {
  path: '/rest/Ticket/review/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_SendPdfOperation: api.OperationInfo = {
  path: '/rest/Ticket/pdf/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_SendPdfWithOutAuthOperation: api.OperationInfo = {
  path: '/rest/Ticket/pdf-noauth/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_SendReceiptOperation: api.OperationInfo = {
  path: '/rest/Ticket/receipt/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_SendSmsOperation: api.OperationInfo = {
  path: '/rest/Ticket/sms/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_CancellTicketOperation: api.OperationInfo = {
  path: '/rest/Ticket/cancell/orderid{orderId}/orderLineId/{orderLineId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
