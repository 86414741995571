import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDevice } from 'hooks/useDevice';
import { useUpdateState } from 'hooks/useUpdateState';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { GOADate } from 'utils/date/GOADate';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

// Types
// =========================

type DateTimeInputProps = {
  name: string;
  date: GOADate;
  minDate: GOADate;
  maxDate: GOADate;
  isReturnDate: boolean;
  onDateTimeChange: (d: GOADate, isReturnDate: boolean) => void;
};

// Elements
// =========================

const DateTimeInputContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 5.4rem;
  margin-top: 1.5rem;
  justify-content: center;
`;

// Component
// =========================
export const DateTimeInput = ({
  name,
  date,
  minDate,
  maxDate,
  isReturnDate,
  onDateTimeChange,
}: DateTimeInputProps): JSX.Element => {
  const { device } = useDevice();
  const { setDialog } = useUpdateState();
  const { currentDialog } = useSelector((store: AppState) => store.global);
  const dateRef = useRef<HTMLButtonElement>(null);
  const timeRef = useRef<HTMLButtonElement>(null);

  const dateId = `${name}ToggleDate`;
  const timeId = `${name}ToggleTime`;

  const handleDateChanged = (d: GOADate): void => {
    const today = new GOADate();
    let newDate = undefined;
    if (d.isSameDay(today)) newDate = d.setHours(today.getHours()).setMinutes(today.getMinutes());
    else newDate = d.setHours(0).setMinutes(0);
    onDateTimeChange(newDate, isReturnDate);
    dateRef.current?.focus();
    setDialog(undefined);
  };

  const handleTimeChanged = (d: GOADate, close: boolean = null): void => {
    const newTime = date.setHours(d.getHours()).setMinutes(d.getMinutes());
    onDateTimeChange(newTime, isReturnDate);
    if (close) {
      timeRef.current?.focus();
      setDialog(undefined);
    }
  };

  const handleToggle = (id: string) => {
    if (currentDialog === id) setDialog(undefined);
    else setDialog({ name: id, color: 'transparent', scroll: true });
  };

  return (
    <>
      {date && (
        <>
          <DateTimeInputContainer>
            <DatePicker
              name={name}
              date={date}
              minDate={minDate}
              maxDate={maxDate}
              device={device}
              ref={dateRef}
              expanded={currentDialog?.name === dateId}
              onToggle={() => handleToggle(dateId)}
              handleDateChanged={handleDateChanged}
            />

            <TimePicker
              name={name}
              date={date}
              minDate={minDate}
              ref={timeRef}
              expanded={currentDialog?.name === timeId}
              onToggle={() => handleToggle(timeId)}
              handleTimeChanged={handleTimeChanged}
            />
          </DateTimeInputContainer>
        </>
      )}
    </>
  );
};
