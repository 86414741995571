import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Campaigns */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Get active campaigns from sanity
 */
export function Campaigns_GetActiveCampaignsAsync(): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultIEnumerableCampaign2>> {
  return gateway.request(Campaigns_GetActiveCampaignsAsyncOperation)
}

/**
 * Reserves a campaign on given date
 * 
 * @param {string} clientType Enum: Android, iOS, Web. 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ReserveCampaignRequest} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReviewResponse1>} Success
 */
export function Campaigns_ReserveCampaignAsync(clientType: 'Android'|'iOS'|'Web', body: api.GoAliveRestApi.Controllers.ControllerModels.ReserveCampaignRequest, options?: Campaigns_ReserveCampaignAsyncOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReviewResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType
    },
    body: {
      body
    }
  }
  return gateway.request(Campaigns_ReserveCampaignAsyncOperation, parameters)
}

export interface Campaigns_ReserveCampaignAsyncOptions {
  authorization?: string
}

const Campaigns_GetActiveCampaignsAsyncOperation: api.OperationInfo = {
  path: '/rest/v2/Campaigns/active',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Campaigns_ReserveCampaignAsyncOperation: api.OperationInfo = {
  path: '/rest/v2/Campaigns/reserve',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
