import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { StationFieldType } from './StationSearchService';
import { MyPositionNearestStation } from '../my-position/MyPositionNearestStation';
import { ClearFieldBtn } from 'elements/new-design/buttons/icon-buttons';
import { InputLabel, InputPlaceholderLabel, TxtPlaceHolderCSS } from 'elements/new-design/InputFields';
import { FlexRow } from 'elements/containers/Containers';

type StationInputProps = {
  field: StationFieldType;
  positionActive: boolean;
  fieldInFocus: boolean;
  neighbourInFocus: boolean;
  displaySwopStations: boolean;
  stopPlaces: boolean;
  activeDecendant: string;
  onFieldFocus: (field: string) => void;
  onFieldChange: (name: string, value: string) => void;
  onClearField: (name: string) => void;
  onBlurField: () => void;
  onPositionChange: (name: string, value: string) => void;
};

type StyleProps = {
  hidden?: boolean;
  left?: boolean;
  focused?: boolean;
  neighbourFocused?: boolean;
  value?: string;
  displaySwop?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
};

const getBorders = (props: StyleProps) => {
  if (props.left) {
    return css`
      border-top-right-radius: ${!props.displaySwop && 0};
      border-bottom-right-radius: ${(!props.displaySwop || (props.displaySwop && props.focused)) && 0};
      border-bottom-left-radius: ${props.focused ? 0 : '3rem'};
      border-right: ${props.neighbourFocused && !props.displaySwop && 0};
    `;
  }
  return css`
    border-top-left-radius: ${!props.displaySwop && 0};
    border-bottom-left-radius: ${(!props.displaySwop || (props.displaySwop && props.focused)) && 0};
    border-bottom-right-radius: ${props.focused ? 0 : '3rem'};
    border-left: ${!props.displaySwop && !props.focused && 0};
  `;
};

const Input = styled.input`
  ${(_: StyleProps) => ''}
  ${TxtPlaceHolderCSS}
  width: 100%;
  border: ${(props) =>
    props.focused || props.neighbourFocused
      ? props.theme.newconstants.borderDarkThick
      : props.theme.newconstants.borderDarkThin};
  border-bottom: ${(props) => !props.left && props.focused && 0};
  border-bottom: ${(props) => props.left && 0};
  border-top: ${(props) => !props.left && props.theme.newconstants.borderDarkThin};
  border-top-right-radius: ${(props) => (props.left ? '1rem' : '0')};
  border-top-left-radius: ${(props) => (props.left ? '1rem' : '0')};
  border-bottom-left-radius: ${(props) => (props.left || props.focused ? '0' : '1rem')};
  border-bottom-right-radius: ${(props) => (props.left || props.focused ? '0' : '1rem')};
  height: ${(props) => (props.focused || props.value !== '' ? '8rem' : '6rem')};
  padding: ${(props) => (props.focused || props.value !== '' ? '2rem 9rem 0 2.4rem' : '0 9rem 0 2.4rem')};
  background: ${(props) => (props.focused ? props.theme.newcolors.bgDarkLight : props.theme.newcolors.bgPrimary)};

  @media ${(props) => props.theme.breakpoints.large} {
    height: 6rem;
    padding: ${(props) => (props.focused ? '0 9rem 0 2.3rem' : '0 9rem 0 2.4rem')};
    border-radius: 3rem;
    border: ${(props) => props.left && props.theme.newconstants.borderDarkThin};
    border: ${(props) =>
      props.focused ? props.theme.newconstants.borderDarkThick : props.theme.newconstants.borderDarkThin};
    ${(props) => getBorders(props)};
    background: ${(props) => props.theme.newcolors.bgPrimary};
  }
`;

const HiddenLabelContainer = styled(FlexRow)`
  ${(_: StyleProps) => ''}
  position: absolute;
  top: 1.6rem;
  left: 2.4rem;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};

  @media ${(props) => props.theme.breakpoints.large} {
    position: static;
    height: 4rem;
    margin-left: 2.4rem;
  }
`;

const StationSmallLabel = styled(InputLabel)`
  font-size: 1.6rem;
`;

const Placeholder = styled(InputPlaceholderLabel)`
  ${(_: StyleProps) => ''}
  position: absolute;
  top: 1.8rem;
  left: 2.4rem;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};

  @media ${(props) => props.theme.breakpoints.large} {
    top: 5.8rem;
  }
`;

const ActionButtons = styled.span`
  ${(_: StyleProps) => ''}
  position: absolute;
  top: ${(props) => (!props.left && props.value !== '' ? '3.6rem' : '1.8rem')};
  right: 2rem;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 1.2rem;
  }
  button:last-of-type {
    margin-right: 0;
  }

  @media ${(props) => props.theme.breakpoints.large} {
    top: 5.8rem;
  }
`;

export const StationInput = React.forwardRef(
  ({ ...props }: StationInputProps, ref: React.Ref<HTMLInputElement>): JSX.Element => {
    const {
      field,
      positionActive,
      fieldInFocus,
      neighbourInFocus,
      displaySwopStations,
      stopPlaces,
      activeDecendant,
      onFieldFocus,
      onFieldChange,
      onClearField,
      onBlurField,
      onPositionChange,
    } = props;
    const [fetchingPosition, setFetchingPosition] = useState(false);
    const { translate } = useI18n();

    useEffect(() => {
      if (fetchingPosition && field.left) onFieldFocus(field.name);
    }, [fetchingPosition]);

    return (
      <>
        <HiddenLabelContainer hidden={(!fieldInFocus && !field.value) || fetchingPosition} onClick={onBlurField}>
          <StationSmallLabel>{translate(field.label)}</StationSmallLabel>
        </HiddenLabelContainer>
        <Input
          id={field.name}
          name={field.name}
          type="text"
          required
          ref={ref}
          left={field.left}
          focused={fieldInFocus}
          neighbourFocused={neighbourInFocus}
          displaySwop={displaySwopStations}
          value={field.value}
          spellCheck="false"
          autoComplete="off"
          aria-controls={`${field.name}ListBox`}
          aria-activedescendant={activeDecendant}
          aria-live="polite"
          aria-labelledby="stationFieldLabel"
          onFocus={() => onFieldFocus(field.name)}
          onChange={(e) => onFieldChange(e.target.name, e.target.value)}
        />

        <Placeholder hidden={!fetchingPosition || (fetchingPosition && field.value !== '')}>
          {translate('FETCHING_YOUR_POSITION')}
        </Placeholder>

        <Placeholder id="stationFieldLabel" hidden={fieldInFocus || field.value !== '' || fetchingPosition}>
          {translate(field.label)}
        </Placeholder>

        <ActionButtons left={field.left} value={field.value}>
          {field.left && stopPlaces && (
            <MyPositionNearestStation
              positionActive={positionActive}
              onFetchingPosition={(fetching) => setFetchingPosition(fetching)}
              onPositionChange={(stationName: string) => onPositionChange(field.name, stationName)}
              onError={() => (field.left ? onFieldFocus(field.name) : null)}
            />
          )}
          {field.value && (
            <ClearFieldBtn hiddenTxt={translate('DELETE_STATION')} onButtonClick={() => onClearField(field.name)} />
          )}
        </ActionButtons>
        <HiddenLabelContainer hidden={true}></HiddenLabelContainer>
      </>
    );
  },
);
