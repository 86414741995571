/// <reference path="../types.ts"/>
// Auto-generated, edits will be overwritten
const spec: api.OpenApiSpec = {
  'host': 'test.goalive.go-aheadnordic.no',
  'schemes': [
    'https'
  ],
  'basePath': '',
  'contentTypes': [],
  'accepts': [
    'application/json'
  ],
  'securityDefinitions': {
    'Bearer': {
      'type': 'apiKey',
      'name': 'Authorization',
      'in': 'header'
    }
  }
}
export default spec
