import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { FlexRow } from 'elements/containers/Containers';
import { LinkUnderline, LinkMediumBoldResp } from 'elements/new-design/links/Links';
import { ClockIconDark } from 'elements/new-design/icons/SymbolIcons';
import { ExpandChevronBtn } from 'elements/new-design/buttons/expand-buttons';

type IsTrainOnTimeLinkProp = {
  type?: string;
  expanded?: boolean;
  onButtonClick?: () => void;
};

type StyleProps = {
  type?: string;
};

const IconLink = styled(LinkUnderline)`
  margin-bottom: 1px;

  :hover {
    margin: 0;
  }
`;

const ClockIcon = styled.div`
  ${(_: StyleProps) => ''}
  margin-top: ${(props) => (props.type === 'button' ? '-0.6rem' : '-1rem')};
  margin-right: 0.8rem;
`;

export const IsTrainOnTimeLink = ({ type, expanded, onButtonClick }: IsTrainOnTimeLinkProp): JSX.Element => {
  const { translate } = useI18n();

  return (
    <FlexRow>
      <ClockIcon type={type}>
        <ClockIconDark width={2.4} height={2.4} />
      </ClockIcon>
      {type === 'button' ? (
        <ExpandChevronBtn
          onButtonClick={onButtonClick}
          text={translate('IS_TRAIN_ON_TIME_PREAMBLE')}
          expanded={expanded}
          popupType="dialog"
        />
      ) : (
        <IconLink href={Constants.ROUTE_DEVIATIONS} color="dark">
          <LinkMediumBoldResp>{translate('IS_TRAIN_ON_TIME')}</LinkMediumBoldResp>
        </IconLink>
      )}
    </FlexRow>
  );
};
