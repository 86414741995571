import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useApi } from 'hooks/use-api/useApi';
import { useUpdateState } from 'hooks/useUpdateState';
import { useI18n } from 'utils/i18n/usei18n';
import { handleDateConversion, replaceTypesInDataStructure } from 'utils/Helpers';
import { AppState } from 'store/index';
import { Constants } from 'utils/Constants';
import { WarningIconDark } from 'elements/new-design/icons/SymbolIcons';
import { ExpandIcon } from 'elements/new-design/icons/Chevrons';
import { BasePageMargin } from 'elements/new-design/Containers';
import { GeneralDeviation } from 'components/new-design/deviations/GeneralDeviation';
import { TxtMediumBoldDarkStatic } from 'elements/new-design/Typography';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginBottom, MarginRight } from 'elements/distance/Margins';
import { FlexRow } from 'elements/containers/Containers';
import { Config } from 'utils/Config';
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import { ModifiedDeviationMessage } from 'types/DeviationTypes';

type CriticalMessagesProps = {
  expanded: string;
  onToggleMenu: (name: string) => void;
  onMenuClick: (slug: string) => void;
};

const CriticalMessagesContainer = styled(BasePageMargin)`
  display: flex;
  align-items: center;
  position: relative;
  background: ${(props) => props.theme.newcolors.warningDark};
`;

const MessageHeader = styled(FlexRow)`
  width: 100%;
  height: 4.8rem;
`;

const BorderBottom = styled.span`
  max-width: 80%;
  display: flex;
  align-items: center;
  border-bottom: ${(props) => props.theme.newconstants.borderTransparantThick};
`;

const MessageToggleBtn = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  :focus-visible,
  :hover {
    box-shadow: none;

    ${BorderBottom} {
      border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    }
  }

  ${TxtMediumBoldDarkStatic} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.8rem;
  }
`;

const ExpandedDeviations = styled(BasePageMargin)`
  max-height: 95vh;
  position: absolute;
  top: 4.8rem;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.newconstants.zIndexMega};
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background: ${(props) => props.theme.newcolors.bgPrimary};
  overflow-y: auto;

  ul {
    width: 100%;
    list-style: none;

    @media ${(props) => props.theme.breakpoints.xlarge} {
      width: 70%;
    }
  }
`;

const SOCKET_URL = Config().deviationConnectionUrl;

export const CriticalDeviationsBanner = ({ ...props }: CriticalMessagesProps) => {
  const deviations = useSelector((state: AppState) => state.deviations);
  const { currentDialog } = useSelector((store: AppState) => store.global);
  const [connection, setConnection] = useState(null);
  const [bannerMessages, setBannerMessages] = useState([]);
  const { API_CALLS, requestGoOps } = useApi();
  const { setCriticalMessages } = useUpdateState();
  const { translate } = useI18n();

  const listenForMessages = async () => {
    await connection
      .start()
      .then(() => {
        connection.on('BroadcastCritical', (messages: ModifiedDeviationMessage[]) => {
          //If messages are updated, closed och opened after initial connect, all valid messages are broadcasted to client
          //Replace types is needed because signal R enpoints is not run through generate-api, see package.json yarn api script

          // After Implementing GoOps signalR, we need to account for new object being sent with a state
          let temp = [...deviations];
          if (messages[0].state === 'Modified') {
            const index = temp.findIndex((item) => item.situationId === messages[0].key);
            if (index === -1) temp.unshift(messages[0].value);
            else temp[index] = messages[0].value;
          }
          if (messages[0].state === 'Removed') temp = temp.filter((item) => item.situationId !== messages[0].key);

          setCriticalMessages(replaceTypesInDataStructure(temp));
        });
      })
      .catch(() => {
        setCriticalMessages([]);
        connection.stop().then(() => setConnection(null));
      });
  };

  const openConnection = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(SOCKET_URL)
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  };

  const init = async () => {
    try {
      const { data } = await requestGoOps(API_CALLS.GET_CRITICAL_GOOPS_DEVIATIONS);
      handleDateConversion(data.result);
      setCriticalMessages(data.result);
      openConnection();
    } catch (error) {
      setCriticalMessages([]);
    }
  };

  useEffect(() => {
    if (connection) listenForMessages();
  }, [connection]);

  useEffect(() => {
    const critical = deviations.filter((deviation) => deviation.messageAlert);

    if (currentDialog?.name === Constants.DIALOGS.CRITICAL_DEVIATIONS && critical.length === 0)
      props.onToggleMenu(Constants.DIALOGS.CRITICAL_DEVIATIONS);
    setBannerMessages(critical);
  }, [deviations]);

  useEffect(() => {
    init();

    return () => {
      setCriticalMessages([]);
      if (connection) connection.stop().then(() => setConnection(null));
    };
  }, []);

  return (
    <>
      {bannerMessages.length > 0 && (
        <CriticalMessagesContainer>
          <MessageHeader>
            <MessageToggleBtn
              aria-label={`${bannerMessages[0].messageAlert.no}. ${translate('READ_MORE', 'NO')}`}
              aria-expanded={props.expanded === Constants.DIALOGS.CRITICAL_DEVIATIONS}
              aria-haspopup={'listbox'}
              onClick={() => props.onToggleMenu(Constants.DIALOGS.CRITICAL_DEVIATIONS)}
            >
              <MarginRight margin={1.6}>
                <WarningIconDark width={2.4} height={2.4} />
              </MarginRight>
              <BorderBottom>
                <TxtMediumBoldDarkStatic>
                  {props.expanded === Constants.DIALOGS.CRITICAL_DEVIATIONS
                    ? translate('CRITICAL_DEVIATIONS')
                    : bannerMessages[0].messageAlert.no}
                </TxtMediumBoldDarkStatic>
                <ExpandIcon
                  width={2.5}
                  height={1.2}
                  expanded={props.expanded === Constants.DIALOGS.CRITICAL_DEVIATIONS}
                />
              </BorderBottom>
            </MessageToggleBtn>
          </MessageHeader>

          {props.expanded === Constants.DIALOGS.CRITICAL_DEVIATIONS && (
            <ExpandedDeviations>
              <ul>
                {bannerMessages.map((dev, index) => (
                  <li key={index}>
                    <GeneralDeviation deviation={dev} />
                  </li>
                ))}
              </ul>

              <MarginBottom margin={2.4}>
                <BorderButtonDark onClick={() => props.onMenuClick(Constants.ROUTE_DEVIATIONS)}>
                  {translate('SEE_ALL_DEVIATIONS')}
                </BorderButtonDark>
              </MarginBottom>
            </ExpandedDeviations>
          )}
        </CriticalMessagesContainer>
      )}
    </>
  );
};
