import React from 'react';
import styled, { css } from 'styled-components';
import { getRouteParts } from 'utils/Navigation';
import { useAuthentication } from 'hooks/useAuthentication';
import { useI18n } from 'utils/i18n/usei18n';
import { isProfileRoute } from 'utils/Navigation';
import { Constants } from 'utils/Constants';
import {
  TxtDefaultMediumDarkResp,
  fontSizeDefaultResp,
  fontColorDark,
  fontFamilyBold,
  fontFamilyMedium,
} from 'elements/new-design/Typography';
import { A } from 'hookrouter';

type BreadCrumb = {
  display: string;
  path: string;
};

type StyleProps = {
  disabled: boolean;
};

const BreadCrumbContainer = styled.nav`
  width: 100%;
  margin-bottom: 2.4rem;

  @media ${(props) => props.theme.devices.large} {
    margin-bottom: 3.2rem;
  }

  ol {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
`;

const Link = styled(A)`
  ${(_: StyleProps) => ''}
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  :focus-visible,
  :hover {
    box-shadow: none;
    border-bottom: ${(props) => (props.disabled ? 'none' : props.theme.newconstants.borderDarkThick)};
  }
`;

const BreadCrumbText = styled.span`
  ${(_: StyleProps) => ''}
  ${fontSizeDefaultResp}
  ${fontColorDark}

  ${(props) => {
    if (props.disabled)
      return css`
        ${fontFamilyMedium}
      `;
    return css`
      ${fontFamilyBold}
    `;
  }};

  :first-letter {
    text-transform: capitalize;
  }
`;

const Separator = styled(TxtDefaultMediumDarkResp)`
  margin: 0 0.8rem;
`;

const constructPath = (pathParts: BreadCrumb[], index: number): string => {
  if (index === 0) return pathParts[0].path;
  const paths = pathParts.filter((p) => pathParts.indexOf(p) !== 0 && pathParts.indexOf(p) <= index);
  return paths
    .map((p) => p.path)
    .join('')
    .toString();
};

export const BreadCrumbs = () => {
  const isProfilePath = isProfileRoute(window.location.pathname);
  const { getUser } = useAuthentication();
  const { translate } = useI18n();
  const user = getUser();
  const parent = isProfilePath
    ? { display: `${user.firstName} ${user.surname}`, path: Constants.ROUTE_PROFILE_LANDING }
    : { display: translate('ROUTE_HOME'), path: Constants.ROUTE_BASE };
  const pathParts = [parent].concat(getRouteParts(translate));

  return (
    <BreadCrumbContainer aria-label="Breadcrumb">
      <ol>
        {pathParts.map((path, index) => (
          <li key={index}>
            <BreadCrumbText disabled={index === pathParts.length - 1}>
              <Link
                tabIndex={index !== pathParts.length - 1 ? 0 : -1}
                href={constructPath(pathParts, index)}
                disabled={index === pathParts.length - 1}
                aria-current={index === pathParts.length - 1 ? 'page' : null}
              >
                {path.display}
              </Link>
            </BreadCrumbText>
            {index !== pathParts.length - 1 && <Separator aria-hidden={true}> / </Separator>}
          </li>
        ))}
      </ol>
    </BreadCrumbContainer>
  );
};
