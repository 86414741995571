import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { LocationType } from 'types/LocationTypes';
import { StationFieldType } from '../StationSearchService';
import { Constants } from 'utils/Constants';
import { InfoIconDark, ErrorIcon } from 'elements/icon/symbol-icons';
import { LinkSmallResp } from 'elements/new-design/links/Links';
import { FlexRowStart } from 'elements/containers/Containers';
import { TxtSmallRegularDarkResp, TxtSmallMediumLightResp } from 'elements/new-design/Typography';
import { MarginTop, MarginRight } from 'elements/distance/Margins';

type SearchMessagesProps = {
  field: StationFieldType;
  resultList: LocationType[];
  stopPlaces: boolean;
};

export const SearchMessages = ({ field, stopPlaces, resultList }: SearchMessagesProps): JSX.Element => {
  const { translate } = useI18n();
  return (
    <div role="alert" aria-live="assertive">
      {!stopPlaces && (
        <FlexRowStart>
          <ErrorIcon />
          <MarginRight margin={0.8} />
          <label aria-label={translate('NO_STATIONS_SYS_ERR_TEXT')}>
            <TxtSmallMediumLightResp>{translate('NO_STATIONS_SYS_ERR_TEXT')}</TxtSmallMediumLightResp>
          </label>
        </FlexRowStart>
      )}
      {stopPlaces && (
        <>
          {field.serviceMessage && (
            <FlexRowStart>
              <InfoIconDark />
              <MarginRight margin={0.8} />
              <div>
                <label aria-label={field.serviceMessage}>
                  <TxtSmallRegularDarkResp>{field.serviceMessage}</TxtSmallRegularDarkResp>
                </label>
                <MarginTop margin={0.8} />
                <label id="noServiceErrorLink" aria-label={translate('FIND_JOURNEY_ENTUR')}>
                  <LinkSmallResp
                    text={translate('FIND_JOURNEY_ENTUR')}
                    url={Constants.ENTUR_WEB}
                    color="dark"
                    external={true}
                  />
                </label>
              </div>
            </FlexRowStart>
          )}
          {field.error && (
            <FlexRowStart>
              <ErrorIcon />
              <MarginRight margin={0.8} />
              <label aria-label={field.error}>
                <TxtSmallMediumLightResp>{field.error}</TxtSmallMediumLightResp>
              </label>
            </FlexRowStart>
          )}
          {resultList && resultList.length === 0 && (
            <FlexRowStart>
              <InfoIconDark />
              <MarginRight margin={0.8} />
              <div>
                <label aria-label={translate('NO_HIT')}>
                  <TxtSmallRegularDarkResp>{translate('NO_HIT')}</TxtSmallRegularDarkResp>
                </label>
                <MarginTop margin={0.8} />
                <label aria-label={translate('SEE_ALL_STATIONS')}>
                  <LinkSmallResp
                    text={translate('SEE_ALL_STATIONS')}
                    url={Constants.ROUTE_CMS_ALL_LINES}
                    color="dark"
                    external={false}
                  />
                </label>
              </div>
            </FlexRowStart>
          )}
        </>
      )}
    </div>
  );
};
