import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { TxtSmallBoldDarkResp, TxtSmallMediumMediumResp } from 'elements/new-design/Typography';

type InputLabelProps = {
  fieldId: string;
  label: string;
  description: string;
};

const InputLabelContainer = styled.label`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const InputLabel = ({ fieldId, label, description }: InputLabelProps) => {
  const { translate } = useI18n();
  return (
    <InputLabelContainer id={`${fieldId}Label`}>
      <TxtSmallBoldDarkResp>{translate(label)}</TxtSmallBoldDarkResp>
      {description && <TxtSmallMediumMediumResp>{translate(description)}</TxtSmallMediumMediumResp>}
    </InputLabelContainer>
  );
};
