import React from 'react';
import styled from 'styled-components';
import { LocationType } from 'types/LocationTypes';
import { isUpKey, isDownKey, isEscapeKey, isActionKey, isTabBackKey, isTabKey } from 'utils/Accessability';
import { FROM_STATION, TO_STATION, lists } from './StationSearchService';

type StationComboBoxProps = {
  owns: string;
  expanded: boolean;
  fieldInFocus: string;
  searchResult: LocationType[];
  earlierSearches: [{ to: string; from: string }] | null;
  shortcuts: string[];
  listFocusState: { row: number; list: string };
  onTab: () => void;
  onBlur: () => void;
  onSelect: (field: string, station: string, secondStation: string | null) => void;
  onUpdateFocusState: ({ row, list }) => void;
  children: JSX.Element[];
};

const ComboBox = styled.div`
  width: 100%;
  position: relative;

  @media ${(props) => props.theme.breakpoints.large} {
    flex: 1;
  }
`;

export const StationComboBox = ({ ...props }: StationComboBoxProps): JSX.Element => {
  const {
    owns,
    fieldInFocus,
    searchResult,
    earlierSearches,
    shortcuts,
    listFocusState,
    expanded,
    onTab,
    onBlur,
    onSelect,
    onUpdateFocusState,
    children,
  } = props;
  const { row, list } = listFocusState;

  const handleActionKey = () => {
    if (list === lists.earlierSearches) onSelect(fieldInFocus, earlierSearches[row].from, earlierSearches[row].to);
    else if (list === lists.shortcuts) onSelect(fieldInFocus, shortcuts[row], null);
    else if (list === lists.searchResult) onSelect(fieldInFocus, searchResult[row].name, null);
  };

  const handleDownKey = () => {
    if (searchResult && Boolean(searchResult.length)) {
      if (row < searchResult.length - 1) onUpdateFocusState({ row: row + 1, list });
      else onUpdateFocusState({ row: 0, list });
    } else if (!earlierSearches) {
      if (row < shortcuts.length - 1) onUpdateFocusState({ row: row + 1, list });
      else onUpdateFocusState({ row: 0, list });
    } else {
      if (list === lists.earlierSearches) {
        if (row < earlierSearches.length - 1) onUpdateFocusState({ row: row + 1, list });
        else {
          onUpdateFocusState({ row: 0, list: lists.shortcuts });
        }
      } else {
        if (row < shortcuts.length - 1) onUpdateFocusState({ row: row + 1, list });
        else onUpdateFocusState({ row: 0, list: lists.earlierSearches });
      }
    }
  };

  const handleUpKey = () => {
    if (searchResult && Boolean(searchResult.length)) {
      if (row > 0) onUpdateFocusState({ row: row - 1, list });
      else onUpdateFocusState({ row: searchResult.length - 1, list });
    } else if (!earlierSearches) {
      if (row > 0) onUpdateFocusState({ row: row - 1, list });
      else onUpdateFocusState({ row: shortcuts.length - 1, list });
    } else {
      if (list === lists.earlierSearches) {
        if (row === 0) onUpdateFocusState({ row: shortcuts.length - 1, list: lists.shortcuts });
        else onUpdateFocusState({ row: row - 1, list });
      } else {
        if (row === 0) {
          onUpdateFocusState({ row: earlierSearches.length - 1, list: lists.earlierSearches });
        } else {
          onUpdateFocusState({ row: row - 1, list });
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (isEscapeKey(e.key)) {
      onBlur();
    } else if (isTabBackKey(e) || isTabKey(e.key)) {
      onTab();
    } else {
      const fieldIsActive = document.activeElement.id === FROM_STATION || document.activeElement.id === TO_STATION;
      if (isActionKey(e.key) && fieldIsActive) handleActionKey();
      else if (isDownKey(e.key)) handleDownKey();
      else if (isUpKey(e.key)) handleUpKey();
    }
  };

  return (
    <ComboBox
      role="combobox"
      aria-owns={owns}
      aria-autocomplete="list"
      aria-expanded={expanded}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      {children}
    </ComboBox>
  );
};
