import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { HiddenInput, StyledCheckbox } from 'elements/new-design/InputFields';
import { FlexRow } from 'elements/containers/Containers';
import { TxtLargeMediumDarkStatic } from 'elements/new-design/Typography';

type CheckboxProps = {
  id?: string;
  name?: string;
  label?: string;
  checked: boolean;
  readonly?: boolean;
  onToggle?: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => void;
};

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const CheckboxLabelContainer = styled(FlexRow)<{ left?: boolean }>`
  cursor: pointer;

  label {
    ${(props) => (props.left ? 'margin-right: 1.2rem' : 'margin-left: 1.2rem')}
  }
`;

const InnerLabel = styled.label`
  cursor: pointer;
`;

export const Checkbox = ({ ...props }: CheckboxProps) => (
  <CheckboxContainer>
    <HiddenInput
      id={props.id}
      type="checkbox"
      name={props.name}
      checked={props.checked}
      disabled={props.readonly}
      aria-readonly={props.readonly}
      onChange={(e) => (props.readonly ? null : props.onToggle(e))}
    />
    <StyledCheckbox />
  </CheckboxContainer>
);

export const CheckboxLabelRight = ({ ...props }: CheckboxProps) => (
  <label>
    <CheckboxLabelContainer>
      <Checkbox {...props} />
      <InnerLabel htmlFor={props.id}>
        <TxtLargeMediumDarkStatic>{props.label}</TxtLargeMediumDarkStatic>
      </InnerLabel>
    </CheckboxLabelContainer>
  </label>
);

export const CheckboxLabelLeft = ({ ...props }: CheckboxProps) => (
  <label>
    <CheckboxLabelContainer left>
      <InnerLabel htmlFor={props.id}>
        <TxtLargeMediumDarkStatic>{props.label}</TxtLargeMediumDarkStatic>
      </InnerLabel>
      <Checkbox {...props} />
    </CheckboxLabelContainer>
  </label>
);

export const CheckboxNoText = ({ ...props }: CheckboxProps) => (
  <label>
    <Checkbox {...props} />
  </label>
);
