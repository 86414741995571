import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';
import { MenuLinksType } from 'types/CmsTypes';
import { LinkList } from 'components/new-design/lists/LinkList';
import { TxtLargeBoldDarkResp } from 'elements/new-design/Typography';
import { MarginBottom } from 'elements/distance/Margins';
import { FlexColumnNoCenter } from 'elements/containers/Containers';

type LargeHamburgerMenuProps = {
  faqLinks: MenuLinksType[];
  currentColumn: number;
  expanded: string;
  headerHeight: string;
  onToggleMenu: (id: string) => void;
  onRowClick: (parentId: string, id: string) => void;
  onTabKey?: (e: KeyboardEvent<HTMLUListElement>) => void;
};

type StyleProps = {
  headerHeight: string;
};

const ExpandedMenu = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  position: fixed;
  top: ${(props) => props.headerHeight};
  left: 0;
  right: 0;
  max-height: 90vh;
  overflow-x: auto;
  background: ${(props) => props.theme.newcolors.bgPrimary};
`;

const ContentContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3.6rem 4.8rem;

  @media ${(props) => props.theme.breakpoints.large} {
    width: 100%;
    padding: 3.6rem 8.8rem;
  }
`;

const LinkSection = styled.section`
  margin-right: 3.2rem;
  margin-bottom: 3.2rem;

  :last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export const LargeHamburgerMenu = ({ ...props }: LargeHamburgerMenuProps) => (
  <ExpandedMenu role="menu" headerHeight={props.headerHeight}>
    <ContentContainer>
      {props.faqLinks.length !== 0 &&
        props.faqLinks.map((link, index) => {
          return (
            <LinkSection key={index}>
              <h2>
                <TxtLargeBoldDarkResp>{link.title}</TxtLargeBoldDarkResp>
              </h2>
              <MarginBottom margin={2.4} />
              <FlexColumnNoCenter>
                <LinkList
                  link={link}
                  expanded={props.expanded}
                  belongsToColumn={index}
                  activeColumn={props.currentColumn}
                  onToggleMenu={props.onToggleMenu}
                  onRowClick={props.onRowClick}
                  onTabKey={props.onTabKey}
                />
              </FlexColumnNoCenter>
            </LinkSection>
          );
        })}
    </ContentContainer>
  </ExpandedMenu>
);
