import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { HiddenSpan, TxtSmallBoldDarkResp } from 'elements/new-design/Typography';

// TYPES
type TicketCategoriesProps = {
  tabCategories: { id: string; text: string | string[]; ariaLabel: string }[] | string[];
  activeTab: string;
  onChangeActiveTab: (name: string) => void;
  numberOfCategories?: number;
};

type StyleProps = {
  active?: boolean;
  numberOfCategories: number;
};

// STYLES
export const TicketCategories = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${(props) =>
    props.numberOfCategories ? `repeat(${props.numberOfCategories}, auto)` : `repeat(3, auto)`};
  grid-gap: 1.6rem;
  justify-content: flex-start;
`;

export const Category = styled.button`
  ${(_: StyleProps) => ''}
  width: 10rem;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  border-bottom: ${(props) =>
    props.active ? props.theme.constants.borderPrimaryAccent : props.theme.constants.borderThickPrimary};

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 13rem;
  }
`;

//COMPONENT
export const SectionTicketCategories = ({
  tabCategories,
  activeTab,
  onChangeActiveTab,
  numberOfCategories = null,
}: TicketCategoriesProps) => {
  const { translate } = useI18n();
  return (
    <TicketCategories numberOfCategories={numberOfCategories}>
      {tabCategories.map((tab) => (
        <Category
          key={tab}
          role="tab"
          id={tab}
          aria-selected={activeTab === tab}
          aria-controls={`${tab}-panel`}
          active={activeTab === tab}
          onClick={() => onChangeActiveTab(tab)}
        >
          <HiddenSpan>{`${translate('LIST')} ${translate(tab)} ${translate('JOURNEYS')}`}</HiddenSpan>
          <TxtSmallBoldDarkResp aria-hidden={true}>{translate(tab)}</TxtSmallBoldDarkResp>
        </Category>
      ))}
    </TicketCategories>
  );
};
