import colors from './colors';

export default {
  borderPrimary: `.1rem solid ${colors.borderColPrimary}`,
  borderThickPrimary: `.3rem solid ${colors.borderColPrimary}`,
  borderLightSecondary: `.1rem solid ${colors.borderColLight}`,
  borderPrimaryAccent: `.3rem solid ${colors.borderColAccent}`,
  borderFocusLight: `0 0 0 .2rem ${colors.borderColLight}, 0 0 .3rem .5rem ${colors.borderColFocus}`,
  borderFocusWhite: `0 0 0 .2rem ${colors.borderColLight}, 0 0 .3rem .5rem ${colors.borderColLight}`,
  borderFocusError: `0 0 0 .2rem ${colors.error}, 0 0 .3rem .5rem ${colors.borderColFocus}`,
  borderFocus: `0 0 0 .2rem ${colors.borderColLight}, 0 0 .3rem .5rem ${colors.borderColFocus}`,
  borderThickInputFocus: `.15rem solid ${colors.borderInputFocus}`,
  borderThickError: `.3rem solid ${colors.error}`,
  borderThinError: `.1rem solid ${colors.error}`,
  borderSoftError: `.1rem solid ${colors.borderError}`,
  borderThickDark: `.3rem solid ${colors.colDark}`,
  borderThickTransparent: `.3rem solid transparent`,
  borderWarning: `.1rem solid ${colors.borderError}`,
  borderActive: `.3rem solid ${colors.linkActive}`,
  borderThinInfo: `.1rem solid ${colors.borderInfo}`,
  borderThinNeutralInfo: `.1rem solid ${colors.borderColPrimary}`,
  borderThinDeviation: `.1rem solid ${colors.borderDeviation}`,
  calendarDateSize: '3.5rem',
  zIndexDefault: 0,
  zIndexLow: 1,
  zIndexMedium: 50,
  zIndexHigh: 100,
  zIndexMega: 1000,
};
