import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Ticket */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.orderLineId] 
 * @param {string[]} [options.orderLines] 
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultListCancelRefundResponse2>} Success
 */
export function Ticket_RefundTicket(orderId: string, options?: Ticket_RefundTicketOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultListCancelRefundResponse2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    query: {
      orderLineId: options.orderLineId,
      orderLines: gateway.formatArrayParam(options.orderLines, 'multi', 'orderLines')
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Ticket_RefundTicketOperation, parameters)
}

/**
 * clientType is an enum on back-end. Values are
 *             Web (default)
 *             Android
 *             iOS
 *             App
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum on backend (see description)
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ReservationRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReservationResponse1>} Success
 */
export function Ticket_Reserve(options?: Ticket_ReserveOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReservationResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_ReserveOperation, parameters)
}

/**
 * Returns all information about the order.
 * 
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReviewResponse1>} Success
 */
export function Ticket_ReviewOrder(orderId: string, options?: Ticket_ReviewOrderOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReviewResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Ticket_ReviewOrderOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Clients.EnTurOrder.Sms} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultString1>} Accepted
 */
export function Ticket_SendSms(orderId: string, options?: Ticket_SendSmsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultString1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Ticket_SendSmsOperation, parameters)
}

export interface Ticket_RefundTicketOptions {
  orderLineId?: string
  orderLines?: string[]
  authorization?: string
}

export interface Ticket_ReserveOptions {
  authorization?: string
  /**
   * Enum on backend (see description)
   */
  clientType?: string
  body?: api.GoAliveRestApi.Controllers.ControllerModels.ReservationRequest
}

export interface Ticket_ReviewOrderOptions {
  authorization?: string
}

export interface Ticket_SendSmsOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Clients.EnTurOrder.Sms
}

const Ticket_RefundTicketOperation: api.OperationInfo = {
  path: '/rest/v2/Ticket/refund/{orderId}',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_ReserveOperation: api.OperationInfo = {
  path: '/rest/v2/Ticket/reserve',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_ReviewOrderOperation: api.OperationInfo = {
  path: '/rest/v2/Ticket/review/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Ticket_SendSmsOperation: api.OperationInfo = {
  path: '/rest/v2/Ticket/sms/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
