/// <reference path="types.ts"/>
/** @module SeatReservation */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Reserve seats for order
 * 
 * @param {string} orderId 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ReserveSeatsRequest} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultReserveSeatEmptyResponse1>} Success
 */
export function SeatReservation_ReserveSpecificSeats(orderId: string, body: api.GoAliveRestApi.Controllers.ControllerModels.ReserveSeatsRequest, options?: SeatReservation_ReserveSpecificSeatsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultReserveSeatEmptyResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      orderId
    },
    body: {
      body
    }
  }
  return gateway.request(SeatReservation_ReserveSpecificSeatsOperation, parameters)
}

export interface SeatReservation_ReserveSpecificSeatsOptions {
  authorization?: string
}

const SeatReservation_ReserveSpecificSeatsOperation: api.OperationInfo = {
  path: '/rest/SeatReservation/specific-seats/{orderId}',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
