import { DateTime, Settings, Interval } from 'luxon';
import { Constants } from '../Constants';

const zone = 'Europe/Oslo';

// Problems with chrome not supporting nb, using dk as fallback.
if (Intl.NumberFormat.supportedLocalesOf(['no', 'no-NO', 'nb', 'nb-NO', 'nn', 'nn-NO']).includes('nb'))
  Settings.defaultLocale = 'nb';
else Settings.defaultLocale = 'da-dk';

const DATE_FORMAT_FULL = 'EEEE d. MMMM';
const DATE_FORMAT_LONG = 'EEE d. MMM y';
const DATE_FORMAT_SHORT = 'EEE d. MMM';
const DATE_FORMAT_MONTH_SHORT = 'MMM';

export class GOADate {
  private dt: DateTime;

  public constructor(date: Date | string | number | DateTime = DateTime.local().setZone(zone), language?: string) {
    if (language) Settings.defaultLocale = language;

    if (date instanceof DateTime) this.dt = date;
    else this.dt = DateTime.fromISO(new Date(date).toJSON(), { zone });
  }

  public static startOfToday(): GOADate {
    return new GOADate().getStartOfDay();
  }

  public static isValidISO(s: string): boolean {
    return DateTime.fromISO(s).isValid;
  }

  /**
   * Getters
   */

  public getYear(): number {
    return this.toLuxonDateTime().get('year');
  }

  public getMonth(): number {
    return this.toLuxonDateTime().get('month');
  }

  public getWeek(): number {
    return this.toLuxonDateTime().get('weekNumber');
  }

  public getDate(): number {
    return this.toLuxonDateTime().get('day');
  }

  public getHours(): number {
    return this.toLuxonDateTime().get('hour');
  }

  public getMinutes(): number {
    return this.toLuxonDateTime().get('minute');
  }

  public getSeconds(): number {
    return this.toLuxonDateTime().get('second');
  }

  public getStartOfYear(): GOADate {
    return new GOADate(this.toLuxonDateTime().startOf('year'));
  }

  public getStartOfMonth(): GOADate {
    return new GOADate(this.toLuxonDateTime().startOf('month'));
  }

  public getStartOfWeek(): GOADate {
    return new GOADate(this.toLuxonDateTime().startOf('week'));
  }

  public getStartOfDay(): GOADate {
    return new GOADate(this.toLuxonDateTime().startOf('day'));
  }

  public getEndOfMonth(): GOADate {
    return new GOADate(this.toLuxonDateTime().endOf('month'));
  }

  public getEndOfWeek(): GOADate {
    return new GOADate(this.toLuxonDateTime().endOf('week'));
  }

  public getEndOfDay(): GOADate {
    return new GOADate(this.toLuxonDateTime().endOf('day'));
  }

  public getEachDayOfInterval(to: GOADate): GOADate[] {
    return Interval.fromDateTimes(this.toLuxonDateTime(), to.toLuxonDateTime())
      .splitBy({ days: 1 })
      .map((interval) => new GOADate(interval.start));
  }

  public getMillisSinceEpoc(): number {
    return this.toLuxonDateTime().toMillis();
  }

  public getDiffInDates(other: GOADate, format: any): any {
    return this.toLuxonDateTime().diff(other.toLuxonDateTime(), format).toObject();
  }

  /**
   * Setters
   */

  public setYear(year): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ year }));
  }

  public setMonth(month): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ month }));
  }

  public setDate(day): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ day }));
  }

  public setHours(hour): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ hour }));
  }

  public setMinutes(minute): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ minute }));
  }

  public setSeconds(second): GOADate {
    return new GOADate(this.toLuxonDateTime().set({ second }));
  }

  /**
   * Math
   */

  public addMonths(month): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ month }));
  }

  public subMonths(month): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ month }));
  }

  public addWeeks(week): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ week }));
  }

  public subWeeks(week): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ week }));
  }

  public addDays(day): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ day }));
  }

  public subDays(day): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ day }));
  }

  public addHours(hour): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ hour }));
  }

  public subHours(hour): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ hour }));
  }

  public addMinutes(minute): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ minute }));
  }

  public subMinutes(minute): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ minute }));
  }

  public addSeconds(second): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ second }));
  }

  public subSeconds(second): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ second }));
  }

  public subYears(year): GOADate {
    return new GOADate(this.toLuxonDateTime().minus({ year }));
  }

  public addYears(year): GOADate {
    return new GOADate(this.toLuxonDateTime().plus({ year }));
  }

  /**
   * Comparing
   */

  public isSameDay(other: GOADate): boolean {
    return this.toLuxonDateTime().hasSame(other.toLuxonDateTime(), 'day');
  }

  public isSameYear(other: GOADate): boolean {
    return this.toLuxonDateTime().hasSame(other.toLuxonDateTime(), 'year');
  }

  public isSame(other: GOADate): boolean {
    return this.toLuxonDateTime().equals(other.toLuxonDateTime());
  }

  public isBefore(other: GOADate): boolean {
    return this.toLuxonDateTime() < other.toLuxonDateTime();
  }

  public isToday(): boolean {
    return this.isSameDay(new GOADate());
  }

  public isAfter(other: GOADate): boolean {
    return this.toLuxonDateTime() > other.toLuxonDateTime();
  }

  /**
   * Formatting
   */

  capitalizeFirstLetter(dateFormat) {
    return this.format(dateFormat).charAt(0).toUpperCase() + this.format(dateFormat).slice(1);
  }

  public format(dateFormat: string): string {
    return this.toLuxonDateTime().toFormat(dateFormat);
  }

  public formatDate(device: string): string {
    let dateFormat = '';
    switch (device) {
      case Constants.DEVICE_SMALL:
        dateFormat = DATE_FORMAT_SHORT;
        break;
      case Constants.DEVICE_MEDIUM:
        dateFormat = DATE_FORMAT_SHORT;
        break;
      case Constants.DEVICE_LARGE:
        dateFormat = DATE_FORMAT_LONG;
        break;
      case Constants.DEVICE_MEGA:
        dateFormat = DATE_FORMAT_LONG;
        break;
      case 'full':
        dateFormat = DATE_FORMAT_FULL;
        break;
      default:
        dateFormat = DATE_FORMAT_SHORT;
        break;
    }
    return this.capitalizeFirstLetter(dateFormat);
  }

  public formatMonth(): string {
    return this.format(DATE_FORMAT_MONTH_SHORT);
  }

  public formatDateMonth(): string {
    return this.format('MMMM y');
  }

  public formatFullDateShortMonth(): string {
    return this.capitalizeFirstLetter('EEEE d. MMM');
  }

  public formatFullDateFullMonth(currentLanguage: string): string {
    const formattedDate = this.dt.setLocale(currentLanguage).toFormat(DATE_FORMAT_FULL);
    const split = formattedDate.split('. ');
    return `${split[0].charAt(0).toUpperCase() + split[0].slice(1)}. ${
      split[1].charAt(0).toUpperCase() + split[1].slice(1)
    }`;
  }

  public formatDateFormatShort(currentLanguage): string {
    return this.dt.setLocale(currentLanguage).toFormat(DATE_FORMAT_SHORT);
  }

  public formateDateToNumberString(): string {
    let currentMonth: string | number = this.getMonth();
    let currentDay: string | number = this.getDate();

    if (currentDay < 10) currentDay = `${'0' + currentDay}`;
    if (currentMonth < 10) currentMonth = `${'0' + currentMonth}`;

    return `${currentDay}.${currentMonth}.${this.getYear().toString().substr(-2)}`;
  }

  public formatTime(): string {
    return this.format('HH:mm');
  }

  public formatAssistiveDate(): string {
    return this.formatDate('full');
  }

  public formatDayMonthYearNorwegian(): string {
    return this.format('dd.MM.yyyy');
  }

  /**
   * Converting
   */

  public toLuxonDateTime(): DateTime {
    return this.dt;
  }

  public toZonedDate(): Date {
    return this.dt.toJSDate();
  }

  public toJSON(): string {
    return this.toZonedDate().toJSON();
  }

  public toDateString(): string {
    return this.toZonedDate().toLocaleString();
  }
}
