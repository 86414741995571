import styled from 'styled-components';

type StyleProps = {
  margin: string;
};

export const ContainerBoxShadow = styled.div`
  background: ${(props) => props.theme.newcolors.bgPrimary};
  box-shadow: ${(props) => props.theme.newcolors.boxshadow};
`;

export const ContainerBorderBoxShadow = styled.div`
  background: ${(props) => props.theme.newcolors.bgPrimary};
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
`;

export const FloatingDropdown = styled.div`
  position: absolute;
  padding: 2.4rem 1.6rem 3.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.theme.newconstants.zIndexMega};
  background: ${(props) => props.theme.newcolors.bgPrimary};
  box-shadow: ${(props) => props.theme.newcolors.boxshadow};
`;

export const BasePageMargin = styled.div`
  padding: 0 2.4rem;

  @media ${(props) => props.theme.breakpoints.large} {
    padding: 0 5.6rem;
  }
`;

export const FloatingMenuContainer = styled(FloatingDropdown)`
  ${(_: StyleProps) => ''}
  top: 6.6rem;
  left: 2.4rem;
  right: 2.4rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 32rem;
    top: 4.6rem;
    left: -25rem;
    right: auto;
  }

  @media ${(props) => props.theme.breakpoints.large} {
    top: 3.6rem;
    left: ${(props) => `${props.margin}`};
    right: ${(props) => `${props.margin}`};
  }
`;
