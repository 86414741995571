import { css, keyframes } from 'styled-components';

const ticktock = keyframes`
  0%    { transform: rotate(0deg); }
  100%  { transform: rotate(-70deg); }
`;

export const TickTockAnimation = css`
  animation-name: ${ticktock};
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
`;
