import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { FormFieldType } from 'types/FormTypes';
import { InputError } from 'elements/forms/InputError';
import { ErrorIcon } from './InputErrorIcon';
import { InputLabel } from 'elements/forms/InputLabel';
import { InputPlaceholderLabel } from 'elements/new-design/InputFields';
import { FlexRow } from 'elements/containers/Containers';

type TextAreaProps = {
  field: FormFieldType;
  onInputChange: (field: FormFieldType, value: string | number) => void;
  onInputBlur: (field: FormFieldType) => void;
};

type StyleProps = {
  error: boolean;
  height: number;
};

const TextAreaContainer = styled(FlexRow)`
  position: relative;
`;

const TextAreaInput = styled.textarea`
  ${(_: StyleProps) => ''}
  width: 100%;

  ${InputPlaceholderLabel}
  width: 100%;
  height: ${(props) => `${props.height}rem`};
  min-height: 6rem;
  padding: 1.2rem;
  overflow: auto;
  border-radius: 0.8rem;
  border: ${(props) => (props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary)};
  background: ${(props) => (props.error ? props.theme.colors.bgError : props.theme.colors.bgSecondary)};

  :focus-visible {
    box-shadow: ${(props) => (props.error ? 'none' : props.theme.constants.borderFocusLight)};
  }
`;

export const TextArea = ({ field, onInputChange, onInputBlur }: TextAreaProps) => {
  const inputRef = createRef<HTMLTextAreaElement>();
  const [height, setHeight] = useState(0);

  const handleChange = (field: FormFieldType, value: string) => {
    if (inputRef.current.scrollHeight > 60) setHeight(inputRef.current.scrollHeight / 10);
    if (value === '') setHeight(6);
    onInputChange(field, value);
  };

  return (
    <>
      <InputLabel fieldId={field.id} label={field.label} description={field.description} />
      <TextAreaContainer>
        {Boolean(field.error) && <ErrorIcon />}
        <TextAreaInput
          id={field.id}
          value={field.value}
          spellCheck={false}
          error={Boolean(field.error)}
          ref={inputRef}
          height={height}
          aria-labelledby={`${field.id}Label ${field.id}Error`}
          onChange={(e) => handleChange(field, e.target.value)}
          onBlur={() => onInputBlur(field)}
        />
      </TextAreaContainer>
      <InputError fieldId={field.id} error={field.error} />
    </>
  );
};
