import { History_GetDetails, History_GetTransactionHistory, History_GetTransactionHistoryOrder } from 'apiV2/History';

export const useHistoryApi = (token: string) => {
  const getHistoryDetails = async (orderId: string): Promise<any> =>
    await History_GetDetails(orderId, {
      authorization: 'Bearer ' + token,
    });

  const getTransactionHistory = async (): Promise<any> =>
    await History_GetTransactionHistory({ authorization: 'Bearer ' + token, pageNbr: 1 });

  const getTransactionById = async (orderId: string): Promise<any> =>
    await History_GetTransactionHistoryOrder(orderId, { authorization: 'Bearer ' + token });

  return {
    getHistoryDetails,
    getTransactionHistory,
    getTransactionById,
  };
};
