import {
  SET_MESSAGES,
  REMOVE_MESSAGE,
  REMOVE_ALL_MESSAGES,
  Message,
  MessagesActionTypes,
} from '../../types/MessagesTypes';

export const setMessages = (message: Message): MessagesActionTypes => ({
  type: SET_MESSAGES,
  payload: message,
});

export const removeMessage = (index: number): MessagesActionTypes => ({
  type: REMOVE_MESSAGE,
  payload: index,
});

export const removeAllMessages = (): MessagesActionTypes => ({
  type: REMOVE_ALL_MESSAGES,
});
