import React from 'react';
import styled, { css } from 'styled-components';
import CloseIconBlue from 'assets/svg/close-blue.svg';
import CloseIconWhite from 'assets/svg/close-white.svg';
import CloseIconDark from 'assets/svg/close.svg';
import InfoIcon from 'assets/svg/checkbox-on.svg';
import ErrorIcon from 'assets/svg/error.svg';
import RedErrorIcon from 'assets/svg/error-red.svg';
import { WarningIconDark } from 'elements/new-design/icons/SymbolIcons';
import { Constants } from 'utils/Constants';
import { BaseContainerSmaller, FlexRow } from 'elements/containers/Containers';
import { isActionKey } from 'utils/Accessability';
import { Icon } from 'elements/icon/Icon';

// Types
// =========================
type PageBannerProps = {
  type: string;
  text: string;
  message?: string;
  closable: boolean;
  icon?: string;
  onPageBannerClick: () => void;
};

type PageBannerStyleProps = {
  type: string;
  closable?: boolean;
};

type RightIconProps = {
  icon: string;
  message: string;
};

const PageBannerContainer = styled(FlexRow)`
  ${(_: PageBannerStyleProps) => ''}
  margin-bottom: 2rem;
  width: 100%;
  padding: 0.5rem 0;
  justify-content: center;
  font-size: ${(props) => props.theme.fonts.fontSize.small};
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};
  cursor: ${(props) => (props.closable ? 'pointer' : 'default')};

  ${(props) => {
    if (props.type === Constants.INFO_MESSAGE) {
      return css`
        background: ${props.theme.colors.bgInfo};
        color: ${props.theme.colors.txtColAccent};
        border-top: ${props.theme.constants.borderThinInfo};
        border-bottom: ${props.theme.constants.borderThinInfo};
      `;
    } else if (props.type === Constants.ERROR_MESSAGE) {
      return css`
        background: ${props.theme.colors.error};
        color: ${props.theme.colors.txtColLight};
        border-top: ${props.theme.constants.borderThickError};
        border-bottom: ${props.theme.constants.borderThickError};
      `;
    } else if (props.type === Constants.DEVIATION_MESSAGE) {
      return css`
        background: ${props.theme.colors.deviation};
        border-top: ${props.theme.constants.borderThinDeviation};
        border-bottom: ${props.theme.constants.borderThinDeviation};
        color: ${props.theme.colors.txtColDark};
      `;
    } else {
      return css`
        color: ${props.theme.colors.error};
        background: ${props.theme.colors.warning};
        border-top: ${props.theme.constants.borderSoftError};
        border-bottom: ${props.theme.constants.borderSoftError};
      `;
    }
  }};

  p {
    margin-left: 0.5rem;
  }
`;

const LeftIconWrapper = styled.span`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  align-self: flex-start;
`;

const RightIcon = styled.button`
  ${(_: RightIconProps) => ''}
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.2rem;
  cursor: pointer;
  background: url(${(props) => props.icon}) no-repeat center;
  background-size: 1.6rem;

  &:focus-visible {
    box-shadow: ${(props) =>
      props.message === Constants.INFO_MESSAGE
        ? props.theme.constants.borderFocusLight
        : props.theme.constants.borderFocusError};
  }
`;

const LeftIcon = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
`;

const TextWrapper = styled.div`
  flex: 1;
  align-items: flex-start;

  @media ${(props) => props.theme.devices.large} {
    margin-right: 8rem;
  }
`;

const MessageText = styled.div`
  ${(_: PageBannerStyleProps) => ''}
  ${(props) => {
    if (props.type === Constants.ERROR_MESSAGE) {
      return css`
        color: ${props.theme.colors.txtColLight};
      `;
    } else {
      return css`
        color: ${(props) => props.theme.colors.txtColMedium};
      `;
    }
  }};
`;

const getCloseIcon = (type: string) => {
  if (type === Constants.INFO_MESSAGE) return CloseIconBlue;
  else if (type === Constants.DEVIATION_MESSAGE) return CloseIconDark;
  else return CloseIconWhite;
};

export const PageBannerMessage = ({ ...props }: PageBannerProps) => {
  return (
    <PageBannerContainer type={props.type}>
      <BaseContainerSmaller>
        <FlexRow>
          <LeftIconWrapper>
            {!props.icon && props.type === Constants.INFO_MESSAGE && <LeftIcon icon={InfoIcon} />}
            {!props.icon && props.type === Constants.ERROR_MESSAGE && <LeftIcon icon={ErrorIcon} />}
            {!props.icon && props.type === Constants.WARNING_MESSAGE && <LeftIcon icon={RedErrorIcon} />}
            {!props.icon && props.type === Constants.DEVIATION_MESSAGE && <WarningIconDark width={2} height={2} />}
            {props.icon && <LeftIcon icon={props.icon} />}
          </LeftIconWrapper>
          <TextWrapper data-cy={'pageBannerMessageCy'}>
            {props.text}
            <MessageText type={props.type}>{props.message}</MessageText>
          </TextWrapper>
          {props.closable && (
            <RightIcon
              aria-label={'Close banner'}
              message={props.type}
              icon={getCloseIcon(props.type)}
              onClick={props.onPageBannerClick}
              onKeyDown={(e) => (isActionKey(e.key) ? props.onPageBannerClick : null)}
            />
          )}
        </FlexRow>
      </BaseContainerSmaller>
    </PageBannerContainer>
  );
};
