import { Assistance_GetAssistanceOptions, Assistance_SendAssistance } from 'apiV2/Assistance';

type AssistanceStationBooking = {
  assistanceAtStation: boolean;
  id: string;
  information: string;
};

type AssistanceBooking = {
  assistanceAid: string;
  arrivalStation: AssistanceStationBooking;
  departureStation: AssistanceStationBooking;
  assistanceNeeds: string;
  confirmationMethod: string;
  descriptionOfNeed: string;
  luggage: number;
  phoneNumber: string;
  travelAlone: boolean;
  authorization?: string;
};

type BookingData = {
  orderId: string;
  orderLineId: string;
  booking: AssistanceBooking;
};

export const useAssistanceApi = (token: string) => {
  const getAssistanceOptions = async () => await Assistance_GetAssistanceOptions();

  const sendAssistance = async (bookingData: BookingData) => {
    const options = {
      authorization: 'Bearer ' + token,
      orderLineId: bookingData.orderLineId,
      orderId: bookingData.orderId,
      body: bookingData.booking,
    };
    return await Assistance_SendAssistance(options);
  };

  return {
    getAssistanceOptions,
    sendAssistance,
  };
};
