import React from 'react';
import styled from 'styled-components';
import { FormFieldType } from 'types/FormTypes';
import { InputLabel } from './InputLabel';
import { InputError } from './InputError';

type RadioSelectionBoxProps = {
  checked: boolean;
  children: JSX.Element;
  onInputChange: () => void;
};

type RadioSelectionGroupBoxProps = {
  field: FormFieldType;
  children: JSX.Element;
};

type StyleProps = {
  checked: boolean;
};

const SelectionBox = styled.li`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.6rem;
  background: ${(props) => (props.checked ? props.theme.colors.bgSecondary : props.theme.colors.bgAthensDark)};
  box-shadow: ${(props) => (props.checked ? '0 0 5px 0 rgba(0, 0, 0, .15)' : '0')};
  cursor: pointer;
`;

const SelectionBoxGroup = styled.ul`
  width: 100%;
  list-style: none;
  border: ${(props) => props.theme.constants.borderPrimary};
  border-top: 0;

  li {
    border-top: ${(props) => props.theme.constants.borderPrimary};
  }
`;

export const RadioSelectionBox = ({ checked, onInputChange, children }: RadioSelectionBoxProps) => (
  <SelectionBox checked={checked} onClick={onInputChange}>
    {children}
  </SelectionBox>
);

export const RadioSelectionBoxGroup = ({ field, children }: RadioSelectionGroupBoxProps) => (
  <div
    role="form-group"
    aria-labelledby={`${field.id}Label ${field.id}Error`}
    aria-required={field.validationRules.includes('isRequired')}
  >
    <InputLabel fieldId={field.id} label={field.label} description={field.description} />
    <SelectionBoxGroup>{children}</SelectionBoxGroup>
    <InputError fieldId={field.id} error={field.error} />
  </div>
);
