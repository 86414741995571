import {
  GlobalActionTypes,
  SET_ORDER_VALID,
  SET_HAS_FORM_CHANGED,
  SET_CURRENT_DIALOG,
  SET_LANGUAGE,
  SET_PROMPT_LOGIN,
  SET_PAGE_TITLE,
} from 'types/GlobalTypes';

export function setOrderValid(status: boolean): GlobalActionTypes {
  return {
    type: SET_ORDER_VALID,
    payload: status,
  };
}

export function setHasFormChanged(status: boolean): GlobalActionTypes {
  return {
    type: SET_HAS_FORM_CHANGED,
    payload: status,
  };
}

export function setCurrentDialog(dialog: object): GlobalActionTypes {
  return {
    type: SET_CURRENT_DIALOG,
    payload: dialog,
  };
}

export function setLanguage(language: string): GlobalActionTypes {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
}

export function setPromptLogin(status: boolean): GlobalActionTypes {
  return {
    type: SET_PROMPT_LOGIN,
    payload: status,
  };
}

export function setPageTitle(title: string): GlobalActionTypes {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
}
