import React, { useState, useRef, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { isTabBackKey, isTabKey } from 'utils/Accessability';
import { MenuLinksType } from 'types/CmsTypes';
import { HeaderLogo } from './components/HeaderLogo';
import { LanguageSelector } from './components/LanguageSelector';
import { HeaderProfile } from './components/HeaderProfile';
import { LargeHamburgerMenu } from './components/LargeHamburgerMenu';
import { SmallHamburgerMenu } from './components/SmallHamburgerMenu';
import { ExpandHamburgerBtn } from 'elements/new-design/buttons/expand-buttons';
import { FlexBetween, FlexOne } from 'elements/containers/Containers';

type SmallHeaderProps = {
  faqLinks: MenuLinksType[];
  profileLinks: { title: string; slug: null; subRows: { title: string; slug: string | null }[] };
  headerHeight: string;
  expanded: string;
  isPhoneDevice: boolean;
  onToggleMenu: (id: string | null) => void;
  onOpenLogin: () => void;
  onMenuClick: (slug: string) => void;
  onLogoutUser: () => void;
};

const SmallMenuContainer = styled(FlexBetween)`
  width: 100%;
  height: 7.5rem;
  position: relative;
  padding: 0 2.4rem;

  @media ${(props) => props.theme.breakpoints.large} {
    padding: 0 5.6rem;
  }
`;

const LeftsideContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2.4rem;
  align-items: center;
  justify-content: end;
`;

export const SmallHeader = ({ ...props }: SmallHeaderProps) => {
  const {
    faqLinks,
    profileLinks,
    expanded,
    headerHeight,
    isPhoneDevice,
    onToggleMenu,
    onOpenLogin,
    onMenuClick,
    onLogoutUser,
  } = props;
  const [currentColumn, setCurrentColumn] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>();
  const { translate } = useI18n();

  const handleRowClick = (parentTitle: string, title: string) => {
    if (title === translate('LOGOUT')) onLogoutUser();
    else {
      const menuLinks = [...faqLinks, profileLinks];
      const parent = menuLinks.find((link) => link.title === parentTitle);
      const slug = parent.subRows.find((row) => row.title === title)['slug'];
      onMenuClick(parent.slug ? `/${parent.slug}/${slug}` : slug);
      setCurrentColumn(0);
    }
  };

  const closeMenu = () => {
    onToggleMenu(null);
    setCurrentColumn(0);
    buttonRef.current?.focus();
  };

  const handleToggleMenu = (id: string) => {
    onToggleMenu(id);
    setCurrentColumn(0);
  };

  const handleToggleSubmenu = (index: number) => {
    if (index === currentColumn) setCurrentColumn(null);
    else setCurrentColumn(index);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault();
    if (isTabBackKey(e)) {
      if (currentColumn === 0) closeMenu();
      else setCurrentColumn((prev) => prev - 1);
    } else if (isTabKey(e.key)) {
      if (currentColumn === faqLinks.length - 1) closeMenu();
      else setCurrentColumn((prev) => prev + 1);
    } else {
      return null;
    }
  };

  return (
    <SmallMenuContainer>
      <HeaderLogo onLinkClick={onMenuClick} />
      <FlexOne>
        <LeftsideContainer>
          <LanguageSelector expanded={expanded} onToggleMenu={onToggleMenu} />

          <HeaderProfile
            profileLinks={profileLinks}
            expanded={expanded}
            isPhoneDevice={isPhoneDevice}
            onOpenLogin={onOpenLogin}
            onToggleMenu={onToggleMenu}
            onRowClick={handleRowClick}
          />
          <ExpandHamburgerBtn
            small
            gap={!isPhoneDevice && 0.8}
            text="Meny"
            ref={buttonRef}
            expanded={expanded === 'menu'}
            popupType="menu"
            onButtonClick={() => handleToggleMenu('menu')}
          />
        </LeftsideContainer>
      </FlexOne>
      {expanded === 'menu' && (
        <>
          {!isPhoneDevice ? (
            <LargeHamburgerMenu
              faqLinks={faqLinks}
              expanded={expanded}
              currentColumn={currentColumn}
              headerHeight={headerHeight}
              onToggleMenu={onToggleMenu}
              onRowClick={handleRowClick}
              onTabKey={handleKeyDown}
            />
          ) : (
            <SmallHamburgerMenu
              faqLinks={faqLinks}
              expanded={expanded}
              headerHeight={headerHeight}
              currentColumn={currentColumn}
              onToggleMenu={onToggleMenu}
              onToggleSubmenu={handleToggleSubmenu}
              onRowClick={handleRowClick}
              onTabKey={handleKeyDown}
            />
          )}
        </>
      )}
    </SmallMenuContainer>
  );
};
