import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { FormFieldType } from 'types/FormTypes';
import { TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { isActionKey, isUpKey, isDownKey, isTabKey, isTabBackKey } from 'utils/Accessability';
import { BtnExpandDark } from 'elements/buttons/Buttons';
import { InputLabel } from './InputLabel';
import { InputError } from './InputError';
import { ErrorIcon } from './InputErrorIcon';
import { FlexRow } from 'elements/containers/Containers';
import { MarginTop } from 'elements/distance/Margins';

type DropDownProps = {
  field: FormFieldType;
  expanded: boolean;
  required: boolean;
  onToggle: (id: string) => void;
  onInputChange?: (field: FormFieldType, value: number | string) => void;
};

type StyleProps = {
  last?: boolean;
  isFocused?: boolean;
  expanded?: boolean;
  error?: string;
};

const Relative = styled.div`
  position: relative;
`;

const DropDownContainer = styled.div`
  ${(_: StyleProps) => ''}
  width:100%;
  min-height: 100%;
  border-radius: 0.8rem;
  position: absolute;
  z-index: ${(props) => (props.expanded ? props.theme.constants.zIndexMedium : 0)};
  border: ${(props) => (props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary)};
  background: ${(props) => props.theme.colors.bgSecondary};
  box-shadow: ${(props) => (props.expanded ? props.theme.constants.borderFocusLight : 'none')};
`;

export const TxtButtonDropDown = styled.button`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border-radius: 0.8rem;
  border-bottom-right-radius: ${(props) => (props.expanded ? 0 : '0.8rem')};
  border-bottom-left-radius: ${(props) => (props.expanded ? 0 : '0.8rem')};
  background: ${(props) => (props.error ? props.theme.colors.bgError : props.theme.colors.bgSecondary)};

  &:focus-visible {
    box-shadow: ${(props) => (props.expanded ? 'none' : props.theme.constants.borderFocusLight)};
  }
`;

const DropDownListItem = styled.li`
  ${(_: StyleProps) => ''}
  padding: 1.2rem;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  border-top: ${(props) => props.theme.constants.borderPrimary};
  background: ${(props) => (props.isFocused ? props.theme.colors.bgHover : props.theme.colors.bgSecondary)};
  border-bottom-right-radius: ${(props) => (props.last ? '0.8rem' : 0)};
  border-bottom-left-radius: ${(props) => (props.last ? '0.8rem' : 0)};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.bgHover};
  }
`;

export const DropDown = ({ field, expanded, required, onInputChange, onToggle }: DropDownProps) => {
  const [isFocused, setIsFocused] = useState(0);
  const [selectText, setSelectText] = useState(null);
  const { translate } = useI18n();
  const defaultText = translate('CHOOSE_OPTION');

  const handleRowClick = (field: FormFieldType, value: string | number) => {
    onInputChange(field, value);
    onToggle(field.id);
  };

  const handleKeyDown = (e: any): void => {
    if (!isTabKey(e.key) && !isTabBackKey(e)) e.preventDefault();

    if (isUpKey(e.key) && expanded) {
      if (isFocused !== 0) setIsFocused(isFocused - 1);
      else setIsFocused(field.options.length - 1);
    } else if (isDownKey(e.key) && expanded) {
      if (isFocused !== field.options.length - 1) setIsFocused(isFocused + 1);
      else setIsFocused(0);
    } else if (isActionKey(e.key)) {
      handleRowClick(field, field.options[isFocused].value);
    } else if (expanded && (isTabKey(e.key) || isTabBackKey(e))) {
      onToggle(field.id);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (field.value === '') setSelectText(defaultText);
    else setSelectText(field.options.find((option) => option.value === field.value).label);
  }, [field.value]);

  return (
    <>
      <Relative>
        <InputLabel fieldId={field.id} label={field.label} description={field.description} />

        <DropDownContainer expanded={expanded} error={field.error}>
          <FlexRow>
            {field.error && <ErrorIcon />}
            <TxtButtonDropDown
              id={field.id}
              type="button"
              role="combobox"
              aria-controls={`${field.id}List`}
              aria-labelledby={`${field.id}Label ${field.id}Error`}
              aria-expanded={expanded}
              aria-required={required}
              aria-activedescendant={field.options[isFocused].id}
              expanded={expanded}
              error={field.error}
              onKeyDown={(e) => handleKeyDown(e)}
              onClick={() => onToggle(field.id)}
            >
              <TxtSmallMediumDarkResp>{selectText}</TxtSmallMediumDarkResp>
              <BtnExpandDark expanded={expanded} />
            </TxtButtonDropDown>
          </FlexRow>

          {expanded && (
            <ul id={`${field.id}List`} tabIndex={-1} role="listbox">
              {field.options.map((option, index) => {
                return (
                  <DropDownListItem
                    id={option.id}
                    key={index}
                    role="option"
                    last={index === field.options.length - 1}
                    isFocused={isFocused === index}
                    onClick={() => handleRowClick(field, option.value)}
                  >
                    <TxtSmallMediumDarkResp>{option.label}</TxtSmallMediumDarkResp>
                  </DropDownListItem>
                );
              })}
            </ul>
          )}
        </DropDownContainer>
      </Relative>
      <MarginTop margin={6} />
      <InputError fieldId={field.id} error={field.error} />
    </>
  );
};
