import React, { useEffect, useState } from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { UserPositionType } from 'types/PositionTypes';
import { MyPositionBtn } from 'elements/new-design/buttons/icon-buttons';

type MyPositionProps = {
  positionActive: boolean;
  onFetchingPosition: (fetching: boolean) => void;
  onNewPosition: (coords: UserPositionType) => void;
  onError: () => void;
};

export const MyPosition = ({
  positionActive,
  onFetchingPosition,
  onNewPosition,
  onError,
}: MyPositionProps): JSX.Element => {
  const [active, setActive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { translate } = useI18n();
  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: Infinity,
  };

  const success = (pos) => {
    setActive(true);
    setIsFetching(false);
    onFetchingPosition(false);
    onNewPosition(pos.coords);
  };
  const error = () => {
    setIsFetching(false);
    onFetchingPosition(false);
    onError();
  };
  const handleMyPosition = () => {
    if (!active) {
      setIsFetching(true);
      onFetchingPosition(true);
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      onNewPosition(undefined);
      setActive(undefined);
    }
  };

  useEffect(() => {
    if (!positionActive) setActive(false);
  }, [positionActive]);

  return (
    <MyPositionBtn
      hiddenTxt={translate('GET_MY_POSITION')}
      active={active}
      fetching={isFetching}
      onButtonClick={handleMyPosition}
    />
  );
};
