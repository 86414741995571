import React, { HTMLProps, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import InfoAsset from 'assets/svg/info.svg';
import InfoGreyAsset from 'assets/svg/info-grey.svg';
import PlusAsset from 'assets/svg/plus.svg';
import PlusAssetBold from 'assets/svg/plus-bold.svg';
import MinusAsset from 'assets/svg/minus.svg';
import MinusAssetBold from 'assets/svg/minus-bold.svg';
import CloseAsset from 'assets/svg/close-blue.svg';
import SelectedAsset from 'assets/svg/selected.svg';
import ChevronIcon from 'assets/svg/chevron-down-link.svg';
import ChevronDarkIcon from 'assets/svg/chevron-down-dark.svg';
import ChevronWhiteIcon from 'assets/svg/chevron-down-white.svg';
import { TxtDefaultBoldAccentResp } from 'elements/new-design/Typography';

// Types
// ========================
type ButtonProps = HTMLProps<HTMLButtonElement> & {
  big?: boolean;
  type?: string;
  tabIndex?: number;
  children?: ReactNode;
  isSmallDevice?: boolean;
  isActive?: boolean;
};

// Elements
// ========================

const sizesBig = css`
  font-size: ${(props) => props.theme.fonts.fontSize.default};
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};
  padding: 1.7rem 3.3rem;
`;

const sizesSmall = css`
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};
  font-size: ${(props) => props.theme.fonts.fontSize.small};
  padding: 0.8rem 2rem;
`;

export const Button: FC<ButtonProps> = styled.button.attrs({
  type: 'button',
})`
  ${(_: ButtonProps) => ''}
  border: solid 0.1rem transparent;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  &:disabled {
    cursor: initial;
    box-shadow: none;
    opacity: 0.5;
  }

  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }

  ${(props) => (props.big ? sizesBig : sizesSmall)}
`;

export const ButtonCircle = styled(Button)`
  border-radius: 50%;
  padding: 0; /*overriding Button small*/
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Component
// ========================

export const BasicButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <Button ref={ref} type={props.type ? props.type : 'button'} {...props} tabIndex={props.tabIndex ? props.tabIndex : 0}>
    {props.children}
  </Button>
));

export const ButtonAccentPrimary = styled(BasicButton)`
  color: ${(props) => props.theme.colors.txtColLight};
  background: ${(props) => props.theme.colors.accentPrimary};

  &:hover {
    background: ${(props) => props.theme.colors.hoverAccentPrimary};
    &:disabled {
      background: ${(props) => props.theme.colors.accentPrimary};
    }
  }

  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentPrimary};
  }
`;

export const ButtonAccentSecondary = styled(BasicButton)`
  color: ${(props) => props.theme.colors.txtColLight};
  background: ${(props) => props.theme.colors.accentSecondary};

  &:hover {
    background: ${(props) => props.theme.colors.hoverAccentSecondary};
    &:disabled {
      background: ${(props) => props.theme.colors.accentSecondary};
    }
  }

  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentSecondary};
  }
`;

export const ButtonBorderPrimary = styled(BasicButton)`
  color: ${(props) => props.theme.colors.accentPrimary};
  background: ${(props) => props.theme.colors.bgHover};
  border: solid 0.1rem ${(props) => props.theme.colors.borderColPrimary};
  font-family: ${(props) => props.theme.fonts.fontFamily.medium};

  &:hover {
    color: ${(props) => props.theme.colors.hoverAccentPrimary};
    background: ${(props) => props.theme.colors.bgPrimary};
  }

  &:focus-visible {
    color: ${(props) => props.theme.colors.hoverAccentPrimary};
    background: ${(props) => props.theme.colors.bgPrimary};
  }
`;

const ButtonCircleAccentPrimary = styled(ButtonCircle)`
  height: 3.2rem;
  width: 3.2rem;
  color: ${(props) => props.theme.colors.txtColLight};
`;

/*ICON BUTTONS*/
export const ButtonInfo = styled(ButtonCircle)`
  height: 1.5rem;
  width: 1.5rem;
  border: 0.1rem solid ${(props) => props.theme.colors.mako};
  background: url(${InfoAsset}) no-repeat center center;
`;

export const ButtonInfoBgGrey = styled.div`
  background: url(${InfoGreyAsset}) no-repeat;
`;

export const ButtonAdd = styled(ButtonCircleAccentPrimary)`
  background: ${(props) => props.theme.colors.accentPrimary} url(${PlusAsset}) no-repeat center center;
  background-size: 72%;

  &:hover,
  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentPrimary} url(${PlusAsset}) no-repeat center center;
    background-size: 72%;
  }

  &:hover {
    &:disabled {
      background: ${(props) => props.theme.colors.accentPrimary} url(${PlusAsset}) no-repeat center center;
      background-size: 72%;
    }
  }
`;

export const ButtonAddBold = styled(ButtonAdd)`
  background: ${(props) => props.theme.colors.accentPrimary} url(${PlusAssetBold}) no-repeat center center;
  background-size: 60%;
  border: none;

  &:hover,
  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentPrimary} url(${PlusAssetBold}) no-repeat center center;
    background-size: 60%;
  }

  &:hover {
    &:disabled {
      background: ${(props) => props.theme.colors.accentPrimary} url(${PlusAssetBold}) no-repeat center center;
      background-size: 60%;
    }
  }
`;

export const ButtonSubtract = styled(ButtonCircleAccentPrimary)`
  background: ${(props) => props.theme.colors.accentPrimary} url(${MinusAsset}) no-repeat center center;
  background-size: 72%;

  &:hover,
  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentPrimary} url(${MinusAsset}) no-repeat center center;
    background-size: 72%;
  }

  &:hover {
    &:disabled {
      background: ${(props) => props.theme.colors.accentPrimary} url(${MinusAsset}) no-repeat center center;
      background-size: 72%;
    }
  }
`;

export const ButtonSubtractBold = styled(ButtonSubtract)`
  background: ${(props) => props.theme.colors.accentPrimary} url(${MinusAssetBold}) no-repeat center center;
  background-size: 60%;
  border: none;

  &:hover,
  &:focus-visible {
    background: ${(props) => props.theme.colors.hoverAccentPrimary} url(${MinusAssetBold}) no-repeat center center;
    background-size: 60%;
  }

  &:hover {
    &:disabled {
      background: ${(props) => props.theme.colors.accentPrimary} url(${MinusAssetBold}) no-repeat center center;
      background-size: 60%;
    }
  }
`;

export const ButtonClose = styled(ButtonCircle)`
  height: 1.8rem;
  width: 1.8rem;
`;

export const ButtonDialogClose = styled(ButtonCircleAccentPrimary)`
  background: url(${CloseAsset}) no-repeat center center;
  &:focus {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

type ButtonRadioProps = {
  checked: boolean;
};

export const ButtonRadio = styled(ButtonCircleAccentPrimary)`
  ${(_: ButtonRadioProps) => ''}
  width: 3.2rem;
  height: 3.2rem;
  flex-shrink: 0;
  background: ${(props) => {
    if (props.checked) {
      return `${props.theme.colors.accentPrimary} url(${SelectedAsset}) no-repeat center center }`;
    } else {
      return `${props.theme.colors.bgMystic}`;
    }
  }};
  border: ${(props) => (props.checked ? 'none' : props.theme.constants.borderPrimary)};

  &:active,
  &:disabled {
    background: ${(props) => props.theme.colors.accentPrimary};
  }
`;

export const ButtonAccentDark = styled(ButtonAccentPrimary)`
  background-color: ${(props) => props.theme.colors.readOnly};
`;

//Expand button with text and chevron
type TxtButtonProps = HTMLProps<HTMLButtonElement> & {
  text?: string;
  expanded?: boolean;
  left?: boolean;
  expired?: boolean;
  tabIndex?: string;
  children?: JSX.Element;
  error?: string;
  onButtonClick?: () => void;
};

export const TxTButton = styled(BasicButton)`
  border-radius: 0;
  padding: 0;
`;

export const ExpandChevronAccent = styled.svg`
  ${(_: TxtButtonProps) => ''}
  height: 1.44rem;
  width: 1.5rem;
  margin-left: ${(props) => (props.left ? '0' : '1rem')};
  margin-right: ${(props) => (props.left ? '1rem' : '0')};
  background: url(${ChevronIcon}) no-repeat center;
  transform: ${(props) => (props.expanded ? 'rotate(180deg);' : '')};
`;

const ExpandChevronDark = styled(ExpandChevronAccent)`
  ${(_: TxtButtonProps) => ''}
  background: url(${ChevronDarkIcon}) no-repeat center;
`;

const ExpandChevronLight = styled(ExpandChevronAccent)`
  ${(_: TxtButtonProps) => ''}
  background: url(${ChevronWhiteIcon}) no-repeat center;
`;

export const BtnExpandDark = ({ expanded }: TxtButtonProps) => <ExpandChevronDark expanded={expanded} />;

export const BtnExpandWhite = ({ expanded }: TxtButtonProps) => <ExpandChevronLight expanded={expanded} />;

export const TextBtnExpandAccent = ({ children, text, expanded, left, onButtonClick }: TxtButtonProps) => (
  <TxTButton data-cy={'textBtnExpandAccentCy'} onClick={onButtonClick}>
    {left && <ExpandChevronAccent expanded={expanded} aria-expanded={expanded ? 'true' : 'false'} left={left} />}
    {children ? children : <TxtDefaultBoldAccentResp>{text}</TxtDefaultBoldAccentResp>}
    {!left && <ExpandChevronAccent expanded={expanded} left={left} />}
  </TxTButton>
);

export const TxtButtonAccent = ({ ...props }: TxtButtonProps) => (
  <TxTButton data-cy={'txtBtnAccentCy'} onClick={props.onButtonClick}>
    <TxtDefaultBoldAccentResp>{props.text}</TxtDefaultBoldAccentResp>
  </TxTButton>
);
