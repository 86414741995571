import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { FormFieldType } from 'types/FormTypes';
import { InputLabel } from './InputLabel';
import { InputError } from './InputError';
import { ErrorIcon } from './InputErrorIcon';
import { TxtPlaceHolderCSS } from 'elements/new-design/InputFields';

type TextInputProps = {
  field: FormFieldType;
  children?: JSX.Element | JSX.Element[];
  onInputChange?: (field: FormFieldType, value: string | number) => void;
  onInputBlur?: (field: FormFieldType) => void;
};

type StyleProps = {
  error: boolean;
};

const TextField = styled.input`
  ${(_: StyleProps) => ''}
  ${TxtPlaceHolderCSS}
  width: 100%;
  height: 5.5rem;
  padding: 0 1.6rem;
  border-radius: 0.8rem;
  border: ${(props) => (props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary)};
  background: ${(props) => (props.error ? props.theme.colors.bgError : props.theme.colors.bgSecondary)};

  :focus-visible {
    box-shadow: ${(props) => (props.error ? 'none' : props.theme.constants.borderFocusLight)};
  }

  ::-moz-placeholder {
    opacity: 1;
  }

  ::placeholder {
    opacity: 0.8;
  }
`;

const RelativeContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const TextFieldPhone = styled(TextField)`
  padding-left: 6.8rem;
`;

const CountryCode = styled.span`
  ${(_: StyleProps) => ''}
  ${TxtPlaceHolderCSS}
  height: 100%;
  position: absolute;
  padding: 1.6rem 1.2rem 0 1.2rem;
  border-right: ${(props) =>
    props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary};
`;

export const TextInputComplete = ({ field, children }: TextInputProps) => {
  return (
    <>
      <InputLabel fieldId={field.id} label={field.label} description={field.description} />
      <RelativeContainer>
        {Boolean(field.error) && <ErrorIcon />}
        {children}
      </RelativeContainer>
      <InputError fieldId={field.id} error={field.error} />
    </>
  );
};

export const TextInput = ({ field, onInputChange }: TextInputProps) => {
  const { translate } = useI18n();
  return (
    <TextInputComplete field={field}>
      <TextField
        id={field.id}
        tabIndex={0}
        error={Boolean(field.error)}
        value={field.value}
        type={field.type}
        placeholder={translate(field.label)}
        aria-labelledby={`${field.id} ${field.id}Error`}
        onChange={(e) => onInputChange(field, e.target.value)}
      />
    </TextInputComplete>
  );
};

export const PhoneInputCountryNorway = ({ field, onInputChange, onInputBlur }: TextInputProps) => {
  const { translate } = useI18n();
  return (
    <TextInputComplete field={field}>
      <RelativeContainer>
        <CountryCode error={Boolean(field.error)}>+47</CountryCode>
        <TextFieldPhone
          id={field.id}
          tabIndex={0}
          error={Boolean(field.error)}
          value={field.value}
          type={field.type}
          placeholder={translate(field.label)}
          onChange={(e) => onInputChange(field, e.target.value)}
          onBlur={() => onInputBlur(field)}
        />
      </RelativeContainer>
    </TextInputComplete>
  );
};
