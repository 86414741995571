import React, { useState } from 'react';
import styled from 'styled-components';
import { TxtDefaultBoldDarkResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { BorderButtonDark, BgButtonDark } from 'elements/new-design/buttons/text-buttons';
import { ModalButtonDark } from 'elements/new-design/buttons/text-buttons';
import { CookiesInformationDialog } from 'components/new-design/dialogs/page-dialogs/CookiesInformationDialog';
import { MarginTop } from 'elements/distance/Margins';

type SettingsProps = {
  cookieInfo: any;
  onAcceptAll: () => void;
  onGoToSettings: () => void;
};

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.8rem;

  button:last-of-type {
    margin-top: 2.4rem;
  }

  @media ${(props) => props.theme.breakpoints.small} {
    flex-direction: row;
    justify-content: space-between;

    button {
      flex: 1;
    }

    button:last-of-type {
      margin-top: 0;
      margin-left: 2.4rem;
    }
  }
`;

export const CookieInformation = ({ cookieInfo, onAcceptAll, onGoToSettings }: SettingsProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <h2>
        <TxtDefaultBoldDarkResp>{cookieInfo.title}</TxtDefaultBoldDarkResp>
      </h2>
      <MarginTop margin={1.6}>
        <p>
          <TxtSmallMediumDarkResp>{cookieInfo.text}</TxtSmallMediumDarkResp>
        </p>
      </MarginTop>
      <MarginTop margin={1.6}>
        <ModalButtonDark text={cookieInfo.linktText} onButtonClick={() => setModalOpen(true)} />
      </MarginTop>

      {modalOpen && (
        <CookiesInformationDialog slug={cookieInfo.link.slug.current} onModalClose={() => setModalOpen(false)} />
      )}
      <ActionButtons>
        <BorderButtonDark onClick={onGoToSettings}>{cookieInfo.goToSettingsText}</BorderButtonDark>
        <BgButtonDark data-cy={'acceptAllCookiesButton'} onClick={onAcceptAll}>
          {cookieInfo.acceptText}
        </BgButtonDark>
      </ActionButtons>
    </>
  );
};
