import React, { useState } from 'react';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { Constants } from 'utils/Constants';
import { LoginDialog } from './LoginDialog';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { ForgotPasswordSuccessDialog } from './ForgotPasswordSuccessDialog';

type LoginProps = {
  showCreate: boolean;
  redirectTo: string;
  onLogin: () => void;
  onModalClose: () => void;
};

const LOGIN = 'login';
const PASSWORD = 'password';
const PASSWORD_SUCCESS = 'passwordSuccess';

export const Login = ({ ...props }: LoginProps) => {
  const [currentModal, setCurrentModal] = useState(LOGIN);
  const { navigateWithState } = useStateNavigation();
  const { cacheItem } = usePersistentStorage();

  const handleModalClose = () => {
    setCurrentModal(null);
    props.onModalClose();
  };

  const handleCreateProfile = (): void => {
    cacheItem(Constants.CACHE_REDIRECT, props.redirectTo);
    handleModalClose();
    navigateWithState(Constants.ROUTE_CREATE_PROFILE);
  };

  return (
    <>
      {currentModal === LOGIN && (
        <LoginDialog
          showCreate={true}
          onLogin={props.onLogin}
          onToggleModal={() => setCurrentModal(PASSWORD)}
          onCreateProfile={handleCreateProfile}
          onModalClose={props.onModalClose}
        />
      )}
      {currentModal === PASSWORD && (
        <ForgotPasswordDialog
          onToggleModal={() => setCurrentModal(PASSWORD_SUCCESS)}
          onModalClose={props.onModalClose}
        />
      )}

      {currentModal === PASSWORD_SUCCESS && (
        <ForgotPasswordSuccessDialog onToggleModal={() => setCurrentModal(LOGIN)} onModalClose={props.onModalClose} />
      )}
    </>
  );
};
