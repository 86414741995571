import React, { useState } from 'react';
import { useAuthentication } from 'hooks/useAuthentication';
import { useSelector } from 'react-redux';
import { useI18n } from 'utils/i18n/usei18n';
import { AppState } from 'store/index';
import { DialogPromptLogin } from 'components/new-design/dialogs/DialogPromptLogin';
import { Login } from 'components/new-design/dialogs/login-dialog/Login';
import { CampaignSorpasset } from './sorpasset/CampaignSorpasset';

type CampaignPurchaseProps = {
  campaign: any;
  onModalClose: () => void;
};

const LOGIN = 'LOGIN';
const PROMPT_LOGIN = 'PROMPT_LOGIN';
const PAYMENT = 'PAYMENT';

export const CampaignPurchase = ({ campaign, onModalClose }: CampaignPurchaseProps): JSX.Element => {
  const { translate } = useI18n();
  const { isAuthenticated } = useAuthentication();
  const accountUser = isAuthenticated();
  const [modalOpen, setModalOpen] = useState(accountUser ? PAYMENT : PROMPT_LOGIN);
  const user = useSelector((store: AppState) => store.user);
  const order = useSelector((store: AppState) => store.order);

  const handleModalClose = () => {
    setModalOpen(null);
    onModalClose();
  };

  return (
    <>
      {modalOpen === PROMPT_LOGIN && (
        <DialogPromptLogin
          headline={translate('SORPASSET_LOGIN_HEAD')}
          text={translate('SORPASSET_LOGIN_TEXT')}
          returnToPath={window.location.pathname}
          onModalClose={handleModalClose}
          onLogin={() => setModalOpen(LOGIN)}
        />
      )}
      {modalOpen === LOGIN && (
        <Login
          showCreate={true}
          redirectTo={window.location.pathname}
          onLogin={() => setModalOpen(PAYMENT)}
          onModalClose={handleModalClose}
        />
      )}

      {modalOpen === PAYMENT && (
        <>
          {campaign.apiId === 'GOA_CAMPAIGN_SORPASSET' && (
            <CampaignSorpasset campaign={campaign} order={order} user={user} onModalClose={handleModalClose} />
          )}
        </>
      )}
    </>
  );
};
