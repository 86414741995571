import { SET_LOCATIONS, LocationsState } from '../../types/LocationTypes';

const initialState: LocationsState = {
  stopPlaces: undefined,
  stopPlaceProblemCombinationTexts: undefined,
  assistanceLastOrderTimeHours: undefined,
  assistanceLocations: undefined,
};

export default function locationsReducer(state = initialState, action): LocationsState {
  switch (action.type) {
    case SET_LOCATIONS:
      return action.payload;

    default:
      return state;
  }
}
