import React, { useRef, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { ClearFieldBtn, VisibilityBtn } from 'elements/new-design/buttons/icon-buttons';
import { TextInputDefault, InputLabel, InputPlaceholderLabel, InputErrorLabel } from 'elements/new-design/InputFields';
import { FlexRow } from 'elements/containers/Containers';
import { MarginTop } from 'elements/distance/Margins';
import magnifyer from 'assets/svg/magnifyer.svg';
import { Icon } from 'elements/icon/Icon';
import { isActionKey, isDownKey, isUpKey } from 'utils/Accessability';
import { TxtDefaultBoldDarkResp } from 'elements/new-design/Typography';

type InputFieldProps = {
  type: string;
  name: string;
  label: string;
  value: string;
  error: string;
  onFieldChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFieldBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onFieldFocus?: () => void;
  onClearButtonClick?: () => void;
};

type InputFieldWithDropdownProps = {
  type: string;
  name: string;
  label: string;
  value: string;
  error: string;
  id?: string;
  required?: boolean;
  selectOptions?: string[];
  bold?: boolean;
  hideResults: boolean;
  onFieldChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFieldBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onFieldFocus?: () => void;
  onClearButtonClick?: () => void;
  onInputChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

type StyleProps = {
  visible?: boolean;
  hideResults?: boolean;
  noHit?: boolean;
};

export const OuterContainer = styled.div`
  width: 100%;
  height: 8rem;
`;

const InnerContainer = styled(FlexRow)`
  height: 4rem;
  position: relative;
`;

const PlaceholderLabel = styled(InputPlaceholderLabel)`
  ${(_: StyleProps) => ''}
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const IconRightContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${TextInputDefault} {
    padding-right: 2.8rem;
  }
`;

const RightIcon = styled(FlexRow)`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 2.4rem;
  right: 0;
`;

const MagnifyerIcon = styled(Icon)`
  position: absolute;
  right: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  filter: brightness(0) saturate(100%) invert(15%) sepia(9%) saturate(2330%) hue-rotate(199deg) brightness(91%)
    contrast(79%);
`;

const ClearFieldBtnContainer = styled.div`
  position: absolute;
  right: 1rem;
`;

const HiddenElement = styled.span`
  ${(_: StyleProps) => ''}
  height: 2rem;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const TextFieldDefault = ({ ...props }: InputFieldProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { translate } = useI18n();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    inputRef.current.focus();
    setIsFocused(true);
    props.onFieldFocus ? props.onFieldFocus() : null;
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    props.onFieldBlur(e);
  };

  return (
    <OuterContainer>
      <HiddenElement visible={props.value !== '' || isFocused}>
        <InputLabel htmlFor={props.name}>{translate(props.label)}</InputLabel>
      </HiddenElement>
      <InnerContainer onClick={handleFocus}>
        <TextInputDefault
          ref={inputRef}
          id={props.name}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={(e) => props.onFieldChange(e)}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e)}
        />
        <PlaceholderLabel visible={props.value === '' && !isFocused}>{translate(props.label)}</PlaceholderLabel>
      </InnerContainer>
      <MarginTop margin={0.4} />
      <HiddenElement visible={props.error !== undefined}>
        <InputErrorLabel htmlFor={props.name}>{translate(props.error)}</InputErrorLabel>
      </HiddenElement>
    </OuterContainer>
  );
};

export const TextFieldPassword = ({ ...props }: InputFieldProps): JSX.Element => {
  const { translate } = useI18n();
  const [fieldType, setFieldType] = useState(props.type);
  const toggleFieldType = () => {
    if (fieldType === props.type) setFieldType('text');
    else setFieldType(props.type);
  };
  return (
    <IconRightContainer>
      <TextFieldDefault {...props} type={fieldType} />
      <RightIcon>
        <VisibilityBtn
          data-cy={'showPasswordCy'}
          active={fieldType === 'text'}
          hiddenTxt={fieldType === 'text' ? translate('HIDE_PASSWORD') : translate('SHOW_PASSWORD')}
          onButtonClick={toggleFieldType}
        />
      </RightIcon>
    </IconRightContainer>
  );
};

export const TextFieldSearch = ({ ...props }: InputFieldWithDropdownProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { translate } = useI18n();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    inputRef.current.focus();
    setIsFocused(true);
    props.onFieldFocus ? props.onFieldFocus() : null;
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    props.onFieldBlur(e);
  };

  const handleClearButtonClick = () => {
    setIsFocused(false);
    props.onClearButtonClick ? props.onClearButtonClick() : null;
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (isActionKey(e.key) || isDownKey(e.key)) {
      e.preventDefault();
      document.getElementById('RTVStation0').focus();
    }
  };

  return (
    <OuterContainer>
      <HiddenElement visible={props.value !== '' || isFocused}>
        <InputLabel htmlFor={props.name}>{translate(props.label)}</InputLabel>
      </HiddenElement>
      <InnerContainer onClick={handleFocus}>
        <TextInputDefault
          ref={inputRef}
          id={props.name}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={(e) => props.onFieldChange(e)}
          onFocus={handleFocus}
          onBlur={(e) => handleBlur(e)}
          onKeyDown={(e) => handleSearchKeyDown(e)}
        />
        <PlaceholderLabel visible={props.value === '' && !isFocused}>{translate(props.label)}</PlaceholderLabel>
        <ClearFieldBtnContainer>
          {props.value !== '' && (
            <ClearFieldBtn hiddenTxt={translate('DELETE_STATION')} onButtonClick={handleClearButtonClick} />
          )}
        </ClearFieldBtnContainer>
        {props.value === '' && <MagnifyerIcon icon={magnifyer} />}
      </InnerContainer>

      <RTVSearchResults
        resultList={props.selectOptions}
        onSelect={(e) => props.onInputChange(e)}
        hideResults={props.hideResults}
      />

      <MarginTop margin={0.4} />
      <HiddenElement visible={props.error !== undefined}>
        <InputErrorLabel htmlFor={props.name}>{translate(props.error)}</InputErrorLabel>
      </HiddenElement>
    </OuterContainer>
  );
};

export const TextField = ({ ...props }: InputFieldProps & InputFieldWithDropdownProps): JSX.Element => {
  switch (props.type) {
    case 'password':
      return <TextFieldPassword {...props} />;
    case 'GANsearch':
      return <TextFieldSearch {...props} />;
    default:
      return <TextFieldDefault {...props} />;
  }
};

///// Custom search component for RTV /////

type RTVSearchResultsProps = {
  resultList: any;
  hideResults: boolean;
  onSelect: (e: any) => void;
};

const ResultListContainer = styled.div<StyleProps>`
  position: absolute;
  background-color: white;
  width: 94.5%;
  padding: 1rem;
  border: 1px solid black;
  border-top: none;
  z-index: ${(props) => (props.hideResults ? -1 : 2)};

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 96.5%;
  }
`;

const StationOption = styled(TxtDefaultBoldDarkResp)<StyleProps>`
  cursor: ${(props) => (props.noHit ? 'unset' : 'pointer')};
  :focus-visible,
  :hover {
    border-bottom: ${(props) => (props.noHit ? 'none' : props.theme.newconstants.borderDarkThick)};
  }
`;

const handleKeyDown = (e, index, len, onSelect) => {
  e.preventDefault();
  if (isUpKey(e.key)) {
    if (index === 0) document.getElementById(`RTVStation${index}`).focus();
    else document.getElementById(`RTVStation${index - 1}`).focus();
  }
  if (isDownKey(e.key))
    if (index === len - 1) document.getElementById(`RTVStation${index}`).focus();
    else document.getElementById(`RTVStation${index + 1}`).focus();
  if (isActionKey(e.key)) onSelect(e);
};

export const RTVSearchResults = ({ resultList, onSelect, hideResults }: RTVSearchResultsProps): JSX.Element => {
  const { translate } = useI18n();
  return (
    <ResultListContainer hideResults={hideResults}>
      {resultList && resultList.length > 0 && (
        <>
          {resultList.map((station: string, index: number) => {
            const isStationNoHit = station !== translate('NO_HIT');
            return (
              <React.Fragment key={index}>
                {index !== 0 && <MarginTop margin={0.8} />}
                <StationOption
                  tabIndex={1}
                  id={`RTVStation${index}`}
                  onClick={(e) => isStationNoHit && onSelect(e)}
                  onKeyDown={(e) => isStationNoHit && handleKeyDown(e, index, resultList.length, onSelect)}
                  noHit={!isStationNoHit}
                >
                  {station}
                </StationOption>
              </React.Fragment>
            );
          })}
        </>
      )}
    </ResultListContainer>
  );
};
