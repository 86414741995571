import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { LocationType } from 'types/LocationTypes';
import { StationFieldType, lists } from '../StationSearchService';
import { TxtSmallRegularDarkResp } from 'elements/new-design/Typography';
import { TextButtonBoldDarkSmall, BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginTop, MarginBottom } from 'elements/distance/Margins';

type ResultsProps = {
  field: StationFieldType;
  listFocusState: { row: number; list: string };
  resultList: LocationType[];
  earlierSearchedJourneys: [{ to: string; from: string }] | null;
  shortcuts: string[];
  onSelect: (field: string, station: string, secondStation: string | null) => void;
};

const ResultListContainer = styled.ul`
  list-style: none;
  ul {
    list-style: none;
  }

  li {
    cursor: pointer;
    :hover {
      ${TextButtonBoldDarkSmall} {
        border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
      }
    }
  }
`;

const ListHeadline = styled(TxtSmallRegularDarkResp)`
  font-size: 1.6rem;
`;

const ShortcutsListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.6rem;

  li {
    margin: 0.4rem;
  }
`;

export const Results = ({
  field,
  listFocusState,
  resultList,
  earlierSearchedJourneys,
  shortcuts,
  onSelect,
}: ResultsProps): JSX.Element => {
  const { translate } = useI18n();
  return (
    <ResultListContainer id={`${field.name}ListBox`} role="listbox">
      {!resultList && earlierSearchedJourneys && (
        <>
          <li>
            <ListHeadline id="earlierSearchTxt">{translate('EARLIER_SEARCH')}</ListHeadline>
            <MarginBottom margin={0.8} />
          </li>

          <ul aria-labelledby="earlierSearchTxt">
            {earlierSearchedJourneys.map((station, index) => (
              <li key={index} role="option" onClick={() => onSelect(field.name, station.from, station.to)}>
                {index !== 0 && <MarginTop margin={0.8} />}
                <TextButtonBoldDarkSmall
                  id={`${lists.earlierSearches}${index}`}
                  tabIndex={-1}
                  inFocus={listFocusState.list === lists.earlierSearches && listFocusState.row === index}
                >
                  {`${station.from} - ${station.to}`}
                </TextButtonBoldDarkSmall>
              </li>
            ))}
          </ul>
        </>
      )}

      {resultList && resultList.length > 0 && (
        <>
          <li>
            <ListHeadline id="stationsTxt">{translate('STATIONS')}</ListHeadline>
            <MarginBottom margin={0.8} />
          </li>

          <ul aria-labelledby="stationsTxt">
            {resultList.map((station, index) => (
              <li key={index} role="option" onClick={() => onSelect(field.name, station.name, null)}>
                {index !== 0 && <MarginTop margin={0.8} />}
                <TextButtonBoldDarkSmall
                  id={`${lists.searchResult}${index}`}
                  tabIndex={-1}
                  inFocus={listFocusState.list === lists.searchResult && listFocusState.row === index}
                >
                  {station.name}
                </TextButtonBoldDarkSmall>
              </li>
            ))}
          </ul>
        </>
      )}
      {!resultList && (
        <>
          {earlierSearchedJourneys && <MarginTop margin={2.4} />}
          <li>
            <ListHeadline id="shortcutsTxt">{translate('SHORTCUTS')}</ListHeadline>
            <MarginBottom margin={0.8} />
          </li>

          <ShortcutsListContainer aria-labelledby="shortcutsTxt">
            {shortcuts.map((station: string, index: number) => (
              <li key={index} role="option">
                <BorderButtonDark
                  id={`${lists.shortcuts}${index}`}
                  tabIndex={-1}
                  inFocus={listFocusState.list === lists.shortcuts && listFocusState.row === index}
                  onClick={() => onSelect(field.name, station, null)}
                >
                  {station}
                </BorderButtonDark>
              </li>
            ))}
          </ShortcutsListContainer>
        </>
      )}
    </ResultListContainer>
  );
};
