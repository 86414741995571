import { getAssistanceFields, fieldNames } from './AssistanceFormData';
import { validatePhone, validateIsRequired, validateIsOnlyNumbers } from 'utils/Validation';
import { FormFieldType } from 'types/FormTypes';
import { AssistanceLocationType } from 'types/LocationTypes';

export type FormType = Map<string, FormFieldType>;
export const CONFIRMATION_EMAIL = '100000000';

// Helper Functions
// =========================
const setErrorMessages = (rule: string, field: FormFieldType): FormFieldType => {
  switch (rule) {
    case 'isRequired':
      if (field.value && !validateIsRequired(field.value)) field.error = 'MANDATORY_STATION';
      if (field.value && field.value === 'Velge alternativ') field.error = 'MANDATORY_CHOOSE_OPTION';
      if (!field.value && !validateIsRequired(field.value)) field.error = 'MANDATORY';
      break;
    case 'isValidPhone':
      if (!field.value || !validatePhone(field.value)) field.error = 'MUST_HAVE_NORWEGIAN_PHONE_SHORT';
      break;
    case 'isOnlyNumbers':
      if (field.value && !validateIsOnlyNumbers(field.value)) field.error = 'ONLY_NUMBERS_ERROR';
      break;
    default:
      break;
  }
  return field;
};

const getStationBooking = (station: AssistanceLocationType, form: FormType, isFrom: boolean) => {
  return {
    assistanceAtStation: form.get(fieldNames.assistanceLocation).value.includes(station.id),
    id: station.id,
    information: isFrom ? form.get(fieldNames.fromStationInfo)?.value : form.get(fieldNames.toStationInfo)?.value,
  };
};

export const AssistanceService = {
  getAssistanceForm: (assistanceLocations, assistanceOptions, user): FormType => {
    const form = new Map();
    const fields = getAssistanceFields(assistanceLocations, assistanceOptions, user);
    fields.forEach((item) => {
      if (item) form.set(item.id, item);
    });
    return form;
  },
  validateField: (name: string, form: FormType): FormFieldType => {
    const field = form.get(name);
    if (!field.validationRules.length) return field;
    field.validationRules.forEach((rule) => {
      if (field.id === fieldNames.phoneNumber && form.get(fieldNames.confirmationMethod).value === CONFIRMATION_EMAIL)
        setErrorMessages(rule, field);
      else setErrorMessages(rule, field);
    });
    return field;
  },
  getBooking: (
    fromStation: AssistanceLocationType = null,
    toStation: AssistanceLocationType = null,
    form: FormType,
  ) => {
    return {
      assistanceAid: form.get(fieldNames.assistanceAid).value,
      departureStation: getStationBooking(fromStation, form, true),
      arrivalStation: getStationBooking(toStation, form, false),
      assistanceNeeds: form.get(fieldNames.assistanceNeeds).value,
      confirmationMethod: form.get(fieldNames.confirmationMethod).value,
      descriptionOfNeed: form.get(fieldNames.descriptionOfNeed).value,
      luggage: form.get(fieldNames.luggage).value,
      phoneNumber:
        form.get(fieldNames.confirmationMethod).value !== CONFIRMATION_EMAIL
          ? form.get(fieldNames.phoneNumber).value
          : null,
      travelAlone: form.get(fieldNames.travelAlone).value === 'Ja',
    };
  },
};
