import React from 'react';
import styled from 'styled-components';
import SelectedAsset from 'assets/svg/checkbox-square-on.svg';
import { isActionKey } from 'utils/Accessability';
import { HiddenInput } from './HiddenInput';
import { HiddenSpan, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';

type CheckboxProps = {
  id: string | number;
  label?: string;
  value?: string | number;
  checked: boolean;
  onToggle: () => void;
};

type StyleProps = {
  checked: boolean;
};

const RadioButtonWrapper = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  align-items: center;
  grid-template-columns: repeat(2, auto);
`;

const RadioButtonContainer = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
`;

const RadioButtonStyled = styled.div`
  ${(_: StyleProps) => ''}
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  cursor: pointer;
  border: ${(props) => (props.checked ? props.theme.constants.borderAccent : props.theme.constants.borderPrimary)};
  background: ${(props) =>
    props.checked ? `${props.theme.colors.accentPrimary} url(${SelectedAsset}) no-repeat center` : 'transparent'};
  input:focus + & {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

const RadioButton = ({ id, value, checked, onToggle }: CheckboxProps) => {
  return (
    <RadioButtonContainer>
      <HiddenInput
        id={id}
        type="radio"
        value={value}
        checked={checked}
        onToggle={onToggle}
        aria-labelledby={`${id}Label`}
      />
      <RadioButtonStyled
        checked={checked}
        onClick={onToggle}
        onKeyDown={(e) => (isActionKey(e.key) ? onToggle() : null)}
      />
    </RadioButtonContainer>
  );
};

export const RadiobuttonLabelRight = ({ ...props }: CheckboxProps) => {
  return (
    <RadioButtonWrapper>
      <label htmlFor={props.id.toString()}>
        <RadioButton id={props.id} value={props.value} checked={props.checked} onToggle={props.onToggle} />
        <HiddenSpan>{props.label}</HiddenSpan>
      </label>
      <TxtSmallMediumDarkResp aria-hidden={true}>{props.label}</TxtSmallMediumDarkResp>
    </RadioButtonWrapper>
  );
};
