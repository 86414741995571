import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { GOADate } from 'utils/date/GOADate';
import { DeviationMessage } from 'types/DeviationTypes';
import { BusForTrain } from './BusForTrain';
import {
  TxtMediumBoldDarkResp,
  TxtDefaultRegularDarkResp,
  TxtLargeBoldDarkResp,
  HiddenSpan,
  TxtSmallBoldDarkResp,
} from 'elements/new-design/Typography';
import { FlexBetweenWithWrap, FlexRowStart } from 'elements/containers/Containers';
import { MarginTop, MarginBottom } from 'elements/distance/Margins';

type GeneralDeviationProps = {
  deviation: any;
};
type StyleProps = {
  isCritical: boolean;
};

const DeviationContainer = styled.div`
  ${(_: StyleProps) => ''}
  padding: 0.8rem 1.6rem;
  margin-bottom: 2.4rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  grid-template-areas: 'title' 'body';
  grid-gap: 0.8rem;
  box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
`;

const Title = styled(FlexRowStart)`
  width: 100%;
  grid-area: title;

  @media ${(props) => props.theme.devices.large} {
    padding-bottom: 0.8rem;
  }
`;

const Body = styled.div`
  grid-area: body;
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;

  @media ${(props) => props.theme.devices.large} {
    grid-template-rows: 1fr;
    grid-template-columns: 48% auto;
  }
`;

const DescriptionInformation = styled.div`
  padding-top: 1.6rem;
  border-top: ${(props) => props.theme.newconstants.borderDarkThin};

  @media ${(props) => props.theme.devices.large} {
    padding-left: 1.6rem;
    padding-top: 0;
    border-left: ${(props) => props.theme.newconstants.borderDarkThin};
    border-top: none;
  }

  a {
    padding-bottom: 0;
  }

  ul {
    list-style: none;

    li {
      margin-top: 0.8rem;
      display: flex;
      flex-direction: row;

      p {
        margin-left: 0.8rem;
      }
      div {
        margin-top: 0.7rem;
      }
    }
  }
`;

const formatUpdatedTime = (
  deviation: any,
  format: string,
  translate: (key: string) => string,
  getI18nDate: (date: GOADate) => GOADate,
) => {
  //format === 'full' is for assistive technologies like screen readers
  const fromDate = getI18nDate(deviation.fromDateTime);
  const updatedDate = getI18nDate(deviation.updatedTime);
  const untilDate = getI18nDate(deviation.untilDateTime);

  if (!deviation.isPlanned) {
    const isToday = updatedDate.isSameDay(new GOADate());
    return `${isToday ? translate('TODAY') : updatedDate.formatDate('format')} ${translate(
      'CLOCK_SHORT',
    )} ${deviation.updatedTime.formatTime()}`;
  } else {
    const isSameYear = fromDate.isSameYear(untilDate) && new GOADate().isSameYear(fromDate);
    const fromTime = deviation.withDailyTimeSlot
      ? ''
      : `${translate('CLOCK_SHORT')} ${deviation.fromDateTime.formatTime()}`;

    const toTime = deviation.withDailyTimeSlot
      ? ''
      : `${translate('CLOCK_SHORT')} ${deviation.untilDateTime.formatTime()}`;

    return `${fromDate.formatDate(format)} ${!isSameYear ? deviation.fromDateTime.getYear() : ''} ${fromTime} 
    ${format === 'full' ? translate('TO') : '-'} ${untilDate.formatDate(format)} ${
      !isSameYear ? deviation.untilDateTime.getYear() : ''
    } ${toTime}`;
  }
};

const formatInterval = (
  deviation: DeviationMessage,
  format: string,
  currentLanguage: string,
  translate: (key: string) => string,
) => {
  //format === 'full' is for assistive technologies like screen readers
  const displayWeekdays = deviation.weekDays && deviation.weekDays.no?.length < 7;
  if (displayWeekdays || deviation.withDailyTimeSlot) {
    const weekdays = displayWeekdays
      ? currentLanguage === 'no'
        ? deviation.weekDays.no.join(', ').toLowerCase()
        : deviation.weekDays.en.join(', ').toLowerCase()
      : '';
    const timePrefix = format === 'full' ? translate('CLOCK') : translate('CLOCK_SHORT');
    const timeDivider = format === 'full' ? translate('AND') : '-';
    const time = `${translate(
      'BETWEEN',
    )} ${timePrefix} ${deviation.fromDateTime.formatTime()} ${timeDivider} ${deviation.untilDateTime.formatTime()}`;

    if (displayWeekdays && deviation.withDailyTimeSlot) {
      return `${translate('ONLY_VALID')} ${weekdays} ${time}`;
    } else if (displayWeekdays && !deviation.withDailyTimeSlot) {
      return `${translate('ONLY_VALID')} ${weekdays}`;
    } else {
      return `${translate('ONLY_VALID')} ${time}`;
    }
  }
  return '';
};

const formatDescription = (description: string) => description.split('\n');

export const GeneralDeviation = ({ deviation }: GeneralDeviationProps): JSX.Element => {
  const { currentLanguage, translate, getI18nDate } = useI18n();
  return (
    <DeviationContainer isCritical={deviation.messageAlert}>
      {deviation.messageAlert && (
        <Title>
          <h3>
            <TxtLargeBoldDarkResp>
              {currentLanguage === 'no' ? deviation.messageAlert.no : deviation.messageAlert.en}
            </TxtLargeBoldDarkResp>
          </h3>
        </Title>
      )}
      <Body>
        <div>
          <MarginBottom margin={0.8}>
            <FlexBetweenWithWrap>
              <p>
                <TxtMediumBoldDarkResp>
                  {deviation.stationFrom === deviation.stationTo
                    ? `${deviation.stationFrom}`
                    : `${deviation.stationFrom} - ${deviation.stationTo}`}
                </TxtMediumBoldDarkResp>
              </p>

              {deviation.hasReplacements && <BusForTrain deviationId={deviation.situationId} />}
            </FlexBetweenWithWrap>
          </MarginBottom>

          <p>
            <HiddenSpan>{formatUpdatedTime(deviation, 'full', translate, getI18nDate)}</HiddenSpan>
            <TxtSmallBoldDarkResp aria-hidden={true}>
              {formatUpdatedTime(deviation, 'small', translate, getI18nDate)}
            </TxtSmallBoldDarkResp>
          </p>

          <MarginTop margin={0.8}>
            <p>
              <HiddenSpan>{formatInterval(deviation, 'full', currentLanguage, translate)}</HiddenSpan>
              <TxtDefaultRegularDarkResp aria-hidden={true}>
                {formatInterval(deviation, 'small', currentLanguage, translate)}
              </TxtDefaultRegularDarkResp>
            </p>
          </MarginTop>
        </div>

        <DescriptionInformation>
          <p>
            {formatDescription(
              currentLanguage === 'no' ? deviation.messageDescription.no : deviation.messageDescription.en,
            ).map((str, index) => (
              <TxtDefaultRegularDarkResp key={index}>
                {str}
                <br />
              </TxtDefaultRegularDarkResp>
            ))}
          </p>
        </DescriptionInformation>
      </Body>
    </DeviationContainer>
  );
};
