import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import {
  DialogCenterContainer,
  DialogLeftHeaderContainer,
  DialogLeftContainer,
  DialogPageContainer,
  DialogCookieContainer,
} from 'elements/new-design/Dialogs';
import { CloseBtn } from 'elements/new-design/buttons/icon-buttons';
import { FlexEnd } from 'elements/containers/Containers';

type DialogTypeProps = {
  width?: number;
  height?: number;
  children: JSX.Element | JSX.Element[];
  onModalClose?: () => void;
};

export const RelativeParent = styled.div`
  position: relative;
`;

export const CloseButtonContainer = styled(FlexEnd)`
  padding: 2rem 2rem 0 2rem;
`;

export const DialogCenter = ({ width, height, onModalClose, children }: DialogTypeProps) => {
  return (
    <DialogCenterContainer width={width} height={height}>
      <CloseButtonContainer>
        <CloseBtn
          id="dialogCloseBtn"
          width={3.5}
          height={3.5}
          hiddenTxt={useI18n().translate('LABEL_CLOSE_MODAL')}
          onButtonClick={onModalClose}
        />
      </CloseButtonContainer>
      {children}
    </DialogCenterContainer>
  );
};

export const DialogLeftHeader = ({ onModalClose, children }: DialogTypeProps) => {
  return (
    <DialogLeftHeaderContainer>
      <>
        <FlexEnd>
          <CloseBtn hiddenTxt={useI18n().translate('LABEL_CLOSE_MODAL')} onButtonClick={onModalClose} />
        </FlexEnd>
        {children}
      </>
    </DialogLeftHeaderContainer>
  );
};

export const DialogLeft = ({ children }: DialogTypeProps) => {
  return (
    <DialogLeftContainer>
      <RelativeParent>{children}</RelativeParent>
    </DialogLeftContainer>
  );
};

export const DialogPage = ({ onModalClose, children }: DialogTypeProps) => {
  return (
    <DialogPageContainer>
      <CloseButtonContainer>
        <CloseBtn
          id="dialogCloseBtn"
          width={3.5}
          height={3.5}
          hiddenTxt={useI18n().translate('LABEL_CLOSE_MODAL')}
          onButtonClick={onModalClose}
        />
      </CloseButtonContainer>
      {children}
    </DialogPageContainer>
  );
};

export const DialogCookie = ({ children }: DialogTypeProps) => (
  <DialogCookieContainer>{children}</DialogCookieContainer>
);
