import React, { useEffect, createRef } from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Loader } from 'elements/loader/Loader';
import { CustomerServiceContactInfo } from 'elements/new-design/contacts/CustomerServiceContactInfo';
import { DialogErrorTitle, TxtSmallMediumErrorResp } from 'elements/new-design/Typography';
import { BorderButtonError } from 'elements/new-design/buttons/text-buttons';
import { MarginBottom } from 'elements/distance/Margins';

type CancelTicketProps = {
  headline: string;
  text: string;
  loading: boolean;
  Icon?: JSX.Element | JSX.Element[];
  onTryAgain: () => void;
};

export const DialogErrorContent = ({ ...props }: CancelTicketProps) => {
  const { translate } = useI18n();
  const buttonRef = createRef<HTMLButtonElement>();
  useEffect(() => {
    buttonRef.current?.focus();
  }, []);
  return (
    <div>
      {props.Icon ? <MarginBottom margin={2.4}>{props.Icon}</MarginBottom> : null}
      <DialogErrorTitle>{props.headline}</DialogErrorTitle>
      {props.loading && <Loader />}
      {!props.loading && (
        <>
          <p>
            <TxtSmallMediumErrorResp>{props.text}</TxtSmallMediumErrorResp>
          </p>
          <MarginBottom margin={2.4} />
          <CustomerServiceContactInfo />
          <MarginBottom margin={2.4} />
          <BorderButtonError big ref={buttonRef} onClick={props.onTryAgain}>
            {translate('TRY_AGAIN')}
          </BorderButtonError>
        </>
      )}
    </div>
  );
};
