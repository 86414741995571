import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import newcolors from 'styles/new-design/colors';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { useAuthentication } from 'hooks/useAuthentication';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useUpdateState } from 'hooks/useUpdateState';
import { useHeaderHeight } from 'hooks/useHeaderHeight';
import { useI18n } from 'utils/i18n/usei18n';
import { initialOrderState } from 'store/order/orderReducer';
import { Constants } from 'utils/Constants';
import { MenuLinksType } from 'types/CmsTypes';
import { CriticalDeviationsBanner } from 'components/new-design/critical-deviations-banner/CriticalDeviationsBanner';
import { isProtectedRoute, isPurchaseFlowRoute, isWarningModalRoute, isPurchaseFlowTokenRoute } from 'utils/Navigation';
import { HeaderService, MENU } from './HeaderService';
import { Login } from 'components/new-design/dialogs/login-dialog/Login';
import { LargeHeader } from './LargeHeader';
import { SmallHeader } from './SmallHeader';
import { DialogAbort } from 'components/new-design/dialogs/DialogAbort';

type HeaderProps = {
  faqLinks: MenuLinksType[];
};

type StyleProps = {
  headerHeight: string;
  color: string;
};

const Navigation = styled.nav`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: ${(props) => props.headerHeight};
  background: ${(props) => props.color};
`;

const { getProfileLinks, getFAQLinks } = HeaderService;
const PURCHASE_WARNING = 'PURCHASE_WARNING';
const FORM_WARNING = 'FORM_WARNING';
const LOGIN_MODAL = 'LOGIN';

export const Header = ({ faqLinks }: HeaderProps) => {
  const [currentSlug, setCurrentSlug] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { navigateWithState } = useStateNavigation();
  const { logoutUser } = useAuthentication();
  const { device } = useBreakpoints();
  const { setFormChanged, setDialog, setOrderState } = useUpdateState();
  const { headerHeight } = useHeaderHeight();
  const { translate, currentLanguage } = useI18n();
  const { hasFormChanged, currentDialog } = useSelector((state: AppState) => state.global);
  const profileLinks = getProfileLinks(translate);
  const cmsLinks = getFAQLinks(faqLinks).filter((link) => link.language === currentLanguage);
  const route = window.location.pathname;

  const handleOpenLogin = () => {
    setDialog(undefined);
    setModalOpen(LOGIN_MODAL);
  };

  const toggleMenu = (name: string = null) => {
    if (currentDialog?.name === name || !name) {
      setExpanded(false);
      setDialog(undefined);
    } else {
      setExpanded(true);
      setDialog({ name, color: newcolors.backdrop, scroll: true });
    }
  };

  const handleOnProceed = () => {
    setModalOpen(null);
    setOrderState(initialOrderState);
    setFormChanged(false);
    navigateWithState(currentSlug);
  };

  const handleMenuClick = (slug: string) => {
    if (!isWarningModalRoute(route)) navigateWithState(slug);
    else {
      if (hasFormChanged) setModalOpen(FORM_WARNING);
      else if (isPurchaseFlowRoute(route)) setModalOpen(PURCHASE_WARNING);
      else navigateWithState(slug);
    }
    setExpanded(false);
    setDialog(undefined);
    setCurrentSlug(slug);
  };

  const handleLogoutUser = () => {
    setDialog(undefined);
    if (isProtectedRoute(route) || isPurchaseFlowTokenRoute(route)) navigateWithState(Constants.ROUTE_BASE);
    else navigateWithState(window.location.pathname);
    logoutUser();
  };

  useEffect(() => {
    if (!currentDialog) setExpanded(false);
  }, [currentDialog]);

  return (
    <>
      <Navigation
        headerHeight={headerHeight.headerH}
        color={
          (device === Constants.DEVICE_XLARGE && expanded) ||
          (device !== Constants.DEVICE_XLARGE && expanded && currentDialog?.name !== MENU)
            ? newcolors.backdrop
            : 'transparent'
        }
      >
        <CriticalDeviationsBanner
          expanded={currentDialog?.name}
          onToggleMenu={toggleMenu}
          onMenuClick={handleMenuClick}
        />

        {device !== Constants.DEVICE_XLARGE && (
          <SmallHeader
            faqLinks={cmsLinks}
            profileLinks={profileLinks}
            expanded={currentDialog?.name}
            headerHeight={headerHeight.headerH}
            isPhoneDevice={
              device === Constants.DEVICE_XSMALL ||
              device === Constants.DEVICE_SMALL ||
              device === Constants.DEVICE_MEDIUM
            }
            onToggleMenu={toggleMenu}
            onMenuClick={handleMenuClick}
            onOpenLogin={handleOpenLogin}
            onLogoutUser={handleLogoutUser}
          />
        )}

        {device === Constants.DEVICE_XLARGE && (
          <LargeHeader
            faqLinks={cmsLinks}
            profileLinks={profileLinks}
            expanded={currentDialog?.name}
            onToggleMenu={toggleMenu}
            onOpenLogin={handleOpenLogin}
            onMenuClick={handleMenuClick}
            onLogoutUser={handleLogoutUser}
          />
        )}
      </Navigation>

      {(modalOpen === PURCHASE_WARNING || modalOpen === FORM_WARNING) && (
        <DialogAbort
          formWarning={modalOpen === FORM_WARNING}
          onModalClose={() => setModalOpen(null)}
          onProceed={handleOnProceed}
        ></DialogAbort>
      )}
      {modalOpen === LOGIN_MODAL && (
        <Login
          showCreate={true}
          redirectTo={window.location.pathname}
          onLogin={() => setModalOpen(null)}
          onModalClose={() => setModalOpen(null)}
        />
      )}
    </>
  );
};
