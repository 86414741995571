import React from 'react';
import styled from 'styled-components';

type MarginProps = {
  margin: number;
  children?: JSX.Element | JSX.Element[];
};

export const MarginBottomOne = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;
export const MarginTopOne = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const MarginLeftOne = styled.div`
  width: 100%;
  margin-left: 1rem;
`;

export const MarginRightOne = styled.div`
  width: 100%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
`;

export const MarginTopTwo = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const MarginBottomTwo = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const MarginRightTwo = styled.div`
  margin-right: 2rem;
`;

export const MarginTopThree = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

export const MarginRightThree = styled.div`
  width: 100%;
  margin-right: 3rem;
`;

export const MarginBottomFour = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`;

export const MarginBottomStyle = styled.div`
  ${(_: MarginProps) => ''}
  width: 100%;
  margin-bottom: ${(props) => `${props.margin}rem`};
`;

export const MarginBottomTextAlignRightStyle = styled.div`
  ${(_: MarginProps) => ''}
  width: 100%;
  text-align: right;
  margin-bottom: ${(props) => `${props.margin}rem`};
`;

export const MarginTopStyle = styled.div`
  ${(_: MarginProps) => ''}
  margin-top: ${(props) => `${props.margin}rem`};
`;

export const MarginRightStyle = styled.div`
  ${(_: MarginProps) => ''}
  margin-right: ${(props) => `${props.margin}rem`};
`;

export const MarginLeftStyle = styled.div`
  ${(_: MarginProps) => ''}
  margin-left: ${(props) => `${props.margin}rem`};
`;

export const MarginBottom = ({ margin, children }: MarginProps) => (
  <MarginBottomStyle margin={margin}>{children}</MarginBottomStyle>
);
export const MarginBottomTextAlignRight = ({ margin, children }: MarginProps) => (
  <MarginBottomTextAlignRightStyle margin={margin}>{children}</MarginBottomTextAlignRightStyle>
);
export const MarginTop = ({ margin, children }: MarginProps) => (
  <MarginTopStyle margin={margin}>{children}</MarginTopStyle>
);
export const MarginRight = ({ margin, children }: MarginProps) => (
  <MarginRightStyle margin={margin}>{children}</MarginRightStyle>
);
export const MarginLeft = ({ margin, children }: MarginProps) => (
  <MarginLeftStyle margin={margin}>{children}</MarginLeftStyle>
);
