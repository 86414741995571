import React from 'react';
import styled from 'styled-components';
import newcolors from 'styles/new-design/colors';
import UserDarkIcon from 'assets/svg/new-design/user.svg';
import UserDarkActiveIcon from 'assets/svg/new-design/user-active.svg';
import EnterLightIcon from 'assets/svg/new-design/enter-light.svg';
import HamburgerMenuDarkIcon from 'assets/svg/new-design/hamburger-menu.svg';
import CloseDarkIcon from 'assets/svg/new-design/clear.svg';
import VisibilityIcon from 'assets/svg/new-design/visibility.svg';
import VisibilityIconActive from 'assets/svg/new-design/visibility-focus.svg';
import InfoDarkIcon from 'assets/svg/new-design/info-dark.svg';
import InfoWarningIcon from 'assets/svg/new-design/info-warning.svg';
import InfoInfoIcon from 'assets/svg/new-design/info-info.svg';
import InfoAlertIcon from 'assets/svg/new-design/info-alert.svg';
import BusIcon from 'assets/svg/new-design/bus.svg';
import TrainDarkIcon from 'assets/svg/new-design/train-dark.svg';
import TrainLightIcon from 'assets/svg/new-design/train-big-light.svg';
import WarningDarkIcon from 'assets/svg/new-design/warning-dark.svg';
import WarningIcon from 'assets/svg/new-design/warning.svg';
import TicketLightIcon from 'assets/svg/new-design/ticket-light.svg';
import ClockDarkIcon from 'assets/svg/new-design/clock-dark.svg';
import ArrowIcon from 'assets/svg/new-design/arrow-dark.svg';
import TrackDarkIcon from 'assets/svg/new-design/track-dark.svg';
import BusStopDarkIcon from 'assets/svg/new-design/bus-stop-dark.svg';
import LanguageDarkIcon from 'assets/svg/new-design/language-dark.svg';
import BedDarkIcon from 'assets/svg/new-design/bed-dark.svg';
import SeatDarkIcon from 'assets/svg/new-design/seat-dark.svg';
import CarriageDarkIcon from 'assets/svg/new-design/carriage-dark.svg';
import { Icon } from './Icon';
import { Circle } from 'elements/new-design/Shapes';

type StyleProps = {
  width?: number;
  height?: number;
  color?: string;
  active?: boolean;
};

export const UserIconDark = styled(Icon)`
  ${(_: StyleProps) => ''}
  background: ${(props) =>
    props.active ? `url(${UserDarkActiveIcon}) no-repeat center` : `url(${UserDarkIcon}) no-repeat center`};
  background-size: 100%;
`;

export const EnterIconLight = styled(Icon)`
  background: url(${EnterLightIcon}) no-repeat center;
  background-size: 100%;
`;

export const HamburgerMenuDark = styled(Icon)`
  background: url(${HamburgerMenuDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const CloseIconDark = styled(Icon)`
  background: url(${CloseDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const VisibilityIconDark = styled(Icon)`
  ${(_: StyleProps) => ''}
  background: ${(props) =>
    props.active ? `url(${VisibilityIconActive}) no-repeat center` : `url(${VisibilityIcon}) no-repeat center`};
  background-size: 100%;
`;

export const InfoIconDark = styled(Icon)`
  background: url(${InfoDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const InfoIconWarning = styled(Icon)`
  background: url(${InfoWarningIcon}) no-repeat center;
  background-size: 100%;
`;

export const InfoIconInfo = styled(Icon)`
  background: url(${InfoInfoIcon}) no-repeat center;
  background-size: 100%;
`;

export const InfoIconAlert = styled(Icon)`
  background: url(${InfoAlertIcon}) no-repeat center;
  background-size: 100%;
  margin-top: -0.3rem;
`;

export const BusIconDark = styled(Icon)`
  background: url(${BusIcon}) no-repeat center;
  background-size: 100%;
`;

export const TrainIconDark = styled(Icon)`
  background: url(${TrainDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const WarningIconDark = styled(Icon)`
  background: url(${WarningDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const WarningIconWarning = styled(Icon)`
  background: url(${WarningIcon}) no-repeat center;
  background-size: 100%;
`;

export const TicketIconLight = styled(Icon)`
  background: url(${TicketLightIcon}) no-repeat center;
  background-size: 100%;
`;

export const TrainIconLight = styled(Icon)`
  background: url(${TrainLightIcon}) no-repeat center;
  background-size: 100%;
`;

export const ClockIconDark = styled(Icon)`
  background: url(${ClockDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const ArrowRightIconDark = styled(Icon)`
  background: url(${ArrowIcon}) no-repeat center;
  background-size: 100%;
  transform: rotate(-90deg);
`;

export const TrackIconDark = styled(Icon)`
  background: url(${TrackDarkIcon}) no-repeat center bottom;
  background-size: 100%;
`;

export const BusStopIcon = styled(Icon)`
  background: url(${BusStopDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const LanguageIconDark = styled(Icon)`
  background: url(${LanguageDarkIcon}) no-repeat center;
  background-size: 100%;
`;
export const SeatIconDark = styled(Icon)`
  background: url(${SeatDarkIcon}) no-repeat center bottom;
  background-size: 100%;
`;

export const BedIconDark = styled(Icon)`
  background: url(${BedDarkIcon}) no-repeat center bottom;
  background-size: 100%;
`;

export const CarriageIconDark = styled(Icon)`
  background: url(${CarriageDarkIcon}) no-repeat center;
  background-size: 100%;
`;

export const TicketDarkCircleIcon = () => (
  <Circle width={4.7} height={4.7} color={newcolors.bgDark}>
    <TicketIconLight />
  </Circle>
);

export const TrainDarkCircleIcon = () => (
  <Circle width={4.7} height={4.7} color={newcolors.bgDark}>
    <TrainIconLight width={3} height={3} />
  </Circle>
);

export const TrainErrorCircleIcon = () => (
  <Circle width={4.7} height={4.7} color={newcolors.errorDark}>
    <TrainIconLight width={3} height={3} />
  </Circle>
);
