import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { useI18n } from 'utils/i18n/usei18n';
import { useAuthentication } from 'hooks/useAuthentication';
import { useApi } from 'hooks/use-api/useApi';
import { getValidatedField } from 'utils/Validation';
import { Constants } from 'utils/Constants';
import { getForm, EMAIL, PWD } from './LoginData';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';
import { DialogTitle } from 'elements/new-design/Typography';
import { Loader } from 'elements/loader/Loader';
import { CommonErrorLabel } from 'elements/new-design/InputFields';
import { BorderButtonDark, TextButtonBoldDarkLarge } from 'elements/new-design/buttons/text-buttons';
import { TextField } from 'components/new-design/formfields/TextField';
import { FlexColumnNoCenter } from 'elements/containers/Containers';
import { CheckboxLabelRight } from 'components/new-design/formfields/CheckBox';
import { MarginBottom, MarginTop } from 'elements/distance/Margins';
import { GOADate } from 'utils/date/GOADate';

type LoginProps = {
  showCreate: boolean;
  onLogin: () => void;
  onToggleModal?: () => void;
  onCreateProfile: () => void;
  onModalClose: () => void;
};

type StyleProps = {
  visible: boolean;
};

type LoginFieldType = {
  name: string;
  label: string;
  value: string;
  error: string;
  validationRules: string[];
  type: string;
};

type FormType = Map<string, LoginFieldType>;

const LoginErrorContainer = styled.div`
  ${(_: StyleProps) => ''}
  height: 2rem;
  margin: 1.6rem 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const ForgotPwdRememberMeContainer = styled(FlexColumnNoCenter)`
  margin: 0.8rem 0 3.2rem 0;
  justify-content: space-between;
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 15rem);
  }
`;

const LoginState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const LoginDialog = ({ showCreate, onLogin, onToggleModal, onCreateProfile, onModalClose }: LoginProps) => {
  const { API_CALLS, guardedRequest, requestGoOps } = useApi();
  const { loginAccountUser } = useAuthentication();
  const { translate } = useI18n();
  const [state, setState] = useState<typeof LoginState>(Constants.IDLE);
  const [loginForm, setLoginForm] = useState(getForm());
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const pwordInputName = Array.from(loginForm.values()).pop().name;

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const form = cloneDeep(loginForm);
    const updated = form.get(e.target.name);
    updated.value = e.target.value;
    updated.error = undefined;
    setLoginForm(form);
    setState(Constants.IDLE);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    const form = cloneDeep(loginForm);
    form.set(e.target.name, getValidatedField(form.get(e.target.name)));
    setLoginForm(form);
  };

  const validateForm = (form: FormType): FormType => {
    form.set(EMAIL, getValidatedField(form.get(EMAIL)));
    form.set(PWD, getValidatedField(form.get(PWD)));
    return form;
  };

  const submitForm = async () => {
    const validatedForm = validateForm(cloneDeep(loginForm));
    const hasErrors = Array.from(validatedForm.values()).find((field) => field.error);
    setLoginForm(validatedForm);
    if (hasErrors) {
      document.getElementById(hasErrors.name).focus();
    } else {
      try {
        setState(Constants.LOADING);
        const { data } = await guardedRequest(API_CALLS.VERIFY_CUSTOMER_CREDENTIALS_OLD, {
          email: loginForm.get(EMAIL).value,
          password: loginForm.get(PWD).value,
        });

        const { data: goOpsData } = await requestGoOps(API_CALLS.VERIFY_CUSTOMER_CREDENTIALS, {
          email: loginForm.get(EMAIL).value,
          password: loginForm.get(PWD).value,
        });

        loginAccountUser(
          Object.assign(
            {},
            {
              ...data.result,
              tokenGoOps: goOpsData.result.token,
              refreshTokenGoOps: goOpsData.result.refreshToken,
              refreshTokenExpirationGoOps: new GOADate(goOpsData.result.refreshTokenExpiration),
            },
            { keep: keepLoggedIn },
          ),
        );
        onLogin();
      } catch (error) {
        setState(Constants.ERROR);
      }
    }
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (document.activeElement.name === pwordInputName && e.key === 'Enter') submitForm();
  };

  return (
    <Dialog type="center" title={translate('LOG_IN')} height={62} width={47} onModalClose={onModalClose}>
      <DialogCenterContentContainer>
        <DialogTitle>{translate('LOG_IN')}</DialogTitle>
        {state === Constants.LOADING && (
          <MarginTop margin={5.6}>
            <Loader />
          </MarginTop>
        )}
        <form aria-live="polite" onKeyDown={handleEnter}>
          {(state === Constants.IDLE || state === Constants.ERROR) && (
            <>
              {Array.from(loginForm.values()).map((item, index) => (
                <div key={index}>
                  <TextField
                    name={item.name}
                    type={item.type}
                    label={item.label}
                    value={item.value}
                    error={item.error}
                    onFieldChange={handleOnChange}
                    onFieldBlur={handleBlur}
                  />
                  {index === 0 && <MarginBottom margin={4} />}
                </div>
              ))}
              <LoginErrorContainer visible={state === Constants.ERROR}>
                <CommonErrorLabel data-cy={'loginErrorMsgCy'}>{translate('WRONG_CREDENTIALS')}</CommonErrorLabel>
              </LoginErrorContainer>

              <ForgotPwdRememberMeContainer>
                <span>
                  <TextButtonBoldDarkLarge onClick={onToggleModal}>
                    {translate('FORGOT_PASSWORD')}
                  </TextButtonBoldDarkLarge>
                </span>
                <MarginBottom margin={2.4} />
                <CheckboxLabelRight
                  id="keepLoggedIn"
                  name="keepLoggedIn"
                  checked={keepLoggedIn}
                  label={translate('REMEMBER_ME')}
                  onToggle={() => setKeepLoggedIn(!keepLoggedIn)}
                />
              </ForgotPwdRememberMeContainer>

              <ActionButtonContainer>
                <BorderButtonDark data-cy={'loginSubmitButton'} big inFocus={false} onClick={submitForm}>
                  {translate('LOG_IN')}
                </BorderButtonDark>

                {showCreate && (
                  <BorderButtonDark data-cy={'createProfileButton'} big inFocus={false} onClick={onCreateProfile}>
                    {translate('NEW_USER')}
                  </BorderButtonDark>
                )}
              </ActionButtonContainer>
            </>
          )}
        </form>
      </DialogCenterContentContainer>
    </Dialog>
  );
};
