import React from 'react';
import styled from 'styled-components';
import colors from 'styles/colors';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogActionContent } from 'components/new-design/dialogs/DialogActionContent';
import { FlexColumn } from 'elements/containers/Containers';
import { ButtonAccentPrimary, ButtonAccentSecondary } from 'elements/buttons/Buttons';
import { TrainCircleIcon } from 'elements/icon/symbol-icons';
import { MarginBottom } from 'elements/distance/Margins';

type WarningModalProps = {
  formWarning: boolean;
  onModalClose: () => void;
  onProceed: () => void;
};

type AlertText = {
  paragraph: string;
  backBtnText: string;
  proceedBtnText: string;
};

const ButtonContainer = styled(FlexColumn)`
  padding: 2rem 0rem;
  align-items: flex-start;

  @media ${(props) => props.theme.breakpoints.medium} {
    flex-direction: row;
  }

  ${ButtonAccentPrimary} {
    margin: 0 0 2rem 0;
    @media ${(props) => props.theme.breakpoints.medium} {
      margin: 0 3rem 0 0;
    }
  }
`;

const getAlertTexts = (formWarning, translate: (key: string) => string): AlertText => {
  if (formWarning) {
    return {
      paragraph: translate('CANCEL_FORM_TEXT'),
      backBtnText: translate('CANCEL_FORM_BACK'),
      proceedBtnText: translate('CANCEL_FORM_PROCEED'),
    };
  }
  return {
    paragraph: translate('CANCEL_PURCHASE_TEXT'),
    backBtnText: translate('CANCEL_PURCHASE_BACK'),
    proceedBtnText: translate('CANCEL_PURCHASE_PROCEED'),
  };
};

export const DialogAbort = ({ formWarning, onModalClose, onProceed }: WarningModalProps) => {
  const { translate } = useI18n();
  const texts = getAlertTexts(formWarning, translate);
  return (
    <Dialog type="center" title={translate('CANCEL_WARNING_HEADER')} width={60} height={42} onModalClose={onModalClose}>
      <DialogActionContent
        headline={translate('CANCEL_WARNING_HEADER')}
        text={texts.paragraph}
        loading={false}
        Icon={<TrainCircleIcon color={colors.bgSecondaryAccent} />}
      >
        <>
          <MarginBottom margin={3.2} />
          <ButtonContainer>
            <ButtonAccentPrimary id="alertBackButton" data-cy={'alertBackBtnCy'} big onClick={onModalClose}>
              {texts.backBtnText}
            </ButtonAccentPrimary>
            <ButtonAccentSecondary id="alertProceedButton" data-cy={'alertProceedBtnCy'} big onClick={onProceed}>
              {texts.proceedBtnText}
            </ButtonAccentSecondary>
          </ButtonContainer>
        </>
      </DialogActionContent>
    </Dialog>
  );
};
