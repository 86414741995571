import { useState, useEffect } from 'react';
import { Constants } from 'utils/Constants';
import { breakPoints } from 'styles/new-design/breakpoints';
import throttle from 'lodash/fp/throttle';

const { small, medium, large, xlarge } = breakPoints;

const getDevice = (): string => {
  const { innerWidth: width } = window;
  if (width < small) return Constants.DEVICE_XSMALL;
  if (width >= small && width < medium) return Constants.DEVICE_SMALL;
  if (width >= medium && width < large) return Constants.DEVICE_MEDIUM;
  if (width >= large && width <= xlarge) return Constants.DEVICE_LARGE;
  if (width >= xlarge) return Constants.DEVICE_XLARGE;
  return Constants.DEVICE_LARGE;
};

export const useBreakpoints = () => {
  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleResize = throttle(100, () => setDevice(getDevice()));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSmallDevice = () => {
    const { innerWidth: width } = window;
    return width < medium;
  };

  const isMaxMediumDevice = () => {
    const { innerWidth: width } = window;
    return width < large;
  };

  return {
    device,
    isSmallDevice,
    isMaxMediumDevice,
  };
};
