import { Deviation_GetAll, Deviation_GetCritical } from 'apiV1/Deviation';

export const useDeviationApi = () => {
  const getAllDeviations = async () => await Deviation_GetAll();

  const getCriticalDeviations = async () => await Deviation_GetCritical();

  return {
    getAllDeviations,
    getCriticalDeviations,
  };
};
