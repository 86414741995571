import { encryptString } from 'utils/Helpers';
import { Order } from 'types/OrderTypes';
import { UserType } from 'types/CustomerTypes';
import { Constants } from 'utils/Constants';
import { TransactionOrderLineType } from 'types/HistoryTypes';
import {
  Ticket_SendPdf,
  Ticket_CancelPaymentTransaction,
  Ticket_GetPaymentTransactionStatus,
  Ticket_Capture,
  Ticket_Pay,
  Ticket_SendReceipt,
  Ticket_AddConsent,
} from 'apiV1/Ticket';
import { Ticket_Reserve, Ticket_ReviewOrder, Ticket_SendSms, Ticket_RefundTicket } from 'apiV2/Ticket';

export const useTicketApi = (token: string, user: UserType, order: Order) => {
  const sendPdf = async () =>
    await Ticket_SendPdf(order.orderID, {
      authorization: 'Bearer ' + token,
      body: {
        firstName: order.contactInformation.firstName,
        lastName: order.contactInformation.surname,
        landCode: '+47',
        number: order.contactInformation.telephoneNumberNoCountryCode,
        email: order.contactInformation.email,
        pdfLanguage: 'nob',
      },
    });

  const cancelPaymentTransaction = async ({ gaPaymentId, gaTransactionId }) =>
    await Ticket_CancelPaymentTransaction(gaPaymentId, gaTransactionId, {
      authorization: 'Bearer ' + token,
    });

  const getVippsTransactionStatus = async ({ gaPaymentId, gaTransactionId }) =>
    await Ticket_GetPaymentTransactionStatus(gaPaymentId, gaTransactionId, {
      authorization: 'Bearer ' + token,
    });

  const captureTicket = async ({ gaPaymentId, gaTransactionId }) =>
    await Ticket_Capture(Number(gaPaymentId), Number(gaTransactionId), {
      authorization: 'Bearer ' + token,
    });

  const ticketPay = async () => {
    const body = {
      paymentType: String(order.paymentMethod),
      authorization: 'Bearer ' + token,
      callbackUrl: undefined,
      phoneNumber: undefined,
      recurringPaymentId: undefined,
    };

    if (order.recurringCard) {
      body.paymentType = order.recurringCard.paymentType;
      body.callbackUrl = `${window.location.protocol}//${window.location.host}${Constants.ROUTE_NETS_REDIRECT}`;
      body.recurringPaymentId = order.recurringCard.recurringPaymentId;
    } else {
      if (order.paymentMethod === Constants.VIPPS) {
        const encryptedToken = encryptString(token);
        const url = `${window.location.protocol}//${window.location.host}${
          Constants.ROUTE_VIPPS_REDIRECT
        }?distribution=${order.deliveryMethod}&sendSms=${
          order.contactInformation.sendSMS ? 'true' : 'false'
        }&token=${encryptedToken}`;

        body.callbackUrl = url;
        body.phoneNumber = order.contactInformation.telephoneNumberNoCountryCode;
      } else {
        body.callbackUrl = `${window.location.protocol}//${window.location.host}${Constants.ROUTE_NETS_REDIRECT}`;
      }
    }
    return await Ticket_Pay(order.orderID, body);
  };

  const sendReceipt = async (historyOrderID: string) => {
    return Ticket_SendReceipt(historyOrderID, {
      authorization: 'Bearer ' + token,
      body: {
        email: user.email,
        language: 'nob',
      },
    });
  };

  const sendSMS = async () => {
    return Ticket_SendSms(order.orderID, {
      authorization: 'Bearer ' + token,
      body: {
        countryCode: order.contactInformation.countryCode.split('+')[1],
        number: order.contactInformation.telephoneNumberNoCountryCode,
      },
    });
  };

  const reserve = async (offers) => {
    return await Ticket_Reserve({
      authorization: 'Bearer ' + token,
      body: { offers },
    });
  };

  const reviewOrder = async () => await Ticket_ReviewOrder(order.orderID, { authorization: 'Bearer ' + token });

  const addConsent = async () => await Ticket_AddConsent(order.orderID, { authorization: 'Bearer ' + token });

  const refundTicket = async (orderLine: TransactionOrderLineType) => {
    await Ticket_RefundTicket(orderLine.orderId, {
      authorization: 'Bearer ' + token,
      orderLines: orderLine.refundOptions.orderLines,
    });
  };

  return {
    sendPdf,
    cancelPaymentTransaction,
    getVippsTransactionStatus,
    captureTicket,
    ticketPay,
    sendReceipt,
    sendSMS,
    reserve,
    reviewOrder,
    addConsent,
    refundTicket,
  };
};
