import { useState, useEffect } from 'react';
import { breakPoints } from 'styles/devices';
import throttle from 'lodash/fp/throttle';
import { Constants } from 'utils/Constants';

const { small, medium, large, laptop, bigscreen } = breakPoints;

const getDevice = (): string => {
  const { innerWidth: width } = window;

  if (width < medium) return Constants.DEVICE_SMALL;
  if (width < large) return Constants.DEVICE_MEDIUM;
  if (width < laptop) return Constants.DEVICE_LARGE;
  if (width >= bigscreen) return Constants.DEVICE_LAPTOP;
  return Constants.DEVICE_LAPTOP;
};

export const useDevice = () => {
  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleResize = throttle(100, () => setDevice(getDevice()));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSmallerThanBigScreen = () => {
    const { innerWidth: width } = window;
    return width < bigscreen;
  };

  const isSmallerThanLarge = () => {
    const { innerWidth: width } = window;
    return width < large;
  };

  const isSmallerThanLaptop = () => {
    const { innerWidth: width } = window;
    return width < laptop;
  };

  const isMaxMedium = () => {
    const { innerWidth: width } = window;
    return width <= medium;
  };

  const isSmall = () => {
    const { innerWidth: width } = window;
    return width <= small;
  };

  return {
    device,
    isSmallerThanBigScreen,
    isSmallerThanLarge,
    isSmallerThanLaptop,
    isMaxMedium,
    isSmall,
  };
};
