import React from 'react';
import styled from 'styled-components';
import newColors from 'styles/new-design/colors';
import colors from 'styles/colors';
import BabyCarriage from 'assets/svg/babycarriage.svg';
import WheelChair from 'assets/svg/wheelchair.svg';
import PlayRoom from 'assets/svg/family.svg';
import Nursing from 'assets/svg/nursing.svg';
import WC from 'assets/svg/wc.svg';
import NotAvailable from 'assets/svg/not-available-seat.svg';
import Available from 'assets/svg/available-seat.svg';
import Selected from 'assets/svg/selected-seat.svg';
import NotAvailableRecliner from 'assets/svg/not-available-recliner-seat.svg';
import AvailableRecliner from 'assets/svg/available-recliner-seat.svg';
import SelectedRecliner from 'assets/svg/selected-recliner-seat.svg';
import CancelIcon from 'assets/svg/cancel.svg';
import TicketDarkIcon from 'assets/svg/ticket-dark.svg';
import TicketLightIcon from 'assets/svg/ticket-white.svg';
import InfoLightIcon from 'assets/svg/info-white.svg';
import EmailBigWhite from 'assets/svg/email-big-white.svg';
import TrainIcon from 'assets/svg/train-big.svg';
import TrainIconWhite from 'assets/svg/train-big.svg';
import ExclamationIcon from 'assets/svg/exclamation-mark-dark.svg';
import { Icon } from './Icon';
import { Circle } from 'elements/new-design/Shapes';
import { fontFamilyBold, fontColorLight, fontColorError, fontSmallStatic } from '../new-design/Typography';

type StyleProps = {
  width?: number;
  height?: number;
  color?: string;
};

const SymbolIcon = styled.div`
  ${(_: StyleProps) => ''}
  width: ${(props) => (props.width ? `${props.width}rem` : '3.5rem')};
  height: ${(props) => (props.height ? `${props.height}rem` : '3.5rem')};
  background-size: ${(props) => (props.width ? `${props.width}rem ${props.width}rem` : '3rem')};
`;

export const BabyCarriageIcon = styled(SymbolIcon)`
  background: url(${BabyCarriage}) no-repeat center;
`;
export const WheelChairIcon = styled(SymbolIcon)`
  background: url(${WheelChair}) no-repeat center;
`;
export const PlayRoomIcon = styled(SymbolIcon)`
  background: url(${PlayRoom}) no-repeat center;
`;
export const NursingIcon = styled(SymbolIcon)`
  background: url(${Nursing}) no-repeat center;
`;
export const WCIcon = styled(SymbolIcon)`
  background: url(${WC}) no-repeat center;
`;
export const NotAvailableSeatIcon = styled(SymbolIcon)`
  background: url(${NotAvailable}) no-repeat center;
`;
export const AvailableSeatIcon = styled(SymbolIcon)`
  background: url(${Available}) no-repeat center;
`;
export const SelectedSeatIcon = styled(SymbolIcon)`
  background: url(${Selected}) no-repeat center;
`;
export const NotAvailableReclinerIcon = styled(SymbolIcon)`
  background: url(${NotAvailableRecliner}) no-repeat center;
`;
export const AvailableReclinerIcon = styled(SymbolIcon)`
  background: url(${AvailableRecliner}) no-repeat center;
`;
export const SelectedReclinerIcon = styled(SymbolIcon)`
  background: url(${SelectedRecliner}) no-repeat center;
`;
export const TicketIconDark = styled(SymbolIcon)`
  background: url(${TicketDarkIcon}) no-repeat center;
`;
export const TicketIconLight = styled(SymbolIcon)`
  ${(_: StyleProps) => ''}
  background: url(${TicketLightIcon}) no-repeat center;
  background-size: ${(props) => `${props.width}rem ${props.width}rem`};
`;
export const TrainIconLight = styled(SymbolIcon)`
  ${(_: StyleProps) => ''}
  background: url(${TrainIconWhite}) no-repeat center;
  background-size: ${(props) => `${props.width}rem ${props.width}rem`};
`;
export const InfoIconLight = styled(SymbolIcon)`
  background: url(${InfoLightIcon}) no-repeat center;
`;
export const EmailWhiteBigIcon = styled(SymbolIcon)`
  background: url(${EmailBigWhite}) no-repeat center;
`;
export const TrainLightBigIcon = styled(SymbolIcon)`
  background: url(${TrainIcon}) no-repeat center;
`;
export const ExclamationDarkIcon = styled(SymbolIcon)`
  background: url(${ExclamationIcon}) no-repeat center;
  background-size: ${(props) => `${props.width}rem ${props.width}rem`};
`;
export const CancelledIcon = styled(SymbolIcon)`
  background: url(${CancelIcon}) no-repeat center;
`;

export const TicketIcon = ({ ...props }: StyleProps) => {
  if (props.color === 'light') return <TicketIconLight {...props} />;
  if (props.color === 'dark') return <TicketIconDark {...props} />;
};

const InfoIconTextLight = styled.span`
  ${fontFamilyBold}
  ${fontSmallStatic}
  ${fontColorLight}
`;

const InfoIconTextError = styled(InfoIconTextLight)`
  ${fontColorError}
`;

export const InfoIconDark = () => (
  <Circle width={2} height={2} color={newColors.bgDark}>
    <InfoIconTextLight>i</InfoIconTextLight>
  </Circle>
);

export const ErrorIcon = () => (
  <Circle width={2} height={2} color={newColors.bgPrimary}>
    <InfoIconTextError>!</InfoIconTextError>
  </Circle>
);

export const EmailCircleIcon = () => (
  <Circle width={4.7} height={4.7} color={colors.accentPrimary}>
    <EmailWhiteBigIcon width={4.7} height={4.7} />
  </Circle>
);

export const TicketCircleIcon = ({ ...props }: StyleProps) => (
  <Circle width={4.7} height={4.7} color={props.color}>
    <TicketIconLight width={3} height={3} />
  </Circle>
);

export const TrainCircleIcon = ({ ...props }: StyleProps) => (
  <Circle width={4.7} height={4.7} color={props.color}>
    <TrainIconLight width={3} height={3} />
  </Circle>
);

export const DeviationIcon = () => (
  <Circle width={1.4} height={1.4} color={colors.deviationIcon}>
    <ExclamationDarkIcon height={1.2} width={1.2} />
  </Circle>
);

export const ButtonIcon = styled(Icon)`
  margin-left: 0.7rem;
  width: 2rem;
  height: 2rem;

  @media ${(props) => props.theme.devices.large} {
    display: flex;
  }
`;
