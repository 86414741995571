import React from 'react';
import styled from 'styled-components';
import { Constants } from 'utils/Constants';
import { TxtSmallBoldAccentResp } from 'elements/new-design/Typography';

type CustomerServiceEmailProps = {
  email?: string;
  displayText?: string;
  mailSubject?: string;
  underline?: boolean;
};

type StyleProps = {
  underline: boolean;
};

const CustomerServiceEmailStyle = styled.a`
  ${(_: StyleProps) => ''}
  text-decoration: ${(props) => (props.underline ? 'underline' : '')};
`;

export const CustomerServiceEmail = ({ email, displayText, mailSubject, underline }: CustomerServiceEmailProps) => (
  <CustomerServiceEmailStyle
    data-cy={'customersEmailCy'}
    underline={underline}
    href={`mailto:${email ? email : Constants.GO_AHEAD_CUSTOMER_SERVICE_EMAIL}?subject=${
      mailSubject ? mailSubject : ''
    }`}
  >
    <TxtSmallBoldAccentResp>
      {displayText ? displayText : Constants.GO_AHEAD_CUSTOMER_SERVICE_EMAIL}
    </TxtSmallBoldAccentResp>
  </CustomerServiceEmailStyle>
);
