import React from 'react';
import styled from 'styled-components';
import { CookieSettingsType, CookieOptionsType } from 'types/CmsTypes';
import { TxtDefaultBoldDarkResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { BorderButtonDark, BgButtonDark } from 'elements/new-design/buttons/text-buttons';
import { CheckboxNoText } from 'components/new-design/formfields/CheckBox';
import { FlexBetweenStart } from 'elements/containers/Containers';
import { MarginBottomTwo, MarginTopThree } from 'elements/distance/Margins';

type SettingsProps = {
  cookieSettings: CookieSettingsType;
  savedConsents: any[];
  onToggleConsent: (id: string) => void;
  onSaveChoice: () => void;
  onAcceptAll: () => void;
};

const CookieSettingsContainer = styled.div`
  overflow svg {
    margin-left: 0;
    margin-right: 1rem;
  }

  ul {
    list-style: none;
  }
`;

const CookieRow = styled.li`
  margin-bottom: 2rem;

  ${TxtSmallMediumDarkResp} {
    margin: 1rem 0;
  }

  details > summary {
    list-style-type: none;
    cursor: pointer;
    margin-bottom: 0.8rem;
    padding-bottom: 0.2rem;
    display: inline-block;
    border-bottom: ${(props) => props.theme.newconstants.borderTransparantThick};

    :hover,
    :focus-visible {
      border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }

  summary:before {
    width: 0.7rem;
    height: 0.7rem;
    border-style: solid;
    border-width: 0.25rem 0.25rem 0 0;
    content: '';
    display: inline-block;
    margin-right: 0.8rem;
    vertical-align: top;
    margin-top: 0.5rem;
    transform: rotate(135deg);
  }

  details[open] summary:before {
    margin-top: 1rem;
    transform: rotate(-45deg);
  }
`;

export const CookieSettingsView = ({
  cookieSettings,
  savedConsents,
  onToggleConsent,
  onSaveChoice,
  onAcceptAll,
}: SettingsProps): JSX.Element => {
  return (
    <CookieSettingsContainer>
      <h2>
        <TxtDefaultBoldDarkResp>{cookieSettings.title}</TxtDefaultBoldDarkResp>
      </h2>
      <MarginBottomTwo />
      <p>
        <TxtSmallMediumDarkResp>{cookieSettings.text}</TxtSmallMediumDarkResp>
      </p>
      <MarginBottomTwo />
      <BgButtonDark onClick={onAcceptAll}>{cookieSettings.acceptText} </BgButtonDark>
      <MarginBottomTwo />
      <h2>
        <TxtDefaultBoldDarkResp>{cookieSettings.listOfCookiesTitle}</TxtDefaultBoldDarkResp>
      </h2>
      <MarginBottomTwo />
      <ul>
        {cookieSettings.cookies &&
          cookieSettings.cookies.map((cookie: CookieOptionsType, index: number) => {
            return (
              <CookieRow key={index}>
                <FlexBetweenStart>
                  <span>
                    <details>
                      <summary>
                        <TxtDefaultBoldDarkResp>{cookie.title}</TxtDefaultBoldDarkResp>
                      </summary>

                      <p>
                        <TxtSmallMediumDarkResp>{cookie.text}</TxtSmallMediumDarkResp>
                      </p>
                    </details>
                  </span>
                  <CheckboxNoText
                    id={cookie.apiId}
                    name={cookie.title}
                    readonly={cookie.mandatory}
                    checked={Boolean(savedConsents.find((consent) => consent.apiId === cookie.apiId))}
                    onToggle={() => onToggleConsent(cookie.apiId)}
                  />
                </FlexBetweenStart>
              </CookieRow>
            );
          })}
      </ul>

      <MarginTopThree />
      <BorderButtonDark onClick={onSaveChoice}>{cookieSettings.saveText}</BorderButtonDark>
    </CookieSettingsContainer>
  );
};
