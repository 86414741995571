import React from 'react';
import { useDevice } from 'hooks/useDevice';
import { Constants } from 'utils/Constants';
import { LinkSmallResp } from 'elements/new-design/links/Links';
import { TxtSmallBoldDarkResp } from 'elements/new-design/Typography';

type PhoneProps = {
  number?: string;
  text?: string;
};

export const CustomerServicePhone = ({ number, text }: PhoneProps) => {
  const { device } = useDevice();
  const isSmall = device === Constants.DEVICE_SMALL;

  return (
    <>
      {isSmall ? (
        <LinkSmallResp
          text={text ? text : Constants.GO_AHEAD_PHONE_NUMBER}
          url={number ? number : Constants.GO_AHEAD_PHONE_LINK}
        />
      ) : (
        <TxtSmallBoldDarkResp>{text ? text : Constants.GO_AHEAD_PHONE_NUMBER}</TxtSmallBoldDarkResp>
      )}
    </>
  );
};
