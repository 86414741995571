import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { useI18n } from 'utils/i18n/usei18n';
import { useApi } from 'hooks/use-api/useApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { AssistanceLocationType } from 'types/LocationTypes';
import { TicketDistributionType, TransactionOrderLineType } from 'types/HistoryTypes';
import { FormFieldType } from 'types/FormTypes';
import { Constants } from 'utils/Constants';
import { fieldNames } from './services/AssistanceFormData';
import { AssistanceService, FormType, CONFIRMATION_EMAIL } from './services/AssistanceService';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogLeftHeader } from 'components/new-design/dialogs/DialogTypes';
import { DialogLeftFooter, DialogLeftContentContainer } from 'elements/new-design/Dialogs';
import { Loader } from 'elements/loader/Loader';
import { AssistanceError } from './AssistanceError';
import { AssistanceSuccess } from './AssistanceSuccess';
import { AssistanceForm } from './AssistanceForm';
import { ButtonAccentPrimary } from 'elements/buttons/Buttons';
import { MarginTop, MarginBottom } from 'elements/distance/Margins';
import { AssistancePersonalIntegrity } from './AssistancePersonalIntegrity';
import { HiddenSpan, TxtLargeBoldDarkResp } from 'elements/new-design/Typography';

type AssistanceDialogProps = {
  orderLine: TransactionOrderLineType;
  ticket: TicketDistributionType;
  assistanceLocations: AssistanceLocationType[];
  onClose: (caseNumber: string) => void;
};

type StyleProps = {
  invalid: boolean;
};

const ContentContainer = styled.div`
  width: 100%;
  @media ${(props) => props.theme.devices.medium} {
    width: 50%;
  }

  @media ${(props) => props.theme.devices.laptop} {
    width: 80%;
  }
`;

const SubmitButton = styled(ButtonAccentPrimary)`
  ${(_: StyleProps) => ''}
  opacity: ${(props) => (props.invalid ? 0.5 : 1)};
`;

const AssistanceState = `
  ${Constants.LOADING} | ${Constants.LOADING_REQUEST} | ${Constants.SUCCESS} |
  ${Constants.IDLE} | ${Constants.ERROR} | ${Constants.ERROR_LOAD}
`;

const getToAndFrom = (ticket: TicketDistributionType, locations: AssistanceLocationType[]) => {
  const fromStation = locations.find((loc) => loc.id === ticket.topology.fromStopPlace);
  const toStation = locations.find((loc) => loc.id === ticket.topology.toStopPlace);
  return {
    from: fromStation
      ? Object.assign({}, fromStation, { available: true })
      : {
          id: ticket.topology.fromStopPlace,
          name: ticket.topology.fromStopPlaceName,
          available: false,
        },
    to: toStation
      ? Object.assign({}, toStation, { available: true })
      : {
          id: ticket.topology.toStopPlace,
          name: ticket.topology.toStopPlaceName,
          available: false,
        },
  };
};

export const AssistanceDialog = ({ orderLine, ticket, assistanceLocations, onClose }: AssistanceDialogProps) => {
  const user = useSelector((state: AppState) => state.user);
  const { API_CALLS, guardedRequest } = useApi();
  const { translate } = useI18n();
  const [assistanceForm, setAssistanceForm] = useState(null);
  const [formInvalid, setFormInvalid] = useState(true);
  const [state, setState] = useState<typeof AssistanceState>(null);
  const [expanded, setExpanded] = useState(null);
  const [caseNumber, setCaseNumber] = useState(null);
  const locations = getToAndFrom(ticket, assistanceLocations);
  const fromStation = locations.from;
  const toStation = locations.to;
  let mounted = null;

  const handleToggleDropdown = (fieldId: string) => {
    if (expanded === fieldId) setExpanded(null);
    else setExpanded(fieldId);
  };

  const handleCheckboxField = (field: FormFieldType, value: string | number) => {
    if (field.value.includes(value)) return field.value.filter((val: string) => val !== value);
    return [...field.value, value];
  };

  const handleInputChange = (field: FormFieldType, value: string | number) => {
    const form = cloneDeep(assistanceForm);
    const updated = form.get(field.id);
    updated.error = undefined;
    const type = field.options ? field.options.find((option) => option.value === value).type : field.type;
    if (type === 'checkbox') updated.value = handleCheckboxField(updated, value);
    else updated.value = value;
    setAssistanceForm(form);
  };

  const getValidatedForm = (): FormType => {
    const form = cloneDeep(assistanceForm);
    Array.from(form.values()).forEach((field: FormFieldType) => {
      form.set(field.id, AssistanceService.validateField(field.id, form));

      switch (field.id) {
        case fieldNames.phoneNumber:
          if (form.get(fieldNames.confirmationMethod).value === CONFIRMATION_EMAIL) {
            const phone = form.get(fieldNames.phoneNumber);
            phone.error = undefined;
          }
          break;
        case fieldNames.fromStationInfo:
          if (!form.get(fieldNames.assistanceLocation).value.includes(fromStation.id)) {
            const from = form.get(fieldNames.fromStationInfo);
            from.error = undefined;
          }
          break;
        case fieldNames.toStationInfo:
          if (!form.get(fieldNames.assistanceLocation).value.includes(toStation.id)) {
            const to = form.get(fieldNames.toStationInfo);
            to.error = undefined;
          }
          break;
        default:
          break;
      }
    });
    return form;
  };

  const handleInputBlur = (field: FormFieldType): void => {
    const form = cloneDeep(assistanceForm);
    form.set(field.id, AssistanceService.validateField(field.id, form));
    setAssistanceForm(form);
  };

  const getFirstError = (form: FormType): FormFieldType =>
    Array.from(form.values()).find((field: FormFieldType) => field.error);

  const handleSubmit = async () => {
    const validatedForm = getValidatedForm();
    const firstError = getFirstError(validatedForm);
    if (firstError) {
      setAssistanceForm(validatedForm);
      document.getElementById(firstError.id).scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      document.getElementById(firstError.id).focus();
    } else {
      try {
        setState(Constants.LOADING_REQUEST);
        const { data } = await guardedRequest(API_CALLS.SEND_ASSISTANCE, {
          orderId: orderLine.orderId,
          orderLineId: orderLine.orderLineId,
          booking: AssistanceService.getBooking(fromStation, toStation, assistanceForm),
        });
        setCaseNumber(data.result.CaseNumber);
        setState(Constants.SUCCESS);
      } catch (error) {
        setState(Constants.ERROR);
      }
    }
  };

  const fetchAssistanceOptions = async () => {
    setState(Constants.LOADING);
    try {
      const { data } = await guardedRequest(API_CALLS.GET_ASSISTANCE_OPTIONS);
      if (mounted) {
        setAssistanceForm(AssistanceService.getAssistanceForm(locations, data.result, user));
        setState(Constants.IDLE);
        document.getElementById(fieldNames.assistanceNeeds)?.focus();
      }
    } catch (err) {
      if (mounted) setState(Constants.ERROR_LOAD);
    }
  };

  useEffect(() => {
    if (assistanceForm) {
      const validatedForm = getValidatedForm();
      setFormInvalid(Boolean(getFirstError(validatedForm)));
    }
  }, [assistanceForm]);

  useEffect(() => {
    mounted = true;
    fetchAssistanceOptions();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Dialog type="left" title={translate('BOOK_ASSISTANCE')} onModalClose={() => onClose(caseNumber)}>
      <HiddenSpan>{state === Constants.LOADING_REQUEST ? translate('ORDER_ASSISTANCE_REQ') : ''}</HiddenSpan>
      <DialogLeftHeader onModalClose={() => onClose(caseNumber)}>
        <h2>
          <TxtLargeBoldDarkResp>{translate('BOOK_ASSISTANCE')}</TxtLargeBoldDarkResp>
        </h2>
      </DialogLeftHeader>
      <DialogLeftContentContainer aria-busy={state === Constants.LOADING || state === Constants.LOADING_REQUEST}>
        <MarginTop margin={14} />
        <div role="log">
          {state === Constants.SUCCESS && (
            <AssistanceSuccess caseNumber={caseNumber} onButtonClick={() => onClose(caseNumber)} />
          )}

          {(state === Constants.LOADING || state === Constants.LOADING_REQUEST) && <Loader />}
          {(state === Constants.ERROR || state === Constants.ERROR_LOAD) && (
            <AssistanceError
              type={state}
              onTryAgain={state === Constants.ERROR ? handleSubmit : fetchAssistanceOptions}
            />
          )}
        </div>

        {state === Constants.IDLE && (
          <ContentContainer>
            <AssistanceForm
              form={assistanceForm}
              expanded={expanded}
              fromStation={fromStation}
              toStation={toStation}
              onInputChange={handleInputChange}
              onInputBlur={handleInputBlur}
              onToggleDropdown={handleToggleDropdown}
            />
            <AssistancePersonalIntegrity />
            <MarginBottom margin={14} />
          </ContentContainer>
        )}
      </DialogLeftContentContainer>
      {state === Constants.IDLE && (
        <DialogLeftFooter>
          <SubmitButton invalid={formInvalid} big onClick={() => handleSubmit()}>
            {translate('SEND_ORDER')}
          </SubmitButton>
        </DialogLeftFooter>
      )}
    </Dialog>
  );
};
