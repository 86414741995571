import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';
import { DialogTitle, TxtDefaultRegularDarkResp } from 'elements/new-design/Typography';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginTop } from 'elements/distance/Margins';

type ForgotPasswordSuccessDialogProps = {
  onToggleModal: () => void;
  onModalClose: () => void;
};

const SubmitButton = styled(BorderButtonDark)`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.large} {
    width: auto;
  }
`;

export const ForgotPasswordSuccessDialog = ({ ...props }: ForgotPasswordSuccessDialogProps) => {
  const { translate } = useI18n();
  return (
    <Dialog
      type="center"
      title={translate('CHANGE_PASSWORD_SUCCESS_HEADING')}
      width={47}
      height={46}
      onModalClose={props.onModalClose}
    >
      <DialogCenterContentContainer>
        <DialogTitle>{translate('CHANGE_PASSWORD_SUCCESS_HEADING')}</DialogTitle>
        <p>
          <TxtDefaultRegularDarkResp>{translate('FORGOT_PASSWORD_SUCCESS')}</TxtDefaultRegularDarkResp>
        </p>
        <MarginTop margin={4} />

        <SubmitButton big onClick={props.onToggleModal}>
          {translate('LOG_IN')}
        </SubmitButton>
      </DialogCenterContentContainer>
    </Dialog>
  );
};
