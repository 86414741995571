import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useI18n } from 'utils/i18n/usei18n';
import { isLocalHost } from 'utils/Helpers';
import { AppState } from 'store/index';
import { TxtDefaultMediumLightResp } from 'elements/new-design/Typography';

//We want to change design and position of Telias chat widget. Hence this component.

type StyleProps = {
  active: boolean;
  hide: boolean;
};

const ChatWrapper = styled.div`
  ${(_: StyleProps) => ''}
  width: 3.5rem;
  height: 7rem;
  position: fixed;
  right: 0;
  top: calc(50vh - 35px);
  display: ${(props) => (props.hide ? 'none' : 'block')};
  z-index: ${(props) => (props.active ? props.theme.newconstants.zIndexSuper : props.theme.newconstants.zIndexMedium)};

  /* Changes to Telias chat button and modal */
  .humany-trigger {
    width: 3.5rem;
    height: 7rem;
    display: block;
    bottom: auto;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    top: calc(50vh - 35px);
    right: 0;
    z-index: 0;
    transition: none;
  }

  .humany-trigger:hover {
    border: none;
    outline: 0;
  }

  .humany-trigger button {
    border: none;
    position: static;
    padding: 0;
    height: 7rem;
    width: 3.5rem;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    cursor: pointer;
    outline: none;
    transition: none;
    background-color: transparent !important;
  }

  .humany-trigger button:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight} 0px;
    background-color: transparent !important;
  }

  .humany-trigger button:hover {
    box-shadow: none;
    background: ${(props) => props.theme.constants.hoverAccentPrimary};
  }

  .humany-icon,
  .humany-loader,
  .humany-custom-icon {
    display: none;
  }

  .humany-widget {
    bottom: 'auto';
    top: -100%;
    z-index: 0;
    text-align: left;
  }

  .humany-widget.humany-active {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${(props) => props.theme.newconstants.zIndexSuper};
    display: block;
  }

  .humany-bot-widget.humany-gentle-list > ul {
    text-align: left;
  }

  .humany-bot-widget {
    .humany-header {
      .humany-close {
        display: inline-block;
      }
    }
  }

  .humany-list {
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.medium} {
    display: block;
  }

  @media ${(props) => props.theme.breakpoints.large} {
    height: 7rem;
    width: 3.5rem;

    .humany-bot-widget {
      .humany-header {
        .humany-close {
          display: inline-block;
        }
      }
    }
    .humany-widget {
      bottom: auto;
      top: calc(50vh - 318px);
      right: 0 !important;
      left: auto !important;
    }

    .humany-widget.humany-active {
      bottom: auto;
      top: calc(50vh - 318px);
      right: 0 !important;
      left: auto !important;
    }
  }
`;

const ChatBtnWrapper = styled.button`
  width: 3.5rem;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
  background: ${(props) => props.theme.newcolors.bgDark};

  &:hover {
    background: ${(props) => props.theme.constants.hoverAccentPrimary};
  }
`;

const ButtonText = styled.div`
  transform: rotate(-90deg);
`;

// Component
// =========================

export const Chat = () => {
  const [active, setActive] = useState(false);
  const { translate } = useI18n();
  const { currentDialog } = useSelector((state: AppState) => state.global);

  let interval = undefined;
  const observer = undefined;

  const listenChatElemLoad = () => {
    const chatParent = document.getElementById('chatWrapper');
    const chat = document.getElementsByClassName('humany-trigger');
    const modal = document.getElementsByClassName('humany-bot-widget');

    if (chat.length > 0 && modal.length > 0 && chatParent) {
      chatParent.appendChild(chat[0]);
      chatParent.appendChild(modal[0]);
      clearInterval(interval);
    }
  };

  const toggleChat = () => {
    const chat = document.getElementsByClassName('humany-trigger');
    if (chat[0]) {
      const triggerBtn = chat[0].firstElementChild as HTMLElement;
      triggerBtn.click();
    }
  };

  useEffect(() => {
    if (!isLocalHost()) interval = setInterval(listenChatElemLoad, 1000);
    return function cleanup() {
      clearInterval(interval);
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <ChatWrapper id="chatWrapper" hide={currentDialog} active={active} onClick={() => setActive(!active)}>
      <ChatBtnWrapper
        onClick={toggleChat}
        aria-haspopup={true}
        aria-expanded={active}
        aria-label={translate('OPEN_CHAT')}
      >
        <ButtonText>
          <TxtDefaultMediumLightResp>{translate('CHAT')}</TxtDefaultMediumLightResp>
        </ButtonText>
      </ChatBtnWrapper>
    </ChatWrapper>
  );
};
