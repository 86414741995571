import { Constants } from 'utils/Constants';
import { usePersistentStorage } from './usePersistentStorage';

const FACEBOOK_PIXEL = '_fbp';

export const useCookieConsent = () => {
  const { getCachedItem } = usePersistentStorage();

  const showCookieComponent = () => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    return !settings || (settings && (!settings.GOA_NECCESSARY_COOKIE || !settings.GOA_AD_COOKIE));
  };

  const removeAdCookie = () => {
    document.cookie = `${FACEBOOK_PIXEL}=; Domain=.go-aheadnordic.no; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const hasAnalyticConsent = () => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    return settings && settings.GOA_ANALYTICAL_COOKIE === Constants.ON;
  };

  //Facebook pixel is no longer in use
  const deleteOldCookies = () => removeAdCookie();

  return {
    hasAnalyticConsent,
    deleteOldCookies,
    showCookieComponent,
  };
};
