import React from 'react';
import styled from 'styled-components';
import {
  TxtSmallMediumMediumResp,
  fontColorMedium,
  fontFamilyBold,
  fontSizeLargeResp,
} from 'elements/new-design/Typography';
import { MarginBottom } from './distance/Margins';

type CodeReferenceBoxProps = {
  title: string;
  code: string;
};

const CodeBox = styled.input`
  ${fontColorMedium}
  ${fontFamilyBold}
  ${fontSizeLargeResp}
  text-align: center;
  padding: 1.6rem 0;
  margin-bottom: 0.8rem;
  border: ${(props) => props.theme.constants.borderPrimary};
  background: ${(props) => props.theme.colors.bgSecondary};

  &:focus-visible {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

export const CodeReferenceBox = ({ code, title }: CodeReferenceBoxProps) => {
  return (
    <>
      <TxtSmallMediumMediumResp>{title}</TxtSmallMediumMediumResp>
      <MarginBottom margin={0.8} />
      <CodeBox tabIndex={0} value={code} readOnly />
    </>
  );
};
