import colors from './colors';
import fonts from './fonts';
import devices from './devices';
import constants from './constants';
import newconstants from './new-design/constants';
import newcolors from './new-design/colors';
import breakpoints from './new-design/breakpoints';

export default {
  devices,
  colors,
  fonts,
  constants,
  newcolors,
  newconstants,
  breakpoints,
};
