import React from 'react';
import styled from 'styled-components';
import { ParagraphText } from 'elements/new-design/Typography';

type ImageTextWrapProps = {
  box: any;
};

type StyleProps = {
  position: string;
};

const ContentContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: auto;

  img {
    max-width: 100%;
    height: auto;
    float: ${(props) => props.position};
    padding-bottom: 2.4rem;

    @media ${(props) => props.theme.breakpoints.medium} {
      max-width: 40rem;
      padding-bottom: 0;
      padding-left: ${(props) => (props.position === 'right' ? '2.4rem' : 0)};
      padding-right: ${(props) => (props.position === 'left' ? '2.4rem' : 0)};
    }
  }
`;

export const ImageTextWrap = ({ box }: ImageTextWrapProps): JSX.Element => (
  <ContentContainer position={box.imagePosition}>
    <img src={box.url} alt={box.image.alt} />
    <ParagraphText>{box.text}</ParagraphText>
  </ContentContainer>
);
