import React from 'react';
import { HTMLProps, FC } from 'react';
import styled, { css } from 'styled-components';
import {
  TxtSmallBoldDarkResp,
  fontFamilyBold,
  fontColorDark,
  fontColorLight,
  fontColorError,
} from 'elements/new-design/Typography';

type StyleProps = HTMLProps<HTMLButtonElement> & {
  inFocus?: boolean;
  disabled?: boolean;
  big?: boolean;
  type?: string;
  onButtonClick?: () => void;
};

type ButtonProps = StyleProps & {
  text: string;
  onButtonClick: () => void;
};

const sizeBig = css`
  ${fontFamilyBold}
  ${fontColorDark}
  font-size: 1.8rem;
  padding: 1.6rem 3.2rem;
  border-radius: 3rem;
`;

const sizeSmall = css`
  ${fontFamilyBold}
  ${fontColorDark}
  font-size: 1.6rem;
  padding: 0.8rem 1.6rem;
  border-radius: 2rem;
`;

export const BorderButtonDark: FC<StyleProps> = styled.button.attrs({
  type: 'button',
})`
  ${(_: StyleProps) => ''}
  white-space: nowrap;
  border: ${(props) =>
    props.inFocus ? props.theme.newconstants.borderDarkThick : props.theme.newconstants.borderDarkThin};
  margin: ${(props) => (props.inFocus ? '-1px' : '0')};
  ${(props) => (props.big ? sizeBig : sizeSmall)}
  :hover,
  :focus-visible {
    border: ${(props) => props.theme.newconstants.borderDarkThick};
    margin: -1px;
    box-shadow: none;
  }

  :disabled {
    border: ${(props) => props.theme.newconstants.borderDarkThin};
    opacity: 0.5;
    margin: 0;
    cursor: default;
  }
`;

export const BorderButtonError = styled(BorderButtonDark)`
  ${(_: StyleProps) => ''}
  ${fontColorError}
  border: ${(props) => props.theme.newconstants.borderErrorThin};
  :hover,
  :focus-visible {
    border: ${(props) => props.theme.newconstants.borderErrorThick};
  }

  :disabled {
    border: ${(props) => props.theme.newconstants.borderErrorThin};
  }
`;

export const BgButtonDark: FC<StyleProps> = styled.button.attrs({
  type: 'button',
})`
  ${(_: StyleProps) => ''}
  ${(props) => (props.big ? sizeBig : sizeSmall)}
  ${fontColorLight}
  white-space: nowrap;
  background: ${(props) => props.theme.newcolors.bgDark};

  :hover,
  :focus-visible {
    box-shadow: ${(props) => props.theme.newconstants.keyNavFocus};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const BgButtonError: FC<StyleProps> = styled(BgButtonDark).attrs({
  type: 'button',
})`
  ${(_: StyleProps) => ''}
  ${fontColorLight}
  background: ${(props) => props.theme.newcolors.errorDark};
`;

export const TextButtonBoldDarkSmall = styled.button.attrs({
  type: 'button',
})`
  ${(_: StyleProps) => ''}
  font-size: 1.6rem;
  ${fontFamilyBold}
  ${fontColorDark}
   text-align: left;
  border-bottom: ${(props) =>
    props.inFocus ? props.theme.newconstants.borderDarkThick : props.theme.newconstants.borderTransparantThick};

  :hover,
  :focus-visible {
    border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    box-shadow: none;
  }
`;

export const TextButtonBoldDarkLarge = styled(TextButtonBoldDarkSmall)`
  font-size: 2.2rem;
`;

const ModalButton = styled.button.attrs({
  type: 'button',
})`
  ${(_: StyleProps) => ''}
  margin: 0;
  padding-bottom: 0.1rem;
  border-bottom: ${(props) =>
    props.color === 'light' ? props.theme.newconstants.borderLightThin : props.theme.newconstants.borderDarkThin};

  :hover,
  :focus-visible {
    margin-bottom: -1px;
    box-shadow: none;
    border-bottom: ${(props) =>
      props.color === 'light' ? props.theme.newconstants.borderLightThick : props.theme.newconstants.borderDarkThick};
  }
`;

export const ModalButtonDark = ({ text, onButtonClick }: ButtonProps) => (
  <ModalButton onClick={onButtonClick}>
    <TxtSmallBoldDarkResp>{text}</TxtSmallBoldDarkResp>
  </ModalButton>
);
