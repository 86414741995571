import React from 'react';
import styled, { css } from 'styled-components';
import { Constants } from 'utils/Constants';
type SectionMessageProps = {
  type: string;
  children?: JSX.Element | JSX.Element[];
};
type StyleProps = {
  type: string;
};
const MessageContainer = styled.div`
  ${(_: StyleProps) => ''}
  border-radius: ${(props) => props.theme.newconstants.borderRadiusSquare};
  ${(props) => {
    if (props.type === Constants.WARNING_MESSAGE || props.type === Constants.ERROR_MESSAGE) {
      return css`
        background: ${(props) => props.theme.newcolors.errorLight};
        box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
      `;
    } else if (props.type === Constants.INFO_MESSAGE) {
      return css`
        background: ${(props) => props.theme.newcolors.infoLight};
        box-shadow: ${(props) => props.theme.newcolors.borderBoxshadow};
      `;
    }
  }};
`;
export const MessageBox = ({ ...props }: SectionMessageProps) => (
  <MessageContainer type={props.type}>{props.children}</MessageContainer>
);
