import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { A } from 'hookrouter';
import { TxtMediumBoldLightStatic } from 'elements/new-design/Typography';
import { EnterIconLight } from 'elements/new-design/icons/SymbolIcons';

type SkipToLinkProps = {
  height: string;
  onSkipToMain: () => void;
};

type StyleProps = {
  height: string;
};

const SkipToContent = styled(A)`
  ${(_: StyleProps) => ''}
  width: 100vw;
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: header;
  position: fixed;
  top: -20rem;
  z-index: ${(props) => props.theme.newconstants.zIndexMega};
  background-color: ${(props) => props.theme.newcolors.txtColDark};

  &:focus {
    box-shadow: none;
    top: 0;
  }

  ${TxtMediumBoldLightStatic} {
    margin-right: 0.8rem;
  }
`;

export const SkipToMainLink = ({ height, onSkipToMain }: SkipToLinkProps) => {
  const { translate } = useI18n();
  return (
    <SkipToContent href="" height={height} aria-label={translate('SKIP_TO_CONTENT')} onClick={onSkipToMain}>
      <TxtMediumBoldLightStatic>{translate('SKIP_TO_CONTENT')}</TxtMediumBoldLightStatic>
      <EnterIconLight width={2.5} height={2.5} />
    </SkipToContent>
  );
};
