import React, { ReactNode } from 'react';
import theme from 'styles/styled.theme';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { fontGlobals } from 'styles/fonts';

type ThemeProps = {
  children: ReactNode;
};

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-size-adjust: none;
    -webkit-text-size-adjust: none;
    -webkit-font-size-adjust: none;
    margin: 0;
    padding: 0;
    outline: none;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    overflow: hidden;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
  th {
    font-weight: normal;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;

    &:focus-visible {
      box-shadow: ${theme.constants.borderFocusLight};
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:focus-visible {
      box-shadow: ${theme.constants.borderFocusLight};
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    ::-ms-expand {
      display: none;
    }
  }

  input {
    ::-moz-placeholder {
      opacity: 1;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color:  ${theme.colors.txtColPlaceholder};
      font-size: ${theme.fonts.fontSize.default};
      font-family: ${theme.fonts.fontFamily.medium};
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${theme.colors.txtColPlaceholder};
      font-family: ${theme.fonts.fontFamily.medium};
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: ${theme.colors.txtColPlaceholder};
      font-family: ${theme.fonts.fontFamily.medium};
    }
  }

  /*remove clear icon on ie11/edge inputs*/
  ::-ms-clear {
      display: none;
  }

  /*remove spinners on input type number*/
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

  /*remove cdotted border on button focus in firefox*/
  button::-moz-focus-inner {
    border: 0;
  }

  ${fontGlobals}
`;

export const Theme = ({ children }: ThemeProps): JSX.Element => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  </ThemeProvider>
);
