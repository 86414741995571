import React from 'react';
import styled from 'styled-components';
import ExclamationIcon from 'assets/svg/exclamation-mark-white.svg';
import TicketIconLight from 'assets/svg/ticket-white.svg';
import { useI18n } from 'utils/i18n/usei18n';
import { Icon } from 'elements/icon/Icon';
import { EmailCircleIcon } from 'elements/icon/symbol-icons';
import { Constants } from 'utils/Constants';
import { TxtSmallBoldDarkResp, TxtSmallBoldLightResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { MarginBottom } from 'elements/distance/Margins';
import { FlexColumn } from 'elements/containers/Containers';
import { ButtonAccentDark, ButtonAccentPrimary, ButtonAccentSecondary } from 'elements/buttons/Buttons';
import { CustomerServiceEmail } from 'elements/contacts/CustomerServiceEmail';
import { CustomerServicePhone } from 'elements/contacts/CustomerServicePhone';
import { CustomerServiceContactInfo } from 'elements/contacts/CustomerServiceContactInfo';

// Types
// =========================
type SectionMessageProps = {
  type?: string;
  headline: string;
  text: string;
  customerSupport?: boolean;
  mailSubject?: string;
  icon?: string;
  buttonText?: string;
  isDarkButton?: boolean;
  onButtonClick?: () => void;
};

type MessageStyleProps = {
  type?: string;
  customerSupport?: boolean;
};

type ReserveExpiredProps = {
  onButtonClick: () => void;
};

// Component
// ========================

const SectionMessageWrapper = styled.div`
  ${(_: MessageStyleProps) => ''}
  padding: 2rem;
  border-radius: 0rem;
  border-width: 0.1rem 0rem 0.1rem 0rem;
  margin-top: 3.6rem;
  background: ${(props) => {
    if (props.type === (Constants.ERROR_MESSAGE || Constants.WARNING_MESSAGE)) {
      return props.theme.colors.bgError;
    } else {
      return props.theme.colors.bgInfo;
    }
  }};

  border: ${(props) => {
    if (props.type === (Constants.ERROR_MESSAGE || Constants.WARNING_MESSAGE)) {
      return props.theme.constants.borderSoftError;
    } else {
      return props.theme.constants.borderThinInfo;
    }
  }};

  @media ${(props) => props.theme.devices.medium} {
    border-radius: 2rem;
  }
`;

const CustomerServiceContainer = styled(SectionMessageWrapper)`
  border: ${(props) => props.theme.constants.borderThinInfo};
`;

const IconContainer = styled(FlexColumn)`
  ${(_: MessageStyleProps) => ''}
  width: 4.7rem;
  height: 4.7rem;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.type === (Constants.ERROR_MESSAGE || Constants.WARNING_MESSAGE)) {
      return props.theme.colors.error;
    } else {
      return props.theme.colors.accentPrimary;
    }
  }};
`;

const StyledIcon = styled(Icon)`
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 1rem;
`;

const MessageText = styled.div`
  flex: 1;
  margin: 1rem 0 2rem 0;
  text-align: center;

  @media ${(props) => props.theme.devices.medium} {
    margin: 0 2rem 0 2rem;
    text-align: left;
  }

  @media ${(props) => props.theme.devices.large} {
    text-align: left;
  }
`;

const TopSection = styled.div`
  ${(_: MessageStyleProps) => ''}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => (props.customerSupport ? '2rem' : '0')};

  @media ${(props) => props.theme.devices.medium} {
    flex-direction: row;
  }
`;

const CustomerServiceWrapper = styled(FlexColumn)`
  align-items: center;

  @media ${(props) => props.theme.devices.medium} {
    margin-left: 6.5rem;
    align-items: start;
  }
`;

const CustomerServiceContent = styled.div`
  display: grid;
  grid-template-coloumns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 2.4rem;
  text-align: center;
  @media ${(props) => props.theme.devices.medium} {
    grid-template-coloumns: repeat(2, auto);
    grid-template-rows: 1fr;
    grid-gap: 2.4rem;
    justify-content: start;
    text-align: left;
  }
`;

const SectionMessage = ({ ...props }: SectionMessageProps) => {
  return (
    <SectionMessageWrapper data-cy={'sectionMessageCy'} type={props.type} id="SectionMessage">
      <TopSection customerSupport={props.customerSupport}>
        <IconContainer type={props.type}>
          <StyledIcon icon={props.icon} />
        </IconContainer>
        <MessageText>
          <MarginBottom margin={0.5}>
            <h2>
              <TxtSmallBoldDarkResp>{props.headline}</TxtSmallBoldDarkResp>
            </h2>
          </MarginBottom>
          <p>
            <TxtSmallMediumDarkResp data-cy={'errorSmallTextCy'}>{props.text}</TxtSmallMediumDarkResp>
          </p>
        </MessageText>
        {props.buttonText && props.type === (Constants.ERROR_MESSAGE || Constants.WARNING_MESSAGE) && (
          <ButtonAccentSecondary data-cy={'sectionMessageBtnCy'} onClick={props.onButtonClick}>
            <TxtSmallBoldLightResp data-cy={'sectionMessageBtnTxtCy'}>{props.buttonText}</TxtSmallBoldLightResp>
          </ButtonAccentSecondary>
        )}
        {props.buttonText && props.type === Constants.INFO_MESSAGE && !props.isDarkButton && (
          <ButtonAccentPrimary onClick={props.onButtonClick}>
            <TxtSmallBoldDarkResp>{props.buttonText}</TxtSmallBoldDarkResp>
          </ButtonAccentPrimary>
        )}
        {props.buttonText && props.isDarkButton && (
          <ButtonAccentDark onClick={props.onButtonClick}>
            <TxtSmallBoldLightResp>{props.buttonText}</TxtSmallBoldLightResp>
          </ButtonAccentDark>
        )}
      </TopSection>
      {props.customerSupport && (
        <CustomerServiceWrapper>
          <CustomerServicePhone />
          <CustomerServiceEmail mailSubject={props.mailSubject ? props.mailSubject : ''} />
        </CustomerServiceWrapper>
      )}
    </SectionMessageWrapper>
  );
};

export const SectionEmptyMessage = ({ ...props }: SectionMessageProps) => {
  return (
    <SectionMessage
      type={Constants.INFO_MESSAGE}
      icon={props.icon ? props.icon : TicketIconLight}
      text={props.text}
      headline={props.headline}
      buttonText={props.buttonText}
      onButtonClick={props.onButtonClick}
      isDarkButton={props.isDarkButton}
    />
  );
};

export const SectionCustomerMessage = ({ ...props }: SectionMessageProps) => {
  return (
    <CustomerServiceContainer type={props.type}>
      <CustomerServiceContent>
        <EmailCircleIcon />
        <div>
          <MarginBottom margin={0.5}>
            <h2>
              <TxtSmallBoldDarkResp>{props.headline}</TxtSmallBoldDarkResp>
            </h2>
          </MarginBottom>
          <p>
            <TxtSmallMediumDarkResp>{props.text}</TxtSmallMediumDarkResp>
          </p>
          <MarginBottom margin={2.4} />
          <CustomerServiceContactInfo />
        </div>
      </CustomerServiceContent>
    </CustomerServiceContainer>
  );
};

export const SectionErrorMessage = ({ ...props }: SectionMessageProps) => {
  return (
    <SectionMessage
      type={Constants.ERROR_MESSAGE}
      icon={props.icon ? props.icon : ExclamationIcon}
      text={props.text}
      headline={props.headline}
      buttonText={props.buttonText}
      mailSubject={props.mailSubject}
      onButtonClick={props.onButtonClick}
      customerSupport={props.customerSupport}
    />
  );
};

export const ErrorReserveExpired = ({ onButtonClick }: ReserveExpiredProps) => {
  const { translate } = useI18n();
  return (
    <SectionErrorMessage
      text={translate('RESERVATION_EXPIRED_TEXT')}
      headline={translate('RESERVATION_EXPIRED_HEADLINE')}
      buttonText={translate('SEARCH_AGAIN')}
      onButtonClick={onButtonClick}
    />
  );
};

export const ErrorNotAvailableTicket = ({ onButtonClick }: ReserveExpiredProps) => {
  const { translate } = useI18n();
  return (
    <SectionErrorMessage
      text=""
      headline={translate('TICKET_NOT_AVAILABLE')}
      buttonText={translate('SEARCH_AGAIN')}
      onButtonClick={onButtonClick}
    />
  );
};
