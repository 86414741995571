import React from 'react';
import AriaModal from 'react-aria-modal';
import newcolors from 'styles/new-design/colors';
import { DialogCenter, DialogLeft, DialogPage, DialogCookie } from './DialogTypes';

type DialogProps = {
  type: string;
  title: string;
  titleId?: string;
  width?: number;
  height?: number;
  focusDialog?: boolean;
  children: JSX.Element | JSX.Element[];
  onModalClose: () => void;
};

//Dialog must have titleText or titleId for accessability purposes.
//If diplaying title in dialog is not wanted, leave title an empty string and use titleId
//underlayColor=""
export const Dialog = ({
  type,
  title,
  titleId,
  width,
  height,
  focusDialog,
  onModalClose,
  children,
}: DialogProps): JSX.Element => (
  <AriaModal
    titleText={title ? title : titleId ? titleId : 'Dialog'}
    onExit={onModalClose}
    verticallyCenter
    underlayColor={newcolors.backdrop}
    focusDialog={focusDialog}
  >
    {type === 'center' && (
      <DialogCenter width={width} height={height} onModalClose={onModalClose}>
        {children}
      </DialogCenter>
    )}
    {type === 'left' && <DialogLeft onModalClose={onModalClose}>{children}</DialogLeft>}
    {type === 'page' && <DialogPage onModalClose={onModalClose}>{children}</DialogPage>}
    {type === 'cookie' && <DialogCookie onModalClose={onModalClose}>{children}</DialogCookie>}
  </AriaModal>
);
