import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Customer */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Create temporary customer profile
 */
export function Customer_CreateTemporaryCustomerProfile(): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultVerifiedCustomer1>> {
  return gateway.request(Customer_CreateTemporaryCustomerProfileOperation)
}

/**
 * Renew auth token
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<object>} Success
 */
export function Customer_RenewBearerToken(options?: Customer_RenewBearerTokenOptions): Promise<api.Response<any>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Customer_RenewBearerTokenOperation, parameters)
}

/**
 * Get customer profile
 * 
 * @param {string} authorization 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>} Success
 */
export function Customer_GetProfile(authorization: string): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>> {
  const parameters: api.OperationParamGroups = {
    header: {
      authorization
    }
  }
  return gateway.request(Customer_GetProfileOperation, parameters)
}

/**
 * Get available consents
 */
export function Customer_GetAvailableConsents(): Promise<api.Response<any>> {
  return gateway.request(Customer_GetAvailableConsentsOperation)
}

/**
 * Get consents
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultConsent2>} Success
 */
export function Customer_GetConstents(options?: Customer_GetConstentsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultConsent2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Customer_GetConstentsOperation, parameters)
}

/**
 * Create customer profile
 * 
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Services.CreateCustomerRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerProfileResponse1>} Success
 */
export function Customer_CreateCustomerProfile(options?: Customer_CreateCustomerProfileOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerProfileResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_CreateCustomerProfileOperation, parameters)
}

/**
 * Login customer
 * 
 * @param {object} options Optional options
 * @param {string} [options.clientType=Web] 
 * @param {module:types.GoAliveRestApi.Clients.EnTurCustomerProfile.VerifyCredentialsRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultVerifyCredentialResult1>} Success
 */
export function Customer_VerifyCustomerCredentials(options?: Customer_VerifyCustomerCredentialsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultVerifyCredentialResult1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_VerifyCustomerCredentialsOperation, parameters)
}

/**
 * Login customer in App
 * 
 * @param {string} clientType Enum: Android, iOS, Web. 
 * @param {number} appId 
 * @param {string} appGuid 
 * @param {string} deviceId 
 * @param {module:types.GoAliveRestApi.Clients.EnTurCustomerProfile.VerifyCredentialsRequest} body 
 * @param {object} options Optional options
 * @param {boolean} [options.forceLogin] Force login when it's the customer's last move between apps/phones in the current period
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultVerifyAppCustomerCredentialsResponse1>} Success
 */
export function Customer_VerifyAppCustomerCredentials(clientType: 'Android'|'iOS'|'Web', appId: number, appGuid: string, deviceId: string, body: api.GoAliveRestApi.Clients.EnTurCustomerProfile.VerifyCredentialsRequest, options?: Customer_VerifyAppCustomerCredentialsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultVerifyAppCustomerCredentialsResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      clientType,
      appId,
      appGuid,
      deviceId
    },
    query: {
      forceLogin: options.forceLogin
    },
    body: {
      body
    }
  }
  return gateway.request(Customer_VerifyAppCustomerCredentialsOperation, parameters)
}

/**
 * Refresh token
 * 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.RefreshTokenRequest} body 
 * @param {object} options Optional options
 * @param {string} [options.clientType=Web] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRefreshTokenResponse1>} Success
 */
export function Customer_RefreshToken(body: api.GoAliveRestApi.Controllers.ControllerModels.RefreshTokenRequest, options?: Customer_RefreshTokenOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRefreshTokenResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      clientType: options.clientType
    },
    body: {
      body
    }
  }
  return gateway.request(Customer_RefreshTokenOperation, parameters)
}

/**
 * Update temporary customer profile
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Clients.EnTurCustomerProfile.UpdateCustomerProfileRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultOkResult1>} Success
 */
export function Customer_UpdateTempUserProfile(options?: Customer_UpdateTempUserProfileOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultOkResult1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_UpdateTempUserProfileOperation, parameters)
}

/**
 * Update customer password
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {module:types.GoAliveRestApi.Clients.EnTurCustomerProfile.UpdateCustomerRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultOkResult1>} Success
 */
export function Customer_UpdatePassword(options?: Customer_UpdatePasswordOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultOkResult1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_UpdatePasswordOperation, parameters)
}

/**
 * Edit customer profile
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {module:types.GoAliveRestApi.Clients.EnTurCustomerProfile.EditProfileRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>} Success
 */
export function Customer_EditProfile(options?: Customer_EditProfileOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_EditProfileOperation, parameters)
}

/**
 * Edit customer consents
 * 
 * @param {string} authorization 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.CreateGivenConsentRequest[]} body 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>} Success
 */
export function Customer_EditConsents(authorization: string, body: api.GoAliveRestApi.Controllers.ControllerModels.CreateGivenConsentRequest[]): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>> {
  const parameters: api.OperationParamGroups = {
    header: {
      authorization
    },
    body: {
      body
    }
  }
  return gateway.request(Customer_EditConsentsOperation, parameters)
}

/**
 * Edit a customer consent
 * 
 * @param {string} authorization 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.EditConsent} body 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>} Success
 */
export function Customer_EditConsent(authorization: string, body: api.GoAliveRestApi.Controllers.ControllerModels.EditConsent): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerLookUpResult1>> {
  const parameters: api.OperationParamGroups = {
    header: {
      authorization
    },
    body: {
      body
    }
  }
  return gateway.request(Customer_EditConsentOperation, parameters)
}

/**
 * Reset customer password
 * 
 * @param {object} options Optional options
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ForgotPasswordRequest} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultForgotPasswordResponse1>} Success
 */
export function Customer_ForgotPassword(options?: Customer_ForgotPasswordOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultForgotPasswordResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_ForgotPasswordOperation, parameters)
}

/**
 * Delete customer
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultDeleteCustomerResult1>} Success
 */
export function Customer_DeleteCustomer(options?: Customer_DeleteCustomerOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultDeleteCustomerResult1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Customer_DeleteCustomerOperation, parameters)
}

export interface Customer_RenewBearerTokenOptions {
  authorization?: string
}

export interface Customer_GetConstentsOptions {
  authorization?: string
}

export interface Customer_CreateCustomerProfileOptions {
  body?: api.GoAliveRestApi.Services.CreateCustomerRequest
}

export interface Customer_VerifyCustomerCredentialsOptions {
  clientType?: string
  body?: api.GoAliveRestApi.Clients.EnTurCustomerProfile.VerifyCredentialsRequest
}

export interface Customer_VerifyAppCustomerCredentialsOptions {
  /**
   * Force login when it's the customer's last move between apps/phones in the current period
   */
  forceLogin?: boolean
}

export interface Customer_RefreshTokenOptions {
  clientType?: string
}

export interface Customer_UpdateTempUserProfileOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Clients.EnTurCustomerProfile.UpdateCustomerProfileRequest
}

export interface Customer_UpdatePasswordOptions {
  authorization?: string
  body?: api.GoAliveRestApi.Clients.EnTurCustomerProfile.UpdateCustomerRequest
}

export interface Customer_EditProfileOptions {
  authorization?: string
  clientType?: 'Android'|'iOS'|'Web'
  body?: api.GoAliveRestApi.Clients.EnTurCustomerProfile.EditProfileRequest
}

export interface Customer_ForgotPasswordOptions {
  body?: api.GoAliveRestApi.Controllers.ControllerModels.ForgotPasswordRequest
}

export interface Customer_DeleteCustomerOptions {
  authorization?: string
}

const Customer_CreateTemporaryCustomerProfileOperation: api.OperationInfo = {
  path: '/rest/Customer/createtempprofile',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_RenewBearerTokenOperation: api.OperationInfo = {
  path: '/rest/Customer/renew',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_GetProfileOperation: api.OperationInfo = {
  path: '/rest/Customer/get-profile',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_GetAvailableConsentsOperation: api.OperationInfo = {
  path: '/rest/Customer/get-available-consents',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_GetConstentsOperation: api.OperationInfo = {
  path: '/rest/Customer/get-consents',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_CreateCustomerProfileOperation: api.OperationInfo = {
  path: '/rest/Customer/create',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_VerifyCustomerCredentialsOperation: api.OperationInfo = {
  path: '/rest/Customer/login',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_VerifyAppCustomerCredentialsOperation: api.OperationInfo = {
  path: '/rest/Customer/login-app',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_RefreshTokenOperation: api.OperationInfo = {
  path: '/rest/Customer/refresh-token',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_UpdateTempUserProfileOperation: api.OperationInfo = {
  path: '/rest/Customer/updatetempprofile',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_UpdatePasswordOperation: api.OperationInfo = {
  path: '/rest/Customer/update',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_EditProfileOperation: api.OperationInfo = {
  path: '/rest/Customer/edit-profile',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_EditConsentsOperation: api.OperationInfo = {
  path: '/rest/Customer/edit-consents',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_EditConsentOperation: api.OperationInfo = {
  path: '/rest/Customer/edit-consent',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_ForgotPasswordOperation: api.OperationInfo = {
  path: '/rest/Customer/forgot-password',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Customer_DeleteCustomerOperation: api.OperationInfo = {
  path: '/rest/Customer/delete',
  method: 'delete',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
