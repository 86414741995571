export type SelectedSeatType = {
  id: number;
  railcarNumber: number;
  seatNumber: number;
  seatTypeId?: string;
  reversible?: boolean;
};

export type SeatType = {
  carType: string;
  carNumber: number;
  compartmentNumber: number;
  seatNumber: number;
  position: string;
};

export type SelectedSeatListType = { [index: string]: SelectedSeatType[] };

export type SeatIconType = {
  id: number;
  imageContentType: string;
  imageHeight: number;
  imageUrlPath: string;
  imageUrlPathChosen: string;
  imageUrlPathConfirmed: string;
  imageUrlPathConflict: string;
  imageUrlPathUnavailable: string;
  imageWidth: number;
  seat: boolean;
  typeName: string;
};

export type RailcarElementType = {
  direction: string;
  iconType: number;
  id: number;
  positionX: number;
  positionY: number;
  seatNumber: number;
  available: boolean;
  seatSpecification: SeatType;
  reversible: boolean;
};

export type SeatMapType = {
  carNumber: number;
  numberOfSeats: number;
  railcarSetId: number;
  litraCode: string;
  imageContentType: string;
  imageHeight: number;
  imageWidth: number;
  railcarImagePath: string;
  rotated: boolean;
  railcarElements: RailcarElementType[];
  seatIconMap: SeatIconType[];
};

export type SeatReservations = {
  datedServiceJourneyId: string;
  externalReservationId: string;
  id: number;
  seats: SelectedSeatType[];
};

// Set Selected Seats
// =========================
export const SET_SELECTED_SEATS = 'SET_SELECTED_SEATS';

type SetSelectedSeats = {
  type: typeof SET_SELECTED_SEATS;
  payload: SelectedSeatListType;
};

export type SeatActionTypes = SetSelectedSeats;
