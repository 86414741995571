export const validateEmail = (email: string): boolean => {
  const validEmailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validEmailRegEx.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string): boolean => {
  const vailPhoneRegEx = /^\d{8}$/;
  return phone !== '' && phone !== undefined ? vailPhoneRegEx.test(phone) : true;
};

export const validateIsOnlyNumbers = (value: string): boolean => {
  const allowed = /^\d+$/;
  return allowed.test(value);
};

export const validateIsRequired = (value: string): boolean => {
  return value && String(value).trim() !== '';
};

export const validateOnlyAllowedLetters = (value: string): boolean => {
  let valid = true;
  for (let i = 0; i < value.length; i++) {
    if (
      (value.charCodeAt(i) >= 0 && value.charCodeAt(i) <= 31) ||
      (value.charCodeAt(i) >= 33 && value.charCodeAt(i) <= 38) ||
      (value.charCodeAt(i) >= 40 && value.charCodeAt(i) <= 44) ||
      (value.charCodeAt(i) >= 46 && value.charCodeAt(i) <= 64) ||
      (value.charCodeAt(i) >= 91 && value.charCodeAt(i) <= 96) ||
      (value.charCodeAt(i) >= 127 && value.charCodeAt(i) <= 158) ||
      (value.charCodeAt(i) >= 161 && value.charCodeAt(i) <= 191) ||
      value.charCodeAt(i) === 215 ||
      value.charCodeAt(i) === 247
    ) {
      valid = valid && false;
    } else {
      valid = valid && true;
    }
  }
  return valid;
};

export const validateNoPrePostSuffDash = (value: string): boolean => {
  return value.startsWith('-') || value.endsWith('-');
};

export const validatePassword = (password: string): boolean => {
  const validPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@"'-£€$∞%&§/|()[\]{}=≈≠?¿±´`^¨~*™’,„.…_—<>≤≥¶§°]{5,100}$/;
  return validPasswordRegex.test(String(password));
};

export const validateMinLength = (value: string, minLength: number): boolean => {
  return value.length >= minLength;
};

export const validateMaxLength = (value: string, maxLength: number): boolean => {
  return value.length <= maxLength;
};

export const validateMinMaxRange = (value: string, min: number, max: number): boolean => {
  return Number(value) >= min && Number(value) <= max;
};

export const validateMinMaxLengthValid = (value: string, minLength: number, maxLength: number): boolean => {
  return validateMinLength(String(value), minLength) && validateMaxLength(String(value), maxLength);
};

export const validateCountryCode = (value: string) => {
  const validCountryCodeRegex = /^(\d{1,4})$/;
  return validCountryCodeRegex.test(String(value));
};

export const validateIsSamePassword = (form): boolean => {
  const newPwd = form.get('password').value;
  const repeatPwd = form.get('passwordRepeat').value;
  if (newPwd && repeatPwd) return newPwd === repeatPwd;
  return true;
};

export const validateNewOldIsSame = (form): boolean => {
  const oldPwd = form.get('passwordOld').value;
  const newPwd = form.get('password').value;
  if (oldPwd && newPwd) return oldPwd !== newPwd;
  return true;
};

export const getValidatedField = (field: any) => {
  field.validationRules.map((rule: string) => {
    switch (rule) {
      case 'isEmail':
        if (!validateEmail(field.value)) {
          field.error = 'EMAIL_ADRESS_VALIDATION_TEXT';
        }
        break;
      case 'isRequired':
        if (!validateIsRequired(field.value)) {
          field.error = 'CANT_BE_EMPTY';
        }
        break;
      case 'isValidPhone':
        if (!validatePhone(field.value)) {
          field.error = 'MUST_HAVE_NORWEGIAN_PHONE_SHORT';
        }
        break;
      case 'isChecked':
        if (!field.value) {
          field.error = 'CHECK_BOX_ERROR';
        }
        break;
      case 'isAcceptedTerms':
        if (!field.value) {
          field.error = 'ERROR_ACCEPT_TERMS';
        }
        break;
      default:
        break;
    }
  });
  return field;
};

export const validateEitherSBMailOrPhone = (form) => {
  const mail = form.get('strawberryCustomerEmail').value;
  const phone = form.get('strawberryCustomerPhoneNumber').value;
  const isMailEmpty = mail === undefined || mail === '';
  const isPhoneEmpty = phone === undefined || phone === '';

  if ((isMailEmpty && !isPhoneEmpty) || (!isMailEmpty && isPhoneEmpty)) return true;
  return false;
};
