import React, { useState } from 'react';
import { Constants } from 'utils/Constants';
import { CampaignMessage } from './OrderlineMessages';
import { ApplicableExternalCampaignsType } from 'types/HistoryTypes';
import { useApi } from 'hooks/use-api/useApi';
type ExternalCampaignsProps = {
  externalCampaigns: ApplicableExternalCampaignsType[];
  orderLineId: string;
};
const ExternalCampaignsState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;
export const ExternalCampaigns = ({ externalCampaigns, orderLineId }: ExternalCampaignsProps): JSX.Element => {
  const { activateExternalCampaign } = useApi().ExternalCampaignsApi();
  const [state, setState] = useState<typeof ExternalCampaignsState>(Constants.IDLE);
  const handleActivateCampaign = async (campaign: string) => {
    setState(Constants.LOADING);
    if (campaign === Constants.CAMPAIGNS.FLIPP) {
      try {
        const { data } = await activateExternalCampaign(campaign, orderLineId);
        data.result ? window.open(data.result, '_self') : setState(Constants.ERROR);
      } catch (error) {
        setState(Constants.ERROR);
      }
    }
  };
  return (
    <>
      {externalCampaigns.map((campaign, index) => (
        <CampaignMessage
          key={index}
          name={campaign.name}
          buttonText={campaign.buttonText}
          description={campaign.description}
          loading={state === Constants.LOADING}
          error={state === Constants.ERROR}
          onCampaignButtonClick={handleActivateCampaign}
        />
      ))}
    </>
  );
};
