import { Constants } from 'utils/Constants';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import {
  RecurringPayment_GetRecurringPayments,
  RecurringPayment_CreateRecurringPayment,
  RecurringPayment_DeleteRecurringPayment,
  RecurringPayment_UpdateRecurringPayment,
  RecurringPayment_AuthorizeRecurringPayment,
} from 'apiV1/RecurringPayment';

export const useRecurringPaymentApi = (token: string) => {
  const { getCachedItem } = usePersistentStorage();
  const getRecurringPayments = async () =>
    await RecurringPayment_GetRecurringPayments({ authorization: 'Bearer ' + token });

  const createRecurringPayment = async (isPrimary: boolean) =>
    await RecurringPayment_CreateRecurringPayment({
      authorization: 'Bearer ' + token,
      nickname: '',
      redirectUrl: `${window.location.protocol}//${window.location.host}${Constants.ROUTE_NETS_REDIRECT}`,
      isPrimary,
    });

  const deleteRecurringPayment = async (id: number) =>
    await RecurringPayment_DeleteRecurringPayment(id, { authorization: 'Bearer ' + token });

  const updateRecurringPayment = async (data: { id: number; nickname: string; isPrimary: boolean }) =>
    await RecurringPayment_UpdateRecurringPayment(data.id, {
      authorization: 'Bearer ' + token,
      nickname: data.nickname,
      isPrimary: data.isPrimary,
    });

  const authorizeRecurringPayment = async () =>
    await RecurringPayment_AuthorizeRecurringPayment(Number(getCachedItem(Constants.RECURRING)), {
      authorization: 'Bearer ' + token,
    });

  return {
    getRecurringPayments,
    createRecurringPayment,
    deleteRecurringPayment,
    updateRecurringPayment,
    authorizeRecurringPayment,
  };
};
