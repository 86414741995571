import React, { useRef, useState, useEffect, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { isEscapeKey, isTabKey, isUpKey, isDownKey, isActionKey, isTabBackKey } from 'utils/Accessability';
import { MenuLinksType } from 'types/CmsTypes';
import { LinkLargeNoUnderline } from 'elements/new-design/links/Links';

type LinkListProps = {
  link: MenuLinksType | { title: string; slug: null; subRows: { title: string; slug: string | null }[] };
  expanded: string;
  belongsToColumn: number;
  activeColumn: number;
  defaultSelected?: number;
  onToggleMenu: (id: string) => void;
  onRowClick: (parentId: string, id: string) => void;
  onTabKey: (e: KeyboardEvent<HTMLUListElement>) => void;
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const ListRow = styled.li`
  margin-bottom: 1.6rem;
  cursor: pointer;
  :hover,
  :focus {
    a {
      border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    }
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const LinkList = (props: LinkListProps) => {
  const { link, belongsToColumn, activeColumn, defaultSelected, onToggleMenu, onRowClick, onTabKey } = props;
  const listRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [currentRow, setCurrentRow] = useState(defaultSelected ? defaultSelected : 0);

  const handleLinkClick = (title: string) => {
    listRefs.current[currentRow]?.blur();
    onRowClick(link.title, title);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault();
    if (isEscapeKey(e.key)) {
      listRefs.current[currentRow]?.blur();
      onToggleMenu(null);
    } else if (isTabKey(e.key) || isTabBackKey(e)) {
      listRefs.current[currentRow]?.blur();
      onTabKey(e);
    } else if (isUpKey(e.key)) {
      if (currentRow === 0) setCurrentRow(link.subRows.length - 1);
      else setCurrentRow(currentRow - 1);
    } else if (isDownKey(e.key)) {
      if (currentRow === link.subRows.length - 1) setCurrentRow(0);
      else setCurrentRow(currentRow + 1);
    } else if (isActionKey(e.key)) {
      handleLinkClick(link.subRows[currentRow].title);
    } else return null;
  };

  useEffect(() => {
    if (activeColumn === belongsToColumn) listRefs.current[currentRow]?.focus();
    else setCurrentRow(0);
  }, [currentRow, activeColumn]);

  return (
    <List tabIndex={-1} onKeyDown={(e) => handleKeyDown(e)}>
      {link.subRows.map((row, index) => (
        <ListRow
          tabIndex={-1}
          key={index}
          ref={(ref) => (listRefs.current[index] = ref)}
          onClick={() => handleLinkClick(row.title)}
        >
          <LinkLargeNoUnderline
            text={row.title}
            url=""
            onLinkClick={() => handleLinkClick(row.title)}
            external={false}
          />
        </ListRow>
      ))}
    </List>
  );
};
