import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { DialogErrorContent } from 'components/new-design/dialogs/DialogErrorContent';

type AssistanceErrorProps = {
  type: string;
  onTryAgain: () => void;
};

export const AssistanceError = ({ type, onTryAgain }: AssistanceErrorProps) => {
  const { translate } = useI18n();
  return (
    <DialogErrorContent
      headline={
        type === Constants.ERROR ? translate('ASSISTANCE_ORDER_ERROR_TITLE') : translate('GENERAL_ERROR_HEADLINE')
      }
      text={
        type === Constants.ERROR ? translate('ASSISTANCE_ORDER_ERROR_TEXT') : translate('ASSISTANCE_LOAD_FORM_ERROR')
      }
      loading={false}
      onTryAgain={onTryAgain}
    />
  );
};
