import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogActionContent } from 'components/new-design/dialogs/DialogActionContent';
import { TxtDefaultRegularErrorResp } from 'elements/new-design/Typography';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginTop } from 'elements/distance/Margins';

type CancelDialogProps = {
  message: string;
  loading: boolean;
  onCancel: () => void;
  onModalClose: () => void;
};

export const CancelDialog = ({ message, loading, onCancel, onModalClose }: CancelDialogProps) => {
  const { translate } = useI18n();
  return (
    <Dialog type="center" title={translate('CANCEL_THE_TICKET')} width={40} height={50} onModalClose={onModalClose}>
      <DialogActionContent
        headline={translate('CANCEL_THE_TICKET')}
        text={translate('CANCEL_TICKET_PARAGRAPH')}
        loading={loading}
      >
        <p>
          <TxtDefaultRegularErrorResp>{message}</TxtDefaultRegularErrorResp>
        </p>

        <MarginTop margin={3.2}>
          <BorderButtonDark big onClick={onCancel}>
            {translate('CANCEL_TICKET')}
          </BorderButtonDark>
        </MarginTop>
      </DialogActionContent>
    </Dialog>
  );
};
