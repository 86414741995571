import React, { useState, KeyboardEvent, useEffect } from 'react';
import styled from 'styled-components';
import ChevronIcon from 'assets/svg/chevron-right.svg';
import { useI18n } from 'utils/i18n/usei18n';
import { InfoCircleWithBg, Icon } from 'elements/icon/Icon';
import { GOADate } from 'utils/date/GOADate';
import { Container } from 'elements/containers/Containers';
import {
  TxtSmallBoldDarkResp,
  TxtDefaultMediumMediumResp,
  TxtDefaultBoldAccentResp,
  TxtDefaultBoldDarkResp,
} from 'elements/new-design/Typography';
import {
  isUpKey,
  isDownKey,
  isLeftKey,
  isRightKey,
  isActionKey,
  isPageUpKey,
  isPageDownKey,
  isHomeKey,
  isEndKey,
  isTabBackKey,
  isTabKey,
} from 'utils/Accessability';

// Types
// =========================
export type DateListProps = {
  name: string;
  date: GOADate;
  minDate: GOADate;
  maxDate: GOADate;
  onDateChanged?: (d: GOADate) => void;
};

export type StyleProps = {
  selected?: boolean;
  hidden?: boolean;
  abbr?: string;
};

// Elements
// =========================
const CalendarContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 5.4rem;
  left: 0;
  z-index: ${(props) => props.theme.constants.zIndexMega};
  border: ${(props) => props.theme.constants.borderPrimary};
  background: ${(props) => props.theme.colors.bgSecondary};
  border-top: 0;
`;

const CalendarHeader = styled.header`
  padding: 2rem;
  display: flex;
  align-items: center;
`;

const MonthNavBtn = styled.button`
  ${(_: StyleProps) => ''}
  padding: 0.5rem;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const CalendarNavIcon = styled(Icon)`
  width: 1.7rem;
  height: 1.7rem;
`;

const CalendarNavLeftIcon = styled(CalendarNavIcon)`
  transform: rotate(180deg);
`;

const CurrentMonth = styled(TxtDefaultBoldDarkResp)`
  flex: 1;
  text-align: center;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const CalendarGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  padding-bottom: 1.5rem;

  tr {
    width: 100%;
    display: flex;
  }
`;

const CalendarDayHeader = styled.div`
  ${(_: StyleProps) => ''}
  padding: 0.5rem;
  width: 14.28%;
  text-align: center;
  height: 3.8rem;
`;

const CalendarDayWrapper = styled.div`
  width: 14.28%;
  text-align: center;
  padding: 0.5rem 0;
`;

const CalendarDay = styled.button`
  ${(_: StyleProps) => ''}
  height: 3.8rem;
  width: 3.8rem;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.bgSecondary};

  :focus {
    background: ${(props) => props.theme.colors.accentPrimary};
    ${TxtDefaultBoldDarkResp} {
      color: ${(props) => props.theme.colors.txtColLight};
    }
  }

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

const Info = styled(Container)`
  display: flex;
  background: ${(props) => props.theme.colors.bgHover};
  padding: 1rem;

  ${TxtDefaultMediumMediumResp} {
    margin-left: 0.4rem;
  }
`;

// Helper functions
// =========================
const getPrevMonthDisabled = (month: GOADate, minDate: GOADate): boolean => {
  const d = minDate ? minDate : GOADate.startOfToday();
  return month.getMonth() === d.getMonth() && month.getYear() === d.getYear();
};

const getNextMonthDisabled = (month: GOADate, maxDate: GOADate): boolean => {
  return month.getMonth() === maxDate.getMonth() && month.getYear() === maxDate.getYear();
};

const isDayDisabled = (d: GOADate, focusedDate: GOADate, minDate: GOADate, maxDate: GOADate): boolean =>
  d.isBefore(minDate.getStartOfDay()) || d.getMonth() !== focusedDate.getMonth() || d.isAfter(maxDate);

const getCalendarDates = (d: GOADate): GOADate[] => {
  const firstDayOfMonth = d.getStartOfMonth();
  const lastDayOfMonth = d.getEndOfMonth();
  const start = firstDayOfMonth.getStartOfWeek();
  const end = lastDayOfMonth.getEndOfWeek();
  return start.getEachDayOfInterval(end);
};

const getDayIndex = (dates: Array<GOADate>, date: GOADate): number => {
  return dates.findIndex((d) => d.getMonth() === date.getMonth() && d.isSameDay(date));
};

// Component
// =========================
export const Calendar = ({ name, onDateChanged, date, minDate, maxDate }: DateListProps) => {
  const { translate, getTranslatedWeekdays } = useI18n();
  const [focusedDate, setFocusedDate] = useState(date);
  const [calendar, setCalendar] = useState([]);
  const dateHeaders = getTranslatedWeekdays();
  const calendarDayRef = Array<HTMLButtonElement>();
  const prevMonthId = `${name}prevMonthId`;
  const nextMonthId = `${name}nextMonthId`;
  const [currentMonthIdx, setCurrentMonthIdx] = useState(undefined);

  const handleDateChange = (d: GOADate, e: KeyboardEvent | null): void => {
    if (e) e.preventDefault();
    onDateChanged(d);
  };

  const getPrevMonth = (): void => {
    if (focusedDate.subMonths(1).getMonth() === minDate.subMonths(1).getMonth()) return null;
    if (focusedDate.subMonths(1).getMonth() === minDate.getMonth()) setFocusedDate(minDate);
    else setFocusedDate(focusedDate.subMonths(1).getStartOfMonth());
    setCurrentMonthIdx(currentMonthIdx - 1);
  };

  const getNextMonth = (): void => {
    if (focusedDate.addMonths(1).getMonth() === maxDate.addMonths(1).getMonth()) return null;
    setFocusedDate(focusedDate.addMonths(1).getStartOfMonth());
    setCurrentMonthIdx(currentMonthIdx + 1);
  };

  const setFocusNext = (days: number) => {
    const newDay = focusedDate.getStartOfDay().addDays(days);
    if (newDay.isAfter(maxDate)) return null;
    if (newDay.getMonth() !== focusedDate.getMonth()) setCurrentMonthIdx(currentMonthIdx + 1);
    else calendarDayRef[getDayIndex(calendar[currentMonthIdx], newDay)].focus();
    setFocusedDate(newDay);
  };

  const setFocusPrev = (days: number) => {
    const newDay = focusedDate.subDays(days);
    if (newDay.isBefore(minDate)) return null;
    if (newDay.getMonth() !== focusedDate.getMonth()) setCurrentMonthIdx(currentMonthIdx - 1);
    else calendarDayRef[getDayIndex(calendar[currentMonthIdx], newDay)].focus();
    setFocusedDate(newDay);
  };

  const setFocusEndOfWeek = () => {
    let newDay = focusedDate.getEndOfWeek();
    if (newDay.isAfter(maxDate)) newDay = maxDate;
    if (newDay.getMonth() !== focusedDate.getMonth()) setCurrentMonthIdx(currentMonthIdx + 1);
    else calendarDayRef[getDayIndex(calendar[currentMonthIdx], newDay)].focus();
    setFocusedDate(newDay);
  };

  const setFocusStartOfWeek = () => {
    let newDay = focusedDate.getStartOfWeek();
    if (newDay.isBefore(minDate)) newDay = minDate;
    if (newDay.getMonth() !== focusedDate.getMonth()) setCurrentMonthIdx(currentMonthIdx - 1);
    else calendarDayRef[getDayIndex(calendar[currentMonthIdx], newDay)].focus();
    setFocusedDate(newDay);
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (isTabBackKey(e) || isTabKey(e.key)) e.preventDefault();
    if (isPageUpKey(e.key)) getPrevMonth();
    if (isPageDownKey(e.key)) getNextMonth();
    if (isUpKey(e.key)) setFocusPrev(7);
    if (isDownKey(e.key)) setFocusNext(7);
    if (isLeftKey(e.key)) setFocusPrev(1);
    if (isRightKey(e.key)) setFocusNext(1);
    if (isActionKey(e.key)) handleDateChange(focusedDate, e);
    if (isHomeKey(e.key)) setFocusStartOfWeek();
    if (isEndKey(e.key)) setFocusEndOfWeek();
  };

  useEffect(() => {
    if (date) {
      const diff = maxDate.getDiffInDates(minDate, ['months']);
      const calendarDates = [];
      for (let i = 0; i < diff.months + 1; i++) {
        if (i !== 0) minDate = minDate.addMonths(1);
        if (date.getMonth() === minDate.getMonth()) setCurrentMonthIdx(i);
        calendarDates.push(getCalendarDates(minDate));
      }
      setCalendar(calendarDates);
    }
  }, []);

  useEffect(() => {
    if (calendar.length) calendarDayRef[getDayIndex(calendar[currentMonthIdx], focusedDate)].focus();
  }, [currentMonthIdx]);

  return (
    <CalendarContainer id={`${name}DateList`} role="dialog" aria-modal="true" aria-labelledby="currentMonth">
      {Boolean(calendar.length) && (
        <>
          <CalendarHeader>
            <MonthNavBtn
              id={prevMonthId}
              type="button"
              hidden={getPrevMonthDisabled(focusedDate, minDate)}
              aria-label={`Previous month ${focusedDate.subMonths(1)}`}
              tabIndex={0}
              onKeyDown={(e) => (isActionKey(e.key) ? getPrevMonth() : null)}
              onClick={() => getPrevMonth()}
            >
              <CalendarNavLeftIcon icon={ChevronIcon} />
              <TxtDefaultBoldAccentResp>{focusedDate.subMonths(1).formatMonth()}</TxtDefaultBoldAccentResp>
            </MonthNavBtn>

            <CurrentMonth id="currentMonth" aria-live="polite">
              {focusedDate.formatDateMonth()}
            </CurrentMonth>

            <MonthNavBtn
              id={nextMonthId}
              type="button"
              hidden={getNextMonthDisabled(focusedDate, maxDate)}
              aria-label={`Next month ${focusedDate.addMonths(1)}`}
              tabIndex={0}
              onKeyDown={(e: KeyboardEvent) => (isActionKey(e.key) ? getNextMonth() : null)}
              onClick={getNextMonth}
            >
              <TxtDefaultBoldAccentResp>{focusedDate.addMonths(1).formatMonth()}</TxtDefaultBoldAccentResp>
              <CalendarNavIcon icon={ChevronIcon} />
            </MonthNavBtn>
          </CalendarHeader>
          <CalendarGrid role="grid" aria-labelledby="currentMonth">
            {dateHeaders.map((day) => (
              <CalendarDayHeader key={day.short} abbr={day.long}>
                <TxtSmallBoldDarkResp>{day.short}</TxtSmallBoldDarkResp>
              </CalendarDayHeader>
            ))}

            {calendar[currentMonthIdx].map((d, index) => {
              const disabled = isDayDisabled(d, focusedDate, minDate, maxDate);
              return (
                <CalendarDayWrapper key={index} onKeyDown={(e: KeyboardEvent) => handleKeyDown(e)}>
                  <CalendarDay
                    id={`${d.getDate()}${d.getMonth()}${d.getYear()}`}
                    ref={(ref) => {
                      calendarDayRef[index] = ref;
                    }}
                    disabled={disabled}
                    role="grid-cell"
                    aria-label={d.formatAssistiveDate()}
                    onClick={() => (disabled ? null : handleDateChange(d, null))}
                  >
                    <TxtDefaultBoldDarkResp>{d.getDate()}</TxtDefaultBoldDarkResp>
                  </CalendarDay>
                </CalendarDayWrapper>
              );
            })}
          </CalendarGrid>
          {getNextMonthDisabled(focusedDate, maxDate) && (
            <Info>
              <InfoCircleWithBg />
              <TxtDefaultMediumMediumResp>{translate('SELECTABLE_DAYS_INFO')}</TxtDefaultMediumMediumResp>
            </Info>
          )}
        </>
      )}
    </CalendarContainer>
  );
};
