import React from 'react';
import styled from 'styled-components';
import { BreadCrumbs } from 'components/new-design/breadcrumbs/BreadCrumbs';
import { BaseContainerLarger, BaseContainerSmaller } from 'elements/containers/Containers';
import { PageTitleSmall, TxtSmallMediumMediumResp } from 'elements/new-design/Typography';
import { MarginBottom } from 'elements/distance/Margins';

type UserProfileLayoutProps = {
  headline: string;
  preamble?: string;
  children: JSX.Element | JSX.Element[];
};

export const Preamble = styled.p`
  width: 100%;
  margin-bottom: 2.4rem;

  @media ${(props) => props.theme.devices.medium} {
    max-width: 65%;
  }

  @media ${(props) => props.theme.devices.laptop} {
    max-width: 50%;
  }
`;

export const UserProfileLayout = ({ headline, preamble, children }: UserProfileLayoutProps) => {
  return (
    <BaseContainerLarger>
      <BaseContainerSmaller>
        <BreadCrumbs />
        <MarginBottom margin={2.4}>
          <PageTitleSmall>{headline}</PageTitleSmall>
        </MarginBottom>
        <Preamble>
          <TxtSmallMediumMediumResp>{preamble}</TxtSmallMediumMediumResp>
        </Preamble>
      </BaseContainerSmaller>
      {children}
    </BaseContainerLarger>
  );
};
