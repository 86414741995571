import React, { KeyboardEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { TxtSmallBoldDarkResp } from 'elements/new-design/Typography';
import { GOADate } from 'utils/date/GOADate';
import { isUpKey, isDownKey, isActionKey } from 'utils/Accessability';
// Types
// =========================
type TimeListProps = {
  name: string;
  date: GOADate;
  minDate: GOADate;
  onTimeChanged: (hour: GOADate, close: boolean) => void;
};

// Elements
// =========================
const TimeDropDown = styled.ul`
  width: 100%;
  position: absolute;
  top: 5.4rem;
  max-height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: ${(props) => props.theme.constants.zIndexMega};
  border: ${(props) => props.theme.constants.borderPrimary};
  background: ${(props) => props.theme.colors.bgSecondary};
  border-top: 0;

  @media ${(props) => props.theme.devices.large} {
    margin-top: 0rem;
  }
`;

const TimeRow = styled.li`
  text-align: center;
  padding: 1.5rem;
  width: 100%;
  border-top: ${(props) => props.theme.constants.borderPrimary};

  &:first-child {
    border-top: 0;
  }

  &:hover,
  &:focus-visible,
  &:focus {
    background-color: ${(props) => props.theme.colors.bgAthensDark};
  }
`;

// Functions
// =========================
const fillRange = (start: number, end: number): GOADate[] =>
  Array(end - start)
    .fill(new GOADate())
    .map((item: GOADate, index) => item.setHours(start + index).setMinutes(0));

const getTimeIndex = (time: GOADate, selectableHours: GOADate[]): number => {
  return selectableHours.findIndex((d) => d.getHours() === time.getHours());
};

// Component
// =========================

export const TimeTable = ({ name, onTimeChanged, date, minDate }: TimeListProps) => {
  const selectableHours = date.isSameDay(minDate) ? fillRange(minDate.getHours(), 24) : fillRange(0, 24);
  const [focusedTime, setFocusedTime] = useState(undefined);
  const calendarDayRef = Array<HTMLLIElement>();

  const handleClick = (d: GOADate): void => {
    const currentIdx = getTimeIndex(d, selectableHours);
    calendarDayRef[currentIdx].blur();
    onTimeChanged(d, true);
    setFocusedTime(undefined);
  };

  const setFocus = (date: GOADate): void => {
    const initialFocusTime = getTimeIndex(date, selectableHours);
    calendarDayRef[initialFocusTime].focus();
    setFocusedTime(date);
  };

  const updateTime = (newTime: GOADate, idx: number): void => {
    setFocus(newTime);
    onTimeChanged(newTime, false);
    calendarDayRef[idx].focus();
  };

  const setFocusPrevHour = (): void => {
    const newTime = focusedTime.subHours(1).setMinutes(0);
    const idx = selectableHours.findIndex((d: GOADate) => d.getHours() === newTime.getHours());
    if (idx >= 0 && idx < 23) updateTime(newTime, idx);
  };

  const setFocusNextHour = (): void => {
    const newTime = focusedTime.addHours(1).setMinutes(0);
    const idx = selectableHours.findIndex((d: GOADate) => d.getHours() === newTime.getHours());
    if (idx > 0 && idx <= 23) updateTime(newTime, idx);
  };

  const handleKeyDown = (e: KeyboardEvent): void => {
    e.preventDefault();
    if (isUpKey(e.key)) setFocusPrevHour();
    if (isDownKey(e.key)) setFocusNextHour();
    if (isActionKey(e.key)) handleClick(focusedTime);
  };

  useEffect(() => {
    if (selectableHours) setFocus(date);
  }, [selectableHours]);

  return (
    <>
      {date && selectableHours && (
        <TimeDropDown id={`${name}TimeList`} role="dialog" aria-modal="true">
          {selectableHours.map((d: GOADate, index: number) => (
            <TimeRow
              id={`${index}Hour${name}`}
              data-cy={`${index}Hour${name}Cy`}
              key={index}
              ref={(ref) => {
                calendarDayRef[index] = ref;
              }}
              tabIndex={0}
              aria-label={d.formatTime()}
              onClick={() => handleClick(d)}
              onKeyDown={(e: KeyboardEvent) => handleKeyDown(e)}
            >
              <TxtSmallBoldDarkResp>{d.formatTime()}</TxtSmallBoldDarkResp>
            </TimeRow>
          ))}
        </TimeDropDown>
      )}
    </>
  );
};
