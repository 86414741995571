import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { LogoGoAhead, LogoAppIcon } from 'elements/new-design/Logos';
import { TxtMediumBoldLightResp, TxtMediumRegularLightResp } from 'elements/new-design/Typography';
import { MarginTop, MarginRight } from 'elements/distance/Margins';
import { LinkMediumRespNoUnderline } from 'elements/new-design/links/Links';
import { A } from 'hookrouter';

type FooterNewProps = {
  links: any[];
};

const FooterContainer = styled.nav`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 8.8rem 4.8rem;
  background-color: ${(props) => props.theme.colors.bgTuna};

  @media (min-width: 900px) {
    padding: 12.8rem 8.8rem;
  }
  @media (min-width: 1281px) {
    padding: 16rem 18rem;
  }

  ul {
    list-style: none;
  }
`;

const AppInfoContainer = styled(A)`
  display: flex;
  align-items: flex-start;
  width: 25rem;
  cursor: pointer;
  &:focus-visible,
  &:hover {
    box-shadow: none;
    span {
      cursor: pointer;
      border-bottom: ${(props) => props.theme.newconstants.borderLightThick};
    }
  }
`;

const CategoryContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr;
  grid-gap: 5.6rem;
  margin: 8.8rem 0;
  align-items: flex-start;

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr;
    margin: 12.8rem 0;
  }

  @media (min-width: 1281px) {
    grid-template-columns: auto auto 1fr;
    margin: 16rem 0;
    grid-gap: 12.8rem;
  }
`;

const getCategorizedLinks = (footerLinks, width: number) => {
  const order =
    width <= 900
      ? ['GOA_COMPANY_TAG', 'GOA_LEGAL_TAG', 'GOA_SOCIAL_TAG']
      : ['GOA_SOCIAL_TAG', 'GOA_COMPANY_TAG', 'GOA_LEGAL_TAG'];

  return footerLinks
    .map((link) => {
      return {
        tag: link.tag,
        slug: link.slug.current,
        title: link.title,
        subPages: link.subPages,
      };
    })
    .sort((a, b) => {
      return order.indexOf(a.tag) - order.indexOf(b.tag);
    });
};

export const Footer = ({ links }: FooterNewProps): JSX.Element => {
  const { innerWidth: width } = window;
  const { translate, currentLanguage } = useI18n();
  const isRouteBase = window.location.pathname === Constants.ROUTE_BASE;
  const categorizedLinks = getCategorizedLinks(
    links.filter((l) => l.language === currentLanguage),
    width,
  );

  return (
    <FooterContainer>
      <AppInfoContainer href={`${Constants.ROUTE_CMS_TRAVEL_INFO}${Constants.ROUTE_MOBILE_APP_INFO}`}>
        <LogoAppIcon white width={4.8} height={4.8} />
        <MarginRight margin={1.6} />
        <label>
          <TxtMediumRegularLightResp>{translate('DOWNLOAD_APP')}</TxtMediumRegularLightResp>
        </label>
      </AppInfoContainer>
      <CategoryContainer>
        {Boolean(categorizedLinks.length) &&
          categorizedLinks.map((cat, index) => (
            <div key={index}>
              <h2>
                <TxtMediumBoldLightResp>{cat.title}</TxtMediumBoldLightResp>
              </h2>
              <MarginTop margin={1.8} />
              <ul>
                {Boolean(cat.subPages.length) &&
                  cat.subPages.map((page, idx) => (
                    <li key={idx}>
                      <MarginTop margin={1.6} />
                      {(page.externalLink || page.assetLink) && (
                        <LinkMediumRespNoUnderline
                          text={page.title}
                          url={page.assetLink ? page.assetLink : page.externalLink}
                          color="light"
                          external={true}
                        />
                      )}
                      {page.slug && (
                        <LinkMediumRespNoUnderline
                          text={page.title}
                          url={`${isRouteBase ? '' : '/'}${cat.slug}/${page.slug.current}`}
                          color="light"
                          external={false}
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
      </CategoryContainer>
      <LogoGoAhead white width={14} height={4} />
    </FooterContainer>
  );
};
