import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { FlexColumn } from 'elements/containers/Containers';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';

type SearchStationContainerProps = {
  small: string;
  medium: string;
  large: string;
  fromValue: boolean;
  toValue: boolean;
  displaySubmit: boolean;
  onSubmit: () => void;
  children: JSX.Element | JSX.Element[];
};

type StyleProps = {
  small: string;
  medium: string;
  large: string;
  height: string;
};

const SearchFormOuter = styled(FlexColumn)`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.large} {
    flex-direction: row;
    justify-content: center;
  }
  form {
    width: 100%;
  }
`;

const SearchFormInner = styled(FlexColumn)`
  ${(_: StyleProps) => ''}
  width: ${(props) => props.small};
  height: ${(props) => props.height};
  position: relative;

  @media ${(props) => props.theme.breakpoints.medium} {
    width: ${(props) => props.medium};
  }
  @media ${(props) => props.theme.breakpoints.large} {
    height: 14rem;
    width: ${(props) => props.large};
    flex-direction: row;
    justify-content: center;
  }
`;

const Submitwrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.breakpoints.large} {
    width: 24rem;
    justify-content: flex-end;
  }
`;

const SubmitButton = styled(BorderButtonDark)`
  width: 100%;
  height: 6rem;
  padding: 0;
  margin: -1px;
  @media ${(props) => props.theme.breakpoints.large} {
    width: 16rem;
  }
`;

const getHeight = (from: boolean, to: boolean) => {
  if (from && to) return '18rem';
  else if (from || to) return '16rem';
  return '16rem';
};

export const StationSearchContainer = ({
  small,
  medium,
  large,
  fromValue,
  toValue,
  displaySubmit,
  onSubmit,
  children,
}: SearchStationContainerProps) => {
  const { translate } = useI18n();
  return (
    <SearchFormOuter id="stationSearchComponent">
      <SearchFormInner small={small} medium={medium} large={large} height={getHeight(fromValue, toValue)}>
        {children}
      </SearchFormInner>

      {displaySubmit && (
        <Submitwrapper>
          <SubmitButton big onClick={onSubmit}>
            {translate('SEARCH_JOURNEY_BTN')}
          </SubmitButton>
        </Submitwrapper>
      )}
    </SearchFormOuter>
  );
};
