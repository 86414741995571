import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { useApi } from 'hooks/use-api/useApi';
import { getValidatedField } from 'utils/Validation';
import { Constants } from 'utils/Constants';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';
import { Loader } from 'elements/loader/Loader';
import { TextField } from '../../formfields/TextField';
import { CommonErrorLabel } from 'elements/new-design/InputFields';
import { DialogTitle, TxtDefaultRegularDarkResp } from 'elements/new-design/Typography';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginBottom, MarginTop } from 'elements/distance/Margins';

type ForgotPasswordDialogProps = {
  onToggleModal: () => void;
  onModalClose: () => void;
};

const SubmitButton = styled(BorderButtonDark)`
  width: 100%;

  @media ${(props) => props.theme.breakpoints.large} {
    width: auto;
  }
`;

const ForgotPasswordState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const ForgotPasswordDialog = ({ ...props }: ForgotPasswordDialogProps) => {
  const { cacheRedirect } = useStateNavigation();
  const { translate } = useI18n();
  const emailField = {
    name: 'resetEmail',
    type: 'email',
    label: translate('EMAIL_ADRESS'),
    value: '',
    error: undefined,
    validationRules: ['isEmail', 'isRequired'],
  };
  const [state, setState] = useState<typeof ForgotPasswordState>(Constants.IDLE);
  const [email, setEmail] = useState(emailField);
  const { API_CALLS, request } = useApi();

  const submitForm = async (e) => {
    e.preventDefault();
    const tempField = Object.assign({}, getValidatedField(email));
    setEmail(tempField);
    if (!tempField.error) {
      try {
        cacheRedirect(window.location.pathname);
        setState(Constants.LOADING);
        await request(API_CALLS.FORGOT_PASSWORD, email.value);
        setState(Constants.IDLE);
        setEmail(emailField);
        props.onToggleModal();
      } catch (err) {
        setState(Constants.ERROR);
      }
    }
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    const tempField = Object.assign({}, email);
    tempField.value = e.target.value;
    setEmail(getValidatedField(tempField));
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const tempField = Object.assign({}, email);
    tempField.value = e.target.value;
    tempField.error = undefined;
    setEmail(tempField);
    setState(Constants.IDLE);
  };

  return (
    <Dialog type="center" title={translate('FORGOT_PASSWORD')} width={47} height={46} onModalClose={props.onModalClose}>
      <DialogCenterContentContainer>
        <DialogTitle>{translate('FORGOT_PASSWORD')}</DialogTitle>
        {state === Constants.LOADING && <Loader />}
        <form aria-live="polite" onSubmit={submitForm}>
          {(state === Constants.IDLE || state === Constants.ERROR) && (
            <>
              <p>
                <TxtDefaultRegularDarkResp>{translate('FORGOT_PASSWORD_INFORMATION')}</TxtDefaultRegularDarkResp>
              </p>
              <MarginBottom margin={2} />
              <TextField
                name={emailField.name}
                type={emailField.type}
                label={emailField.label}
                value={email.value}
                error={email.error}
                onFieldChange={handleOnChange}
                onFieldBlur={handleBlur}
              />

              {state === Constants.ERROR && (
                <>
                  <MarginTop margin={1.6} />
                  <CommonErrorLabel>{translate('SAVE_CHANGES_TECHNICAL_ERROR')}</CommonErrorLabel>
                </>
              )}
              <MarginTop margin={4} />

              <SubmitButton data-cy={'changePasswordBtnCy'} big onClick={submitForm}>
                {translate('CHANGE_PASSWORD')}
              </SubmitButton>
            </>
          )}
        </form>
      </DialogCenterContentContainer>
    </Dialog>
  );
};
