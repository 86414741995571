import React from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { BoxBodyPortableText } from 'components/new-design/portable-texts/BoxBodyPortableText';
import { ArticleBox } from 'components/new-design/cms/ArticleBox';
import { InfoBox } from 'components/new-design/cms/InfoBox';

type TwoColumnProps = {
  boxes: any;
};

const TwoColumnContainer = styled.section`
  width: 100%;
  display: grid;
  grid-gap: 3.6rem;
  grid-template-columns: 100%;
  grid-template-rows: 1fr, 1fr;

  @media ${(props) => props.theme.breakpoints.large} {
    grid-gap: 2.4rem;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 100%;
    overflow: hidden;
  }
`;

export const TwoColumn = ({ boxes }: TwoColumnProps) => {
  return (
    <TwoColumnContainer>
      <>
        {boxes.map((box, index) => {
          return (
            <React.Fragment key={index}>
              {box._type === 'articleBox' && <ArticleBox position="center" layout="column" boxData={box} />}
              {box._type === 'infoBox' && (
                <InfoBox position="center" layout="column" boxData={box}>
                  <PortableText value={box.infoBoxText} components={BoxBodyPortableText} />
                </InfoBox>
              )}
            </React.Fragment>
          );
        })}
      </>
    </TwoColumnContainer>
  );
};
