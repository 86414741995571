import {
  SET_ORDER_VALID,
  SET_HAS_FORM_CHANGED,
  SET_CURRENT_DIALOG,
  SET_LANGUAGE,
  SET_PROMPT_LOGIN,
  SET_PAGE_TITLE,
  GlobalType,
} from '../../types/GlobalTypes';

const initialState = {
  orderValid: false,
  hasFormChanged: false,
  currentDialog: undefined,
  language: 'no',
  promptForLogin: false,
  pageTitle: '',
};

export default function globalReducer(state = initialState, action): GlobalType {
  switch (action.type) {
    case SET_ORDER_VALID:
      return {
        ...state,
        orderValid: action.payload,
      };
    case SET_HAS_FORM_CHANGED:
      return {
        ...state,
        hasFormChanged: action.payload,
      };
    case SET_CURRENT_DIALOG:
      return {
        ...state,
        currentDialog: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_PROMPT_LOGIN:
      return {
        ...state,
        promptForLogin: action.payload,
      };
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    default:
      return state;
  }
}
