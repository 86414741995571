import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { ChevronDownDark, ChevronUpDark } from 'elements/new-design/icons/Chevrons';
import { HamburgerMenuDark, CloseIconDark } from 'elements/new-design/icons/SymbolIcons';
import { fontColorDark, fontFamilyBold, HiddenSpan } from '../Typography';
import { MarginRight } from 'elements/distance/Margins';

type ExpandBtnProps = {
  small?: boolean;
  gap?: number;
  text: string;
  expanded: boolean;
  popupType: boolean | 'dialog' | 'menu' | 'true' | 'false' | 'grid' | 'listbox' | 'tree';
  icon?: JSX.Element;
  expandedIcon?: JSX.Element;
  onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

type StyleProps = {
  small: boolean;
  gap: number;
};

export const ExpandBtn = styled.button`
  ${(_: StyleProps) => ''}
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.gap ? 'flex-start' : 'space-between')};
  font-size: ${(props) => (props.small ? '1.6rem' : '2rem')};
  border-radius: ${(props) => (props.gap ? 0 : '50%')};
  border-bottom: ${(props) => (props.gap ? props.theme.newconstants.borderTransparantThick : '0')};
  ${fontFamilyBold};
  ${fontColorDark};

  :hover,
  :focus-visible {
    border-bottom: ${(props) => (props.gap ? props.theme.newconstants.borderDarkThick : '0')};
    box-shadow: ${(props) => (props.gap ? 'none' : props.theme.newconstants.keyNavFocus)};
  }

  @media ${(props) => props.theme.breakpoints.medium} {
    font-size: ${(props) => (props.small ? '1.8rem' : '2.2rem')};
  }
`;

export const ExpandIconRight = React.forwardRef<HTMLButtonElement, ExpandBtnProps>(
  (props: ExpandBtnProps, ref): JSX.Element => (
    <ExpandBtn
      ref={ref}
      small={props.small}
      gap={props.gap}
      aria-expanded={props.expanded}
      aria-haspopup={props.popupType}
      onClick={(e) => props.onButtonClick(e)}
    >
      {!props.gap && <HiddenSpan>{props.text}</HiddenSpan>}
      {props.gap && props.text}
      {props.gap && <MarginRight margin={props.gap} />}
      {props.expanded ? (props.expandedIcon ? props.expandedIcon : props.icon) : props.icon}
    </ExpandBtn>
  ),
);

export const ExpandChevronBtn = React.forwardRef<HTMLButtonElement, ExpandBtnProps>(
  (props: ExpandBtnProps, ref): JSX.Element => (
    <ExpandIconRight
      ref={ref}
      gap={0.8}
      small={props.small}
      text={props.text}
      expanded={props.expanded}
      popupType={props.popupType}
      icon={<ChevronDownDark width={props.small ? 2.5 : 3} height={1.2} />}
      expandedIcon={<ChevronUpDark width={props.small ? 2.5 : 3} height={1.2} />}
      onButtonClick={props.onButtonClick}
    />
  ),
);

export const ExpandHamburgerBtn = React.forwardRef<HTMLButtonElement, ExpandBtnProps>(
  (props: ExpandBtnProps, ref): JSX.Element => (
    <ExpandIconRight
      ref={ref}
      small={props.small}
      gap={props.gap}
      text={props.text}
      expanded={props.expanded}
      popupType={props.popupType}
      icon={<HamburgerMenuDark width={props.gap ? 2.5 : 3.5} height={props.gap ? 2.5 : 3.5} />}
      expandedIcon={<CloseIconDark width={props.gap ? 2.5 : 3.5} height={props.gap ? 2.5 : 3.5} />}
      onButtonClick={props.onButtonClick}
    />
  ),
);
