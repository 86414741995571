import React from 'react';
import styled from 'styled-components';
import { BusStopIcon } from './SymbolIcons';
import { TrackIconDark } from './SymbolIcons';
import { SeatIconDark } from './SymbolIcons';
import { BedIconDark } from './SymbolIcons';
import { CarriageIconDark } from './SymbolIcons';
import { BusIconDark } from './SymbolIcons';
import { TrainIconDark } from './SymbolIcons';
import { TxtDefaultBoldDarkResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { FlexRow } from 'elements/containers/Containers';
import { MarginRight } from 'elements/distance/Margins';

type IconWithTextProps = {
  head?: string;
  text: string;
  children?: JSX.Element;
};

const TransportContainer = styled(FlexRow)`
  border-radius: 0.3rem;
  background: ${(props) => props.theme.colors.bgIconPrimary};
  padding-right: 0.5rem;

  ${TxtSmallMediumDarkResp} {
    line-height: normal;
  }
`;

const IconWithTextContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const IconWithText = ({ ...props }: IconWithTextProps) => {
  return (
    <div>
      <TxtSmallMediumDarkResp>{props.head ? props.head : ''}</TxtSmallMediumDarkResp>
      <IconWithTextContainer>
        <MarginRight margin={0.8}>{props.children}</MarginRight>
        <TxtDefaultBoldDarkResp>{props.text}</TxtDefaultBoldDarkResp>
      </IconWithTextContainer>
    </div>
  );
};

export const TransportIconWithText = ({ ...props }: IconWithTextProps) => {
  return (
    <TransportContainer>
      <MarginRight margin={0.8}>{props.children}</MarginRight>
      <TxtSmallMediumDarkResp>{props.text}</TxtSmallMediumDarkResp>
    </TransportContainer>
  );
};

export const BusStopIconText = ({ ...props }: IconWithTextProps) => {
  return (
    <IconWithText head={props.head} text={props.text}>
      <BusStopIcon width={2} height={2} />
    </IconWithText>
  );
};

export const TrackIconText = ({ ...props }: IconWithTextProps) => {
  return (
    <IconWithText head={props.head} text={props.text}>
      <TrackIconDark width={2} height={1.4} />
    </IconWithText>
  );
};

export const SeatIconText = ({ ...props }: IconWithTextProps) => {
  return (
    <IconWithText head={props.head} text={props.text}>
      <SeatIconDark width={1.5} height={1.6} />
    </IconWithText>
  );
};

export const BedIconText = ({ ...props }: IconWithTextProps) => {
  return (
    <IconWithText head={props.head} text={props.text}>
      <BedIconDark width={2.2} height={2} />
    </IconWithText>
  );
};

export const CarriageIconText = ({ ...props }: IconWithTextProps) => {
  return (
    <IconWithText head={props.head} text={props.text}>
      <CarriageIconDark width={2.2} height={1.6} />
    </IconWithText>
  );
};

export const BusIconWithText = ({ ...props }: IconWithTextProps) => {
  return (
    <TransportIconWithText text={props.text}>
      <BusIconDark width={2.6} height={2.6} />
    </TransportIconWithText>
  );
};

export const TrainIconWithText = ({ ...props }: IconWithTextProps) => {
  return (
    <TransportIconWithText text={props.text}>
      <TrainIconDark width={2.6} height={2.6} />
    </TransportIconWithText>
  );
};
