import React from 'react';
import styled from 'styled-components';
import { LargeContainer } from './LayoutContainers';
import { FlexColumn } from 'elements/containers/Containers';

type DefaultPageProps = {
  children: JSX.Element | JSX.Element[];
};

export const PageContainer = styled(FlexColumn)`
  width: 100%;
  align-items: center;
`;

export const DefaultPage = ({ children }: DefaultPageProps) => {
  return (
    <PageContainer>
      <LargeContainer>
        <FlexColumn>{children}</FlexColumn>
      </LargeContainer>
    </PageContainer>
  );
};
