/// <reference path="types.ts"/>
/** @module Location */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 */
export function Location_GetGoAheadLocations(): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultStopPlaceResponse1>> {
  return gateway.request(Location_GetGoAheadLocationsOperation)
}

const Location_GetGoAheadLocationsOperation: api.OperationInfo = {
  path: '/rest/v2/Location/locations',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
