import { Campaigns_ReserveCampaignAsync } from 'apiV2/Campaigns';

export const useCampaignApi = (token: string) => {
  const reserveCampaign = async (order: any) =>
    await Campaigns_ReserveCampaignAsync('Web', order, { authorization: 'Bearer ' + token });

  return {
    reserveCampaign,
  };
};
