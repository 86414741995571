export const breakPoints = {
  small: 640,
  medium: 768,
  large: 1024,
  laptop: 1200,
  bigscreen: 1400,
  mega: 1600,
  huge: 2000,
};

export default {
  small: `(min-width: ${breakPoints.small}px)`,
  medium: `(min-width: ${breakPoints.medium}px)`,
  large: `(min-width: ${breakPoints.large}px)`,
  laptop: `(min-width: ${breakPoints.laptop}px)`,
  bigscreen: `(min-width: ${breakPoints.bigscreen}px)`,
  mega: `(min-width: ${breakPoints.mega}px)`,
  huge: `(min-width: ${breakPoints.huge}px)`,
};
