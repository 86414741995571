export const EMAIL = 'loginEmail';
export const PWD = 'loginPassword';

export const getForm = () => {
  const LoginData = [
    {
      name: EMAIL,
      type: 'email',
      label: 'EMAIL_ADRESS',
      value: '',
      error: undefined,
      validationRules: ['isEmail', 'isRequired'],
    },
    {
      name: PWD,
      type: 'password',
      label: 'PASSWORD',
      value: '',
      error: undefined,
      validationRules: ['isRequired'],
    },
  ];
  const form = new Map();
  LoginData.forEach((item) => {
    form.set(item.name, item);
  });
  return form;
};
