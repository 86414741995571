const ESCAPE_KEY = 'Escape';
const LEFT_KEY = 'ArrowLeft';
const UP_KEY = 'ArrowUp';
const RIGHT_KEY = 'ArrowRight';
const DOWN_KEY = 'ArrowDown';
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';
const PAGE_UP_KEY = 'PageUp';
const PAGE_DOWN_KEY = 'PageDown';
const TAB_KEY = 'Tab';
const HOME_KEY = 'Home';
const END_KEY = 'End';

export const isActionKey = (key: string): boolean => key === ENTER_KEY || key === SPACE_KEY;

export const isEnterKey = (key: string): boolean => key === ENTER_KEY;

export const isEscapeKey = (key: string): boolean => key === ESCAPE_KEY;

export const isUpKey = (key: string): boolean => key === UP_KEY;

export const isDownKey = (key: string): boolean => key === DOWN_KEY;

export const isLeftKey = (key: string): boolean => key === LEFT_KEY;

export const isRightKey = (key: string): boolean => key === RIGHT_KEY;

export const isPageUpKey = (key: string): boolean => key === PAGE_UP_KEY;

export const isPageDownKey = (key: string): boolean => key === PAGE_DOWN_KEY;

export const isTabKey = (key: string): boolean => key === TAB_KEY;

export const isTabBackKey = (e): boolean => e.shiftKey && e.key === TAB_KEY;

export const isHomeKey = (key: string): boolean => key === HOME_KEY;

export const isEndKey = (key: string): boolean => key === END_KEY;
