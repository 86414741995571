import styled from 'styled-components';

type ShapeProps = {
  width: number;
  height?: number;
  color?: string;
};

export const Circle = styled.div`
  ${(_: ShapeProps) => ''}
  width: ${(props) => `${props.width}rem`};
  height: ${(props) => (props.height ? `${props.height}rem` : `${props.width}rem`)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${(props) => (props.color ? props.color : props.theme.newcolors.bgDark)};
`;
