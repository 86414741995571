import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useUpdateState } from 'hooks/useUpdateState';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { useI18n } from 'utils/i18n/usei18n';
import { AppState } from 'store/index';
import { LocationType } from 'types/LocationTypes';
import { Constants } from 'utils/Constants';
import { cloneDeep } from 'lodash';
import {
  StationsSearchService,
  StationFieldType,
  FormType,
  TO_STATION,
  FROM_STATION,
  lists,
} from './StationSearchService';
import { StationSearchContainer } from './StationSearchContainer';
import { StationComboBox } from './StationComboBox';
import { StationInput } from './StationInput';
import { SearchResult } from './SearchResult';
import { SwapStations } from './SwapStations';
import { FlexColumn } from 'elements/containers/Containers';

type StationSearchProps = {
  small: string;
  medium: string;
  large: string;
  displaySwap: boolean;
  displaySubmit: boolean;
  redirectUrl?: string;
  onFormValid?: () => void;
};

type StyleProps = {
  isFocused?: boolean;
  hide?: boolean;
};

const FormGroupContainer = styled(FlexColumn)`
  ${(_: StyleProps) => ''}
  width: 100%;
  position: relative;
  /* Menu needs to be placed over search if search is not in focus*/
  z-index: ${(props) => (props.isFocused ? props.theme.newconstants.zIndexMedium : 0)};

  @media ${(props) => props.theme.breakpoints.large} {
    display: flex;
    flex-direction: row;
    border: 0;
    border-radius: 0;
  }
`;

const filterStations = (value: string, stations: LocationType[]): LocationType[] => {
  if (!value) return null;
  return stations
    .filter((station: LocationType) => station.name.toLowerCase().startsWith(value.trim().toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name))
    .splice(0, 3);
};

const getShortcuts = (earlierSearches: { from: string; to: string }[], popularStations: string[]): string[] => {
  if (!earlierSearches) return popularStations;
  const stations = [];
  earlierSearches.forEach((search) => {
    stations.push(search.from);
    stations.push(search.to);
  });
  return [...new Set(stations.concat(popularStations))].slice(0, 6);
};

export const SectionStationSearch = ({
  small,
  medium,
  large,
  displaySwap,
  displaySubmit,
  redirectUrl,
  onFormValid,
}: StationSearchProps): JSX.Element => {
  const { populateForm, isSameStation, isFormValid, isValidStation, getNoServiceMessages, setServiceMessages } =
    StationsSearchService;
  const { getCachedItem, cacheItem } = usePersistentStorage();
  const { navigateWithState } = useStateNavigation();
  const { setOrderStations, deleteOrderStations, setDialog } = useUpdateState();
  const { translate } = useI18n();
  const { currentDialog } = useSelector((state: AppState) => state.global);
  const [form, setForm] = useState(StationsSearchService.getForm());
  const [positionActive, setPositionActive] = useState(true);
  const [searchResult, setSearchResult] = useState(null);
  const [listFocusState, setListFocusState] = useState({ row: 0, list: null });
  const stations = useSelector((state: AppState) => state.locations);
  const order = useSelector((state: AppState) => state.order);
  const settings = getCachedItem(Constants.CACHE_SETTINGS);
  const earlierSearches = settings && settings[Constants.CACHE_SEARCHED_STATIONS];
  const shortcuts = getShortcuts(earlierSearches, Constants.POPULAR_STATIONS);
  const inputRef = useRef<(HTMLInputElement | null)[]>([]);

  const resetListFocus = () => {
    setSearchResult(null);
    setListFocusState({ row: 0, list: earlierSearches ? lists.earlierSearches : lists.shortcuts });
  };

  const validateNoGANService = (stationForm: FormType): FormType => {
    const fromField = stationForm.get(FROM_STATION);
    const toField = stationForm.get(TO_STATION);

    if (fromField.value && toField.value) {
      const messageIds = getNoServiceMessages(fromField.value, toField.value, stations.stopPlaces);

      if (messageIds) {
        return setServiceMessages(messageIds, stations.stopPlaceProblemCombinationTexts, stationForm);
      }
    }
    return stationForm;
  };

  const validateField = (field: string, stationForm: FormType): StationFieldType => {
    const updated = stationForm.get(field);
    const fromField = stationForm.get(FROM_STATION);
    const toField = stationForm.get(TO_STATION);
    const isSame = isSameStation(fromField.value, toField.value);
    const stationExists = isValidStation(updated.value, stations.stopPlaces);
    fromField.error = undefined;
    fromField.serviceMessage = undefined;
    toField.error = undefined;
    toField.serviceMessage = undefined;

    if (!stationExists || isSame) {
      if (isSame) {
        fromField.error = translate('SEARCH_COMPONENT_NO_SAME');
        toField.error = translate('SEARCH_COMPONENT_NO_SAME');
      }
      if (!stationExists) updated.stationExists = false;
    } else {
      updated.stationExists = true;
      updated.error = undefined;
    }
    return updated;
  };

  const validateForm = (tempForm: FormType): FormType => {
    const fromField = validateField(FROM_STATION, tempForm);
    const toField = validateField(TO_STATION, tempForm);
    let newForm = new Map();
    newForm.set(FROM_STATION, fromField);
    newForm.set(TO_STATION, toField);
    newForm = validateNoGANService(newForm);
    Array.from(newForm.values()).forEach((field) => {
      field.isValid = !field.error && !field.serviceMessage && field.stationExists;
    });

    return newForm;
  };

  const handleFieldFocus = (field: string): void => {
    const validatedForm = validateForm(cloneDeep(form));
    const focusedField = validatedForm.get(field);
    if (inputRef.current[field].value === '') {
      resetListFocus();
    } else {
      setSearchResult(filterStations(focusedField.value, stations.stopPlaces));
      setListFocusState({ row: 0, list: lists.searchResult });
    }
    setDialog({ name: field, color: 'transparent', scroll: true });
  };

  const handleFieldChange = (name: string, value: string): void => {
    const tempForm = cloneDeep(form);
    const updated = tempForm.get(name);
    updated.value = value;
    if (value === '') resetListFocus();
    else {
      setSearchResult(filterStations(value, stations.stopPlaces));
      setListFocusState({ row: 0, list: lists.searchResult });
    }
    setOrderStations(undefined, undefined);
    setPositionActive(false);
    setForm(validateForm(tempForm));
  };

  const handleBlur = (): void => {
    const validatedForm = validateForm(cloneDeep(form));
    const hasErrors = Array.from(validatedForm.values()).find(
      (field) => field.error || field.serviceMessage || (field.value !== '' && !field.stationExists),
    );
    if (hasErrors) {
      inputRef.current[hasErrors.name].focus();
    } else {
      resetListFocus();
      setDialog(undefined);
    }
    setForm(validatedForm);
  };

  const handleTab = (): void => {
    const validatedForm = validateForm(cloneDeep(form));
    const latest = validatedForm.get(currentDialog?.name);
    if (latest && (latest.isValid || latest.value === '')) {
      setDialog(undefined);
      resetListFocus();
    }
    setForm(validatedForm);
  };

  const handleClearField = (field: string): void => {
    const tempForm = cloneDeep(form);
    const updated = tempForm.get(field);
    updated.value = '';
    inputRef.current[field].value = '';
    setForm(validateForm(tempForm));
    setPositionActive(false);
    deleteOrderStations();
    resetListFocus();

    inputRef.current[field].focus();
  };

  const handlePositionChange = (field: string, value: string): void => {
    if (value) {
      setPositionActive(true);
      handleSelect(field, value);
    } else {
      handleClearField(field);
    }
  };

  const handleSelect = (field: string, station: string, secondStation = null): void => {
    const tempForm = cloneDeep(form);
    const updated = tempForm.get(field);
    const fromField = tempForm.get(FROM_STATION);
    const toField = tempForm.get(TO_STATION);
    if (secondStation) {
      fromField.value = station;
      toField.value = secondStation;
    } else {
      updated.value = station;
    }

    const validatedForm = validateForm(tempForm);
    const validatedFrom = validatedForm.get(FROM_STATION);
    const validatedTo = validatedForm.get(TO_STATION);
    const fromErrors = validatedFrom.error || validatedFrom.serviceMessage || !validatedFrom.stationExists;
    const toErrors = validatedTo.error || validatedTo.serviceMessage || !validatedTo.stationExists;

    if (field === FROM_STATION && !fromErrors && (!toField.value || toErrors)) {
      inputRef.current[FROM_STATION].blur();
      inputRef.current[TO_STATION].focus();
    } else if (field === TO_STATION && !toErrors && fromErrors) {
      inputRef.current[TO_STATION].blur();
      inputRef.current[FROM_STATION].focus();
    } else if (field === FROM_STATION && fromErrors) {
      inputRef.current[FROM_STATION].focus();
    } else if (field === TO_STATION && toErrors) {
      inputRef.current[TO_STATION].focus();
    } else {
      if (inputRef) inputRef.current[field].blur();
      resetListFocus();
      setDialog(undefined);
    }
    setForm(validatedForm);
  };

  const cacheSearch = (locationFrom: string, locationTo: string): void => {
    const tempSearches = earlierSearches ? Array.from(earlierSearches) : [];
    const index = tempSearches.findIndex((search) => search['from'] === locationFrom && search['to'] === locationTo);
    if (index >= 0) tempSearches.splice(index, 1);
    tempSearches.unshift({ from: locationFrom, to: locationTo });
    const tempSettings = settings ? cloneDeep(settings) : {};
    tempSettings[Constants.CACHE_SEARCHED_STATIONS] = tempSearches.slice(0, 3);
    cacheItem(Constants.CACHE_SETTINGS, tempSettings);
  };

  const handleSwapStations = (): void => {
    const tempForm = cloneDeep(form);
    const fromField = form.get(FROM_STATION).value;
    const toField = form.get(TO_STATION).value;
    tempForm.get(FROM_STATION).value = toField;
    tempForm.get(TO_STATION).value = fromField;
    setForm(validateForm(tempForm));
  };

  const handleSubmit = () => {
    const tempForm = cloneDeep(form);
    const hasErrors = Array.from(tempForm.values()).find((field) => !field.isValid);
    if (hasErrors) inputRef.current[hasErrors.name].focus();
    else navigateWithState(redirectUrl);
  };

  useEffect(() => {
    if ((order.from || order.to) && form) {
      const populatedForm = populateForm(cloneDeep(form), order);
      setForm(validateForm(populatedForm));
    }
  }, []);

  useEffect(() => {
    const fromField = form.get(FROM_STATION);
    const toField = form.get(TO_STATION);
    if (fromField.isValid && toField.isValid) {
      const locationFrom = stations.stopPlaces.find(
        (station) => station.name.toLowerCase() === fromField.value.trim().toLowerCase(),
      );
      const locationTo = stations.stopPlaces.find(
        (station) => station.name.toLowerCase() === toField.value.trim().toLowerCase(),
      );

      setOrderStations(locationFrom, locationTo);
      cacheSearch(locationFrom.name, locationTo.name);
    }
  }, [form]);

  useEffect(() => {
    if (order.from && order.to) {
      onFormValid ? onFormValid() : null;
    }
  }, [order.from || order.to]);

  return (
    form && (
      <form noValidate autoComplete="off" role="search" aria-label={translate('LABEL_SEARCH_DESTINATIONS')}>
        <StationSearchContainer
          small={small}
          medium={medium}
          large={large}
          fromValue={form.get(FROM_STATION).value !== '' || currentDialog?.name === FROM_STATION}
          toValue={form.get(TO_STATION).value !== '' || currentDialog?.name === TO_STATION}
          displaySubmit={displaySubmit}
          onSubmit={handleSubmit}
        >
          <FormGroupContainer
            id="stationFormGroup"
            role="form-group"
            aria-label={translate('LABEL_CHOOSE_DESTINATIONS')}
            isFocused={currentDialog?.name === 'toStation' || currentDialog?.name === 'fromStation'}
          >
            {Array.from(form.values()).map((field, index) => (
              <React.Fragment key={index}>
                <StationComboBox
                  owns={`${field.name}ListBox`}
                  expanded={currentDialog?.name === field.name}
                  fieldInFocus={currentDialog?.name}
                  searchResult={searchResult}
                  earlierSearches={earlierSearches}
                  shortcuts={shortcuts}
                  listFocusState={listFocusState}
                  onUpdateFocusState={({ row, list }) => setListFocusState({ row, list })}
                  onTab={handleTab}
                  onBlur={handleBlur}
                  onSelect={handleSelect}
                >
                  <StationInput
                    ref={(ref) => (inputRef.current[field.name] = ref)}
                    field={field}
                    positionActive={positionActive}
                    fieldInFocus={currentDialog?.name === field.name}
                    neighbourInFocus={
                      (field.name === 'fromStation' && currentDialog?.name === 'toStation') ||
                      (field.name === 'toStation' && currentDialog?.name === 'fromStation')
                    }
                    displaySwopStations={displaySwap}
                    stopPlaces={Boolean(stations.stopPlaces.length)}
                    activeDecendant={`${listFocusState.list}${listFocusState.row}`}
                    onFieldFocus={(field: string) => handleFieldFocus(field)}
                    onFieldChange={handleFieldChange}
                    onClearField={handleClearField}
                    onBlurField={handleBlur}
                    onPositionChange={handlePositionChange}
                  />

                  {currentDialog?.name === field.name && (
                    <SearchResult
                      field={field}
                      listFocusState={listFocusState}
                      resultList={searchResult}
                      shortcuts={shortcuts}
                      earlierSearches={earlierSearches}
                      stopPlaces={Boolean(stations.stopPlaces.length)}
                      onSelect={handleSelect}
                    />
                  )}
                </StationComboBox>

                {field.left && displaySwap && (
                  <SwapStations isFormValid={isFormValid(form)} onSwapStations={handleSwapStations} />
                )}
              </React.Fragment>
            ))}
          </FormGroupContainer>
        </StationSearchContainer>
      </form>
    )
  );
};
