import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { TransactionCampaignInfoType, TransactionOrderLineType, TransactionPassengerType } from 'types/HistoryTypes';
import { GOADate } from 'utils/date/GOADate';
import { ChevronRight } from 'elements/icon/Icon';
import { MarginBottom } from 'elements/distance/Margins';
import { FlexRow } from 'elements/containers/Containers';
import {
  TxtLargeBoldDarkResp,
  TxtMediumBoldDarkResp,
  TxtSmallBoldDarkResp,
  TxtSmallMediumMediumResp,
} from 'elements/new-design/Typography';

type DepartureInfoProps = {
  campaignInfo: TransactionCampaignInfoType;
  orderLine: TransactionOrderLineType;
  passenger: TransactionPassengerType;
};

const JourneyMainInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stations = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1rem;
`;

const StationsTime = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
`;

const getDepartDate = (departureDate: GOADate): string => {
  const date = departureDate.formatDate('full').split(' ');
  const shortMonth = departureDate.formatDate('small').split(' ')[2];

  return `${date[0]} ${date[1]} ${shortMonth}`;
};

export const DepartureInfo = ({ campaignInfo, orderLine, passenger }: DepartureInfoProps) => {
  const { translate, getI18nDate } = useI18n();
  return (
    <JourneyMainInfo>
      {campaignInfo ? (
        <>
          <TxtMediumBoldDarkResp>{campaignInfo.title}</TxtMediumBoldDarkResp>
          <TxtLargeBoldDarkResp>{`${campaignInfo.daysOfValidity} ${translate('DAYS')}`}</TxtLargeBoldDarkResp>
          <TxtSmallMediumMediumResp>{`${translate('VALID')} ${translate('TO').toLowerCase()} ${getI18nDate(
            passenger.endOfValidity,
          ).formatDate('small')} ${passenger.endOfValidity.formatTime()}`}</TxtSmallMediumMediumResp>
        </>
      ) : (
        <>
          <TxtMediumBoldDarkResp>{orderLine.offerName}</TxtMediumBoldDarkResp>
          <MarginBottom margin={1} />
          <TxtSmallMediumMediumResp>
            {passenger.fromTariffZoneName
              ? `${translate('VALID')} ${getI18nDate(passenger.startOfValidity).formatDate(
                  'small',
                )} ${passenger.startOfValidity.formatTime()} - ${passenger.endOfValidity.formatTime()}`
              : getDepartDate(getI18nDate(passenger.startOfValidity))}
          </TxtSmallMediumMediumResp>
          <MarginBottom margin={1} />
          <TxtLargeBoldDarkResp>{passenger.fromTariffZoneName}</TxtLargeBoldDarkResp>
          <Stations>
            <TxtSmallBoldDarkResp>{passenger.fromStationName}</TxtSmallBoldDarkResp>
            <TxtSmallBoldDarkResp>{passenger.toStationName}</TxtSmallBoldDarkResp>
          </Stations>
          <StationsTime>
            {!passenger.fromTariffZoneName && (
              <>
                <TxtLargeBoldDarkResp>{passenger.startOfValidity.formatTime()}</TxtLargeBoldDarkResp>
                <FlexRow>
                  <ChevronRight color="grey" />
                </FlexRow>
                <TxtLargeBoldDarkResp>{passenger.endOfValidity.formatTime()}</TxtLargeBoldDarkResp>
              </>
            )}
          </StationsTime>
        </>
      )}
    </JourneyMainInfo>
  );
};
