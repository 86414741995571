import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Customer */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Create customer profile
 * 
 * @param {object} options Optional options
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {module:types.GoAliveRestApi.Services.CreateCustomerRequestV2} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerProfileResponseV21>} Success
 */
export function Customer_CreateCustomerProfileV2Async(options?: Customer_CreateCustomerProfileV2AsyncOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerProfileResponseV21>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Customer_CreateCustomerProfileV2AsyncOperation, parameters)
}

export interface Customer_CreateCustomerProfileV2AsyncOptions {
  clientType?: 'Android'|'iOS'|'Web'
  body?: api.GoAliveRestApi.Services.CreateCustomerRequestV2
}

const Customer_CreateCustomerProfileV2AsyncOperation: api.OperationInfo = {
  path: '/rest/v2/Customer/create',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
