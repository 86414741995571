import { Constants } from 'utils/Constants';
import { GOADate } from 'utils/date/GOADate';
import {
  SET_ORDER,
  SET_TYPE,
  SET_ORIGINAL_DEPARTURE_DATE,
  SET_ORIGINAL_RETURN_DATE,
  SET_DEPARTURE_DATE,
  SET_RETURN_DATE,
  SET_FROM,
  SET_TO,
  SET_PASSENGERS,
  SET_SUPPLEMENTS,
  SET_ORDER_ID,
  SET_RESERVE_EXPIRATION,
  SET_PAYMENT_METHOD,
  SET_TRANSACTION_ID,
  SET_DELIVERY_METHOD,
  SET_CONTACT_INFORMATION,
  SET_TERMS_ACCEPTED,
  SET_JOURNEY,
  SET_RETURN_JOURNEY,
  SET_RETURN_TO_PATH,
  OrderState,
  ReturnState,
  ReviewState,
  PlannerState,
  PaymentState,
  JourneyState,
} from '../../types/OrderTypes';
import { SET_SELECTED_SEATS } from 'types/SeatingTypes';
import { SET_RECURRING_CARD } from 'types/RecurringPaymentTypes';

export const initialOrderState: PlannerState = {
  type: Constants.ORDER_TYPE_DEFAULT,
  to: undefined,
  from: undefined,
  originalDepartureDate: new GOADate(),
  originalReturnDate: undefined,
  departureDate: new GOADate(),
  returnDate: undefined,
  passengers: {
    ADULT: 1,
    INFANT: 0,
    CHILD: 0,
    SENIOR: 0,
    STUDENT: 0,
    MILITARY: 0,
  },
  supplements: {
    SMALL_ANIMAL: 0,
    ANIMAL: 0,
    BICYCLE: 0,
  },
};

export const initialJourneyState: JourneyState = {
  journey: undefined,
};

export const initialReturnState: ReturnState = {
  returnJourney: undefined,
};

export const initialReviewState: ReviewState = {
  orderID: undefined,
  selectedSeats: undefined,
  reserveExpiration: undefined,
};

export const InitialContactInformation = {
  firstName: undefined,
  surname: undefined,
  email: undefined,
  countryCode: '+47',
  telephoneNumberNoCountryCode: undefined,
};

export const initialPaymentState: PaymentState = {
  deliveryMethod: undefined,
  paymentMethod: undefined,
  termsAccepted: false,
  contactInformation: InitialContactInformation,
  recurringCard: undefined,
  returnToPath: Constants.ROUTE_PAYMENT,
};

export const initialState: OrderState = {
  ...initialOrderState,
  ...initialJourneyState,
  ...initialReturnState,
  ...initialReviewState,
  ...initialPaymentState,
};

export default function orderReducer(state = initialState, action): OrderState {
  switch (action.type) {
    case SET_ORDER:
      return action.payload;
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_FROM:
      return {
        ...state,
        from: action.payload,
      };
    case SET_TO:
      return {
        ...state,
        to: action.payload,
      };
    case SET_ORIGINAL_DEPARTURE_DATE:
      return {
        ...state,
        originalDepartureDate: action.payload,
      };
    case SET_ORIGINAL_RETURN_DATE:
      return {
        ...state,
        originalReturnDate: action.payload,
      };
    case SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.payload,
      };
    case SET_RETURN_DATE:
      return {
        ...state,
        returnDate: action.payload,
      };
    case SET_PASSENGERS:
      return {
        ...state,
        passengers: action.payload,
      };
    case SET_SUPPLEMENTS:
      return {
        ...state,
        supplements: action.payload,
      };
    case SET_JOURNEY:
      return {
        ...state,
        journey: action.payload,
      };
    case SET_RETURN_JOURNEY:
      return {
        ...state,
        returnJourney: action.payload,
      };
    case SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload,
      };
    case SET_RESERVE_EXPIRATION:
      return {
        ...state,
        reserveExpiration: action.payload,
      };
    case SET_SELECTED_SEATS:
      return {
        ...state,
        selectedSeats: action.payload,
      };
    case SET_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload,
      };
    case SET_CONTACT_INFORMATION:
      return {
        ...state,
        contactInformation: action.payload,
      };
    case SET_RECURRING_CARD:
      return {
        ...state,
        recurringCard: action.payload,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case SET_TERMS_ACCEPTED:
      return {
        ...state,
        termsAccepted: action.payload,
      };
    case SET_TRANSACTION_ID:
      return {
        ...state,
        transactionId: action.payload,
      };
    case SET_RETURN_TO_PATH:
      return {
        ...state,
        returnToPath: action.payload,
      };
    default:
      return state;
  }
}
