export const fieldNames = {
  assistanceNeeds: 'assistanceNeeds',
  assistanceAid: 'assistanceAid',
  fromStation: 'fromStation',
  toStation: 'toStation',
  fromStationInfo: 'fromStationInfo',
  toStationInfo: 'toStationInfo',
  assistanceLocation: 'assistanceLocation',
  travelAlone: 'travelAlone',
  luggage: 'luggage',
  confirmationMethod: 'confirmationMethod',
  phoneNumber: 'phoneNumber',
  descriptionOfNeed: 'descriptionOfNeed',
};

const getStationInfoField = (isFrom: boolean) => {
  return {
    id: isFrom ? fieldNames.fromStationInfo : fieldNames.toStationInfo,
    label: isFrom ? 'ASSISTANCE_DEPARTURE_INFO' : 'ASSISTANCE_DESTINATION_INFO',
    description: 'MANDATORY_FIELD',
    value: '',
    error: undefined,
    validationRules: ['isRequired'],
    element: 'textarea',
    type: 'textarea',
  };
};

export const getAssistanceFields = (assistanceLocations, assistanceOptions, user) => {
  const fromInfo = assistanceLocations.from.available ? getStationInfoField(true) : null;
  const toInfo = assistanceLocations.to.available ? getStationInfoField(false) : null;
  return [
    {
      id: fieldNames.assistanceNeeds,
      label: 'ASSISTANCE_NEEDS',
      description: 'MANDATORY_FIELD',
      value: '',
      error: undefined,
      validationRules: ['isRequired'],
      element: 'dropdown',
      type: 'dropdown',
      options: assistanceOptions.assistanceNeedsOptions.map((aid) => {
        return {
          id: aid.value,
          label: aid.key,
          value: aid.value,
          element: 'dropdown',
          type: 'dropdown',
          parentId: 'assistanceNeeds',
        };
      }),
    },
    {
      id: fieldNames.assistanceAid,
      label: 'ASSISTANCE_AID',
      description: 'MANDATORY_FIELD',
      value: '',
      error: undefined,
      validationRules: ['isRequired'],
      element: 'dropdown',
      type: 'dropdown',
      options: assistanceOptions.assistanceAidOptions.map((aid) => {
        return {
          id: aid.value,
          label: aid.key,
          value: aid.value,
          element: 'dropdown',
          type: 'dropdown',
          parentId: 'assistanceAid',
        };
      }),
    },
    {
      id: fieldNames.assistanceLocation,
      label: 'ASSISTANCE_LOCATION',
      description: 'MANDATORY_FIELD',
      value: [],
      validationRules: ['isRequired'],
      error: undefined,
      element: 'formGroup',
      type: 'checkbox',
      options: [
        {
          id: fieldNames.fromStation,
          label: assistanceLocations.from.available
            ? assistanceLocations.from.name
            : `${assistanceLocations.from.name}, ingen assistance`,
          value: assistanceLocations.from.id,
          readOnly: !assistanceLocations.from.available,
          element: 'checkbox',
          type: 'checkbox',
          parentId: 'assistanceLocation',
        },
        {
          id: fieldNames.toStation,
          label: assistanceLocations.to.available
            ? assistanceLocations.to.name
            : `${assistanceLocations.to.name}, ingen assistance`,
          value: assistanceLocations.to.id,
          readOnly: !assistanceLocations.to.available,
          element: 'checkbox',
          type: 'checkbox',
          parentId: 'assistanceLocation',
        },
      ],
    },
    fromInfo,
    toInfo,
    {
      id: fieldNames.travelAlone,
      label: 'TRAVELLING_ALONE',
      description: '',
      value: 'Ja',
      error: undefined,
      validationRules: ['isRequired'],
      element: 'formGroup',
      type: 'radio',
      options: [
        {
          id: 'alone',
          parentId: 'travelAlone',
          label: 'Ja',
          value: 'Ja',
          element: 'radio',
          type: 'radio',
        },
        {
          id: 'notAlone',
          parentId: 'travelAlone',
          label: 'Nei',
          value: 'Nei',
          element: 'radio',
          type: 'radio',
        },
      ],
    },
    {
      id: fieldNames.luggage,
      label: 'TOTAL_LUGGAGE',
      description: '',
      value: 0,
      error: undefined,
      validationRules: [],
      type: 'counter',
    },
    {
      id: fieldNames.confirmationMethod,
      label: 'CONFIRMATION_CHOICE',
      description: '',
      value: assistanceOptions.confirmationMethodOptions[0].value,
      error: undefined,
      validationRules: ['isRequired'],
      element: 'formGroup',
      type: 'radio',
      options: assistanceOptions.confirmationMethodOptions.map((option) => {
        return {
          id: option.value,
          parentId: 'confirmationMethod',
          label: option.key,
          value: option.value,
          element: 'radio',
          type: 'radio',
        };
      }),
    },
    {
      id: fieldNames.phoneNumber,
      label: 'PHONENUMBER',
      description: 'MANDATORY_FIELD',
      value: user.telephoneNumberNoCountryCode ? user.telephoneNumberNoCountryCode : '',
      error: undefined,
      validationRules: ['isValidPhone'],
      element: 'tel',
      type: 'tel',
    },
    {
      id: fieldNames.descriptionOfNeed,
      label: 'DESCRIPTION_OF_NEED',
      description: '',
      value: '',
      error: undefined,
      validationRules: [],
      element: 'textarea',
      type: 'textarea',
    },
  ];
};
