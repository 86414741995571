import React from 'react';
import styled from 'styled-components';
import { Circle } from 'elements/new-design/Shapes';
import { ArrowRightIconDark } from 'elements/new-design/icons/SymbolIcons';
import {
  TxtSmallBoldDarkResp,
  fontColorAccent,
  fontColorDark,
  fontFamilyMedium,
  fontSizeSmallResp,
  fontSizeMediumResp,
  fontFamilyBold,
  fontLargeStatic,
  TxtMediumRegularLightResp,
} from '../Typography';
import { A } from 'hookrouter';

type LinkProps = {
  text?: string;
  url: string;
  color?: string;
  external?: boolean;
  onLinkClick?: () => void;
};

type StyleProps = {
  color: string;
};

const LinkMediumResp = styled.span`
  ${fontFamilyMedium}
  ${fontSizeMediumResp}
`;

export const LinkMediumBoldResp = styled.span`
  ${fontFamilyBold}
  ${fontSizeMediumResp}
`;

const LinkTxtLargeDarkStatic = styled.span`
  ${fontColorDark}
  ${fontFamilyMedium}
  ${fontLargeStatic}
`;

export const LinkUnderline = styled(A)`
  ${(_: StyleProps) => ''}
  padding-bottom: 0.4rem;
  border-bottom: ${(props) =>
    props.color === 'light' ? props.theme.newconstants.borderLightThin : props.theme.newconstants.borderDarkThin};
  :hover,
  :focus-visible {
    box-shadow: none;
    border-bottom: ${(props) =>
      props.color === 'light' ? props.theme.newconstants.borderLightThick : props.theme.newconstants.borderDarkThick};
  }
`;

const LinkNoUnderline = styled(LinkUnderline)`
  border-bottom: ${(props) => props.theme.newconstants.borderTransparantThick};
  :hover,
  :focus-visible {
    border-bottom: ${(props) =>
      props.color === 'light' ? props.theme.newconstants.borderLightThick : props.theme.newconstants.borderDarkThick};
  }
`;

const LinkUnderlineInline = styled(LinkUnderline)`
  ${(_: StyleProps) => ''}
  padding-bottom: 0;
`;

export const LinkCircle = styled(Circle)`
  background: inherit;
  border: ${(props) => props.theme.newconstants.borderDarkThin};
`;

export const IconCircleLink = styled(A)`
  :hover,
  :focus-visible {
    box-shadow: none;

    ${LinkCircle} {
      border: ${(props) => props.theme.newconstants.borderDarkThick};
    }
  }
`;

const LinkSmallAccent = styled(A)`
  ${fontSizeSmallResp}
  ${fontFamilyMedium}
  ${fontColorAccent}

  &:focus-visible {
    box-shadow: ${(props) => props.theme.newconstants.keyNavFocus};
  }
`;

export const LinkSmallResp = ({ text, url, color, external }: LinkProps): JSX.Element => (
  <span>
    <LinkUnderline href={url} color={color} target={external ? '_blank' : '_top'}>
      <TxtSmallBoldDarkResp>{text}</TxtSmallBoldDarkResp>
    </LinkUnderline>
  </span>
);

export const LinkMediumRespUnderline = ({ text, url, color, external }: LinkProps): JSX.Element => (
  <span>
    <LinkUnderline href={url} target={external ? '_blank' : '_top'} color={color}>
      <LinkMediumResp>{text}</LinkMediumResp>
    </LinkUnderline>
  </span>
);

export const LinkMediumRespInline = ({ text, url, color, external }: LinkProps): JSX.Element => (
  <span>
    <LinkUnderlineInline href={url} target={external ? '_blank' : '_top'} color={color}>
      <LinkMediumResp>{text}</LinkMediumResp>
    </LinkUnderlineInline>
  </span>
);

export const LinkMediumRespNoUnderline = ({ text, url, color, external }: LinkProps): JSX.Element => (
  <LinkNoUnderline href={url} target={external ? '_blank' : '_top'} color={color}>
    <TxtMediumRegularLightResp>{text}</TxtMediumRegularLightResp>
  </LinkNoUnderline>
);

export const LinkLargeNoUnderline = ({ text, url, external, onLinkClick }: LinkProps): JSX.Element => (
  <LinkNoUnderline
    href={url}
    target={external ? '_blank' : '_top'}
    onClick={onLinkClick && !external ? onLinkClick : null}
  >
    <LinkTxtLargeDarkStatic>{text}</LinkTxtLargeDarkStatic>
  </LinkNoUnderline>
);

export const IconLink = ({ url, external, onLinkClick }: LinkProps): JSX.Element => (
  <IconCircleLink
    href={url}
    target={external ? '_blank' : '_top'}
    onClick={onLinkClick && !external ? onLinkClick : null}
  >
    <LinkCircle width={4.5} height={4.5}>
      <ArrowRightIconDark width={3} height={3} />
    </LinkCircle>
  </IconCircleLink>
);

export const ScrollLinkAccent = ({ url, text }: LinkProps): JSX.Element => (
  <LinkSmallAccent href="" onClick={() => document.getElementById(url).scrollIntoView()}>
    {text}
  </LinkSmallAccent>
);
