export type ProblemCombinationType = {
  toMessageId?: number;
  fromMessageId?: number;
};

export type AssistanceLocationType = {
  name: string;
  id: string;
};

export type LocationType = {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  distance?: number;
  parentLocation: string;
  problemCombinations?: { [index: string]: ProblemCombinationType };
};

export type AssistanceLocationsType = {
  lastOrderTimeHours: number;
  locations: AssistanceLocationType[];
};

export type ProblemCombinationTextType = { [index: number]: string };

export type Locations = {
  stopPlaces: LocationType[];
  stopPlaceProblemCombinationTexts: ProblemCombinationTextType;
  assistanceLocations: AssistanceLocationsType;
};

export type LocationsState = Locations;

// Set Locations
// =========================
export const SET_LOCATIONS = 'SET_LOCATIONS';

type SetLocationsAction = {
  type: typeof SET_LOCATIONS;
  payload: Locations;
};

export type LocationsActionTypes = SetLocationsAction;
