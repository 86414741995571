import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { FormFieldType } from 'types/FormTypes';
import { ButtonAddBold, ButtonSubtractBold } from 'elements/buttons/Buttons';
import { TxtLargeBoldDarkResp } from 'elements/new-design/Typography';

type CounterProps = {
  field: FormFieldType;
  onInputChange: (field: FormFieldType, value: number) => void;
};

const BtnSub = styled(ButtonSubtractBold)`
  width: 2.5rem;
  height: 2.5rem;
`;

const BtnAdd = styled(ButtonAddBold)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const Counter = ({ field, onInputChange }: CounterProps) => {
  const { translate } = useI18n();
  return (
    <>
      <li>
        <BtnSub
          id="subtract"
          name={field.id}
          onClick={() => onInputChange(field, field.value - 1)}
          disabled={field.value <= 0}
          aria-label={translate('LABEL_SUBTRACT')}
        />
      </li>
      <li>
        <label htmlFor={field.id}>
          <TxtLargeBoldDarkResp aria-live="assertive">{field.value}</TxtLargeBoldDarkResp>
        </label>
      </li>
      <li>
        <BtnAdd
          id="add"
          name={field.id}
          onClick={() => onInputChange(field, field.value + 1)}
          disabled={field.value >= 3}
          aria-label={translate('LABEL_ADD')}
        />
      </li>
    </>
  );
};
