export const breakPoints = {
  small: 600,
  medium: 768,
  large: 992,
  xlarge: 1200,
};

export default {
  xsmall: `(max-width: ${breakPoints.small - 1}px)`,
  small: `(min-width: ${breakPoints.small}px)`,
  medium: `(min-width: ${breakPoints.medium}px)`,
  large: `(min-width: ${breakPoints.large}px)`,
  xlarge: `(min-width: ${breakPoints.xlarge}px)`,
};
