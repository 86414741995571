import { SET_USER, UserType } from '../../types/CustomerTypes';

export const initialUserState: UserType = {
  firstName: undefined,
  surname: undefined,
  email: undefined,
  account: false,
  keep: false,
  tokenGoOps: undefined,
  token: undefined,
  refreshToken: undefined,
  refreshTokenGoOps: undefined,
  refreshTokenExpirationGoOps: undefined,
  expiration: undefined,
  refreshTokenExpiration: undefined,
  countryCode: '+47',
  nationality: undefined,
  telephoneNumberNoCountryCode: undefined,
  consents: undefined,
  customerEntitlements: [],
};

export default function userReducer(state = initialUserState, action): UserType {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        firstName: action.payload.firstName,
        surname: action.payload.surname,
        email: action.payload.email,
        account: action.payload.account,
        keep: action.payload.keep,
        tokenGoOps: action.payload.tokenGoOps,
        token: action.payload.token,
        expiration: action.payload.expiration,
        nationality: action.payload.nationality,
        refreshTokenGoOps: action.payload.refreshTokenGoOps,
        refreshTokenExpirationGoOps: action.payload.refreshTokenExpirationGoOps,
        refreshToken: action.payload.refreshToken,
        refreshTokenExpiration: action.payload.refreshTokenExpiration,
        telephoneNumberNoCountryCode: action.payload.telephoneNumberNoCountryCode,
        consents: action.payload.consents,
        customerEntitlements: action.payload.customerEntitlements,
      };
    default:
      return state;
  }
}
