import React from 'react';
import styled from 'styled-components';
import { LocationType } from 'types/LocationTypes';
import { StationFieldType } from './StationSearchService';
import { SearchMessages } from './result-list/SearchMessages';
import { Results } from './result-list/Results';
import { FlexColumnNoCenter } from 'elements/containers/Containers';

type SearchResultProps = {
  field: StationFieldType;
  listFocusState: { row: number; list: string };
  resultList: LocationType[];
  shortcuts: string[];
  stopPlaces: boolean;
  earlierSearches: [{ to: string; from: string }] | null;
  onSelect: (field: string, station: string, secondStation: string | null) => void;
};

type StyleProps = {
  error: boolean;
  left: boolean;
};

const SearchResultContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  padding: 1.6rem 2.4rem;
  border: ${(props) => props.theme.newconstants.borderDarkThick};
  border-bottom: ${(props) => props.left && 0};
  border-top: 0;
  background: ${(props) => (props.error ? props.theme.newcolors.errorDark : props.theme.newcolors.bgPrimary)};
  border-bottom-left-radius: ${(props) => (props.left ? '0' : '1rem')};
  border-bottom-right-radius: ${(props) => (props.left ? '0' : '1rem')};

  @media ${(props) => props.theme.breakpoints.large} {
    position: absolute;
    top: 10rem;
    border: ${(props) =>
      props.error ? props.theme.newconstants.borderErrorThick : props.theme.newconstants.borderDarkThick};
    border-top: none;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }
`;

export const SearchResult = ({
  field,
  listFocusState,
  resultList,
  shortcuts,
  stopPlaces,
  earlierSearches,
  onSelect,
}: SearchResultProps): JSX.Element => {
  return (
    <SearchResultContainer error={Boolean(field.error) || !stopPlaces} left={field.left}>
      <FlexColumnNoCenter>
        <SearchMessages field={field} resultList={resultList} stopPlaces={stopPlaces} />
        {stopPlaces && !field.error && !field.serviceMessage && (
          <Results
            field={field}
            listFocusState={listFocusState}
            resultList={resultList}
            earlierSearchedJourneys={earlierSearches}
            shortcuts={shortcuts}
            onSelect={onSelect}
          />
        )}
      </FlexColumnNoCenter>
    </SearchResultContainer>
  );
};
