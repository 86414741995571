import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { TransactionType, TransactionPassengerType } from 'types/HistoryTypes';
import { TicketIcon, CancelledIcon } from 'elements/icon/symbol-icons';
import { HiddenSpan, TxtSmallMediumMediumResp } from 'elements/new-design/Typography';
import { FlexRow } from 'elements/containers/Containers';

type NumberOfTicketsProps = {
  transaction: TransactionType;
  displayCompactVersion: boolean;
  displaySelectedColor: boolean;
};

const NumberOfTicketsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  justify-content: flex-end;

  ${TxtSmallMediumMediumResp} {
    margin-left: 0.4rem;
  }
`;

const getNumOfCancelledTickets = (passengers: TransactionPassengerType[]): number =>
  passengers.filter((p) => p.cancelled).length;

const getNumOfValidTickets = (passengers: TransactionPassengerType[]): number =>
  passengers.filter((p) => p.cancelled != true).length;

export const NumberOfTickets = ({ transaction, displayCompactVersion, displaySelectedColor }: NumberOfTicketsProps) => {
  const cancelled = getNumOfCancelledTickets(transaction.passengers);
  const validTickets = getNumOfValidTickets(transaction.passengers);
  const { translate } = useI18n();

  return (
    <NumberOfTicketsContainer>
      <HiddenSpan>{`${validTickets + cancelled} ${translate('TICKETS')}, ${cancelled} ${translate(
        'CANCELLED',
      )}`}</HiddenSpan>
      {validTickets > 0 && (
        <FlexRow>
          {displaySelectedColor ? (
            <TicketIcon color="light" width={1.4} height={1.4} />
          ) : (
            <TicketIcon color="dark" width={1.4} height={1.4} />
          )}
          <TxtSmallMediumMediumResp aria-hidden={true} data-cy={'ticketAmountCy'}>
            {displayCompactVersion
              ? validTickets
              : `${validTickets} ${validTickets === 1 ? translate('TICKET') : translate('TICKETS')}`}
          </TxtSmallMediumMediumResp>
        </FlexRow>
      )}
      {cancelled > 0 && (
        <FlexRow>
          <CancelledIcon width={1.2} height={1.2} />
          <TxtSmallMediumMediumResp aria-hidden={true}>
            {displayCompactVersion ? cancelled : `${cancelled} ${translate('CANCELLED')}`}
          </TxtSmallMediumMediumResp>
        </FlexRow>
      )}
    </NumberOfTicketsContainer>
  );
};
