import { GOADate } from 'utils/date/GOADate';
import {
  SET_TYPE,
  SET_ORIGINAL_DEPARTURE_DATE,
  SET_ORIGINAL_RETURN_DATE,
  SET_DEPARTURE_DATE,
  SET_RETURN_DATE,
  SET_FROM,
  SET_TO,
  SET_ORDER,
  OrderState,
  SET_PASSENGERS,
  SET_ORDER_ID,
  SET_RESERVE_EXPIRATION,
  ContactInformation,
  OrderActionTypes,
  SET_SUPPLEMENTS,
  SET_PAYMENT_METHOD,
  SET_TRANSACTION_ID,
  SET_DELIVERY_METHOD,
  SET_CONTACT_INFORMATION,
  SET_TERMS_ACCEPTED,
  SET_JOURNEY,
  SET_RETURN_JOURNEY,
  SET_RETURN_TO_PATH,
} from '../../types/OrderTypes';
import { Passengers, Supplements } from 'types/PassengerTypes';
import { LocationType } from 'types/LocationTypes';
import { SelectedSeatListType, SET_SELECTED_SEATS, SeatActionTypes } from 'types/SeatingTypes';
import { SelectedJourneyType } from 'types/JourneyTypes';
import { RecurringCardType, SET_RECURRING_CARD, RecurringCardActionTypes } from 'types/RecurringPaymentTypes';

export function setOrder(order: OrderState): OrderActionTypes {
  return {
    type: SET_ORDER,
    payload: order,
  };
}

export function setType(type: string): OrderActionTypes {
  return {
    type: SET_TYPE,
    payload: type,
  };
}

export function setFrom(station: LocationType): OrderActionTypes {
  return {
    type: SET_FROM,
    payload: station,
  };
}

export function setTo(station: LocationType): OrderActionTypes {
  return {
    type: SET_TO,
    payload: station,
  };
}

export function setOriginalDepartureDate(originalDepartureDate: GOADate): OrderActionTypes {
  return {
    type: SET_ORIGINAL_DEPARTURE_DATE,
    payload: originalDepartureDate,
  };
}

export function setOriginalReturnDate(originalReturnDate: GOADate): OrderActionTypes {
  return {
    type: SET_ORIGINAL_RETURN_DATE,
    payload: originalReturnDate,
  };
}
export function setDepartureDate(DepartureDate: GOADate): OrderActionTypes {
  return {
    type: SET_DEPARTURE_DATE,
    payload: DepartureDate,
  };
}

export function setReturnDate(ReturnDate: GOADate): OrderActionTypes {
  return {
    type: SET_RETURN_DATE,
    payload: ReturnDate,
  };
}

export function setPassengers(passengers: Passengers): OrderActionTypes {
  return {
    type: SET_PASSENGERS,
    payload: passengers,
  };
}

export function setSupplements(supplements: Supplements): OrderActionTypes {
  return {
    type: SET_SUPPLEMENTS,
    payload: supplements,
  };
}

export function setJourney(journey: SelectedJourneyType): OrderActionTypes {
  return {
    type: SET_JOURNEY,
    payload: journey,
  };
}

export function setReturnJourney(journey: SelectedJourneyType): OrderActionTypes {
  return {
    type: SET_RETURN_JOURNEY,
    payload: journey,
  };
}

export function setOrderID(id: string): OrderActionTypes {
  return {
    type: SET_ORDER_ID,
    payload: id,
  };
}

export function setReserveExpiration(date: GOADate): OrderActionTypes {
  return {
    type: SET_RESERVE_EXPIRATION,
    payload: date,
  };
}

export function setDeliveryMethod(method: string): OrderActionTypes {
  return {
    type: SET_DELIVERY_METHOD,
    payload: method,
  };
}

export function setContactInformation(information: ContactInformation): OrderActionTypes {
  return {
    type: SET_CONTACT_INFORMATION,
    payload: information,
  };
}

export function setPaymentMethod(method: string | number): OrderActionTypes {
  return {
    type: SET_PAYMENT_METHOD,
    payload: method,
  };
}

export function setTermsAccepted(accepted: boolean): OrderActionTypes {
  return {
    type: SET_TERMS_ACCEPTED,
    payload: accepted,
  };
}

export function setTransactionID(id: string): OrderActionTypes {
  return {
    type: SET_TRANSACTION_ID,
    payload: id,
  };
}

export function setSelectedSeats(seats: SelectedSeatListType): SeatActionTypes {
  return {
    type: SET_SELECTED_SEATS,
    payload: seats,
  };
}

export function setRecurringCard(information: RecurringCardType): RecurringCardActionTypes {
  return {
    type: SET_RECURRING_CARD,
    payload: information,
  };
}

export function setReturnToPath(route: string): OrderActionTypes {
  return {
    type: SET_RETURN_TO_PATH,
    payload: route,
  };
}
