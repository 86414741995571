import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Offer */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Get period ticket offers
 * 
 * @param {string} clientType Enum: Android, iOS, Web. 
 * @param {module:types.GoAliveRestApi.Models.Requests.PeriodTicketOfferRequestV2} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {boolean} [options.hasDiscountSupport] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultPeriodTicketOfferResponseV21>} Success
 */
export function Offer_GetPeriodTicketOffers(clientType: 'Android'|'iOS'|'Web', body: api.GoAliveRestApi.Models.Requests.PeriodTicketOfferRequestV2, options?: Offer_GetPeriodTicketOffersOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultPeriodTicketOfferResponseV21>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      clientType,
      authorization: options.authorization
    },
    query: {
      hasDiscountSupport: options.hasDiscountSupport
    },
    body: {
      body
    }
  }
  return gateway.request(Offer_GetPeriodTicketOffersOperation, parameters)
}

/**
 * Get offers
 * 
 * @param {object} options Optional options
 * @param {string[]} [options.OfferId] 
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultListOffersResponseV22>} Success
 */
export function Offer_GetOffer(options?: Offer_GetOfferOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultListOffersResponseV22>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    query: {
      OfferId: gateway.formatArrayParam(options.OfferId, 'multi', 'OfferId')
    },
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(Offer_GetOfferOperation, parameters)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.clientType=Web] Enum: Android, iOS, Web. 
 * @param {module:types.GoAliveRestApi.Clients.EnTurOffersV2.Requests.OfferSetRequestV2} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultTripPatternInfo1>} Success
 */
export function Offer_GetTripPatternInfo(options?: Offer_GetTripPatternInfoOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultTripPatternInfo1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization,
      clientType: options.clientType
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Offer_GetTripPatternInfoOperation, parameters)
}

export interface Offer_GetPeriodTicketOffersOptions {
  authorization?: string
  hasDiscountSupport?: boolean
}

export interface Offer_GetOfferOptions {
  OfferId?: string[]
  authorization?: string
}

export interface Offer_GetTripPatternInfoOptions {
  authorization?: string
  clientType?: 'Android'|'iOS'|'Web'
  body?: api.GoAliveRestApi.Clients.EnTurOffersV2.Requests.OfferSetRequestV2
}

const Offer_GetPeriodTicketOffersOperation: api.OperationInfo = {
  path: '/rest/v2/Offer/period-ticket',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Offer_GetOfferOperation: api.OperationInfo = {
  path: '/rest/v2/Offer/Offers',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Offer_GetTripPatternInfoOperation: api.OperationInfo = {
  path: '/rest/v2/Offer/TripPatternInfo',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
