import React from 'react';
import styled, { css } from 'styled-components';
import { useApi } from 'hooks/use-api/useApi';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { CmsArticleBoxType } from 'types/CmsTypes';
import { SectionTitleLarge, ParagraphText } from 'elements/new-design/Typography';
import { LinkMediumRespUnderline } from 'elements/new-design/links/Links';

type ArticleBoxProps = {
  position: string;
  layout: string;
  boxData: CmsArticleBoxType;
};

type StyleProps = {
  position: string;
  layout?: string;
};

const getTextPadding = (layout: string, position: string) => {
  if (layout === 'column') {
    return css`
      padding: 2.4rem 2.4rem 0.8rem 0;

      @media ${(props) => props.theme.breakpoints.medium} {
        padding: 2.4rem 5.6rem 0.8rem 0rem;
      }

      @media ${(props) => props.theme.breakpoints.large} {
        padding: 2.4rem 2.4rem 0.8rem 0;
      }
    `;
  } else {
    if (position === 'left') {
      return css`
        padding: 2.4rem 0 0 2.4rem;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding: 2.4rem 0 0 5.6rem;
        }
      `;
    } else if (position === 'right') {
      return css`
        padding: 2.4rem 2.4rem 0 0;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding: 2.4rem 5.6rem 0 0;
        }
      `;
    } else {
      return css`
        padding: 2.4rem 2.4rem 0 0;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding: 2.4rem 2.4rem 0 0rem;
        }

        @media ${(props) => props.theme.breakpoints.large} {
          padding: 2.4rem 0 0 2.4rem;
        }
      `;
    }
  }
};

const ArticleBoxContainer = styled.article`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media ${(props) => props.theme.breakpoints.medium} {
    flex-direction: ${(props) =>
      props.layout === 'column' || props.position === 'center'
        ? 'column'
        : props.position === 'right'
        ? 'row-reverse'
        : 'row'};
  }

  @media ${(props) => props.theme.breakpoints.large} {
    width: ${(props) => (props.position === 'center' && props.layout !== 'column' ? '80rem' : '100%')};
    flex-direction: ${(props) =>
      props.layout === 'column' ? 'column' : props.position === 'right' ? 'row-reverse' : 'row'};
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    width: 100%;
  }
`;

const ArticleImage = styled.img`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: 20rem;
  object-fit: cover;
  flex-shrink: 0;

  @media ${(props) => props.theme.breakpoints.small} {
    height: 25rem;
  }

  @media ${(props) => props.theme.breakpoints.medium} {
    width: ${(props) => (props.layout === 'column' || props.position === 'center' ? '100%' : '35rem')};
    height: 30rem;
  }

  @media ${(props) => props.theme.breakpoints.large} {
    width: ${(props) => (props.layout === 'column' ? 'auto' : props.position === 'center' ? '40rem' : '50rem')};
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    width: ${(props) => (props.layout === 'column' ? 'auto' : '60rem')};
  }
`;

export const ArticleText = styled.div`
  ${(_: StyleProps) => ''}
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => {
    return getTextPadding(props.layout, props.position);
  }};

  h2,
  p {
    margin-bottom: 1.6rem;

    @media ${(props) => props.theme.breakpoints.large} {
      margin-bottom: 2.4rem;
    }
  }
`;

export const ArticleBox = ({ position, layout, boxData }: ArticleBoxProps) => {
  const { requestImageUrl } = useApi();
  const { navigateWithState } = useStateNavigation();
  const parentLink = Boolean(boxData.linkInternal && boxData.linkInternal.parentLink);
  const url = boxData.linkExternal
    ? boxData.linkExternal
    : parentLink
    ? `/${boxData.linkInternal.parentLink.current}/${boxData.linkInternal.slug.current}`
    : `/${boxData.linkInternal.slug.current}`;

  return (
    <ArticleBoxContainer position={position} layout={layout} onClick={() => navigateWithState(url)}>
      <ArticleImage
        position={position}
        layout={layout}
        src={requestImageUrl({ image: boxData.image.url, width: 880 })}
        alt={boxData.image.alt}
      />
      <ArticleText position={position} layout={layout}>
        <div>
          <SectionTitleLarge>{boxData.heading}</SectionTitleLarge>
          <ParagraphText>{boxData.text}</ParagraphText>
        </div>

        <span>
          <LinkMediumRespUnderline text={boxData.linkText} url={url} external={Boolean(boxData.linkExternal)} />
        </span>
      </ArticleText>
    </ArticleBoxContainer>
  );
};
