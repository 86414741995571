import React from 'react';
import { Constants } from 'utils/Constants';
import { LinkSmallResp } from 'elements/new-design/links/Links';

type CustomerServiceEmailProps = {
  email?: string;
  displayText?: string;
  mailSubject?: string;
};

export const CustomerServiceEmail = ({ email, displayText, mailSubject }: CustomerServiceEmailProps) => (
  <LinkSmallResp
    text={displayText ? displayText : Constants.GO_AHEAD_CUSTOMER_SERVICE_EMAIL}
    url={`mailto:${email ? email : Constants.GO_AHEAD_CUSTOMER_SERVICE_EMAIL}?subject=${
      mailSubject ? mailSubject : ''
    }`}
    external={true}
  />
);
