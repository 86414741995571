import React from 'react';
import styled, { keyframes } from 'styled-components';
import LoadingAsset from 'assets/svg/loader.svg';

// STYLES
// =========================

const loadingFade = keyframes`
  0%,
  80% {
    opacity: 0;
  }
  30%,
  60% {
    opacity: 1;
  }
`;

const loadingFadeBefore = keyframes`
  0%,
  80% {
    opacity: 0;
  }
  30%,
  60% {
    opacity: 0.1;
  }
`;

const loadingMove = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200px);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .loading {
    position: relative;
    width: 20rem;
    height: 6rem;
  }

  .loading div:first-child,
  .loading div:first-child + div,
  .loading div:first-child + div + div {
    background: url(${LoadingAsset}) no-repeat;
    height: inherit;
    width: inherit;
    position: absolute;
    opacity: 0;
  }

  .loading div:first-child {
    left: 1.5rem;
    animation: ${loadingMove} 1.5s infinite linear, ${loadingFade} 1.5s infinite;
    animation-delay: 0.15s;
  }

  .loading div:first-child + div,
  .loading div:first-child + div + div {
    content: ' ';
    animation: ${loadingMove} 1.5s infinite linear, ${loadingFadeBefore} 1.5s infinite;
  }

  .loading div:first-child + div {
    left: 0;
    animation-delay: 0.3s;
  }

  .loading div:first-child + div + div {
    left: 3rem;
    animation-delay: 0s;
  }
`;

const mediumLoadingMove = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100px);
  }
`;

const MediumLoadingContainer = styled(LoadingContainer)`
  .loading {
    width: 10rem;
    height: 3rem;
  }

  .loading div:first-child {
    left: -1rem;
    animation: ${mediumLoadingMove} 1.5s infinite linear, ${loadingFade} 1.5s infinite;
    animation-delay: 0.15s;
  }

  .loading div:first-child + div,
  .loading div:first-child + div + div {
    content: ' ';
    animation: ${mediumLoadingMove} 1.5s infinite linear, ${loadingFadeBefore} 1.5s infinite;
  }

  .loading div:first-child + div {
    left: -1.5rem;
    animation-delay: 0.3s;
  }

  .loading div:first-child + div + div {
    left: -0.5rem;
    animation-delay: 0s;
  }
`;

const smallLoadingMove = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(60px);
  }
`;

const SmallLoadingContainer = styled(MediumLoadingContainer)`
  .loading {
    width: 4rem;
    height: 2rem;
  }

  .loading div:first-child {
    left: -1rem;
    animation: ${smallLoadingMove} 1.5s infinite linear, ${loadingFade} 1.5s infinite;
    animation-delay: 0.15s;
  }

  .loading div:first-child + div,
  .loading div:first-child + div + div {
    content: ' ';
    animation: ${smallLoadingMove} 1.5s infinite linear, ${loadingFadeBefore} 1.5s infinite;
  }

  .loading div:first-child + div {
    left: -1.5rem;
    animation-delay: 0.3s;
  }

  .loading div:first-child + div + div {
    left: -0.5rem;
    animation-delay: 0s;
  }
`;

// COMPONENT
// =========================

export const Loader = () => (
  <LoadingContainer id="pageLoader">
    <div className="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </LoadingContainer>
);

export const MediumLoader = () => (
  <MediumLoadingContainer id="mediumLoader">
    <div className="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </MediumLoadingContainer>
);

export const SmallLoader = () => (
  <SmallLoadingContainer id="smallLoader">
    <div className="loading">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </SmallLoadingContainer>
);
