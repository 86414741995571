import { Constants } from 'utils/Constants';
import { useBreakpoints } from 'hooks/useBreakpoints';

export const ImageSize = () => {
  const { device } = useBreakpoints();
  switch (device) {
    case Constants.DEVICE_XSMALL:
      return 600;
    case Constants.DEVICE_SMALL:
      return 768;
    case Constants.DEVICE_MEDIUM:
    case Constants.DEVICE_LARGE:
    case Constants.DEVICE_XLARGE:
      return 990;
    default:
      return 1200;
  }
};
