import React from 'react';
import styled from 'styled-components';

type VideoProps = {
  url: string;
  height: number;
};

type StyleProps = {
  height: number;
};

const VideoContainer = styled.video`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: ${(props) => `${props.height}rem`};
`;

export const Video = ({ url, height }: VideoProps): JSX.Element => (
  <VideoContainer controls height={height}>
    <source src={url} type="video/mp4" />
    <source src={url} type="video/mkv" />
  </VideoContainer>
);
