import React, { KeyboardEvent, useRef } from 'react';
import styled from 'styled-components';
import { MenuLinksType } from 'types/CmsTypes';
import { LinkList } from 'components/new-design/lists/LinkList';
import { ExpandChevronBtn } from 'elements/new-design/buttons/expand-buttons';

type SmallHamburgerMenuProps = {
  faqLinks: MenuLinksType[];
  currentColumn: number;
  expanded: string;
  headerHeight: string;
  onToggleMenu: (id: string) => void;
  onToggleSubmenu: (index: number) => void;
  onRowClick: (parentId: string, id: string) => void;
  onTabKey?: (e: KeyboardEvent<HTMLUListElement>) => void;
};

type StyleProps = {
  headerHeight: string;
};

const ExpandedMenu = styled.ul`
  ${(_: StyleProps) => ''}
  width: 100%;
  position: fixed;
  top: ${(props) => props.headerHeight};
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 2.4rem;
  overflow-x: auto;
  list-style: none;
  background: ${(props) => props.theme.newcolors.bgPrimary};
`;

const LinkCategory = styled.li`
  margin-top: 1.6rem;
  li:nth-of-type(1) {
    margin-top: 1.2rem;
  }
`;

export const SmallHamburgerMenu = ({ ...props }: SmallHamburgerMenuProps) => {
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  return (
    <ExpandedMenu role="menu" headerHeight={props.headerHeight}>
      {props.faqLinks.length !== 0 &&
        props.faqLinks.map((link, index) => {
          return (
            <div key={index} onClick={() => props.onToggleSubmenu(index)}>
              <LinkCategory>
                <ExpandChevronBtn
                  small
                  ref={(ref) => (buttonRefs.current[index] = ref)}
                  text={link.title}
                  expanded={props.currentColumn === index}
                  popupType="menu"
                  onButtonClick={() => props.onToggleSubmenu(index)}
                />

                {props.currentColumn === index && (
                  <LinkList
                    link={link}
                    expanded={props.expanded}
                    belongsToColumn={index}
                    activeColumn={props.currentColumn}
                    onToggleMenu={props.onToggleMenu}
                    onRowClick={props.onRowClick}
                    onTabKey={props.onTabKey}
                  />
                )}
              </LinkCategory>
            </div>
          );
        })}
    </ExpandedMenu>
  );
};
