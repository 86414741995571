import React from 'react';
import styled from 'styled-components';
import { isWebview } from 'utils/Helpers';
import { SmallContainer } from './LayoutContainers';
import { BreadCrumbs } from 'components/new-design/breadcrumbs/BreadCrumbs';
import { PageTitleLarge } from 'elements/new-design/Typography';
import { Loader } from 'elements/loader/Loader';
import { FlexColumn } from 'elements/containers/Containers';

type BreadcrumbsPageProps = {
  title?: string;
  loading: boolean;
  hideBreadCrumbs?: boolean;
  children: JSX.Element | JSX.Element[];
};

export const PageContainer = styled(FlexColumn)`
  width: 100%;
`;

export const BreadcrumbsPage = ({ title, loading, hideBreadCrumbs, children }: BreadcrumbsPageProps) => {
  return (
    <PageContainer>
      <SmallContainer>
        {!isWebview() && !hideBreadCrumbs && <BreadCrumbs />}
        {title ? <PageTitleLarge>{title}</PageTitleLarge> : null}
        {loading && <Loader />}
      </SmallContainer>
      {children}
    </PageContainer>
  );
};
