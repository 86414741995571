import { css } from 'styled-components';
import DMSansBoldWoff2 from 'assets/fonts/DMSans-Bold.woff2';
import DMSansBoldWoff from 'assets/fonts/DMSans-Bold.woff';
import DMSansMediumWoff2 from 'assets/fonts/DMSans-Medium.woff';
import DMSansMediumWoff from 'assets/fonts/DMSans-Medium.woff2';
import DMSansRegularWoff2 from 'assets/fonts/DMSans-Regular.woff2';
import DMSansRegularWoff from 'assets/fonts/DMSans-Regular.woff';
import DMSansItalicWoff2 from 'assets/fonts/DMSans-Italic.woff2';
import DMSansItalicWoff from 'assets/fonts/DMSans-Italic.woff';

export const fontGlobals = css`
  @font-face {
    font-family: 'dm_sansbold';
    src: url(${DMSansBoldWoff2}) format('woff2'), url(${DMSansBoldWoff}) format('woff');
    font-style: normal;
  }

  @font-face {
    font-family: 'dm_sansitalic';
    src: url(${DMSansItalicWoff2}) format('woff2'), url(${DMSansItalicWoff}) format('woff');
    font-style: normal;
  }

  @font-face {
    font-family: 'dm_sansmedium';
    src: url(${DMSansMediumWoff2}) format('woff2'), url(${DMSansMediumWoff}) format('woff');
    font-style: normal;
  }

  @font-face {
    font-family: 'dm_sansregular';
    src: url(${DMSansRegularWoff2}) format('woff2'), url(${DMSansRegularWoff}) format('woff');
    font-style: normal;
  }
`;

const fontSize = {
  mega: '7.5rem',
  XLarge: '3.8rem',
  large: '2.5rem',
  medium: '2.2rem',
  default: '1.7rem',
  small: '1.5rem',
  XSmall: '1.2rem',
};

const CMSFontSize = {
  titleMega: '6rem',
  titleXlarge: '5rem',
  titleLarge: '4rem',
  titleMedium: '3.6rem',
  titleDefault: '3.2rem',
  titleSmall: '2.8rem',
  titleTiny: '2.4rem',
  linkTextDefault: '2.2rem',
  linkText: '2rem',
  linkTextSmall: '1.8rem',
  figCaption: '1.6rem',
  figCaptionSmall: '1.4rem',
  figCopyRight: '1.2rem',
  figCopyRightSmall: '1rem',
};

const CMSLineHeight = {
  titleXXMega: '7rem',
  titleXmega: '6rem',
  titleMega: '5.6rem',
  titleXlarge: '4.8rem',
  titleLarge: '4rem',
  titleMedium: '3.6rem',
  titleDefault: '3.4rem',
  titleSmall: '3.2rem',
  titleTiny: '2.4rem',
  linkText: '3rem',
  linkTextSmall: '2.6rem',
  figCaption: '2.6rem',
  figCaptionSmall: '2.4rem',
  figCopyRight: '2rem',
  figCopyRightSmall: '1.8rem',
};

const fontFamily = {
  bold: 'dm_sansbold',
  demiBold: 'dm_sansmedium',
  medium: 'dm_sansmedium',
  regular: 'dm_sansregular',
  italic: 'dm_sansitalic',
};

export default {
  fontFamily,
  fontSize,
  CMSFontSize,
  CMSLineHeight,
};
