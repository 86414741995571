import React, { useState } from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { GOADate } from 'utils/date/GOADate';
import { AssistanceLocationType } from 'types/LocationTypes';
import { TicketDistributionType, TransactionOrderLineType } from 'types/HistoryTypes';
import { AssistanceDialog } from './AssistanceDialog';
import { AssistanceIsBooked } from './AssistanceIsBooked';
import { DialogCustomerService } from 'components/new-design/dialogs/DialogCustomerService';
import { TxtSmallBoldAccentResp } from 'elements/new-design/Typography';
import { ButtonBorderPrimary } from 'elements/buttons/Buttons';

type AssistanceProps = {
  orderLine: TransactionOrderLineType;
  ticket: TicketDistributionType;
  departureTime: GOADate;
  deadlineBookAssistance: number;
  assistanceLocations: AssistanceLocationType[];
  onBookedAssistance: () => void;
};

export const Assistance = ({
  orderLine,
  ticket,
  departureTime,
  deadlineBookAssistance,
  assistanceLocations,
  onBookedAssistance,
}: AssistanceProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { translate } = useI18n();
  const needsCustomerService = new GOADate().addHours(deadlineBookAssistance).isAfter(departureTime);

  const handleClose = (caseNumber: string) => {
    setModalOpen(false);
    if (caseNumber) onBookedAssistance();
  };

  return (
    <>
      {!ticket.assistanceReference && (
        <ButtonBorderPrimary onClick={() => setModalOpen(!modalOpen)}>
          <TxtSmallBoldAccentResp>{translate('BOOK_ASSISTANCE')}</TxtSmallBoldAccentResp>
        </ButtonBorderPrimary>
      )}

      {ticket.assistanceReference && <AssistanceIsBooked referenceNumber={ticket.assistanceReference} />}

      {!needsCustomerService && modalOpen && (
        <AssistanceDialog
          orderLine={orderLine}
          ticket={ticket}
          assistanceLocations={assistanceLocations}
          onClose={handleClose}
        />
      )}
      {needsCustomerService && modalOpen && (
        <DialogCustomerService text={translate('ASSISTANCE_TOO_LATE')} onModalClose={() => setModalOpen(false)} />
      )}
    </>
  );
};
