import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { UserType } from 'types/CustomerTypes';
import { initialUserState } from 'store/user/userReducer';
import { useUpdateState } from './useUpdateState';
import { AppState } from 'store/index';
import { usePersistentStorage } from './usePersistentStorage';
import { Customer_RefreshToken } from 'apiV1/Customer';
import { GOADate } from 'utils/date/GOADate';
import { Constants } from 'utils/Constants';
import { replaceTypesInDataStructure } from 'utils/Helpers';
import { isProtectedRoute, isPurchaseFlowTokenRoute } from 'utils/Navigation';
import { useStateNavigation } from './useStateNavigation';
import { Config } from 'utils/Config';

// Helper functions
// =========================

const cacheUser = (user: UserType): void => {
  if (user.keep) window.localStorage.setItem(Constants.CACHE_USER, JSON.stringify(user));
  else window.sessionStorage.setItem(Constants.CACHE_USER, JSON.stringify(user));
};

const getCachedUser = (): UserType => {
  const user = window.localStorage.getItem(Constants.CACHE_USER) || window.sessionStorage.getItem(Constants.CACHE_USER);

  if (user) {
    return replaceTypesInDataStructure(JSON.parse(user));
  }
  return null;
};

const removeUserFromCache = (): void => {
  window.localStorage.removeItem(Constants.CACHE_USER);
  window.sessionStorage.removeItem(Constants.CACHE_USER);
};

const isAccountUser = (): boolean => {
  return getCachedUser() && getCachedUser().account;
};

export const isValidToken = (token: string, expiration: GOADate): boolean => {
  return Boolean(token) && new GOADate().isBefore(expiration);
};

const getCachedToken = (): string => {
  if (getCachedUser()) return getCachedUser().token;
  return null;
};

const getExpirationDate = (token: string): GOADate => {
  const parsedToken = JSON.parse(window.atob(token.split('.')[1]));
  return new GOADate(parsedToken.exp * 1000);
};

const parseUser = (user: UserType): UserType => {
  return {
    firstName: user.firstName,
    surname: user.surname,
    email: user.email,
    account: user.account,
    keep: user.keep,
    tokenGoOps: user.tokenGoOps,
    token: user.token,
    refreshTokenGoOps: user.refreshTokenGoOps,
    refreshTokenExpirationGoOps: user.refreshTokenExpirationGoOps,
    refreshToken: user.refreshToken,
    refreshTokenExpiration: user.refreshTokenExpiration,
    expiration: getExpirationDate(user.token),
    countryCode: user.countryCode ? user.countryCode : '+47',
    nationality: user.nationality,
    telephoneNumberNoCountryCode: user.telephoneNumberNoCountryCode,
    consents: user.consents,
    customerEntitlements: user.customerEntitlements ? user.customerEntitlements : [],
  };
};

// Hook component
// =========================

export const useAuthentication = () => {
  const user = useSelector((state: AppState) => state.user);
  const { setUserState, setDialog } = useUpdateState();
  const { deleteCachedProfileForms } = usePersistentStorage();
  const { navigateWithState } = useStateNavigation();
  const isAuthenticated = (): boolean => isAccountUser();
  const getToken = (): string => getCachedToken();
  const getUser = (): UserType => getCachedUser();

  useEffect(() => {
    if (
      window.location.pathname !== Constants.ROUTE_REVIEW &&
      window.location.pathname !== Constants.ROUTE_PAYMENT &&
      window.location.pathname !== Constants.ROUTE_NETS_REDIRECT &&
      window.location.pathname !== Constants.ROUTE_VIPPS_REDIRECT
    ) {
      deleteTempUser();
    }
  }, [window.location.pathname]);

  const updateUser = (data: UserType): void => {
    const updated = parseUser(Object.assign({}, user, data));
    setUserState(updated);
    cacheUser(updated);
  };

  const loginTempUser = (token: string): void => {
    const userObj = {
      account: false,
      token: token,
      expiration: getExpirationDate(token),
    };
    updateUser(userObj);
  };

  const loginAccountUser = (userData: UserType): void => {
    userData.account = true;
    updateUser(parseUser(userData));
    deleteCachedProfileForms();
  };

  const deleteTempUser = (): void => {
    const cachedUser = getCachedUser();
    if (!cachedUser?.account) {
      setUserState(initialUserState);
      removeUserFromCache();
    }
  };

  const logoutUser = (): void => {
    setUserState(initialUserState);
    removeUserFromCache();
    if (isProtectedRoute(window.location.pathname) || isPurchaseFlowTokenRoute(window.location.pathname)) {
      navigateWithState(Constants.ROUTE_BASE);
    }
  };

  const updateToken = async (): Promise<void> => {
    const cachedUser = getCachedUser();
    try {
      const { data } = await Customer_RefreshToken({ token: cachedUser.token, refreshToken: cachedUser.refreshToken });
      const { data: goOpsData } = await axios.post(`${Config().goOpsApi}/customer/refresh-token`, {
        token: cachedUser.tokenGoOps,
        refreshToken: cachedUser.refreshTokenGoOps,
      });
      const updatedUser = Object.assign({}, cachedUser, {
        tokenGoOps: goOpsData.result.token,
        token: data.result.token,
        expiration: getExpirationDate(data.result.token),
        refreshTokenGoOps: goOpsData.result.refreshToken,
        refreshTokenExpirationGoOps: goOpsData.result.refreshTokenExpiration,
        refreshToken: data.result.refreshToken,
        refreshTokenExpiration: data.result.refreshTokenExpiration,
      });
      updateUser(updatedUser);
    } catch (error) {
      setDialog({ name: 'LOGIN', color: 'transparent', scroll: true });
      logoutUser();
    }
  };

  const handleUserState = (): void => {
    const cachedUser = getCachedUser();
    if (cachedUser) {
      if (cachedUser.account) {
        const hasValidToken = isValidToken(cachedUser.refreshToken, cachedUser.refreshTokenExpiration);
        if (hasValidToken) {
          loginAccountUser(cachedUser);
          updateToken();
        } else {
          logoutUser();
        }
      } else {
        loginTempUser(cachedUser.token);
      }
    }
  };

  return {
    deleteTempUser,
    loginTempUser,
    loginAccountUser,
    logoutUser,
    updateToken,
    getToken,
    getUser,
    isAuthenticated,
    handleUserState,
    updateUser,
  };
};
