import React, { useState, useEffect, Suspense, createRef } from 'react';
import styled from 'styled-components';
import { isWebview } from 'utils/Helpers';
import { isEscapeKey } from 'utils/Accessability';
import { AppState } from 'store/index';
import { useUpdateState } from 'hooks/useUpdateState';
import { useHeaderHeight } from 'hooks/useHeaderHeight';
import { useRoutes } from 'hookrouter';
import { useSelector } from 'react-redux';
import { isPurchaseFlowRoute } from 'utils/Navigation';
import { MessagesActionTypes } from 'types/MessagesTypes';
import { Chat } from 'components/chat/Chat';
import { Login } from 'components/new-design/dialogs/login-dialog/Login';
import { Header } from 'components/new-design/header/Header';
import { Footer } from 'components/new-design/footer/Footer';
import { CookieConsent } from 'components/new-design/cookie-consent/CookieConsent';
import { SectionPageMessages } from 'components/section-page-messages/SectionPageMessages';
import { HeaderWebview } from 'components/new-design/header/HeaderWebView';
import { HiddenSpan } from 'elements/new-design/Typography';
import { SkipToMainLink } from 'components/new-design/skiplink/SkipToMainLink';
import NotFoundPage from 'pages/404/NotFoundPage';

type AppProps = {
  headerLinks: any;
  footerLinks: any;
  routes: any;
  cookieData: any;
  onRouteChangeFocusRef: React.RefObject<HTMLElement>;
};

type StyleProps = {
  orderValid?: boolean;
  hasOrderProgressBar?: boolean;
  hasMessages?: boolean;
  color?: string;
  headerHeight?: string;
};

const AppContainer = styled.div<StyleProps>`
  ${(_: StyleProps) => ''}
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${(props) => `${props.headerHeight} 1fr`};
  grid-template-areas: 'header' 'backdropScrollArea';
  justify-content: center;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${(props) => props.theme.newcolors.bgPrimary};
  scroll-behavior: smooth;
`;

const AppHeader = styled.header`
  grid-area: header;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.newconstants.zIndexHigh};
  background: ${(props) => props.theme.newcolors.bgPrimary};
  @media ${(props) => props.theme.breakpoints.xlarge} {
    position: relative;
  }
`;

const AppMain = styled.main<StyleProps>`
  ${(_: StyleProps) => ''}
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70rem;
  margin-top: 2.4rem;
  padding-bottom: ${(props) => (props.orderValid && props.hasOrderProgressBar ? '12rem' : '0')};

  @media ${(props) => props.theme.breakpoints.large} {
    margin-top: 3.2rem;
  }

  @media ${(props) => props.theme.breakpoints.xlarge} {
    padding-bottom: ${(props) => (props.orderValid && props.hasOrderProgressBar ? '6rem' : '0')};
  }
`;

const AppFooter = styled.footer`
  width: 100vw;
  margin-top: 8.8rem;

  @media (min-width: 900px) {
    margin-top: 12.8rem;
  }

  @media (min-width: 1281px) {
    margin-top: 21rem;
  }
`;

const BackdropScroll = styled.div`
  ${(_: StyleProps) => ''}
  width: 100vw;
  height: 100%;
  grid-area: backdropScrollArea;
  z-index: ${(props) => props.theme.newconstants.zIndexLow};
  background: ${(props) => (props.color ? props.color : props.theme.newcolors.backdrop)};
`;

const BackdropScrollArea = styled.div`
  width: 100vw;
  grid-area: backdropScrollArea;
`;

const isWebviewRequest = isWebview();

export const App = ({ ...props }: AppProps) => {
  const { removePageMessage, setDialog, removeAllPageMessages } = useUpdateState();
  const { headerHeight } = useHeaderHeight();
  const { orderValid, currentDialog, pageTitle } = useSelector((store: AppState) => store.global);
  const [initialrequest, setInitialRequest] = useState(true);
  const mainSectionRef = createRef<HTMLDivElement>();
  const messages = useSelector((store: AppState) => store.messages);
  const isTicketSolutionRoute = isPurchaseFlowRoute(window.location.pathname);
  const route = useRoutes(props.routes);

  const closeMessage = (index: number): MessagesActionTypes => removePageMessage(index);
  const handleCloseDialog = () => setDialog(undefined);

  useEffect(() => {
    //remove page messages on routing but not on application fresh load
    //(when coming through preloader) Systemmessages needs to be displayed
    if (initialrequest) setInitialRequest(false);
    else removeAllPageMessages();
  }, [window.location.pathname]);

  return (
    <AppContainer
      id="appContainer"
      hasMessages={messages.length > 0}
      headerHeight={headerHeight.headerH}
      onKeyDown={(e) => (isEscapeKey(e.key) && currentDialog?.scroll ? handleCloseDialog() : null)}
    >
      {currentDialog?.scroll && <BackdropScroll onClick={handleCloseDialog} color={currentDialog.color} />}
      {currentDialog?.name === 'LOGIN' ? (
        <Login
          showCreate={true}
          redirectTo={currentDialog.redirectTo}
          onLogin={handleCloseDialog}
          onModalClose={handleCloseDialog}
        />
      ) : null}
      <HiddenSpan ref={props.onRouteChangeFocusRef} tabIndex={-1} aria-label={pageTitle}>
        {window.document.title}
      </HiddenSpan>
      <AppHeader>
        <SkipToMainLink height={headerHeight.skipLinkH} onSkipToMain={() => mainSectionRef.current?.focus()} />
        {isWebviewRequest ? <HeaderWebview /> : <Header faqLinks={props.headerLinks} />}
      </AppHeader>
      <Chat />
      <BackdropScrollArea>
        <AppMain
          ref={mainSectionRef}
          tabIndex={-1}
          orderValid={orderValid}
          hasOrderProgressBar={isTicketSolutionRoute || (!isTicketSolutionRoute && isWebviewRequest)}
        >
          {messages.length !== 0 && <SectionPageMessages messages={messages} onCloseMessage={closeMessage} />}
          <Suspense fallback={''}>{route ? route : <NotFoundPage />}</Suspense>
        </AppMain>
        {route && !isTicketSolutionRoute && !isWebviewRequest && (
          <AppFooter>
            <Footer links={props.footerLinks} />
          </AppFooter>
        )}
      </BackdropScrollArea>
      {props.cookieData && <CookieConsent cookieSection={props.cookieData} />}
    </AppContainer>
  );
};
