import React from 'react';
import styled from 'styled-components';
import CalendarIcon from 'assets/svg/calendar.svg';
import { useI18n } from 'utils/i18n/usei18n';
import { BtnExpandDark } from 'elements/buttons/Buttons';
import { Calendar } from './Calendar';
import { HiddenSpan, TxtDefaultBoldDarkResp } from 'elements/new-design/Typography';
import { Icon } from 'elements/icon/Icon';
import { GOADate } from 'utils/date/GOADate';

type DatePickerProps = {
  name: string;
  date: GOADate;
  minDate: GOADate;
  maxDate: GOADate;
  device: any;
  expanded: boolean;
  onToggle: () => void;
  handleDateChanged: (d: GOADate) => void;
};

const DateTimeToggle = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  background: ${(props) => props.theme.colors.bgSecondary};
  cursor: pointer;
  &:focus {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

const DateTimeWrapper = styled.div`
  position: relative;
  border: ${(props) => props.theme.constants.borderPrimary};
`;

const DateWrapper = styled(DateTimeWrapper)`
  width: 62%;
  border-right: none;

  &:focus-within {
    z-index: ${(props) => props.theme.constants.zIndexMega};
  }

  @media ${(props) => props.theme.devices.medium} {
    width: 70%;
  }
`;

const TypeIcon = styled(Icon)`
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const DateToggleText = styled(TxtDefaultBoldDarkResp)`
  flex: 1;
  text-align: left;
`;
export const DatePicker = React.forwardRef(
  ({ ...props }: DatePickerProps, ref: React.Ref<HTMLButtonElement>): JSX.Element => {
    const { date, name, expanded, minDate, maxDate, device, onToggle, handleDateChanged } = props;
    const { getI18nDate } = useI18n();
    return (
      <>
        <DateWrapper>
          <DateTimeToggle ref={ref} id={`${name}ToggleDate`} type="button" name={name} tabIndex={0} onClick={onToggle}>
            <TypeIcon icon={CalendarIcon} />
            <HiddenSpan>{date.formatFullDateShortMonth()}</HiddenSpan>
            <DateToggleText aria-hidden={true}>{getI18nDate(date).formatDate(device)}</DateToggleText>
            <BtnExpandDark expanded={expanded} />
          </DateTimeToggle>
        </DateWrapper>

        {expanded && (
          <Calendar
            name={name}
            date={getI18nDate(date)}
            minDate={minDate}
            maxDate={maxDate}
            onDateChanged={handleDateChanged}
          />
        )}
      </>
    );
  },
);
