import { Order } from 'types/OrderTypes';
import { Constants } from 'utils/Constants';
import { ProductType, JourneyLegType } from 'types/JourneyTypes';
import { SeatMap_GetAvailableSeats } from 'apiV1/SeatMap';
import { SeatReservation_ReserveSpecificSeats } from 'apiV1/SeatReservation';

export const useSeatingApi = (token: string, device: string, order: Order) => {
  const getAvailableSeats = async (data: { leg: JourneyLegType; product: ProductType }) => {
    const options = {
      authorization: `Bearer ${token}`,
      imageFormat: 'SVG',
    };
    return await SeatMap_GetAvailableSeats(
      order.orderID,
      data.leg.offerDatedServiceJourneyId,
      data.leg.fromId,
      data.leg.toId,
      data.product.seatType,
      data.leg.trainNumber,
      device === Constants.DEVICE_SMALL ? 300 : 200,
      options,
    );
  };

  const reserveSpecificSeats = async (args) =>
    await SeatReservation_ReserveSpecificSeats(order.orderID, args, {
      authorization: `Bearer ${token}`,
    });

  return {
    getAvailableSeats,
    reserveSpecificSeats,
  };
};
