import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { Constants } from 'utils/Constants';
import { Dialog } from './Dialog';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';
import { DialogTitle, TxtDefaultRegularDarkResp } from 'elements/new-design/Typography';
import { MarginBottom } from 'elements/distance/Margins';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';

type DialogPromptLoginProps = {
  headline: string;
  text: string;
  returnToPath: string;
  onLogin: () => void;
  onModalClose: () => void;
};

const ActionButtonContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 1fr 1fr;
  }
`;

export const DialogPromptLogin = ({ headline, text, returnToPath, onLogin, onModalClose }: DialogPromptLoginProps) => {
  const { cacheItem } = usePersistentStorage();
  const { navigateWithState } = useStateNavigation();
  const { translate } = useI18n();

  const goToCreateProfile = () => {
    cacheItem(Constants.CACHE_REDIRECT, returnToPath);
    navigateWithState(Constants.ROUTE_CREATE_PROFILE);
  };

  return (
    <Dialog type="center" title={headline} width={42} height={40} onModalClose={onModalClose}>
      <DialogCenterContentContainer>
        <DialogTitle>{headline}</DialogTitle>
        <p>
          <TxtDefaultRegularDarkResp>{text}</TxtDefaultRegularDarkResp>
        </p>
        <MarginBottom margin={4} />
        <ActionButtonContainer>
          <BorderButtonDark big onClick={onLogin}>
            {translate('LOG_IN')}
          </BorderButtonDark>
          <BorderButtonDark big onClick={goToCreateProfile}>
            {translate('NEW_USER')}
          </BorderButtonDark>
        </ActionButtonContainer>
      </DialogCenterContentContainer>
    </Dialog>
  );
};
