import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { TxtSmallBoldDarkResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';
import { MarginTop } from 'elements/distance/Margins';
import { ExternalLink } from 'elements/links/Links';

export const AssistancePersonalIntegrity = () => {
  const { translate } = useI18n();
  return (
    <MarginTop margin={0.8}>
      <h3>
        <TxtSmallBoldDarkResp>{translate('HANDLING_PERSONAL_INFO')}</TxtSmallBoldDarkResp>
      </h3>
      <p>
        <TxtSmallMediumDarkResp>{translate('LEGAL_LIABILITY_ASSISTANCE_1')}</TxtSmallMediumDarkResp>
      </p>
      <br />
      <br />
      <p>
        <TxtSmallMediumDarkResp>{translate('LEGAL_LIABILITY_ASSISTANCE_2')}</TxtSmallMediumDarkResp>
      </p>
      <br />
      <br />
      <p>
        <TxtSmallMediumDarkResp>{translate('LEGAL_LIABILITY_ASSISTANCE_3')}</TxtSmallMediumDarkResp>{' '}
        <ExternalLink
          text={translate('PERSONAL_INTEGRITY_DECLARATION')}
          url="https://www.banenor.no/verktoy/personvernerklaring"
        />
      </p>
    </MarginTop>
  );
};
