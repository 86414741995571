import styled, { css } from 'styled-components';
import { fontStyleParagraph } from 'elements/new-design/Typography';

const SharedStyle = css`
  li {
    ${fontStyleParagraph}
    margin-left: 1.8rem;
    margin-top: 1rem;

    @media ${(props) => props.theme.breakpoints.large} {
      margin-top: 1.3rem;
    }
  }
`;

export const UnorderedList = styled.ul`
  list-style: disc;
  ${SharedStyle}
`;

export const NumberedList = styled.ol`
  ${SharedStyle}
`;

export const StyledListItem = styled.li`
  ${fontStyleParagraph}
`;
