import React from 'react';
import styled from 'styled-components';
import { Constants } from 'utils/Constants';
import { DialogTitle, TxtDefaultRegularDarkResp } from 'elements/new-design/Typography';
import { MessageBox } from 'elements/new-design/messages/MessageBox';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';

type SectionMessageProps = {
  type: string;
  title: string;
  text?: string;
  buttonText?: string;
  children?: JSX.Element | JSX.Element[];
  onButtonClick?: () => void;
};

type StyleProps = {
  type: string;
};

const ContentContainer = styled.div`
  padding: 1.6rem;
`;

const Title = styled(DialogTitle)`
  ${(_: StyleProps) => ''}
  margin-bottom: 0;
  color: ${(props) =>
    props.type === Constants.ERROR_MESSAGE ? props.theme.newcolors.txtColError : props.theme.newcolors.txtColDark};
`;

const Text = styled.p`
  margin-top: 1.6rem;
  @media ${(props) => props.theme.breakpoints.large} {
    max-width: 80%;
  }
`;

const ActionButton = styled(BorderButtonDark)`
  flex-shrink: 0;
  margin-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.large} {
    margin-top: 0;
  }
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.large} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const SectionMessage = ({ ...props }: SectionMessageProps) => (
  <div role="log">
    <MessageBox type={props.type}>
      <ContentContainer>
        <Title type={props.type}>{props.title}</Title>
        {props.text && (
          <DescriptionSection>
            <Text>
              <TxtDefaultRegularDarkResp>{props.text}</TxtDefaultRegularDarkResp>
            </Text>
            {props.buttonText && <ActionButton onClick={props.onButtonClick}>{props.buttonText}</ActionButton>}
          </DescriptionSection>
        )}

        {props.children && props.children}
      </ContentContainer>
    </MessageBox>
  </div>
);
