import React from 'react';
import styled from 'styled-components';
import {
  StopPlaceName,
  handleGoingToBaneNor,
  StopPlaceNameContainer,
  AbsoluteHorizontalBorder,
  MapRow,
} from './RealTimeViewUtils';
import { RTVStation, RTVTrain } from 'types/RTVTypes';

type Props = {
  locations: RTVStation[];
  trainsToDisplay: RTVTrain[];
  handleDisplayingTrains: (trainsToDisplay: RTVTrain[]) => any;
  isSmallScreen: boolean;
};

type StyleProps = {
  isSmallScreen: boolean;
};

const ArendalContainer = styled.div<StyleProps>`
  display: flex;
  left: -7rem;
  top: 0rem;
  padding: 0rem 1rem 1rem 1rem;
  flex-direction: column;

  position: ${(props) => (props.isSmallScreen ? 'unset' : 'absolute')};
  margin-left: ${(props) => (props.isSmallScreen ? '9rem' : '0rem')};

  @media ${(props) => props.theme.breakpoints.small} {
    margin-left: ${(props) => (props.isSmallScreen ? '17rem' : '0rem')};
  }
  @media ${(props) => props.theme.breakpoints.large} {
    left: -15rem;
  }
`;

const ArendalLineStopPlaceNameContainer = styled(StopPlaceNameContainer)`
  width: 7rem;
`;

const ArendalLineAbsoluteHorizontalBorder = styled(AbsoluteHorizontalBorder)`
  left: 9rem;
  height: 92%;
  bottom: 2rem;
`;

const HorizontalTrain = ({ locationName, locationId, isBig, handleDisplayingTrains, trainsToDisplay }: any) => (
  <MapRow>
    <ArendalLineStopPlaceNameContainer>
      <StopPlaceName id={locationName} isBig={isBig} onClick={() => handleGoingToBaneNor(locationId)}>
        {locationName}
      </StopPlaceName>
    </ArendalLineStopPlaceNameContainer>
    {handleDisplayingTrains(trainsToDisplay.filter((train) => train.position === locationId))}
  </MapRow>
);

const ArendalLine = ({ locations, trainsToDisplay, handleDisplayingTrains, isSmallScreen }: Props) => (
  <ArendalContainer isSmallScreen={isSmallScreen}>
    {!isSmallScreen && <ArendalLineAbsoluteHorizontalBorder />}

    {locations.map((station: RTVStation, index) => (
      <HorizontalTrain
        key={index}
        locationName={station.locationName}
        locationId={station.locationId}
        isBig={station.isBig}
        handleDisplayingTrains={handleDisplayingTrains}
        trainsToDisplay={trainsToDisplay}
      />
    ))}
  </ArendalContainer>
);

export default ArendalLine;
