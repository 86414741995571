import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ErrorIcon from 'assets/svg/exclamation-mark-white.svg';
import { useI18n } from 'utils/i18n/usei18n';
import { useApi } from 'hooks/use-api/useApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { TransactionType, TransactionOrderLineType } from 'types/HistoryTypes';
import { Constants } from 'utils/Constants';
import { DepartureInfo } from './components/DepartureInfo';
import { SendReciept } from './components/SendReciept';
import { CancelledMessage } from './components/OrderlineMessages';
import { ExternalCampaigns } from './components/ExternalCampaigns';
import { Loader } from 'elements/loader/Loader';
import { SectionErrorMessage } from 'elements/messages/SectionMessage';
import { PassengerInfo } from './components/PassengerInfo';
import { TicketInfo } from './components/TicketInfo';
import { FlexColumnNoCenter } from 'elements/containers/Containers';

export type TicketDetailsProps = {
  transaction: TransactionType;
  expired: boolean;
  onCancel: (transaction: TransactionType) => void;
};

type StyleProps = {
  expired?: boolean;
  isCancelled?: boolean;
  last?: boolean;
};

const TransactionContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  border-spacing: 0;
  list-style: none;
  background-color: ${(props) => (props.expired ? props.theme.colors.expired : props.theme.colors.bgSecondary)};
`;

const OrderlineMessage = styled.section`
  padding: 1rem 0.8rem;
`;

const DetailedJourneyType = styled(FlexColumnNoCenter)`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: ${(props) => (props.last ? 'none' : props.theme.constants.borderThickPrimary)};
  opacity: ${(props) => (props.isCancelled ? '0.5' : '1')};

  @media ${(props) => props.theme.devices.large} {
    flex-direction: row;
    border-bottom: ${(props) => (props.last ? 'none' : props.theme.constants.borderPrimary)};
  }
`;

const RowCell = styled.div`
  flex: 1;
  margin: 1rem 0;
  padding: 0 2rem;

  @media ${(props) => props.theme.devices.large} {
    padding: 0 2.4rem;
  }
`;

const BorderRightCell = styled(RowCell)`
  border-bottom: ${(props) => props.theme.constants.borderPrimary};
  padding-bottom: 1rem;

  @media ${(props) => props.theme.devices.large} {
    border-bottom: 0;
    padding-bottom: 0;
    border-right: ${(props) => props.theme.constants.borderPrimary};
  }
`;

const LoadAndErrorContainer = styled.div`
  ${(_: StyleProps) => ''}
  background-color: ${(props) => (props.expired ? props.theme.colors.warning : props.theme.colors.bgSecondary)};
  padding: 2rem 0 2rem 0;

  @media ${(props) => props.theme.devices.medium} {
    padding: 2rem;
  }
`;

const TransactionState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const Transaction = ({ transaction, expired, onCancel }: TicketDetailsProps) => {
  const locations = useSelector((state: AppState) => state.locations);
  const [orderLines, setOrderLines] = useState(undefined);
  const [receiptSent, setReceiptSent] = useState(false);
  const { guardedRequest, API_CALLS } = useApi();
  const { translate } = useI18n();
  const [state, setState] = useState<typeof TransactionState>(Constants.LOADING);
  let mounted = null;
  const fetchDetailsHistory = async (orderId: string) => {
    try {
      return await guardedRequest(API_CALLS.GET_ORDER_BY_ID, orderId);
    } catch (err) {
      setState(Constants.ERROR);
    }
  };

  const fetchTransactionById = async (orderId: string) => {
    try {
      const { data } = await guardedRequest(API_CALLS.GET_TRANSACTION_BY_ID, orderId);
      onCancel(data.result);
    } catch (err) {
      setState(Constants.ERROR);
    }
  };

  const handleReceipt = () => {
    setReceiptSent(true);
    return guardedRequest(API_CALLS.SEND_RECEIPT, transaction.orderId);
  };

  const handleRefetchTransaction = async () => {
    setState(Constants.LOADING);
    const { data } = await fetchDetailsHistory(transaction.orderId);
    await fetchTransactionById(transaction.orderId);

    setOrderLines(data.result.items);
    setState(Constants.IDLE);
    document.getElementById(transaction.orderId).scrollIntoView();
  };

  useEffect(() => {
    mounted = true;
    async function init() {
      const { data } = await fetchDetailsHistory(transaction.orderId);
      if (mounted) {
        setOrderLines(data.result.items);
        setState(Constants.IDLE);
      }
    }
    init();

    return () => {
      mounted = false;
    };
  }, [transaction.orderId]);

  return (
    <>
      {(state === Constants.LOADING || state === Constants.ERROR) && (
        <LoadAndErrorContainer expired={expired}>
          {state === Constants.LOADING && <Loader />}

          {state === Constants.ERROR && (
            <SectionErrorMessage
              type={Constants.ERROR_MESSAGE}
              icon={ErrorIcon}
              headline={translate('GENERAL_ERROR_HEADLINE')}
              text={translate('TICKET_DETAILS_ERROR_TEXT')}
              customerSupport={true}
            />
          )}
        </LoadAndErrorContainer>
      )}

      {state === Constants.IDLE && orderLines && (
        <TransactionContainer expired={expired} data-cy={'myJTicketDetails'}>
          <SendReciept transaction={transaction} onSendReceipt={handleReceipt} receiptSent={receiptSent} />

          {orderLines.map((orderLine: TransactionOrderLineType, index: number) => (
            <React.Fragment key={index}>
              {orderLine.refundOptions && orderLine.refundOptions.isCancelled && (
                <OrderlineMessage>
                  <CancelledMessage text={translate('TICKET_IS_CANCELLED')} />
                </OrderlineMessage>
              )}
              {Boolean(orderLine.applicableExternalCampaigns?.length) && (
                <OrderlineMessage>
                  <ExternalCampaigns
                    externalCampaigns={orderLine.applicableExternalCampaigns}
                    orderLineId={orderLine.orderLineId}
                  />
                </OrderlineMessage>
              )}

              <DetailedJourneyType
                data-cy={'ticketJourneyDetails'}
                isCancelled={orderLine.refundOptions && orderLine.refundOptions.isCancelled}
                last={index === orderLines.length - 1}
              >
                <BorderRightCell>
                  <DepartureInfo
                    campaignInfo={transaction.campaignInfo}
                    orderLine={orderLine}
                    passenger={transaction.passengers.find(
                      (passenger) => passenger.orderLineId === orderLine.orderLineId,
                    )}
                  />
                </BorderRightCell>
                <BorderRightCell>
                  <PassengerInfo
                    campaignInfo={transaction.campaignInfo}
                    orderLine={orderLine}
                    assistanceLocations={locations.assistanceLocations}
                    onBookedAssistance={handleRefetchTransaction}
                  />
                </BorderRightCell>
                <RowCell>
                  <TicketInfo orderLine={orderLine} onCancel={handleRefetchTransaction} />
                </RowCell>
              </DetailedJourneyType>
            </React.Fragment>
          ))}
        </TransactionContainer>
      )}
    </>
  );
};
