import React from 'react';
import styled from 'styled-components';
import { useApi } from 'hooks/use-api/useApi';
import { SectionTitleLarge } from 'elements/new-design/Typography';

type InfoBoxProps = {
  boxData: any;
  layout: string;
  children: JSX.Element | JSX.Element[];
};

type StyleProps = {
  layout?: string;
  color?: string;
};

const InfoBoxSection = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  height: fit-content;
  background: ${(props) => props.color};

  @media ${(props) => props.theme.breakpoints.large} {
    width: ${(props) => (props.layout === 'column' ? '100%' : '80rem')};
  }
`;

const InfoBoxContainer = styled.div`
${(_: StyleProps) => ''}
  width: 100%;
  display: grid;
  grid-template-rows; 2fr;
  grid-template-columns: 1fr;
  flex-direction: column;
  padding: 1.6rem 2.4rem;
  grid-gap: 1.6rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    grid-template-rows: ${(props) => (props.layout === 'row' ? '1fr' : '2fr')};
    grid-template-columns: ${(props) => (props.layout === 'row' ? '14rem auto' : '100%')};
    padding: 4.8rem 5.6rem; 
    grid-gap: ${(props) => (props.layout === 'row' ? '5.6rem' : '2.4rem')};
  }
`;

const Image = styled.img`
  width: 10rem;
  height: 10rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 14rem;
    height: 14rem;
  }
`;

export const InfoBox = ({ layout, boxData, children }: InfoBoxProps): JSX.Element => {
  const { requestImageUrl } = useApi();
  return (
    <article>
      <InfoBoxSection layout={layout} color={boxData.bgColor}>
        <InfoBoxContainer layout={layout}>
          <Image src={requestImageUrl({ image: boxData.image.url, width: 140 })} alt={boxData.image.alt} />
          <div>
            <SectionTitleLarge>{boxData.heading}</SectionTitleLarge>
            {children}
          </div>
        </InfoBoxContainer>
      </InfoBoxSection>
    </article>
  );
};
