import React from 'react';
import { FormFieldType } from 'types/FormTypes';
import { InputLabel } from 'elements/forms/InputLabel';
import { InputError } from 'elements/forms/InputError';
import { Counter } from 'elements/forms/Counter';
import { FormGroupContainer } from 'elements/forms/FormGroupContainer';

type LuggageCountProps = {
  field: FormFieldType;
  onInputChange: (field: FormFieldType, value: number) => void;
};

export const LuggageCount = ({ field, onInputChange }: LuggageCountProps) => {
  return (
    <>
      <InputLabel fieldId={field.id} label={field.label} description={field.description} />
      <FormGroupContainer field={field} error={false} column={false}>
        <Counter field={field} onInputChange={onInputChange} />
      </FormGroupContainer>
      <InputError fieldId={field.id} error={field.error} />
    </>
  );
};
