import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { useUpdateState } from 'hooks/useUpdateState';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { LanguageIconDark } from 'elements/new-design/icons/SymbolIcons';
import { HeaderFloatingMenu } from './HeaderFloatingMenu';
import { FloatingMenuContainer } from 'elements/new-design/Containers';
import { LinkList } from 'components/new-design/lists/LinkList';
import { IconBtnBasicFocus } from 'elements/new-design/buttons/icon-buttons';
import { FlexRow } from 'elements/containers/Containers';

type LanguageSelectorProps = {
  expanded: string;
  onToggleMenu: (id: string) => void;
};

const FloatingMenuOuter = styled.div`
  position: static;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.medium} {
    position: relative;
  }
`;

const LargeLangIconWrapper = styled.div`
  margin-right: 0.8rem;
  margin-top: -0.4rem;
`;

export const LanguageSelector = ({ expanded, onToggleMenu }: LanguageSelectorProps) => {
  const { currentLanguage, LANGUAGES, translate } = useI18n();
  const { setCurrentLanguage } = useUpdateState();
  const { isMaxMediumDevice } = useBreakpoints();
  const [langOptions, setLangOptions] = useState(null);
  const smallLayout = isMaxMediumDevice();
  const buttonRef = useRef<HTMLButtonElement>();

  const handleCloseList = () => {
    buttonRef.current?.focus();
    onToggleMenu(null);
  };

  const handleChangeLanguage = (_: string, row: string) => {
    setCurrentLanguage(LANGUAGES.find((lang) => lang.title === row).key);
    handleCloseList();
  };

  useEffect(() => {
    setLangOptions({
      title: translate('LANGUAGE'),
      slug: null,
      subRows: LANGUAGES.map((lang) => {
        return { title: lang.title, slug: null };
      }),
    });
  }, [currentLanguage]);

  return (
    <>
      {langOptions && (
        <>
          {smallLayout ? (
            <FloatingMenuOuter>
              <IconBtnBasicFocus
                ref={buttonRef}
                width={3}
                height={3}
                onButtonClick={() => onToggleMenu(langOptions.title)}
                hiddenTxt={translate('SELECT_LANGUAGE')}
              >
                <LanguageIconDark width={3} height={3} />
              </IconBtnBasicFocus>
              {expanded === langOptions.title && (
                <FloatingMenuContainer margin="0">
                  <LinkList
                    link={langOptions}
                    expanded={expanded}
                    belongsToColumn={0}
                    activeColumn={0}
                    defaultSelected={LANGUAGES.findIndex((lang) => lang.key === currentLanguage)}
                    onToggleMenu={onToggleMenu}
                    onRowClick={handleChangeLanguage}
                    onTabKey={handleCloseList}
                  />
                </FloatingMenuContainer>
              )}
            </FloatingMenuOuter>
          ) : (
            <FlexRow>
              <LargeLangIconWrapper>
                <LanguageIconDark width={2.5} height={2.5} />
              </LargeLangIconWrapper>

              <HeaderFloatingMenu
                link={langOptions}
                margin="-10rem"
                expanded={expanded}
                defaultSelected={LANGUAGES.findIndex((lang) => lang.key === currentLanguage)}
                onToggleMenu={onToggleMenu}
                onRowClick={handleChangeLanguage}
              />
            </FlexRow>
          )}
        </>
      )}
    </>
  );
};
