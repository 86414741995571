import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { useAuthentication } from 'hooks/useAuthentication';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { isHideLoginRoute } from 'utils/Navigation';
import { FloatingMenuContainer } from 'elements/new-design/Containers';
import { LinkList } from 'components/new-design/lists/LinkList';
import { ExpandIconRight, ExpandChevronBtn } from 'elements/new-design/buttons/expand-buttons';
import { UserIconDark } from 'elements/new-design/icons/SymbolIcons';
import { IconBtnBasicFocus } from 'elements/new-design/buttons/icon-buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';

type HeaderProfileProps = {
  profileLinks: { title: string; slug: null; subRows: { title: string; slug: string | null }[] };
  expanded: string;
  isPhoneDevice: boolean;
  onOpenLogin: () => void;
  onToggleMenu: (id: string) => void;
  onRowClick: (parentId: string, id: string) => void;
  onTabKey?: (e: KeyboardEvent<HTMLUListElement>) => void;
};

const FloatingMenuOuter = styled.div`
  position: static;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.medium} {
    position: relative;
  }
`;

export const HeaderProfile = ({
  profileLinks,
  expanded,
  isPhoneDevice,
  onOpenLogin,
  onToggleMenu,
  onRowClick,
}: HeaderProfileProps) => {
  const { isAuthenticated } = useAuthentication();
  const { translate } = useI18n();
  const { isMaxMediumDevice } = useBreakpoints();
  const isHideLoginPath = isHideLoginRoute(window.location.pathname);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const showLogin = !isHideLoginPath && !isLoggedIn;
  const smallLayout = isMaxMediumDevice();
  const buttonRef = useRef<HTMLButtonElement>();
  const { account } = useSelector((store: AppState) => store.user);

  const handleCloseList = () => {
    buttonRef.current?.focus();
    onToggleMenu(null);
  };

  useEffect(() => {
    setIsLoggedIn(isAuthenticated());
  }, [account]);

  return (
    <>
      {(isLoggedIn || (!isLoggedIn && showLogin)) && (
        <FloatingMenuOuter>
          {smallLayout && (
            <IconBtnBasicFocus
              width={3}
              height={3}
              ref={buttonRef}
              onButtonClick={() => (isLoggedIn ? onToggleMenu(profileLinks.title) : onOpenLogin())}
              hiddenTxt={isLoggedIn ? profileLinks.title : translate('LOG_IN')}
            >
              <UserIconDark active={isLoggedIn} width={isPhoneDevice ? 3.5 : 2.5} height={isPhoneDevice ? 3.5 : 2.5} />
            </IconBtnBasicFocus>
          )}

          {!smallLayout && (
            <>
              {isLoggedIn ? (
                <ExpandChevronBtn
                  small
                  ref={buttonRef}
                  gap={0.8}
                  expanded={expanded === profileLinks.title}
                  popupType="menu"
                  text={profileLinks.title}
                  onButtonClick={() => onToggleMenu(profileLinks.title)}
                />
              ) : (
                <ExpandIconRight
                  small
                  ref={buttonRef}
                  gap={!isPhoneDevice && 0.8}
                  expanded={true}
                  popupType="dialog"
                  text={translate('LOG_IN')}
                  icon={<UserIconDark active={false} width={2.5} height={2.5} />}
                  onButtonClick={onOpenLogin}
                />
              )}
            </>
          )}
          {expanded === profileLinks.title && (
            <FloatingMenuContainer margin="-20rem">
              <LinkList
                link={profileLinks}
                expanded={expanded}
                belongsToColumn={0}
                activeColumn={0}
                onToggleMenu={onToggleMenu}
                onRowClick={onRowClick}
                onTabKey={handleCloseList}
              />
            </FloatingMenuContainer>
          )}
        </FloatingMenuOuter>
      )}
    </>
  );
};
