import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'styles/colors';
import { cloneDeep } from 'lodash';
import { useApi } from 'hooks/use-api/useApi';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useI18n } from 'utils/i18n/usei18n';
import { getValidatedField } from 'utils/Validation';
import { Constants } from 'utils/Constants';
import { GOADate } from 'utils/date/GOADate';
import { Order } from 'types/OrderTypes';
import { UserType } from 'types/CustomerTypes';
import { FormFieldType } from 'types/FormTypes';
import { CmsCampaignType } from 'types/CmsTypes';
import { SorpassetService, fieldNames } from './SorpassetService';
import { Loader } from 'elements/loader/Loader';
import { DateTimeInput } from 'components/section-date-time/date-time-input/DateTimeInput';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogLeftHeader } from 'components/new-design/dialogs/DialogTypes';
import { DialogErrorContent } from 'components/new-design/dialogs/DialogErrorContent';
import { DialogLeftFooter, DialogLeftContentContainer } from 'elements/new-design/Dialogs';
import { SectionPaymentMethod } from 'components/section-payment-method/SectionPaymentMethod';
import { PhoneInputCountryNorway } from 'elements/forms/TextInput';
import { AcceptTerms } from 'elements/forms/AcceptTerms';
import { CampaignFooter } from '../CampaignFooter';
import { TicketCircleIcon } from 'elements/icon/symbol-icons';
import { MarginTop } from 'elements/distance/Margins';
import {
  HiddenSpan,
  TxtLargeBoldDarkResp,
  TxtSmallBoldDarkResp,
  TxtSmallMediumMediumResp,
} from 'elements/new-design/Typography';
import { useUpdateState } from 'hooks/useUpdateState';

type DatePickerContentProps = {
  campaign: any;
  order: Order;
  user: UserType;
  onModalClose: () => void;
};

const ContentContainer = styled.div`
  width: 100%;
  padding: 12.8rem 0 14rem 0;

  @media ${(props) => props.theme.devices.medium} {
    width: 60%;
  }

  @media ${(props) => props.theme.devices.large} {
    width: 75%;
  }
`;

const SorpassetState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR} | ${Constants.ERROR_UNAUTH}`;
const { getFormData } = SorpassetService;

export const CampaignSorpasset = ({ campaign, order, user, onModalClose }: DatePickerContentProps): JSX.Element => {
  const launchDate = new GOADate(campaign.launchAtUtc);
  const minDate = launchDate.isBefore(new GOADate()) ? new GOADate() : launchDate;
  const [date, setDate] = useState(minDate);
  const [isValidForm, setIsValidForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(null);
  const [cards, setCards] = useState(null);
  const [state, setState] = useState<typeof SorpassetState>(Constants.LOADING);
  const [orderId, setOrderId] = useState(null);
  const { cacheItem } = usePersistentStorage();
  const { translate } = useI18n();
  const { API_CALLS, guardedRequest } = useApi();
  const { setOrderState } = useUpdateState();

  const handleDateTimeChange = (date: GOADate, _: boolean) => setDate(date);

  const getOrderObj = (id: string, campaign: CmsCampaignType) => {
    const isDefaultPaymentMethod = Constants.PAYMENT_OPTIONS.find(
      (option) => option.key === paymentForm.get(fieldNames.paymentMethod).value,
    );
    return {
      orderID: id,
      departureDate: date,
      returnToPath: window.location.pathname,
      type: campaign.apiId,
      deliveryMethod: Constants.COLLECT_AS_PDF,
      paymentMethod: paymentForm.get(fieldNames.paymentMethod).value,
      termsAccepted: paymentForm.get(fieldNames.acceptTerms).value,
      reportRef: campaign.reportRef,
      contactInformation: {
        firstName: user.firstName,
        surname: user.surname,
        email: user.email,
        countryCode: user.countryCode,
        telephoneNumberNoCountryCode: paymentForm.get(fieldNames.phoneNumber).value,
        sendSMS: false,
      },
      recurringCard: isDefaultPaymentMethod
        ? undefined
        : cards.find((card) => card.recurringPaymentId === paymentForm.get(fieldNames.paymentMethod).value),
    };
  };

  const handlePay = async () => {
    try {
      const { data } = await guardedRequest(API_CALLS.TICKET_PAY);
      cacheItem(Constants.CACHE_ORDER, Object.assign({}, order, getOrderObj(orderId, campaign)));
      if (order.paymentMethod === Constants.VIPPS) window.location.href = data.result.appClaimUri;
      else window.location.href = data.result.terminalUri;
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  const reserveTicket = async () => {
    setState(Constants.LOADING);
    const departureDate = date.isBefore(new GOADate()) ? new GOADate() : date;
    const reserveObj = {
      apiId: campaign.apiId,
      count: 1,
      travelDate: departureDate,
    };
    try {
      const { data } = await guardedRequest(API_CALLS.CAMPAIGN_RESERVE, reserveObj);
      setOrderId(data.result.orderId);
      setOrderState(Object.assign({}, order, getOrderObj(data.result.orderId, campaign)));
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  const handleInputBlur = (field: FormFieldType) => {
    const form = cloneDeep(paymentForm);
    const updated = form.get(field.id);
    form.set(field.id, getValidatedField(updated));
    setPaymentForm(form);
  };

  const handleInputChange = (field: FormFieldType, value: string | number) => {
    const form = cloneDeep(paymentForm);
    const updated = form.get(field.id);
    updated.error = undefined;
    if (field.id === fieldNames.acceptTerms) updated.value = !field.value;
    else updated.value = value;
    setPaymentForm(form);
  };

  const submitPayment = () => {
    if (isValidForm) reserveTicket();
    else {
      const validatedForm = SorpassetService.validateForm(cloneDeep(paymentForm));
      const firstError = Array.from(validatedForm.values()).find((field: FormFieldType) => field.error);
      document.getElementById(firstError['id']).focus();
      document
        .getElementById(firstError['id'])
        .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      setPaymentForm(validatedForm);
    }
  };

  useEffect(() => {
    if (order.orderID && state === Constants.LOADING) handlePay();
  }, [order]);

  useEffect(() => {
    if (paymentForm) {
      const validatedForm = SorpassetService.validateForm(cloneDeep(paymentForm));
      const hasErrors = Array.from(validatedForm.values()).find((field: FormFieldType) => field.error);
      setIsValidForm(Boolean(!hasErrors));
    }
  }, [paymentForm]);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const { data } = await guardedRequest(API_CALLS.GET_RECURRING_PAYMENTS);
        setCards(data.result.length === 0 ? null : data.result);
        const defaultPaymentMethod =
          data.result.length === 0
            ? Constants.PAYMENT_OPTIONS[0].key
            : data.result.find((card) => card.primary).recurringPaymentId;
        setPaymentForm(getFormData(user, defaultPaymentMethod));
      } catch (err) {
        setCards(null);
        setPaymentForm(getFormData(user, Constants.PAYMENT_OPTIONS[0].key));
      }
      setState(Constants.IDLE);
    };
    fetchCards();
  }, []);

  return (
    <Dialog type="left" title={`${translate('ORDER')} ${campaign.title}`} onModalClose={onModalClose}>
      <DialogLeftHeader onModalClose={onModalClose}>
        <h2>
          <TxtLargeBoldDarkResp>{campaign.title}</TxtLargeBoldDarkResp>
        </h2>
      </DialogLeftHeader>
      <DialogLeftContentContainer>
        {state === Constants.LOADING && (
          <>
            <MarginTop margin={20} />
            <Loader />
          </>
        )}
        <ContentContainer>
          <MarginTop margin={0.8} />
          <div role="log">
            {state === Constants.ERROR && (
              <DialogErrorContent
                headline={translate('GENERAL_ERROR_HEADLINE')}
                text={translate('GENERAL_ERROR_TEXT')}
                loading={false}
                Icon={<TicketCircleIcon color={colors.bgSecondaryAccent} />}
                onTryAgain={reserveTicket}
              />
            )}
          </div>
          {state === Constants.IDLE && (
            <section>
              <TxtSmallMediumMediumResp>{campaign.detailedText}</TxtSmallMediumMediumResp>
              <MarginTop margin={2.4} />
              <form noValidate>
                <TxtSmallBoldDarkResp>{translate('VALID_FROM')}</TxtSmallBoldDarkResp>
                <HiddenSpan>{translate('SR_PICK_STARTDATE')}</HiddenSpan>
                <DateTimeInput
                  name="campaignDate"
                  date={date}
                  minDate={minDate}
                  maxDate={new GOADate(campaign.endAtUtc)}
                  isReturnDate={false}
                  onDateTimeChange={handleDateTimeChange}
                />
                <MarginTop margin={3} />
                {Array.from(paymentForm.values()).map((field: FormFieldType, index: number) => (
                  <React.Fragment key={index}>
                    {field.element === 'selectionBoxGroup' && (
                      <SectionPaymentMethod field={field} cards={cards} onInputChange={handleInputChange} />
                    )}
                    {field.element === fieldNames.acceptTerms && (
                      <AcceptTerms field={field} checked={field.value} onInputChange={handleInputChange} />
                    )}
                    {field.type === 'tel' && paymentForm.get(fieldNames.paymentMethod).value === Constants.VIPPS && (
                      <PhoneInputCountryNorway
                        field={field}
                        onInputChange={handleInputChange}
                        onInputBlur={handleInputBlur}
                      />
                    )}
                  </React.Fragment>
                ))}
              </form>
            </section>
          )}
        </ContentContainer>
      </DialogLeftContentContainer>
      {state === Constants.IDLE && (
        <DialogLeftFooter>
          <CampaignFooter isValid={isValidForm} campaign={campaign} onProceed={submitPayment} />
        </DialogLeftFooter>
      )}
    </Dialog>
  );
};
