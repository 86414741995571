import React from 'react';
import ContentPage from 'pages/cms/ContentPage';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';

type CookiesInformationDialogProps = {
  slug: string;
  onModalClose: () => void;
};

export const CookiesInformationDialog = ({ slug, onModalClose }: CookiesInformationDialogProps) => {
  const { translate } = useI18n();
  return (
    <Dialog type="page" title={translate('COOKIES_TITLE')} onModalClose={onModalClose}>
      <ContentPage hideBreadCrumbs slug={slug} />
    </Dialog>
  );
};
