import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { init } from 'apiV1/gateway/index';
import { init as init2 } from 'apiV2/gateway/index';
import { processResponse, isValidDeviationTime, removeStation } from 'utils/Helpers';
import { useApi } from 'hooks/use-api/useApi';
import { useAuthentication } from 'hooks/useAuthentication';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useCookieConsent } from 'hooks/useCookieConsent';
import { useUpdateState } from 'hooks/useUpdateState';
import { useI18n } from 'utils/i18n/usei18n';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { isAcceptedNoStationsRoute } from 'utils/Navigation';
import { cloneDeep } from 'lodash';
import { AppState } from 'store/index';
import { Constants } from 'utils/Constants';
import { Routes } from './Routes';
import { App } from './App';
import { Loader } from 'elements/loader/Loader';
import { BaseLoaderContainer } from 'elements/containers/Containers';

const initApi = () => {
  init({
    url: `${window.location.protocol}//${window.location.host}`,
    processResponse,
  });

  init2({
    url: `${window.location.protocol}//${window.location.host}`,
    processResponse,
  });
};

export const Preloader = () => {
  const { handleUserState } = useAuthentication();
  const { deleteOldCache } = usePersistentStorage();
  const { setPageMessages, setStations } = useUpdateState();
  const { API_CALLS, requestCms, request } = useApi();
  const { showCookieComponent, deleteOldCookies } = useCookieConsent();
  const { initI18n, currentLanguage } = useI18n();
  const { navigateWithState } = useStateNavigation();
  const [loading, setLoading] = useState(true);
  const [cookieData, setCookieData] = useState(null);
  const stations = useSelector((state: AppState) => state.locations);

  const displayInfoMessage = (title: string, message: string, error: boolean): void => {
    setPageMessages({
      type: error ? Constants.ERROR_MESSAGE : Constants.DEVIATION_MESSAGE,
      title: title,
      message: message,
      closable: true,
    });
  };

  const fetchLocations = async () => {
    try {
      const { data } = await request(API_CALLS.GET_LOCATIONS);
      const locations = cloneDeep(data.result);
      const filteredStopPlaces = data.result.stopPlaces.map((s) => {
        s.name = removeStation(s.name);
        return s;
      });
      locations.stopPlaces = filteredStopPlaces;
      setStations(Object.assign({}, stations, locations));
    } catch (error) {
      setStations({
        stopPlaces: [],
        stopPlaceProblemCombinationTexts: {},
        assistanceLocations: {
          lastOrderTimeHours: 24,
          locations: null,
        },
      });
    }
  };

  const handleMessages = async (messages) => {
    const validmessages = messages.filter((message) => isValidDeviationTime(message.launchAtUtc, message.endAtUtc));
    validmessages.forEach((element) => {
      displayInfoMessage(element.title, element.message, false);
    });
  };

  const fetchCmsData = async () => {
    try {
      const messages = await requestCms(API_CALLS.CMS_GET_SYSTEM_MESSAGES, { currentLanguage });
      handleMessages(messages);

      if (showCookieComponent()) setCookieData(await requestCms(API_CALLS.CMS_GET_COOKIES));
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stations.stopPlaces) {
      setLoading(false);
      if (!stations.stopPlaces.length && !isAcceptedNoStationsRoute(window.location.pathname))
        navigateWithState(Constants.ROUTE_BASE, true);
      else navigateWithState(window.location.pathname, true);
    }
  }, [stations, cookieData]);

  useEffect(() => {
    const initApp = async () => {
      initApi();
      initI18n();
      deleteOldCache();
      deleteOldCookies();
      handleUserState();
      await fetchLocations();
      await fetchCmsData();
    };
    initApp();
  }, []);

  return (
    <Routes>
      {({ routesLoading, routes, cmsLinks, onRouteChangeFocusRef }) => {
        return (
          <>
            {routesLoading || loading ? (
              <BaseLoaderContainer>
                <Loader />
              </BaseLoaderContainer>
            ) : (
              <App
                headerLinks={cmsLinks.filter((link) => link.tag && link.tag === Constants.SANITY.GOA_INFORMATIONAL_TAG)}
                footerLinks={cmsLinks.filter((link) => link.tag && link.tag !== Constants.SANITY.GOA_INFORMATIONAL_TAG)}
                cookieData={cookieData}
                routes={routes}
                onRouteChangeFocusRef={onRouteChangeFocusRef}
              />
            )}
          </>
        );
      }}
    </Routes>
  );
};
