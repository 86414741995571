import { getValidatedField } from 'utils/Validation';
import { UserType } from 'types/CustomerTypes';
import { FormType, FormFieldType } from 'types/FormTypes';
import { Constants } from 'utils/Constants';

export const fieldNames = {
  paymentMethod: 'paymentMethod',
  phoneNumber: 'phoneNumber',
  acceptTerms: 'acceptTerms',
};

export const SorpassetService = {
  getFormData: (user: UserType, defaultPaymentMethod: string | number) => {
    const data = [
      {
        id: 'paymentMethod',
        label: 'LABEL_CHOOSE_PAYMENT_METHOD',
        description: '',
        value: defaultPaymentMethod,
        error: undefined,
        validationRules: ['isRequired'],
        element: 'selectionBoxGroup',
        type: 'radio',
      },
      {
        id: 'phoneNumber',
        label: 'PHONENUMBER',
        description: 'MANDATORY_FIELD',
        value: user.telephoneNumberNoCountryCode ? user.telephoneNumberNoCountryCode : '',
        error: undefined,
        validationRules: ['isValidPhone', 'isRequired'],
        element: 'tel',
        type: 'tel',
      },
      {
        id: 'acceptTerms',
        label: 'ACCEPT_CONDITIONS_TEXT',
        description: 'MANDATORY_FIELD',
        value: false,
        error: undefined,
        validationRules: ['isAcceptedTerms'],
        element: 'acceptTerms',
        type: 'acceptTerms',
      },
    ];

    const form = new Map();
    data.forEach((field) => {
      if (field) form.set(field.id, field);
    });
    return form;
  },
  validateForm: (form: FormType): FormType => {
    form.forEach((field: FormFieldType) => {
      if (field.id === fieldNames.phoneNumber) {
        if (form.get(fieldNames.paymentMethod).value === Constants.VIPPS) return getValidatedField(field);
        else {
          field.error = undefined;
          return field;
        }
      }
      return getValidatedField(field);
    });
    return form;
  },
};
