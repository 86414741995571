import React from 'react';
import { SmallContainer } from 'components/new-design/layouts/LayoutContainers';
import { PageTitleLarge, Premable } from 'elements/new-design/Typography';
import { MarginTop, MarginBottom } from 'elements/distance/Margins';

export const PreviewNotAvailable = (): JSX.Element => (
  <SmallContainer>
    <MarginTop margin={2}>
      <MarginBottom margin={2}>
        <PageTitleLarge>No new updates are made!</PageTitleLarge>
      </MarginBottom>
    </MarginTop>
    <Premable>To see a preview, the page can not be published.</Premable>
  </SmallContainer>
);
