import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { Loader } from 'elements/loader/Loader';
import { MarginBottom } from 'elements/distance/Margins';
import { DialogErrorTitle, DialogTitle, HiddenSpan, TxtDefaultRegularDarkResp } from 'elements/new-design/Typography';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';

type DialogActionContentProps = {
  headline: string;
  text: string;
  loading: boolean;
  status?: string;
  Icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
};

export const DialogActionContent = ({ ...props }: DialogActionContentProps) => {
  const { translate } = useI18n();
  return (
    <DialogCenterContentContainer>
      <HiddenSpan aria-live="assertive">{props.loading ? translate('PLEASE_WAIT') : ''}</HiddenSpan>
      {props.Icon ? <MarginBottom margin={2.4}>{props.Icon}</MarginBottom> : null}
      <div>
        {props.status === Constants.ERROR ? (
          <DialogErrorTitle>{props.headline}</DialogErrorTitle>
        ) : (
          <DialogTitle>{props.headline}</DialogTitle>
        )}
        {props.loading && <Loader />}
        {!props.loading && (
          <>
            <p>
              <TxtDefaultRegularDarkResp>{props.text}</TxtDefaultRegularDarkResp>
            </p>
            {props.children}
          </>
        )}
      </div>
    </DialogCenterContentContainer>
  );
};
