import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module ExternalCampaign */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Activate campaign by linking it to an orderLineId
 * 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.ActivateExternalCampaignRequest} body 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultString1>} Success
 */
export function ExternalCampaign_ActivateExternalCampaign(body: api.GoAliveRestApi.Controllers.ControllerModels.ActivateExternalCampaignRequest, options?: ExternalCampaign_ActivateExternalCampaignOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultString1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    body: {
      body
    }
  }
  return gateway.request(ExternalCampaign_ActivateExternalCampaignOperation, parameters)
}

export interface ExternalCampaign_ActivateExternalCampaignOptions {
  authorization?: string
}

const ExternalCampaign_ActivateExternalCampaignOperation: api.OperationInfo = {
  path: '/rest/ExternalCampaign/activate',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
