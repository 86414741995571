import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { GOADate } from 'utils/date/GOADate';
import { TransactionType } from 'types/HistoryTypes';
import colors from 'styles/new-design/colors';
import { MarginBottom, MarginRight } from 'elements/distance/Margins';
import { BaseContainerSmaller, FlexColumn, FlexRow } from 'elements/containers/Containers';
import { Ticket } from 'elements/tickets/Ticket';
import { ChevronRight } from 'elements/icon/Icon';
import { TicketIcon } from 'elements/icon/symbol-icons';
import {
  TxtMediumBoldDarkResp,
  TxtMediumMediumDarkResp,
  TxtSmallBoldDarkResp,
  TxtSmallMediumDarkResp,
  TxtSmallMediumMediumResp,
} from 'elements/new-design/Typography';
import { LinkSmallResp } from 'elements/new-design/links/Links';
import { SectionTicketCategories } from 'pages/user-profile/my-journeys/SectionTicketCategories';
import { UserProfileLayout } from 'pages/user-profile/UserProfileLayout';
import { TicketList } from 'pages/user-profile/my-journeys/transaction-list/TransactionList';

type StrawberryPageProps = {
  pageData: any;
  bonusPageData: any;
  isStrawberryEnabled: boolean;
};

const BonusTabSection = styled(FlexColumn)`
  width: 100%;
  align-items: flex-start;

  &:first-of-type {
    width: 100%;
    margin-bottom: 3rem;
  }
`;

const TicketListItem = styled.li`
  width: 100%;
  margin-bottom: 1.6rem;
`;

const ListHeaderContainer = styled(FlexRow)`
  display: none;
  @media ${(props) => props.theme.devices.large} {
    display: flex;
    width: 100%;
    padding: 1rem 2.4rem;
    ${TxtSmallBoldDarkResp} {
      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 10%;
      }
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 15%;
        text-align: right;
      }
      &:nth-child(5) {
        width: 15%;
        text-align: right;
      }
      &:nth-child(6) {
        width: 15%;
        text-align: right;
      }
      &:nth-child(7) {
        width: 5%;
      }
    }
  }
`;

const BonusTicketContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media ${(props) => props.theme.devices.large} {
    padding: 2.4rem;
  }
`;

const OrderId = styled.div`
  width: 100%;
  text-align: left;
`;

const PurchaseAmount = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  text-align: right;

  @media ${(props) => props.theme.devices.large} {
    width: 80%;
    margin-top: 0;
  }
`;

const BonusAmount = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 0.6rem;

  @media ${(props) => props.theme.devices.large} {
    width: 15%;
    margin-top: 0;
  }
`;

const PriceTitle = styled.span`
  @media ${(props) => props.theme.devices.large} {
    display: none;
  }
`;

const EmptyDivRight = styled.div`
  width: 100%;

  @media ${(props) => props.theme.devices.large} {
    width: 5%;
  }
`;

const EmptyDivLeft = styled(EmptyDivRight)`
  @media ${(props) => props.theme.devices.large} {
    width: 10%;
  }
`;

const TravelDate = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 1.2rem;

  @media ${(props) => props.theme.devices.large} {
    width: 10%;
    margin-top: 0;
  }
`;

const Stations = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.4rem;

  @media ${(props) => props.theme.devices.large} {
    width: 39%;
    margin-top: 0rem;
  }
`;

const Tickets = styled(FlexRow)`
  width: 50%;
  text-align: left;
  margin-top: 0.6rem;
  justify-content: flex-end;

  ${TxtSmallMediumMediumResp} {
    margin-left: 0.4rem;
  }

  @media ${(props) => props.theme.devices.large} {
    justify-content: flex-start;
    width: 41%;
    margin-top: 0;
  }
`;

// HELPERS
const all = 'ALL';
const created = 'CREATED';
const past = 'PAST';
const cancelled = 'CANCELLED';

const getTabCategories = () => [all, created, past, cancelled];

const StrawberryPage = ({ pageData, bonusPageData, isStrawberryEnabled }: StrawberryPageProps): JSX.Element => {
  const [currentList, setCurrentList] = useState(bonusPageData);
  const [activeTab, setActiveTab] = useState(getTabCategories()[0]);
  const { translate } = useI18n();

  const sortByActiveTab = (tab: string, bonusPageData): TransactionType[] => {
    if (bonusPageData) {
      if (tab === created) return bonusPageData.filter((bonus) => bonus.orderStatus === 'Created');
      else if (tab === past) return bonusPageData.filter((bonus) => bonus.orderStatus === 'Completed');
      else if (tab === cancelled) return bonusPageData.filter((bonus) => bonus.orderStatus === 'Cancelled');
      else return bonusPageData;
    }
  };

  const getBackground = (status: string) => {
    if (status === 'Cancelled') return colors.errorLight;
    return colors.infoLight;
  };

  const getBorder = (status: string) => {
    if (status === 'Cancelled') return colors.borderError;
    return colors.borderInfo;
  };

  useEffect(() => {
    if (currentList) setCurrentList(sortByActiveTab(activeTab, bonusPageData));
  }, [activeTab]);

  useEffect(() => {
    setCurrentList(sortByActiveTab(activeTab, bonusPageData));
  }, [bonusPageData]);

  return (
    <>
      <UserProfileLayout headline={pageData.title} preamble={isStrawberryEnabled ? pageData.text : pageData.infoText}>
        <BaseContainerSmaller>
          {!isStrawberryEnabled && (
            <>
              <LinkSmallResp text={translate('VERIFY_ACCOUNT_CUSTOMER_INFO')} url={'/edit-profile'} />
              <MarginBottom margin={1.6} />
            </>
          )}

          {isStrawberryEnabled && (
            <BonusTabSection aria-label={translate('LABEL_CHOOSE_PROFILE_SECTION')}>
              <SectionTicketCategories
                tabCategories={getTabCategories()}
                activeTab={activeTab}
                onChangeActiveTab={(tab) => setActiveTab(tab)}
                numberOfCategories={4}
              />
              {bonusPageData.length > 0 && (
                <TicketList>
                  <ListHeaderContainer aria-hidden={true}>
                    <TxtSmallBoldDarkResp>{translate('REFERENCE')}</TxtSmallBoldDarkResp>
                    <TxtSmallBoldDarkResp>{translate('DATE_OF_TRAVEL')}</TxtSmallBoldDarkResp>
                    <TxtSmallBoldDarkResp>{translate('JOURNEY')}</TxtSmallBoldDarkResp>
                    <TxtSmallBoldDarkResp>{translate('TICKETS')}</TxtSmallBoldDarkResp>
                    <TxtSmallBoldDarkResp>{translate('AMOUNT_PAYED')}</TxtSmallBoldDarkResp>
                    <TxtSmallBoldDarkResp>{translate('CALCULATED_BONUS')}</TxtSmallBoldDarkResp>
                  </ListHeaderContainer>
                  {currentList &&
                    currentList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TicketListItem>
                            <Ticket
                              background={getBackground(item.orderStatus)}
                              border={getBorder(item.orderStatus)}
                              multiple={false}
                            >
                              <BonusTicketContainer>
                                <OrderId>
                                  <TxtMediumBoldDarkResp>{item.orderId}</TxtMediumBoldDarkResp>
                                </OrderId>

                                {item.orderLineLegs.map((leg, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <EmptyDivLeft />
                                      <TravelDate>
                                        <TxtMediumMediumDarkResp aria-hidden={true}>
                                          {new GOADate(leg.travelDate).formateDateToNumberString()}
                                        </TxtMediumMediumDarkResp>
                                      </TravelDate>
                                      <Stations>
                                        <>
                                          <MarginRight margin={0.8}>
                                            <TxtMediumBoldDarkResp aria-hidden={true}>
                                              {leg.stationFromName}
                                            </TxtMediumBoldDarkResp>
                                          </MarginRight>
                                          <MarginRight margin={0.8}>
                                            <ChevronRight color={'dark'} width={1} height={1} />
                                          </MarginRight>
                                          <TxtMediumBoldDarkResp aria-hidden={true}>
                                            {leg.stationToName}
                                          </TxtMediumBoldDarkResp>
                                        </>
                                      </Stations>
                                      <Tickets aria-hidden={true}>
                                        <TicketIcon color="dark" width={1.4} height={1.4} />
                                        <TxtSmallMediumMediumResp aria-hidden={true}>
                                          {leg.count}
                                        </TxtSmallMediumMediumResp>
                                      </Tickets>
                                    </React.Fragment>
                                  );
                                })}
                                <PurchaseAmount>
                                  <PriceTitle>
                                    <TxtSmallMediumDarkResp>{translate('AMOUNT_PAYED')}: </TxtSmallMediumDarkResp>
                                  </PriceTitle>
                                  <TxtSmallBoldDarkResp aria-hidden={true}>
                                    {`kr. ${Number(item.purchaseAmount)},-`}
                                  </TxtSmallBoldDarkResp>
                                </PurchaseAmount>
                                <BonusAmount>
                                  <PriceTitle>
                                    <TxtSmallMediumDarkResp>{translate('CALCULATED_BONUS')}: </TxtSmallMediumDarkResp>
                                  </PriceTitle>
                                  <TxtSmallBoldDarkResp aria-hidden={true}>
                                    {item.bonusAmount ? `kr. ${Number(item.bonusAmount)},-` : ``}
                                  </TxtSmallBoldDarkResp>
                                </BonusAmount>
                                <EmptyDivRight />
                              </BonusTicketContainer>
                            </Ticket>
                          </TicketListItem>
                        </React.Fragment>
                      );
                    })}
                </TicketList>
              )}
            </BonusTabSection>
          )}
        </BaseContainerSmaller>
      </UserProfileLayout>
    </>
  );
};

export default StrawberryPage;
