import { useDispatch } from 'react-redux';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { cloneDeep } from 'lodash';
import { Constants } from 'utils/Constants';
import { GOADate } from 'utils/date/GOADate';
import { initialOrderState } from 'store/order/orderReducer';
import { removeMessage, removeAllMessages, setMessages } from 'store/messages/actions';
import { setLocations } from 'store/locations/actions';
import {
  setFrom,
  setTo,
  setOrder,
  setOrderID,
  setDepartureDate,
  setReturnDate,
  setOriginalDepartureDate,
  setOriginalReturnDate,
  setTermsAccepted,
  setPaymentMethod,
  setRecurringCard,
  setContactInformation,
  setReturnToPath,
  setType,
  setDeliveryMethod,
  setReserveExpiration,
  setSelectedSeats,
  setPassengers,
  setSupplements,
  setJourney,
  setReturnJourney,
} from 'store/order/actions';
import {
  setOrderValid,
  setHasFormChanged,
  setCurrentDialog,
  setLanguage,
  setPromptLogin,
  setPageTitle,
} from 'store/global/action';
import { setCriticalDeviations } from 'store/deviations/actions';
import { setUser } from 'store/user/action';
import { ContactInformation, Order } from 'types/OrderTypes';
import { RecurringCardType } from 'types/RecurringPaymentTypes';
import { DeviationMessage } from 'types/DeviationTypes';
import { Message } from 'types/MessagesTypes';
import { Locations, LocationType } from 'types/LocationTypes';
import { SelectedSeatListType } from 'types/SeatingTypes';
import { Passengers, Supplements } from 'types/PassengerTypes';
import { SelectedJourneyType } from 'types/JourneyTypes';

import { UserType } from 'types/CustomerTypes';

export const useUpdateState = () => {
  const dispatch = useDispatch();
  const { getCachedItem, cacheItem } = usePersistentStorage();

  //MESSAGES
  const setCriticalMessages = (messages: DeviationMessage[]) => dispatch(setCriticalDeviations(messages));
  const removePageMessage = (index: number) => dispatch(removeMessage(index));
  const removeAllPageMessages = () => dispatch(removeAllMessages());
  const setPageMessages = (pageMessage: Message) => {
    dispatch(
      setMessages({
        type: pageMessage.type,
        title: pageMessage.title,
        message: pageMessage.message,
        closable: pageMessage.closable,
      }),
    );
  };

  //LOCATIONS
  const setStations = (stations: Locations) => dispatch(setLocations(stations));

  //GLOBALS
  const setIsOrderValid = (isValid: boolean) => dispatch(setOrderValid(isValid));
  const setFormChanged = (status: boolean) => dispatch(setHasFormChanged(status));
  const setDialog = (dialog: object) => dispatch(setCurrentDialog(dialog));
  const setPromptForLogin = (status: boolean) => dispatch(setPromptLogin(status));
  const setTitle = (title: string) => dispatch(setPageTitle(title));

  const setCurrentLanguage = (language: string = null) => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    dispatch(setLanguage(language));
    cacheItem(Constants.CACHE_SETTINGS, Object.assign(settings, { LANGUAGE: language }));
  };

  //ORDER
  const setOrderState = async (order: Order) => {
    return dispatch(setOrder(order));
  };

  const resetOrderState = async () => {
    return dispatch(setOrder(cloneDeep(initialOrderState)));
  };

  const setOrderId = (id: string) => dispatch(setOrderID(id));

  const setOrderStations = (fromStation: LocationType, toStation: LocationType) => {
    dispatch(setFrom(fromStation));
    dispatch(setTo(toStation));
  };

  const deleteOrderStations = () => {
    dispatch(setFrom(undefined));
    dispatch(setTo(undefined));
  };

  const setOrderFromStation = (fromStation: LocationType) => dispatch(setFrom(fromStation));
  const setOrderToStation = (toStation: LocationType) => dispatch(setTo(toStation));

  const setOrderTravelTime = (date: GOADate, isReturnJourney: boolean) => {
    if (isReturnJourney) dispatch(setReturnDate(date));
    else dispatch(setDepartureDate(date));
  };

  const setOrderOriginalTravelTime = (date: GOADate, isReturnJourney: boolean) => {
    if (isReturnJourney) dispatch(setOriginalReturnDate(date));
    else dispatch(setOriginalDepartureDate(date));
  };

  const setOrderTermsAccepted = (accepted: boolean) => dispatch(setTermsAccepted(accepted));
  const setOrderPaymentMethod = (method: string | number) => dispatch(setPaymentMethod(method));
  const setOrderRecurringCard = (card: RecurringCardType) => dispatch(setRecurringCard(card));
  const setOrderContactInformation = (information: ContactInformation) => dispatch(setContactInformation(information));
  const setOrderReturnToPath = (path: string) => dispatch(setReturnToPath(path));
  const setOrderType = (type: string) => dispatch(setType(type));
  const setOrderDeliveryMethod = (method: string) => dispatch(setDeliveryMethod(method));
  const setOrderReserveExpiration = (date: GOADate) => dispatch(setReserveExpiration(date));
  const setOrderSelectedSeats = (seats: SelectedSeatListType) => dispatch(setSelectedSeats(seats));
  const setOrderPassengers = (passengers: Passengers) => dispatch(setPassengers(passengers));
  const setOrderSupplements = (supplements: Supplements) => dispatch(setSupplements(supplements));
  const setOrderJourney = (journey: SelectedJourneyType) => dispatch(setJourney(journey));
  const setOrderReturnJourney = (journey: SelectedJourneyType) => dispatch(setReturnJourney(journey));

  //USER
  const setUserState = (user: UserType) => dispatch(setUser(Object.assign({}, user)));

  return {
    setCriticalMessages,
    removePageMessage,
    removeAllPageMessages,
    setPageMessages,
    setStations,
    setIsOrderValid,
    setFormChanged,
    setDialog,
    setCurrentLanguage,
    setPromptForLogin,
    setTitle,
    setOrderState,
    resetOrderState,
    setOrderId,
    setOrderStations,
    deleteOrderStations,
    setOrderFromStation,
    setOrderToStation,
    setOrderTravelTime,
    setOrderOriginalTravelTime,
    setOrderTermsAccepted,
    setOrderPaymentMethod,
    setOrderRecurringCard,
    setOrderContactInformation,
    setOrderReturnToPath,
    setOrderType,
    setOrderDeliveryMethod,
    setOrderReserveExpiration,
    setOrderSelectedSeats,
    setOrderPassengers,
    setOrderSupplements,
    setOrderJourney,
    setOrderReturnJourney,
    setUserState,
  };
};
