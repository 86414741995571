import { Constants } from 'utils/Constants';
import { GOADate } from 'utils/date/GOADate';
import { OrderState, Order } from 'types/OrderTypes';
import { Passengers, Supplements } from 'types/PassengerTypes';
import { LocationType } from 'types/LocationTypes';
import {
  initialJourneyState,
  initialReturnState,
  initialReviewState,
  initialPaymentState,
  initialOrderState,
} from 'store/order/orderReducer';
import { cloneDeep } from 'lodash';

export const isCMSRoute = (route: string): boolean => {
  //remove slug from route and compare only to first part
  const path = `/${route.split('/').slice(1)[0]}`;
  const routes = [
    Constants.ROUTE_BASE,
    Constants.ROUTE_CMS_CUSTOMER_SERVICE,
    Constants.ROUTE_CMS_TRAVEL_INFO,
    Constants.ROUTE_CMS_TRAVEL_INFO_OLD,
    Constants.ROUTE_CMS_WELCOME_ONBOARD,
    Constants.ROUTE_CMS_TRAFFIC_INFO,
    Constants.ROUTE_CMS_ARTICLES,
    Constants.ROUTE_CMS_PREVIEW,
    Constants.ROUTE_CMS_ABOUT,
    Constants.ROUTE_CMS_LEGAL,
    Constants.ROUTE_CMS_TRANSPORT_CONDITIONS,
    Constants.ROUTE_CMS_PERSONAL_INTEGRITY,
    Constants.ROUTE_CMS_LEGAL_OLD,
    Constants.ROUTE_CMS_REFUNDINFO,
    Constants.ROUTE_CMS_ALL_LINES,
    Constants.ROUTE_CMS_STRAWBERRY,
  ];
  return routes.some((r) => r === path);
};

export const isResponseRoute = (route: string): boolean => {
  return (
    route === `${Constants.ROUTE_RESPONSE}/${Constants.EDIT_PROFILE_ERROR}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.CHANGE_PASSWORD_ERROR}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.CREATE_PROFILE_ERROR}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.CREATE_PROFILE_SUCCESS}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.FORGOT_PASSWORD_SUCCESS}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.DELETE_PROFILE_SUCCESS}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.DELETE_PROFILE_ERROR}`
  );
};

export const isProtectedRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_EDIT_PROFILE ||
    route === Constants.ROUTE_PROFILE_DATA ||
    route === Constants.ROUTE_DELETE_PROFILE ||
    route === Constants.ROUTE_CHANGE_PASSWORD ||
    route === Constants.ROUTE_PROFILE_LANDING ||
    route === Constants.ROUTE_MY_JOURNEYS ||
    route === Constants.ROUTE_MY_CARDS ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.EDIT_PROFILE_ERROR}` ||
    route === `${Constants.ROUTE_RESPONSE}/${Constants.CHANGE_PASSWORD_ERROR}`
  );
};

export const isProfileRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_PROFILE_LANDING ||
    route === Constants.ROUTE_CREATE_PROFILE ||
    route === Constants.ROUTE_EDIT_PROFILE ||
    route === Constants.ROUTE_CHANGE_PASSWORD ||
    route === Constants.ROUTE_PROFILE_DATA ||
    route === Constants.ROUTE_DELETE_PROFILE ||
    route === Constants.ROUTE_MY_JOURNEYS ||
    route === Constants.ROUTE_MY_CARDS
  );
};

export const isPurchaseFlowRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_PLANNER ||
    route === Constants.ROUTE_JOURNEY ||
    route === Constants.ROUTE_RETURN_JOURNEY ||
    route === Constants.ROUTE_LOGIN_PROCEED ||
    route === Constants.ROUTE_REVIEW ||
    route === Constants.ROUTE_PAYMENT
  );
};

export const isKeepCachedOrderRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_PAYMENT ||
    route === Constants.ROUTE_NETS_REDIRECT ||
    route === Constants.ROUTE_VIPPS_REDIRECT
  );
};

export const isWarningModalRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_EDIT_PROFILE || route === Constants.ROUTE_CREATE_PROFILE || isPurchaseFlowRoute(route)
  );
};

export const isPurchaseFlowTokenRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_LOGIN_PROCEED || route === Constants.ROUTE_REVIEW || route === Constants.ROUTE_PAYMENT
  );
};

export const isHideLoginRoute = (route: string): boolean => {
  return isPurchaseFlowTokenRoute(route) || isResponseRoute(route) || route === Constants.ROUTE_CREATE_PROFILE;
};

export const isAcceptedNoStationsRoute = (route: string): boolean =>
  isCMSRoute(route) || isProfileRoute(route) || isResponseRoute(route);

export const isClearStateRoute = (route: string): boolean => isCMSRoute(route);

export const isEncodedRoute = (route: string): boolean => {
  return (
    route === Constants.ROUTE_PLANNER || route === Constants.ROUTE_JOURNEY || route === Constants.ROUTE_RETURN_JOURNEY
  );
};

export const isCurrentRoute = (path: string): boolean => window.location.pathname === path;

export const getRouteParts = (translate: (key: string) => string): { display: string; path: string }[] => {
  const route = window.location.pathname;
  const path = route.split('/').slice(1);
  const breadCrumbs = new Map();
  breadCrumbs.set('edit-profile', translate('CUSTOMER_INFORMATION'));
  breadCrumbs.set('change-password', translate('CHANGE_TO_NEW_PASSWORD'));
  breadCrumbs.set('profile-data', translate('CLIENT_DATA'));
  breadCrumbs.set('delete-profile', translate('DELETE_PROFILE'));
  breadCrumbs.set('my-journeys', translate('MY_JOURNEYS'));
  breadCrumbs.set('my-cards', translate('PAYMENT_CARD'));
  return path.map((p) => {
    const hardCodedDisplay = breadCrumbs.get(p);
    //TODO: temporary until mobilde apps updated their links 2021-09-06
    if (p === 'passasjererkategorier') p = 'billettkategorier';
    return {
      display: hardCodedDisplay ? hardCodedDisplay : p,
      path: '/' + p,
    };
  });
};

export const getQueryParams = (
  location = window.location,
): { responseCode: string; state: string; transactionId: string } => {
  let match;
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = (s): string => decodeURIComponent(s.replace(pl, ' '));
  const query = location.search.substring(1);
  const queryParams = { responseCode: undefined, state: undefined, transactionId: undefined };

  while ((match = search.exec(query))) queryParams[decode(match[1])] = decode(match[2]);
  return queryParams;
};

// Decode State (Order)
// =========================

const shapePassengers = (json: Passengers): Passengers => {
  let ADULT = json.ADULT || 0;
  const INFANT = json.INFANT || 0;
  const CHILD = json.CHILD || 0;
  const SENIOR = json.SENIOR || 0;
  const STUDENT = json.STUDENT || 0;
  const MILITARY = json.MILITARY || 0;

  const total = ADULT + INFANT + CHILD + SENIOR + STUDENT + MILITARY;
  if (total === 0) ADULT = 1;

  return {
    ADULT,
    INFANT,
    CHILD,
    SENIOR,
    STUDENT,
    MILITARY,
  };
};

const shapeSupplements = (json: Supplements): Supplements => {
  const ANIMAL = json.ANIMAL || 0;
  const SMALL_ANIMAL = json.SMALL_ANIMAL || 0;
  const BICYCLE = json.BICYCLE || 0;
  return {
    ANIMAL,
    SMALL_ANIMAL,
    BICYCLE,
  };
};

// Encode State (Order)
// =========================

const encodeDate = (date: GOADate): string => date.toJSON().split(':').slice(0, 2).join(':').concat('Z');

export const encodeState = (state: OrderState): string => {
  const passengers = Object.entries(state.passengers).reduce((acc, pair) => {
    const [k, v] = pair;
    if (v) acc[k] = v;
    return acc;
  }, {});

  const supplements = Object.entries(state.supplements).reduce((acc, pair) => {
    const [k, v] = pair;
    if (v) acc[k] = v;
    return acc;
  }, {});

  const originalDepartureDate = state.originalDepartureDate && encodeDate(state.originalDepartureDate);
  const originalReturnDate = state.originalReturnDate && encodeDate(state.originalReturnDate);
  const departureDate = state.departureDate && encodeDate(state.departureDate);
  const returnDate = state.returnDate && encodeDate(state.returnDate);
  const from = state.from && state.from.id;
  const to = state.to && state.to.id;
  const toEncode = {
    ...passengers,
    ...supplements,
    ...(originalDepartureDate ? { originalDepartureDate } : {}),
    ...(originalReturnDate ? { originalReturnDate } : {}),
    ...(departureDate ? { departureDate } : {}),
    ...(returnDate ? { returnDate } : {}),
    ...(from ? { from } : {}),
    ...(to ? { to } : {}),
  };

  return encodeURIComponent(JSON.stringify(toEncode));
};

export const decodeState = (location = window.location, stopPlaces: LocationType[] = []): Order => {
  const { state } = getQueryParams(location);
  if (state) {
    const json = JSON.parse(decodeURIComponent(state));
    const order: Order = {
      passengers: shapePassengers(json),
      supplements: shapeSupplements(json),
      originalDepartureDate: json.originalDepartureDate && new GOADate(json.originalDepartureDate),
      originalReturnDate: json.originalReturnDate && new GOADate(json.originalReturnDate),
      departureDate: json.departureDate && new GOADate(json.departureDate),
      returnDate: json.returnDate && new GOADate(json.returnDate),
      from: json.from ? stopPlaces.find((station) => station.id === json.from) : undefined,
      to: json.to ? stopPlaces.find((station) => station.id === json.to) : undefined,
    };
    return order;
  }
  return {};
};

export const cleanState = (route: string, state: OrderState): Order => {
  if (isClearStateRoute(route)) return Object.assign({}, cloneDeep(initialOrderState));
  else if (route === Constants.ROUTE_PLANNER || route === Constants.ROUTE_JOURNEY)
    return Object.assign(state, initialJourneyState, initialReturnState, initialReviewState, initialPaymentState);
  else if (route === Constants.ROUTE_RETURN_JOURNEY)
    return Object.assign(state, initialReturnState, initialReviewState, initialPaymentState);
  else if (route === Constants.ROUTE_LOGIN_PROCEED)
    return Object.assign(state, initialReviewState, initialPaymentState);
  else if (route === Constants.ROUTE_LOGIN_PROCEED || route === Constants.ROUTE_REVIEW)
    return Object.assign(state, initialPaymentState);
  else return state;
};
