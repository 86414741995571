import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { CustomerServiceEmail } from './CustomerServiceEmail';
import { CustomerServicePhone } from './CustomerServicePhone';
import { TxtSmallBoldDarkResp } from 'elements/new-design/Typography';
import { FlexColumnNoCenter, FlexRowStart } from 'elements/containers/Containers';
import { MarginBottom } from 'elements/distance/Margins';

type CustomerServiceContactInfoProps = {
  isGroupVisible?: boolean;
  mailSubject?: string;
};

export const CustomerServiceContactInfo = ({ isGroupVisible, mailSubject }: CustomerServiceContactInfoProps) => (
  <FlexColumnNoCenter>
    <FlexRowStart>
      <TxtSmallBoldDarkResp data-cy="customerServicePhoneTitleCy">
        {useI18n().translate('PHONE')}&nbsp;
      </TxtSmallBoldDarkResp>
      <CustomerServicePhone />
    </FlexRowStart>
    <MarginBottom margin={0.5} />
    <CustomerServiceEmail mailSubject={mailSubject} />
    {isGroupVisible && (
      <CustomerServiceEmail
        email={Constants.GO_AHEAD_GROUP_SERVICE_EMAIL}
        displayText={Constants.GO_AHEAD_GROUP_SERVICE_EMAIL}
        mailSubject={mailSubject}
      />
    )}
  </FlexColumnNoCenter>
);
