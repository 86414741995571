import React from 'react';
import styled from 'styled-components';

type TicketProps = {
  multiple: boolean;
  border: string;
  background: string;
  children: JSX.Element;
};

type StyleProps = {
  border: string;
  background?: string;
};

// Containers
// =========================

const TicketContainer = styled.div`
  width: 100%;
  background: inherit;
`;

const SelectionContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  position: relative;
  background: ${(props) => props.background};
  border: ${(props) => `0.1rem solid ${props.border}`};
`;

export const Circle = styled.div`
  ${(_: StyleProps) => ''}
  height: 2.2rem;
  width: 1.1rem;
  top: 2.2rem;
  position: absolute;
  background: ${(props) => props.theme.colors.bgSecondary};
  border: ${(props) => `0.1rem solid ${props.border}`};
`;

export const BorderCover = styled.div`
  height: 2.1rem;
  width: 0.8rem;
  position: absolute;
  top: 2.2rem;
  background: ${(props) => props.theme.colors.bgSecondary};
`;

const LeftCircle = styled(Circle)`
  left: 0;
  border-left: 0;
  border-bottom-right-radius: 2.2rem;
  border-top-right-radius: 2.2rem;
`;

const LeftBorderCover = styled(BorderCover)`
  left: -0.8rem;
`;

const RightCircle = styled(Circle)`
  right: 0;
  border-right: 0;
  border-bottom-left-radius: 2.2rem;
  border-top-left-radius: 2.2rem;
`;

const RightBorderCover = styled(BorderCover)`
  right: -0.8rem;
`;

const BelowTicketOne = styled.div`
  ${(_: StyleProps) => ''}

  height: 0.5rem;
  margin: 0 0.6rem;
  background: ${(props) => props.background};
  border: ${(props) => `0.1rem solid ${props.border}`};
  border-top: 0;
`;

const BelowTicketTwo = styled(BelowTicketOne)`
  ${(_: StyleProps) => ''}
  margin: 0 1.2rem;
`;

export const Ticket = ({ multiple, border, background, children }: TicketProps) => {
  return (
    <TicketContainer>
      <SelectionContainer border={border} background={background}>
        <LeftCircle border={border} />
        <LeftBorderCover />
        {children}
        <RightCircle border={border} />
        <RightBorderCover />
      </SelectionContainer>
      {multiple && (
        <>
          <BelowTicketOne border={border} background={background} />
          <BelowTicketTwo border={border} background={background} />
        </>
      )}
    </TicketContainer>
  );
};
