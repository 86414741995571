import { norwegian } from './norweigan';
import { english } from './english';

export const getTranslation = (language: string): Record<string, string | any> => {
  if (language === 'no') return norwegian;
  if (language === 'en-GB') return english;
  return norwegian;
};

export const translate = (key: string, language: string): string | any => getTranslation(language)[key];

export const getWeekDays = (lang: string): { short: string; long: string }[] => {
  if (lang === 'no') {
    return [
      { short: 'Ma', long: 'Mandag' },
      { short: 'Ti', long: 'Tirsdag' },
      { short: 'On', long: 'Onsdag' },
      { short: 'To', long: 'Torsdag' },
      { short: 'Fr', long: 'Fredag' },
      { short: 'Lø', long: 'Lørdag' },
      { short: 'Sø', long: 'Søndag' },
    ];
  }
  if (lang === 'en-GB') {
    return [
      { short: 'Mon', long: 'Monday' },
      { short: 'Tue', long: 'Tuesday' },
      { short: 'Wed', long: 'Wednesday' },
      { short: 'Thu', long: 'Thursday' },
      { short: 'Fri', long: 'Friday' },
      { short: 'Sat', long: 'Saturday' },
      { short: 'Sun', long: 'Sunday' },
    ];
  }
  return [];
};

export const getMonths = (lang: string): string[] => {
  if (lang === 'no') {
    return [
      'Januar',
      'Februar',
      'Mars',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Desember',
    ];
  }
  if (lang === 'en-GB') {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }
  return [];
};
