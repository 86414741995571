import React, { ChangeEvent, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import SelectedAsset from 'assets/svg/checkbox-square-on.svg';
import { HiddenInput } from './HiddenInput';
import { HiddenSpan, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';

type CheckboxProps = {
  id: string;
  tabIndex?: number;
  label?: string;
  value?: string;
  error?: boolean;
  readOnly: boolean;
  name: string;
  checked: boolean;
  onToggle: (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => void;
};

type StyleProps = {
  checked: boolean;
  readOnly: boolean;
  error?: boolean;
};

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const StyledCheckbox = styled.div`
  ${(_: StyleProps) => ''}
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.5rem;
  cursor: ${(props) => (props.readOnly ? 'default' : 'pointer')};
  border: ${(props) => (props.error ? props.theme.constants.borderThinError : props.theme.constants.borderPrimary)};

  ${(props) => {
    if (props.checked) {
      return css`
        background: ${props.theme.colors.accentPrimary} url(${SelectedAsset}) no-repeat center;
      `;
    } else if (props.readOnly) {
      return css`
        background: ${props.theme.colors.readOnly};
        opacity: 0.1;
      `;
    } else {
      return css`
        background: ${(props) => (props.error ? props.theme.colors.bgError : 'transparant')};
      `;
    }
  }};

  input:focus + & {
    box-shadow: ${(props) => props.theme.constants.borderFocusLight};
  }
`;

const CheckboxLabelContainer = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  align-items: center;
  grid-template-columns: repeat(2, auto);
`;

export const Checkbox = ({ id, checked, error, readOnly, value, onToggle }: CheckboxProps) => {
  return (
    <CheckboxContainer>
      <HiddenInput id={id} type="checkbox" value={value} aria-invalid={error} readOnly={readOnly} onToggle={onToggle} />
      <StyledCheckbox checked={checked} error={error} readOnly={readOnly} />
    </CheckboxContainer>
  );
};

export const CheckboxLabelRight = ({ ...props }: CheckboxProps) => {
  return (
    <CheckboxLabelContainer>
      <label htmlFor={props.id}>
        <Checkbox
          id={props.id}
          tabIndex={props.tabIndex}
          checked={props.checked}
          name={props.name}
          value={props.value}
          readOnly={props.readOnly}
          onToggle={props.onToggle}
        />
        <HiddenSpan>{props.label}</HiddenSpan>
      </label>
      <TxtSmallMediumDarkResp aria-hidden={true}>{props.label}</TxtSmallMediumDarkResp>
    </CheckboxLabelContainer>
  );
};
