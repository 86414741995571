import React from 'react';
import styled from 'styled-components';
import { Message } from 'types/MessagesTypes';
import { PageBannerMessage } from 'elements/messages/PageBannerMessage';

// Types
// =========================
type SectionPageMessagesProps = {
  messages: Message[];
  onCloseMessage: (index: number) => void;
};

// Styled components
// =========================
export const PageMessageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

// Component
// =========================
export const SectionPageMessages = ({ ...props }: SectionPageMessagesProps) => (
  <PageMessageContainer>
    {props.messages.map((message: Message, index) => (
      <PageBannerMessage
        key={index}
        type={message.type}
        text={message.title}
        message={message.message}
        closable={message.closable}
        onPageBannerClick={() => props.onCloseMessage(index)}
      />
    ))}
  </PageMessageContainer>
);
