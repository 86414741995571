import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogErrorContent } from 'components/new-design/dialogs/DialogErrorContent';
import { DialogCenterContentContainer } from 'elements/new-design/Dialogs';

type CancelDialogProps = {
  loading: boolean;
  onTryAgain: () => void;
  onModalClose: () => void;
};

export const CancelErrorDialog = ({ loading, onTryAgain, onModalClose }: CancelDialogProps) => {
  const { translate } = useI18n();
  return (
    <Dialog
      type="center"
      title={translate('GENERAL_ERROR_HEADLINE')}
      width={40}
      height={40}
      onModalClose={onModalClose}
    >
      <DialogCenterContentContainer>
        <DialogErrorContent
          headline={translate('GENERAL_ERROR_HEADLINE')}
          text={translate('CANCEL_TICKET_ERROR_TEXT')}
          loading={loading}
          onTryAgain={onTryAgain}
        />
      </DialogCenterContentContainer>
    </Dialog>
  );
};
