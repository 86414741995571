import React from 'react';
import styled from 'styled-components';
import TimeIcon from 'assets/svg/time.svg';
import { TxtDefaultBoldDarkResp } from 'elements/new-design/Typography';
import { Icon } from 'elements/icon/Icon';
import { BtnExpandDark } from 'elements/buttons/Buttons';
import { GOADate } from 'utils/date/GOADate';
import { TimeTable } from './TimeTable';

type TimePickerProps = {
  name: string;
  date: GOADate;
  minDate: GOADate;
  expanded: boolean;
  onToggle: () => void;
  handleTimeChanged: (d: GOADate, close: boolean) => void;
};

const DateTimeToggle = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  background: ${(props) => props.theme.colors.bgSecondary};
  cursor: pointer;
`;

const DateTimeWrapper = styled.div`
  position: relative;
  border: ${(props) => props.theme.constants.borderPrimary};
`;

const TimeWrapper = styled(DateTimeWrapper)`
  width: 38%;

  @media ${(props) => props.theme.devices.medium} {
    width: 30%;
  }
`;

const TypeIcon = styled(Icon)`
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  flex-shrink: 0;
`;

const DateToggleText = styled(TxtDefaultBoldDarkResp)`
  flex: 1;
  text-align: left;
`;

export const TimePicker = React.forwardRef(
  ({ ...props }: TimePickerProps, ref: React.Ref<HTMLButtonElement>): JSX.Element => {
    const { date, name, expanded, minDate, onToggle, handleTimeChanged } = props;
    return (
      <TimeWrapper>
        <DateTimeToggle
          type="button"
          id={`${name}ToggleTime`}
          data-cy={'timeToggleCy'}
          name={name}
          ref={ref}
          onClick={onToggle}
        >
          <TypeIcon icon={TimeIcon} />
          <DateToggleText>{date.formatTime()}</DateToggleText>
          <BtnExpandDark expanded={expanded} />
        </DateTimeToggle>
        {expanded ? <TimeTable name={name} date={date} minDate={minDate} onTimeChanged={handleTimeChanged} /> : null}
      </TimeWrapper>
    );
  },
);
