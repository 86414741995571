import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { TxtLinkDefault } from 'elements/links/Links';
import { FlexRow } from 'elements/containers/Containers';
import { MarginBottomTwo } from 'elements/distance/Margins';
import { isActionKey } from 'utils/Accessability';

type SidebarProps = {
  pageTitle: string;
  underTitles: [];
};

type StyleProps = {
  isAbsolute?: boolean;
  isActive?: boolean;
};

const SidebarContainer = styled.nav`
  ${(_: StyleProps) => ''}
  background-color: ${(props) => props.theme.colors.bgSecondary};
  padding: 2rem;
  height: 35rem;
  overflow: auto;
  border-top: ${(props) => props.theme.constants.borderPrimary};

  @media ${(props) => props.theme.devices.laptop} {
    max-width: 35rem;
    height: auto;
    position: sticky;
    top: 0;
    border-radius: 0.7rem;
    margin-right: 5.6rem;
    margin-top: 9.6rem;
    border-top: 0;
  }
`;

const SidebarTitle = styled(TxtLinkDefault)`
  font-family: ${(props) => props.theme.fonts.fontFamily.bold};
`;

const SidebarList = styled.ul`
  display: flex;
  flex-direction: column;
`;

const SidebarItem = styled.li`
  display: flex;

  &::marker {
    content: '';
  }
`;

const SidebarLink = styled(TxtLinkDefault)`
  ${(_: StyleProps) => ''}
  width: -webkit-fill-available;
  padding: 1rem 0;
  border-left: 1px solid black;
  padding-left: 1.5rem;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${(props) => props.theme.colors.bgTuna};
      color: ${(props) => props.theme.colors.txtColLight};
      border-radius: 3rem;
      margin-left: -1.5rem;
      padding-left: 1rem;
    `}
`;

const Dot = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  border-radius: 50%;
  margin-right: 1rem;
  padding-right: 1rem;
`;

export const Sidebar = ({ pageTitle, underTitles }: SidebarProps): JSX.Element => {
  const [isActive, setIsActive] = useState(undefined);
  const baseLayoutElement = document.getElementById('appContainer');

  const handleClick = (e, item, index) => {
    e.target.href = `#${item.children[0].text}`;
    e.currentTarget.href = `#${item.children[0].text}`;
    setIsActive(index);
  };

  const handleReset = () => {
    baseLayoutElement.scrollTo(0, 0);
    setIsActive(undefined);
  };

  return (
    <SidebarContainer>
      <SidebarTitle tabIndex={0} onClick={() => handleReset()} onKeyDown={() => handleReset()}>
        {pageTitle}
      </SidebarTitle>
      <MarginBottomTwo />
      <SidebarList>
        {underTitles.map((item, index) => {
          return (
            <SidebarItem key={index}>
              <SidebarLink
                tabIndex={0}
                isActive={isActive === index}
                onClick={(e) => handleClick(e, item, index)}
                onKeyDown={(e) => (isActionKey(e.key) ? handleClick(e, item, index) : null)}
              >
                <FlexRow>
                  {isActive === index && <Dot />}
                  {item.children[0].text}
                </FlexRow>
              </SidebarLink>
            </SidebarItem>
          );
        })}
      </SidebarList>
    </SidebarContainer>
  );
};
