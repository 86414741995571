import { ExternalCampaign_ActivateExternalCampaign } from 'apiV1/ExternalCampaign';
export const useExternalCampaignsApi = (token: string) => {
  const activateExternalCampaign = async (campaign: string, orderLineId: string) => {
    const body = { campaign, orderLineId };
    return await ExternalCampaign_ActivateExternalCampaign(body, { authorization: 'Bearer ' + token });
  };
  return {
    activateExternalCampaign,
  };
};
