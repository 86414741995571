import 'core-js/es/promise';
import React from 'react';
import ReactDOM from 'react-dom';
import store from './store/index';
import { Provider } from 'react-redux';
import { Theme } from 'styles/Theme';
import { Preloader } from 'components/app/Preloader';

ReactDOM.render(
  <Provider store={store}>
    <Theme>
      <Preloader />
    </Theme>
  </Provider>,
  document.getElementById('root'),
);

if (window && window['Cypress']) {
  window['store'] = store;
}
