import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PortableText } from '@portabletext/react';
import { AppState } from 'store/index';
import { useApi } from 'hooks/use-api/useApi';
import { useStateNavigation } from 'hooks/useStateNavigation';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { Login } from 'components/new-design/dialogs/login-dialog/Login';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { SmallContainer } from 'components/new-design/layouts/LayoutContainers';
import { BodyPortableText } from 'components/new-design/portable-texts/BodyPortableText';
import { FlexRow } from 'elements/containers/Containers';
import { MarginTop, MarginRight } from 'elements/distance/Margins';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { Premable } from 'elements/new-design/Typography';
import { Loader } from 'elements/loader/Loader';
import { SectionMessage } from 'elements/new-design/messages/SectionMessage';
import StrawberryPage from './StrawberryPage';

type PartnerProps = {
  pageData: any;
};

const StrawberryPageState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const PartnerComponent = ({ pageData }: PartnerProps): JSX.Element => {
  const { API_CALLS, guardedRequestGoOps } = useApi();
  const { translate } = useI18n();
  const { navigateWithState, cacheRedirect } = useStateNavigation();
  const { account } = useSelector((store: AppState) => store.user);
  const [loginModal, setLoginModal] = useState(false);
  const [state, setState] = useState<typeof StrawberryPageState>(Constants.LOADING);
  const [bonusPageData, setBonusPage] = useState(undefined);
  const [isStrawberryEnabled, setIsStrawberryEnabled] = useState(false);

  const initBonusRequest = async () => {
    setState(Constants.LOADING);

    try {
      const rawProfileData = await guardedRequestGoOps(API_CALLS.GET_STRAWBERRY_PROFILE);
      const bonusData = await guardedRequestGoOps(API_CALLS.GET_STRAWBERRY_BONUSES);

      if (bonusData && rawProfileData) {
        setBonusPage(bonusData.data.result);
        setIsStrawberryEnabled(rawProfileData.data.result.isStrawberrySortogetBonusEnabled);
      }
      setState(Constants.IDLE);
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  const handleCreateProfile = () => {
    cacheRedirect(Constants.ROUTE_CMS_STRAWBERRY);
    navigateWithState(Constants.ROUTE_CREATE_PROFILE);
  };

  useEffect(() => {
    if (account) {
      initBonusRequest();
    }
  }, [account]);

  return (
    <>
      {!account ? (
        <>
          <BreadcrumbsPage title={pageData.notLoggedIn.title} loading={false}>
            <SmallContainer>
              <Premable>{pageData.notLoggedIn.preamble}</Premable>
            </SmallContainer>

            <PortableText value={pageData.notLoggedIn.body} components={BodyPortableText} />
          </BreadcrumbsPage>
          {loginModal && (
            <Login
              showCreate={false}
              redirectTo={window.location.pathname}
              onLogin={() => true} //TODO: Refactor login dialog to not demand onLogin
              onModalClose={() => setLoginModal(false)}
            />
          )}

          <MarginTop margin={3}>
            <FlexRow>
              <MarginRight margin={3}>
                <BorderButtonDark big onClick={() => setLoginModal(true)}>
                  {translate('LOG_IN')}
                </BorderButtonDark>
              </MarginRight>
              <BorderButtonDark big onClick={handleCreateProfile}>
                {translate('CREATE_PROFILE')}
              </BorderButtonDark>
            </FlexRow>
          </MarginTop>
        </>
      ) : (
        <>
          {state === Constants.ERROR && isStrawberryEnabled && (
            <SectionMessage
              type={Constants.ERROR_MESSAGE}
              title={translate('GENERAL_ERROR_HEADLINE')}
              text={translate('TECHNICAL_ERROR_TEXT')}
              buttonText={translate('TRY_AGAIN')}
              onButtonClick={() => window.location.reload()}
            />
          )}
          {state === Constants.LOADING ? (
            <Loader />
          ) : (
            <StrawberryPage
              pageData={pageData.loggedIn}
              bonusPageData={bonusPageData}
              isStrawberryEnabled={isStrawberryEnabled}
            />
          )}
        </>
      )}
    </>
  );
};
