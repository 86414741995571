import styled from 'styled-components';
import { ContainerBoxShadow } from 'elements/new-design/Containers';

type StyleProps = {
  width?: number;
  height?: number;
};

export const DialogCommonContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 2.4rem 2rem 4rem 4rem;

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 47rem;
    min-height: ${(props) => `${props.height}rem`};
    padding: 2.4rem 2.4rem 4rem 4.8rem;
  }
`;

export const DialogContentContainer = styled.div`
  padding-right: 2rem;

  @media ${(props) => props.theme.breakpoints.large} {
    padding-right: 2.4rem;
  }
`;

export const DialogCenterContainer = styled(ContainerBoxShadow)`
  ${(_: StyleProps) => ''}
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => props.theme.constants.zIndexHigh};

  @media ${(props) => props.theme.devices.medium} {
    position: static;
    width: ${(props) => (props.width ? `${props.width}rem` : '47rem')};
    height: ${(props) => (props.height ? `${props.height}rem` : 'auto')};
  }
`;

export const DialogCenterContentContainer = styled.div`
  padding: 0 2.4rem 4rem 2.4rem;

  @media ${(props) => props.theme.devices.medium} {
    padding: 2.4rem 2.4rem 4rem 4.8rem;
  }
`;

export const DialogPageContainer = styled.div`
  ${(_: StyleProps) => ''}
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(props) => props.theme.newcolors.bgPrimary};
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10rem;

  width: 60%;
  height: 90%;
  align-self: center;
  justify-self: center;
`;

export const DialogCookieContainer = styled.div`
  ${(_: StyleProps) => ''}
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  padding: 3.2rem 2.4rem;
  align-items: flex-start;
  z-index: ${(props) => props.theme.constants.zIndexMega};

  @media ${(props) => props.theme.breakpoints.medium} {
    width: 45rem;
    top: auto;
    right: auto;
    border-top-right-radius: 1rem;
  }
`;

export const DialogLeftContainer = styled(ContainerBoxShadow)`
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  background: ${(props) => props.theme.colors.bgSecondary};

  @media ${(props) => props.theme.devices.laptop} {
    width: 50vw;
  }

  @media ${(props) => props.theme.devices.mega} {
    width: 40vw;
  }
`;

export const DialogLeftHeaderContainer = styled.header`
  width: 100vw;
  position: fixed;
  padding: 2rem;
  background: ${(props) => props.theme.colors.bgPrimary};
  border-bottom: ${(props) => props.theme.constants.borderPrimary};
  z-index: ${(props) => props.theme.constants.zIndexHigh};
  -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1);

  @media ${(props) => props.theme.devices.medium} {
    padding: 2rem 3.2rem;
  }

  @media ${(props) => props.theme.devices.laptop} {
    width: 50vw;
  }

  @media ${(props) => props.theme.devices.mega} {
    width: 40vw;
  }
`;

export const DialogLeftFooter = styled.footer`
  width: 100vw;
  position: fixed;
  padding: 2rem;
  background: ${(props) => props.theme.colors.bgPrimary};
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: ${(props) => props.theme.constants.zIndexHigh};
  border-top: ${(props) => props.theme.constants.borderPrimary};
  -moz-box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.1);

  @media ${(props) => props.theme.devices.medium} {
    padding: 2rem 3.2rem;
  }

  @media ${(props) => props.theme.devices.laptop} {
    width: 50vw;
  }

  @media ${(props) => props.theme.devices.mega} {
    width: 40vw;
  }
`;

export const DialogLeftContentContainer = styled.main`
  width: 100%;
  height: 100vh;
  padding: 0 2rem;
  overflow-y: scroll;
  background: ${(props) => props.theme.colors.bgPrimary};

  @media ${(props) => props.theme.devices.medium} {
    padding: 0 3.2rem;
  }
`;
