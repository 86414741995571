import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { UserPositionType } from 'types/PositionTypes';
import { MyPosition } from './MyPosition';
import { LocationType } from 'types/LocationTypes';

type MyPositionProps = {
  positionActive: boolean;
  onFetchingPosition: (fetching: boolean) => void;
  onPositionChange: (stationName: string) => void;
  onError: () => void;
};

const haversineDistance = (userPosition: UserPositionType, station: LocationType): number => {
  const toRadians = (x) => (x * Math.PI) / 180.0;
  const hav = (x) => (1 - Math.cos(x)) / 2;

  const R = 6378136.6; // IERS mean equatorial radius in meters
  const userLatRad = toRadians(userPosition.latitude);
  const userLongRad = toRadians(userPosition.longitude);
  const stationLatRad = toRadians(station.latitude);
  const stationLongRad = toRadians(station.longitude);

  const ht =
    hav(stationLatRad - userLatRad) +
    Math.cos(userLatRad) * Math.cos(stationLatRad) * hav(stationLongRad - userLongRad);

  return 2 * R * Math.asin(Math.sqrt(ht));
};

const sortDistance = (a: number, b: number): number => a - b;

const getNearestStation = (userPosition: UserPositionType, stations: LocationType[]) => {
  const tempStations = Array.from(stations);
  return tempStations
    .map((station) => {
      station['distance'] = haversineDistance(userPosition, station);
      return station;
    })
    .sort((a, b) => sortDistance(a['distance'], b['distance']));
};

export const MyPositionNearestStation = ({
  positionActive,
  onFetchingPosition,
  onPositionChange,
  onError,
}: MyPositionProps): JSX.Element => {
  const stations = useSelector((state: AppState) => state.locations.stopPlaces);

  const handleMyPosition = (coords) => {
    if (coords) {
      const nearestStation = getNearestStation(coords, stations)[0]['name'];
      onPositionChange(nearestStation);
    } else onPositionChange('');
  };

  return (
    <MyPosition
      positionActive={positionActive}
      onFetchingPosition={onFetchingPosition}
      onNewPosition={handleMyPosition}
      onError={onError}
    />
  );
};
