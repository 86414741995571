import { cloneDeep } from 'lodash';
import { Constants } from 'utils/Constants';

export const usePersistentStorage = (): any => {
  const cacheItem = (name: string, data: any): void => {
    localStorage.setItem(name, JSON.stringify(data));
  };

  const getCachedItem = (name: string): any => {
    const data = window.localStorage.getItem(name);
    return data ? JSON.parse(data) : null;
  };

  const removeCachedItem = (name: string): void => {
    window.localStorage.removeItem(name);
  };

  const cacheForm = (name: string, data): void => {
    sessionStorage.setItem(name, JSON.stringify([...data]));
  };

  const getCachedForm = (name: string): any => {
    const data = JSON.parse(sessionStorage.getItem(name));
    if (data) return new Map(data);
    return null;
  };

  const deleteCachedProfileForms = (): void => {
    if (getCachedForm('GOA:PROFILE_FORM') !== null) {
      sessionStorage.removeItem('GOA:PROFILE_FORM');
    }
    if (getCachedForm('GOA:CONSENT_FORM') !== null) {
      sessionStorage.removeItem('GOA:CONSENT_FORM');
    }
  };

  const removeCachedOrder = (): void => {
    removeCachedItem(Constants.CACHE_ORDER);
    removeCachedItem(Constants.CACHE_PAYMENT); //TODO: deprecated removed from code 2020-01-31
  };

  const deleteOldCache = () => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    if (settings && (settings.cookieConsent || settings.GOA_NECCESSARY_COOKIE === true))
      removeCachedItem(Constants.CACHE_SETTINGS);

    const searchHistory = getCachedItem(Constants.GOA_SEARCH_HISTORY);
    if (searchHistory) {
      const tempSettings = settings ? cloneDeep(settings) : {};
      tempSettings[Constants.CACHE_SEARCHED_STATIONS] = searchHistory;
      cacheItem(Constants.CACHE_SETTINGS, tempSettings);
      removeCachedItem(Constants.GOA_SEARCH_HISTORY);
    }
    // 25-09-2020 - clearing storage as some users will have "old" stations that will crash them.
    removeCachedItem('prevSearch');
    removeCachedItem('prevSearchTwo');
    removeCachedItem(Constants.OLD_CACHE_USER);
    removeCachedItem(Constants.OLD_CACHE_TEMP);
  };

  return {
    cacheItem,
    cacheForm,
    getCachedItem,
    getCachedForm,
    removeCachedItem,
    deleteCachedProfileForms,
    removeCachedOrder,
    deleteOldCache,
  };
};
