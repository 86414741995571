import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { CustomerServiceEmail } from 'elements/contacts/CustomerServiceEmail';
import { CustomerServicePhone } from 'elements/contacts/CustomerServicePhone';
import { TxtSmallBoldDarkResp, TxtXSmallMediumDarkResp } from 'elements/new-design/Typography';
import { MarginBottom } from 'elements/distance/Margins';

type AssistanceIsBookedProps = {
  referenceNumber: string;
};

export const AssistanceIsBooked = ({ referenceNumber }: AssistanceIsBookedProps) => {
  const { translate } = useI18n();
  return (
    <div>
      <p>
        <TxtSmallBoldDarkResp>{translate('ASSISTANCE_IS_BOOKED')}</TxtSmallBoldDarkResp>
      </p>
      <p>
        <TxtSmallBoldDarkResp>
          {translate('CASE_NUMBER')}: {referenceNumber}
        </TxtSmallBoldDarkResp>
      </p>
      <p>
        <TxtXSmallMediumDarkResp>{translate('CONTACT_BANENOR')}</TxtXSmallMediumDarkResp>
      </p>
      <MarginBottom margin={0.8} />
      <div>
        <CustomerServicePhone number={Constants.BANENOR_PHONE_LINK} text={Constants.BANENOR_PHONE_NUMBER} />
      </div>
      <div>
        <CustomerServiceEmail
          email={Constants.BANENOR_CUSTOMER_SERVICE_EMAIL}
          displayText={Constants.BANENOR_CUSTOMER_SERVICE_EMAIL}
          mailSubject={`Assistanse ${referenceNumber}`}
        />
      </div>
    </div>
  );
};
