import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { TransactionOrderLineType } from 'types/HistoryTypes';
import { Constants } from 'utils/Constants';
import { formatShortPrice } from 'utils/Helpers';
import { Cancel } from './cancel/Cancel';
import { MarginTop } from 'elements/distance/Margins';
import { TxtLargeBoldDarkResp, TxtXSmallMediumMediumResp } from 'elements/new-design/Typography';
import { FlexBetweenStart, FlexColumnEnd } from 'elements/containers/Containers';

type TicketInfoProps = {
  orderLine: TransactionOrderLineType;
  onCancel: () => void;
};

const getTaxRates = (ticketDistributions) => {
  const taxRates = ticketDistributions
    .map((ticket) => {
      if (ticket.price.taxRate) return ticket.price.taxRate;
      return ticket.price.taxRates;
    })
    .reduce((acc: string[], taxRate: string) => acc.concat(taxRate), []);
  const uniqueTaxes = [...new Set(taxRates)];
  return uniqueTaxes.map((tax, index) => {
    if (index !== uniqueTaxes.length - 1) return `${tax}% og `;
    return `${tax}%`;
  });
};

export const TicketInfo = ({ orderLine, onCancel }: TicketInfoProps) => {
  const { translate } = useI18n();
  return (
    <>
      <FlexBetweenStart>
        <div data-cy={'ticketReference'}>
          <TxtXSmallMediumMediumResp>{translate('REFERENCE')}</TxtXSmallMediumMediumResp>
          <br />
          <TxtXSmallMediumMediumResp>{orderLine.orderId}</TxtXSmallMediumMediumResp>
          <MarginTop margin={1} />
          <TxtXSmallMediumMediumResp data-cy={'ticketSource'}>
            {translate('TICKET_BOUGHT_FROM')}
          </TxtXSmallMediumMediumResp>
          <br />
          <TxtXSmallMediumMediumResp>
            {orderLine.pointOfSales === Constants.GO_AHEAD_ENTUR_WEB
              ? translate('GO_ALIVE_WEB')
              : orderLine.pointOfSales}
          </TxtXSmallMediumMediumResp>
        </div>
        <div>
          <FlexColumnEnd data-cy="priceAndTax">
            <TxtLargeBoldDarkResp>
              {formatShortPrice(orderLine.ticketDistributions[0].price.amount)}
            </TxtLargeBoldDarkResp>
            <TxtXSmallMediumMediumResp data-cy="taxAmountCy">{`${translate('TAX_OF')} ${
              orderLine.ticketDistributions[0].price.taxAmount
            } `}</TxtXSmallMediumMediumResp>
            <TxtXSmallMediumMediumResp>{`(${getTaxRates(orderLine.ticketDistributions)
              .join('')
              .toString()})`}</TxtXSmallMediumMediumResp>
          </FlexColumnEnd>
        </div>
      </FlexBetweenStart>
      <MarginTop margin={1} />
      {orderLine.refundOptions && orderLine.refundOptions.canBeCancelled ? (
        <Cancel orderLine={orderLine} onCancel={onCancel} />
      ) : (
        <TxtXSmallMediumMediumResp>
          {orderLine.refundOptions?.isCancelled ? '' : translate('JOURNEY_NOT_REFUNDABLE')}
        </TxtXSmallMediumMediumResp>
      )}
    </>
  );
};
