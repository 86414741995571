import React from 'react';
import styled, { css } from 'styled-components';
import ClearIcon from 'assets/svg/new-design/clear.svg';
import ClearIconFocus from 'assets/svg/new-design/clear-focus.svg';
import PositionIconFocus from 'assets/svg/new-design/position-focus.svg';
import PositionIcon from 'assets/svg/new-design/position.svg';
import PositionIconActive from 'assets/svg/new-design/position-active.svg';
import SwapPlaces from 'assets/svg/new-design/swap.svg';
import SwapPlacesFocus from 'assets/svg/new-design/swap-focus.svg';
import { CloseIconDark } from 'elements/new-design/icons/SymbolIcons';
import { VisibilityIconDark } from 'elements/new-design/icons/SymbolIcons';
import { HiddenSpan } from '../Typography';
import { TickTockAnimation } from 'elements/new-design/Animations';

type IconBtnProps = {
  id?: string;
  width?: number;
  height?: number;
  hiddenTxt: string;
  active?: boolean;
  disabled?: boolean;
  children?: JSX.Element;
  onButtonClick: () => void;
};

type MyPositionProps = IconBtnProps & {
  fetching: boolean;
};

type StyleProps = {
  width: number;
  height: number;
  active?: boolean;
  fetching?: boolean;
  disabled?: boolean;
};

type IconBtnStyleProps = {
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const IconBtn = styled.button.attrs({
  type: 'button',
})`
  ${(_: IconBtnStyleProps) => ''}
  width: ${(props) => (props.width ? `${props.width}rem` : '')};
  height: ${(props) => (props.height ? `${props.height}rem` : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  &:focus-visible {
    box-shadow: ${(props) => props.theme.newconstants.keyNavFocus};
  }
`;

export const IconBtnFocus = styled(IconBtn)`
  &:focus {
    box-shadow: ${(props) => props.theme.newconstants.keyNavFocus};
  }
`;

export const IconBtnBasic = React.forwardRef<HTMLButtonElement, IconBtnProps>(
  (props: IconBtnProps, ref): JSX.Element => (
    <IconBtn ref={ref} onClick={props.onButtonClick} {...props}>
      {props.children}
      <HiddenSpan>{props.hiddenTxt}</HiddenSpan>
    </IconBtn>
  ),
);

export const IconBtnBasicFocus = React.forwardRef<HTMLButtonElement, IconBtnProps>(
  (props: IconBtnProps, ref): JSX.Element => (
    <IconBtnFocus ref={ref} onClick={props.onButtonClick}>
      {props.children}
      <HiddenSpan>{props.hiddenTxt}</HiddenSpan>
    </IconBtnFocus>
  ),
);

const ClearField = styled(IconBtn)`
  ${(_: StyleProps) => ''}
  background: url(${ClearIcon}) no-repeat center;

  @media ${(props) => props.theme.breakpoints.large} {
    &:hover {
      background: url(${ClearIconFocus}) no-repeat center;
      transition: all 150ms ease-in;
    }
  }
`;

const MyPosition = styled(IconBtn)`
  ${(_: StyleProps) => ''}
  background: ${(props) =>
    props.active ? `url(${PositionIconActive}) no-repeat center` : `url(${PositionIcon}) no-repeat center`};
  ${(props) =>
    props.fetching &&
    css`
      ${TickTockAnimation};
    `}

  @media ${(props) => props.theme.breakpoints.large} {
    &:hover {
      background: ${(props) =>
        props.active ? `url(${PositionIconActive}) no-repeat center` : `url(${PositionIconFocus}) no-repeat center`};
      transition: all 150ms ease-in;
    }
  }
`;

const Swap = styled(IconBtn)`
  ${(_: StyleProps) => ''}
  background: url(${SwapPlaces}) no-repeat center;

  @media ${(props) => props.theme.breakpoints.large} {
    &:hover {
      background: url(${SwapPlacesFocus}) no-repeat center;
      transition: all 150ms ease-in;
    }
  }
`;

const SwapCircle = styled(IconBtn)`
  ${(_: StyleProps) => ''}
  border: ${(props) => props.theme.newconstants.borderDarkThin};
  border-radius: 50%;
  transform: rotate(90deg);
  background: ${(props) => `${props.theme.newcolors.bgPrimary} url(${SwapPlaces}) no-repeat center`};

  @media ${(props) => props.theme.breakpoints.large} {
    &:hover {
      border: ${(props) => props.theme.newconstants.borderDarkThick};
      background: ${(props) => `${props.theme.newcolors.bgPrimary} url(${SwapPlacesFocus}) no-repeat center`};
      transition: all 150ms ease-in;
    }
  }
`;

export const ClearFieldBtn = ({ hiddenTxt, onButtonClick }: IconBtnProps): JSX.Element => (
  <ClearField width={2.4} height={2.4} onClick={onButtonClick}>
    <HiddenSpan>{hiddenTxt}</HiddenSpan>
  </ClearField>
);

export const MyPositionBtn = ({ hiddenTxt, active, fetching, onButtonClick }: MyPositionProps): JSX.Element => (
  <MyPosition width={2.4} height={2.4} active={active} fetching={fetching} onClick={onButtonClick}>
    <HiddenSpan>{hiddenTxt}</HiddenSpan>
  </MyPosition>
);

export const SwapBtn = ({ hiddenTxt, disabled, onButtonClick }: IconBtnProps): JSX.Element => (
  <Swap width={2.4} height={2.4} disabled={disabled} onClick={disabled ? null : onButtonClick}>
    <HiddenSpan>{hiddenTxt}</HiddenSpan>
  </Swap>
);

export const SwapBtnCircle = ({ hiddenTxt, disabled, onButtonClick }: IconBtnProps): JSX.Element => (
  <SwapCircle width={4.8} height={4.8} disabled={disabled} onClick={disabled ? null : onButtonClick}>
    <HiddenSpan>{hiddenTxt}</HiddenSpan>
  </SwapCircle>
);

export const CloseBtn = ({ ...props }: IconBtnProps): JSX.Element => (
  <IconBtnFocus id={props.id} onClick={props.onButtonClick}>
    <HiddenSpan>{props.hiddenTxt}</HiddenSpan>
    <CloseIconDark width={props.width ? props.width : 2.4} height={props.height ? props.height : 2.4} />
  </IconBtnFocus>
);

export const VisibilityBtn = ({ ...props }: IconBtnProps): JSX.Element => (
  <IconBtnFocus onClick={props.onButtonClick}>
    <VisibilityIconDark width={2.4} height={2.4} active={props.active} />
  </IconBtnFocus>
);
