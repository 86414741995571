import { Entitlement_RegisterDiscountAsync, Entitlement_GetCustomerEntitlements } from 'apiV1/Entitlement';

export const useEntitlementApi = (token: string) => {
  const addEntitlement = async (data: { code: string }) => {
    const options = {
      authorization: 'Bearer ' + token,
      body: {
        code: data.code,
      },
    };
    return await Entitlement_RegisterDiscountAsync(options);
  };

  const getUserEntitlements = async () => {
    return await Entitlement_GetCustomerEntitlements({
      authorization: 'Bearer ' + token,
    });
  };

  return {
    addEntitlement,
    getUserEntitlements,
  };
};
