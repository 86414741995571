import styled, { css } from 'styled-components';

type StyleProps = {
  position?: string;
  fullWidth?: boolean;
};

export const SmallTop = css`
  margin-top: 1.6rem;

  @media ${(props) => props.theme.breakpoints.xlarge} {
    margin-top: 3.2rem;
  }
`;

export const LargeTop = css`
  margin-top: 3.2rem;

  @media ${(props) => props.theme.breakpoints.xlarge} {
    margin-top: 5.6rem;
  }
`;

export const LargeTopContainer = styled.div`
  ${LargeTop}
`;

export const SmallTopContainer = styled.div`
  ${SmallTop}
`;

export const LargeContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: flex;

  ${(props) => {
    if (props.position === 'left')
      return css`
        justify-content: flex-start;
        padding-right: 2.4rem;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding-right: 5.6rem;
        }
      `;
    else if (props.position === 'right')
      return css`
        justify-content: flex-end;
        padding-left: 2.4rem;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding-left: 5.6rem;
        }
      `;
    else
      return css`
        justify-content: center;
        padding: 0 2.4rem;

        @media ${(props) => props.theme.breakpoints.medium} {
          padding: 0 5.6rem;
        }

        @media ${(props) => props.theme.breakpoints.large} {
          padding: 0;
          width: 80rem;
        }
      `;
  }};

  @media ${(props) => props.theme.breakpoints.xlarge} {
    padding: 0;
    width: 110rem;
  }
`;

export const SmallContainer = styled(LargeContainer)`
  ${(_: StyleProps) => ''}
  flex-direction: column;
  @media ${(props) => props.theme.breakpoints.large} {
    padding: 0;
    width: 80rem;
  }
`;

export const SmallWidthSmallTop = styled(SmallContainer)`
  ${SmallTop}
`;

export const LargeWidthLargeTop = styled(LargeContainer)`
  ${LargeTop}
`;

export const LargeWidthLargeTopCenterSmall = styled(LargeWidthLargeTop)`
  @media ${(props) => props.theme.breakpoints.large} {
    justify-content: ${(props) =>
      props.position === 'center' ? 'center' : props.position === 'left' ? 'flex-start' : 'flex-end'};
  }
`;

export const CaptionContainer = styled.div`
  ${(_: StyleProps) => ''}
  padding: 0.8rem 0 0 2.4rem;
`;
