import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { FormFieldType } from 'types/FormTypes';
import { RecurringCardType } from 'types/RecurringPaymentTypes';
import { RadioSelectionBoxGroup, RadioSelectionBox } from 'elements/forms/RadioSelectionBox';
import { RadiobuttonLabelRight } from 'elements/forms/Radiobutton';
import { FlexColumnNoCenter, FlexBetween, FlexEnd } from 'elements/containers/Containers';
import { TxtSmallMediumMediumResp } from 'elements/new-design/Typography';

type SectionPaymentMethodsProps = {
  field: FormFieldType;
  cards: RecurringCardType[];
  onInputChange: (field: FormFieldType, value: string | number) => void;
};

const RecurringPaymentContainer = styled(FlexColumnNoCenter)`
  width: 100%;
`;

export const SectionPaymentMethod = ({ ...props }: SectionPaymentMethodsProps) => {
  const { translate } = useI18n();
  return (
    <RadioSelectionBoxGroup field={props.field}>
      <>
        {props.cards &&
          props.cards.map((card, index) => {
            const label = card.paymentType.toLowerCase();
            const capitalized = label.charAt(0).toUpperCase() + label.slice(1);
            return (
              <RadioSelectionBox
                key={index}
                checked={props.field.value === card.recurringPaymentId}
                onInputChange={() => props.onInputChange(props.field, card.recurringPaymentId)}
              >
                <RecurringPaymentContainer>
                  <FlexBetween>
                    <RadiobuttonLabelRight
                      id={card.recurringPaymentId}
                      value={card.recurringPaymentId}
                      label={`${capitalized} ${card.nickname ? card.nickname : ''}`}
                      checked={props.field.value === card.recurringPaymentId}
                      onToggle={() => props.onInputChange(props.field, card.recurringPaymentId)}
                    />
                    <p>
                      <TxtSmallMediumMediumResp>{`****${card.maskedPan}`}</TxtSmallMediumMediumResp>
                    </p>
                  </FlexBetween>
                  {card.primary && (
                    <FlexEnd>
                      <p>
                        <TxtSmallMediumMediumResp>{translate('PRIMARY')}</TxtSmallMediumMediumResp>
                      </p>
                    </FlexEnd>
                  )}
                </RecurringPaymentContainer>
              </RadioSelectionBox>
            );
          })}
        {Constants.PAYMENT_OPTIONS.map((option, index) => (
          <RadioSelectionBox
            key={index}
            checked={props.field.value === option.key}
            onInputChange={() => props.onInputChange(props.field, option.key)}
          >
            <RadiobuttonLabelRight
              id={option.key}
              value={option.key}
              label={option.text}
              checked={props.field.value === option.key}
              onToggle={() => props.onInputChange(props.field, option.key)}
            />
          </RadioSelectionBox>
        ))}
      </>
    </RadioSelectionBoxGroup>
  );
};
