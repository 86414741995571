import { useSelector } from 'react-redux';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useUpdateState } from 'hooks/useUpdateState';
import { getTranslation, getWeekDays, getMonths } from 'utils/i18n/i18n';
import { Constants } from 'utils/Constants';
import { AppState } from 'store/index';
import { GOADate } from 'utils/date/GOADate';

const BASE_LANGUAGE = 'no';

const mapDateLanguage = (language: string) => {
  if (language === 'en') return 'en-GB';
  if (language === 'nb') return BASE_LANGUAGE;
  return language;
};

export const useI18n = () => {
  const currentLanguage = useSelector((store: AppState) => store.global.language);
  const { getCachedItem, cacheItem } = usePersistentStorage();
  const { setCurrentLanguage } = useUpdateState();
  const LANGUAGES = Constants.LANGUAGES;

  const initI18n = () => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    const language = settings?.LANGUAGE ? settings.LANGUAGE : BASE_LANGUAGE;
    cacheItem(Constants.CACHE_SETTINGS, Object.assign(settings ? settings : {}, { LANGUAGE: language }));
    setCurrentLanguage(language);
  };

  const translate = (key: string) => getTranslation(currentLanguage)[key];
  const getTranslatedWeekdays = () => getWeekDays(currentLanguage);
  const getTranslatedMonths = () => getMonths(currentLanguage);
  const getI18nDate = (date: GOADate) => {
    return new GOADate(date.toJSON(), mapDateLanguage(currentLanguage));
  };

  return {
    currentLanguage,
    BASE_LANGUAGE,
    LANGUAGES,
    initI18n,
    translate,
    getTranslatedWeekdays,
    getTranslatedMonths,
    getI18nDate,
  };
};
