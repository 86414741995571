import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { checkHasAssistance, getSuppTranslation, mapPassengerToGOAId } from 'utils/Helpers';
import { GOADate } from 'utils/date/GOADate';
import { TransactionCampaignInfoType, TransactionOrderLineType } from 'types/HistoryTypes';
import { AssistanceLocationsType } from 'types/LocationTypes';
import { Constants } from 'utils/Constants';
import { MyJourneyService } from '../../../MyJourneysService';
import { FlexBetweenStart, FlexBetween, FlexColumnNoCenter, FlexRow } from 'elements/containers/Containers';
import {
  TxtSmallBoldDarkResp,
  TxtSmallMediumMediumResp,
  TxtXSmallMediumMediumResp,
} from 'elements/new-design/Typography';
import {
  CarriageIconText,
  SeatIconText,
  BedIconText,
  TrainIconWithText,
} from 'elements/new-design/icons/IconsWithText';
import { Assistance } from './assistance/Assistance';
import { MarginBottom, MarginRight, MarginTop } from 'elements/distance/Margins';

type PassengerInfoProps = {
  campaignInfo: TransactionCampaignInfoType;
  orderLine: TransactionOrderLineType;
  assistanceLocations: AssistanceLocationsType;
  onBookedAssistance: () => void;
};

const Border = styled.div`
  width: 100%;
  margin: 1rem 0;
  border-bottom: ${(props) => props.theme.constants.borderPrimary};
`;

const isProductSleep = (ticket) => {
  const leg = ticket.legs.find((leg) => leg.datedServiceJourneyId === ticket.topology.datedServiceJourneyRef[0]);
  return (leg && leg.productName === Constants.PRODUCT_SLEEP) || ticket.group;
};

const getProductName = (ticket) => {
  const leg = ticket.legs.find((leg) => leg.datedServiceJourneyId === ticket.topology.datedServiceJourneyRef[0]);
  if (leg) return leg.productName;
  return '';
};

const hasSeatSupplements = (ticket) =>
  ticket.supplementProducts.find((supp) => MyJourneyService.isSeatSupplement(supp.product.id));

const concatenateDuplicates = (supplements) => {
  const concatenatedDuplicates = [];
  let existingSupp = null;
  supplements.map((supp) => {
    existingSupp = concatenatedDuplicates.find((s) => s.product.id === supp.product.id);
    if (!existingSupp) concatenatedDuplicates.push(supp);
    else existingSupp.price.amount = Number(existingSupp.price.amount) + Number(supp.price.amount);
  });
  return concatenatedDuplicates;
};

const getSupplements = (ticketDistributions) => {
  const supplements = ticketDistributions
    .filter((ticket) => ticket.supplementProducts && ticket.supplementProducts.length > 0)
    .map((dist) => dist.supplementProducts)
    .reduce((a, b) => a.concat(b), []);

  return concatenateDuplicates(supplements);
};

export const PassengerInfo = ({
  campaignInfo,
  orderLine,
  assistanceLocations,
  onBookedAssistance,
}: PassengerInfoProps) => {
  const { getPassWithPrice, isLuggageSupp } = MyJourneyService;
  const { ticketDistributions } = orderLine;
  const supplements = getSupplements(ticketDistributions);
  const isCancelled = orderLine.refundOptions ? orderLine.refundOptions.isCancelled : false;
  const { translate } = useI18n();

  return (
    <>
      {orderLine.ticketDistributions.map((ticket, index) => {
        const hasAssistance =
          checkHasAssistance(
            ticket.topology.fromStopPlace,
            ticket.topology.toStopPlace,
            assistanceLocations.locations,
          ) &&
          !isCancelled &&
          new GOADate().isBefore(ticket.departureTime);

        return (
          <React.Fragment key={index}>
            {index === 0 && (
              <>
                <FlexBetweenStart>
                  <TxtXSmallMediumMediumResp>{translate('PASSENGERS')}</TxtXSmallMediumMediumResp>
                </FlexBetweenStart>
                <FlexBetweenStart>
                  {!ticket.group ? (
                    <TxtSmallBoldDarkResp>
                      {translate(`PASSENGER_TYPE_${getPassWithPrice(ticket, supplements).name}_NAME`)}
                    </TxtSmallBoldDarkResp>
                  ) : (
                    <FlexRow>
                      {ticket.group.userProfileList.map((profile, index) => (
                        <TxtSmallBoldDarkResp key={index}>
                          {translate(`PASSENGER_TYPE_${mapPassengerToGOAId(profile.id)}_NAME`)}
                          {index !== ticket.group.userProfileList.length - 1 ? <span>, </span> : null}
                        </TxtSmallBoldDarkResp>
                      ))}
                    </FlexRow>
                  )}
                </FlexBetweenStart>
                {Boolean(supplements.length) &&
                  supplements.map((supp, idx: number) => {
                    if (isLuggageSupp(supp.product.id)) {
                      return (
                        <FlexBetweenStart key={idx}>
                          <TxtSmallBoldDarkResp>{translate(getSuppTranslation(supp.product.id))}</TxtSmallBoldDarkResp>
                        </FlexBetweenStart>
                      );
                    }
                  })}
              </>
            )}
            <Border />

            {campaignInfo ? (
              <TxtSmallMediumMediumResp>{campaignInfo.ticketInfoText}</TxtSmallMediumMediumResp>
            ) : (
              <>
                <FlexBetween>
                  <FlexColumnNoCenter>
                    <TxtXSmallMediumMediumResp>
                      {ticket.topology.fromStopPlaceName} - {ticket.topology.toStopPlaceName}
                    </TxtXSmallMediumMediumResp>
                    <TxtSmallBoldDarkResp data-cy={'legTypeCy'}>
                      {getProductName(ticket)}
                      {hasSeatSupplements(ticket) &&
                        `, ${translate(getSuppTranslation(hasSeatSupplements(ticket).product.id))}`}
                    </TxtSmallBoldDarkResp>
                  </FlexColumnNoCenter>
                  <TrainIconWithText
                    text={
                      ticket.legs.find((leg) =>
                        ticket.topology.datedServiceJourneyRef.some((ref) => ref === leg.datedServiceJourneyId),
                      ).linePublicCode
                    }
                  />
                </FlexBetween>
                <MarginTop margin={1.6} />
                <FlexRow>
                  {Boolean(ticket.reservations.length) && (
                    <MarginRight margin={1}>
                      <CarriageIconText
                        head={translate('WAGON')}
                        text={ticket.reservations[0].seats[0] && ticket.reservations[0].seats[0].railcarNumber}
                      />
                    </MarginRight>
                  )}
                  <div>
                    {isProductSleep(ticket) ? (
                      <BedIconText
                        head={translate('COMPARTMENT')}
                        text={MyJourneyService.getSeats(ticket, Boolean(ticket.reservations.length), translate)}
                      />
                    ) : (
                      <SeatIconText
                        head={translate('SEAT')}
                        text={MyJourneyService.getSeats(ticket, Boolean(ticket.reservations.length), translate)}
                      />
                    )}
                  </div>
                </FlexRow>
                <MarginBottom margin={1.6} />
                {hasAssistance && (
                  <Assistance
                    orderLine={orderLine}
                    ticket={ticket}
                    departureTime={ticket.departureTime}
                    deadlineBookAssistance={assistanceLocations.lastOrderTimeHours}
                    assistanceLocations={assistanceLocations.locations.filter(
                      (loc) => loc.id === ticket.topology.fromStopPlace || loc.id === ticket.topology.toStopPlace,
                    )}
                    onBookedAssistance={onBookedAssistance}
                  />
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
