import styled, { css } from 'styled-components';
import ExclamationIcon from 'assets/svg/exclamation-mark.svg';
import CheckboxOn from 'assets/svg/new-design/checkbox-on-dark.svg';
import CheckboxOff from 'assets/svg/new-design/checkbox-off-dark.svg';
import CheckboxOnReadonly from 'assets/svg/new-design/checkbox-readonly.svg';
import { fontLargeStatic, fontFamilyBold, fontSizeDefaultResp, fontColorDark, fontColorError } from './Typography';

// INPUT FONTS
export const TxtPlaceHolderCSS = css`
  ${fontSizeDefaultResp};
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const InputPlaceholderLabel = styled.label`
  ${TxtPlaceHolderCSS}
`;

export const InputLabel = styled.label`
  ${fontSizeDefaultResp}
  ${fontFamilyBold}
  ${fontColorDark}
`;

export const InputErrorLabel = styled(InputLabel)`
  ${fontColorError}
`;

export const CommonErrorLabel = styled.label`
  ${fontLargeStatic};
  ${fontFamilyBold}
  ${fontColorError}
`;

/***** TEXT INPUT *****/
export const TextInputDefault = styled.input.attrs((props) => ({ type: props.type }))`
  ${TxtPlaceHolderCSS}
  width: 100%;
  height: 100%;
  background: inherit;
  border: none;
  border-radius: 0;
  border-bottom: ${(props) => props.theme.newconstants.borderDarkThin};
  padding-bottom: ${(props) => (props.type === 'password' ? '0.1rem' : '0.6rem')};

  :focus,
  :focus-visible {
    padding-bottom: ${(props) => (props.type === 'password' ? '0' : '0.5rem')};
    border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
    box-shadow: none;
  }

  /* remove background autofill*/
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

/**** CHECKBOX ****/
export const HiddenInput = styled.input.attrs((props) => ({ type: props.type }))`
  order: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0.2rem;
  cursor: pointer;
  background: url(${CheckboxOff}) no-repeat center;

  input:hover + &,
  input:focus-visible + & {
    box-shadow: ${(props) => props.theme.newconstants.keyNavFocus};
  }
  input:checked + & {
    background: url(${CheckboxOn}) no-repeat center;
  }
  input:disabled + & {
    background: url(${CheckboxOnReadonly}) no-repeat center;
    box-shadow: none;
  }
`;

/**** COMMON ****/
export const ErrorIcon = styled.span`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-left: -0.7rem;
  background: ${(props) => props.theme.colors.error} url(${ExclamationIcon}) center;
  background-size: 1.2rem;
`;
