import styled from 'styled-components';
import GoAheadNordic from 'assets/svg/new-design/GAN-web-blue.svg';
import GoAheadNordicWhite from 'assets/svg/new-design/GAN-web-white.svg';
import GoAheadIcon from 'assets/svg/new-design/GAN-appicon-blue.svg';
import GoAheadIconWhite from 'assets/svg/new-design/GAN-appicon-whitetransparent.svg';

type StyleProps = {
  white?: boolean;
  width?: number;
  height?: number;
};

export const LogoGoAhead = styled.div`
  ${(_: StyleProps) => ''}
  width: ${(props) => `${props.width}rem`};
  height: ${(props) => `${props.height}rem`};
  background: ${(props) => (props.white ? `url(${GoAheadNordicWhite})` : `url(${GoAheadNordic})`)} no-repeat;
  flex-shrink: 0;
  background-size: contain;
`;

export const LogoAppIcon = styled(LogoGoAhead)`
  ${(_: StyleProps) => ''}
  background: ${(props) => (props.white ? `url(${GoAheadIconWhite})` : `url(${GoAheadIcon})`)}  no-repeat;
`;
