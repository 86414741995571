import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module History */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Returns a list of orders customer has made. To do a detailed search of a specific order
 * save OrderId and ServiceJourneyRef and send into details/{orderId}/{serviceJourneyRef}.
 * To map passengers from details/ api, save the OrderLineId as it represents each leg trip for a specific passenger.
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] Authorization token with customer number
 * @param {number} [options.pageNbr] page number of results
 * @param {number} [options.itemsPerPage=30] Numer of items per page. Default value is 30
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerOrderHistoryResponse1>} Success
 */
export function History_GetTransactionHistory(options?: History_GetTransactionHistoryOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerOrderHistoryResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    query: {
      pageNbr: options.pageNbr,
      itemsPerPage: options.itemsPerPage,
      returnMockedData: options.returnMockedData
    }
  }
  return gateway.request(History_GetTransactionHistoryOperation, parameters)
}

/**
 * Returns a list of orders customer has made. To do a detailed search of a specific order
 * save OrderId and ServiceJourneyRef and send into details/{orderId}/{serviceJourneyRef}.
 * To map passengers from details/ api, save the OrderLineId as it represents each leg trip for a specific passenger.
 * 
 * @param {string} orderId orderId for order
 * @param {object} options Optional options
 * @param {string} [options.authorization] Authorization token with customer number
 * @param {boolean} [options.returnMockedData] Returns mocked CustomerOrderHistoryItem
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCustomerOrderHistoryItem1>} Success
 */
export function History_GetTransactionHistoryOrder(orderId: string, options?: History_GetTransactionHistoryOrderOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCustomerOrderHistoryItem1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    path: {
      orderId
    },
    header: {
      authorization: options.authorization
    },
    query: {
      returnMockedData: options.returnMockedData
    }
  }
  return gateway.request(History_GetTransactionHistoryOrderOperation, parameters)
}

/**
 * @param {string} orderId 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {boolean} [options.returnMockedData] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultTicketDistributionGroups1>} Success
 */
export function History_GetDetails(orderId: string, options?: History_GetDetailsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultTicketDistributionGroups1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      orderId
    },
    query: {
      returnMockedData: options.returnMockedData
    }
  }
  return gateway.request(History_GetDetailsOperation, parameters)
}

export interface History_GetTransactionHistoryOptions {
  /**
   * Authorization token with customer number
   */
  authorization?: string
  /**
   * page number of results
   */
  pageNbr?: number
  /**
   * Numer of items per page. Default value is 30
   */
  itemsPerPage?: number
  returnMockedData?: boolean
}

export interface History_GetTransactionHistoryOrderOptions {
  /**
   * Authorization token with customer number
   */
  authorization?: string
  /**
   * Returns mocked CustomerOrderHistoryItem
   */
  returnMockedData?: boolean
}

export interface History_GetDetailsOptions {
  authorization?: string
  returnMockedData?: boolean
}

const History_GetTransactionHistoryOperation: api.OperationInfo = {
  path: '/rest/v2/History',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const History_GetTransactionHistoryOrderOperation: api.OperationInfo = {
  path: '/rest/v2/History/historyOrder/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const History_GetDetailsOperation: api.OperationInfo = {
  path: '/rest/v2/History/details/{orderId}',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
