import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import styleColors from 'styles/colors';
import { useI18n } from 'utils/i18n/usei18n';
import { useDevice } from 'hooks/useDevice';
import { GOADate } from 'utils/date/GOADate';
import { TransactionCampaignInfoType, TransactionType } from 'types/HistoryTypes';
import { Transaction } from './transaction/Transaction';
import { Ticket } from 'elements/tickets/Ticket';
import { ListHeader } from './ListHeader';
import { TicketHeader } from './TicketHeader';
import { TxtSmallBoldAccentResp } from 'elements/new-design/Typography';
import { MarginRight, MarginTop } from 'elements/distance/Margins';
import { DirectionDownAccentIcon } from 'elements/icon/arrow-icons';
import { ButtonBorderPrimary } from 'elements/buttons/Buttons';
import { FlexCenterCenter } from 'elements/containers/Containers';

type Props = {
  currentList: TransactionType[];
  activeTab: string;
  onCancel: (transaction: TransactionType) => void;
};

type StyleProps = {
  expired?: boolean;
  disabled?: boolean;
};

export const TicketList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  list-style-type: none;
`;

const TicketListItem = styled.li`
  ${(_: StyleProps) => ''}
  width: 100%;
  margin-bottom: 1.6rem;
`;

const getBackground = (expired: boolean, selected: boolean, campaignInfo: TransactionCampaignInfoType): string => {
  if (expired) return styleColors.bgExpired;
  if (selected) return styleColors.accentPrimary;
  if (campaignInfo) return campaignInfo.primaryColor;
  return styleColors.bgPrimary;
};

const getBorder = (expired: boolean): string => {
  if (expired) return styleColors.borderExpired;
  return styleColors.borderColPrimary;
};

export const TransactionList = ({ currentList, activeTab, onCancel }: Props) => {
  const { isSmallerThanLarge } = useDevice();
  const { translate } = useI18n();
  const [expanded, setExpanded] = useState(null);
  const [numTicketsShown, setNumTicketsShown] = useState(5);

  useEffect(() => {
    setExpanded(null);
  }, [activeTab]);

  const handleExpansion = (index: number): void => {
    if (index === expanded) setExpanded(null);
    else setExpanded(index);
  };

  return (
    <>
      {currentList.length !== 0 && (
        <TicketList>
          {!isSmallerThanLarge() && Boolean(currentList.length) && <ListHeader />}
          {currentList.slice(0, numTicketsShown).map((transaction, index) => {
            const expired = transaction.campaignInfo
              ? transaction.endOfValidity.isBefore(new GOADate())
              : transaction.arrivalTime.isBefore(new GOADate());
            const selected = expanded === index;
            return (
              <TicketListItem id={transaction.orderId} key={index} expired={expired} aria-expanded={selected}>
                <Ticket
                  multiple={transaction.passengers.length > 1 && !selected}
                  background={getBackground(expired, selected, transaction.campaignInfo)}
                  border={getBorder(expired)}
                  data-cy={'jTicket'}
                >
                  <>
                    <TicketHeader
                      ariaLabel={`${translate('JOURNEY')} ${index + 1}`}
                      transaction={transaction}
                      expired={expired}
                      selected={selected}
                      onToggleTicket={() => handleExpansion(index)}
                    />
                    {selected && <Transaction transaction={transaction} expired={expired} onCancel={onCancel} />}
                  </>
                </Ticket>
              </TicketListItem>
            );
          })}
        </TicketList>
      )}
      <MarginTop margin={3}>
        <FlexCenterCenter>
          <ButtonBorderPrimary
            disabled={numTicketsShown >= currentList.length}
            onClick={() => setNumTicketsShown(numTicketsShown + 5)}
          >
            <MarginRight margin={1}>
              <DirectionDownAccentIcon width={1.5} height={1.5} />
            </MarginRight>
            <TxtSmallBoldAccentResp>
              {numTicketsShown >= currentList.length ? translate('NO_MORE_JOURNEYS') : translate('SHOW_MORE_JOURNEYS')}
            </TxtSmallBoldAccentResp>
          </ButtonBorderPrimary>
        </FlexCenterCenter>
      </MarginTop>
    </>
  );
};
