import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { SwapBtnCircle, SwapBtn } from 'elements/new-design/buttons/icon-buttons';

type SwapStationsProps = {
  isFormValid: boolean;
  onSwapStations: () => void;
};

const SwapBetweenContainer = styled.div`
  display: none;
  margin: 0 2rem;

  @media ${(props) => props.theme.breakpoints.large} {
    display: block;
  }
`;

const SwapOnTopContainer = styled.div`
  position: absolute;
  top: 5.5rem;
  right: 0.8rem;
  z-index: ${(props) => props.theme.constants.zIndexMega};

  @media ${(props) => props.theme.breakpoints.large} {
    display: none;
  }
`;

export const SwapStations = (props: SwapStationsProps): JSX.Element => {
  const { isFormValid, onSwapStations } = props;
  const { translate } = useI18n();
  return (
    <>
      <SwapBetweenContainer>
        <SwapBtn hiddenTxt={translate('SWAP_STATIONS')} disabled={!isFormValid} onButtonClick={onSwapStations} />
      </SwapBetweenContainer>
      {isFormValid && (
        <SwapOnTopContainer>
          <SwapBtnCircle
            hiddenTxt={translate('SWAP_STATIONS')}
            disabled={!isFormValid}
            onButtonClick={onSwapStations}
          />
        </SwapOnTopContainer>
      )}
    </>
  );
};
