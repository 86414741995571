import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { FormFieldType } from 'types/FormTypes';
import { Checkbox } from './Checkbox';
import { ErrorIcon } from 'elements/new-design/InputFields';
import { HiddenSpan, TxtSmallBoldAccentResp, TxtSmallMediumDarkResp } from 'elements/new-design/Typography';

type AcceptTermsProps = {
  field: any;
  checked: boolean;
  onInputChange: (field: FormFieldType, value: string) => void;
};

const CheckboxLabelContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1.2rem;
  align-items: center;
  grid-template-columns: repeat(2, auto);
`;

export const AcceptTerms = ({ field, checked, onInputChange }: AcceptTermsProps) => {
  const { translate } = useI18n();
  return (
    <CheckboxLabelContainer>
      {field.error && <ErrorIcon />}
      <label htmlFor={field.id}>
        <Checkbox
          id={field.id}
          checked={checked}
          name={field.id}
          value={field.value}
          error={field.error !== undefined}
          readOnly={false}
          onToggle={() => onInputChange(field, field.value)}
        />
        <HiddenSpan>{translate(field.label)}</HiddenSpan>
        {field.error && <HiddenSpan role="log">{translate(field.error)}</HiddenSpan>}
      </label>
      <p>
        <TxtSmallMediumDarkResp aria-hidden={true}>
          {translate('ACCEPT_TERMS_AND_CONDITION_TEXT')}{' '}
          <a
            href={Constants.TRANSPORT_CONDITIONS_CMS_SLUG}
            target="_blank"
            rel="noreferrer"
            aria-label={translate('SR_OPEN_TRANSPORT_TAB')}
          >
            <TxtSmallBoldAccentResp>{translate('TRANSPORT_CONDITIONS')}</TxtSmallBoldAccentResp>
          </a>{' '}
          {translate('AND')}{' '}
          <a
            href={Constants.PERSONAL_INTEGRITY_CMS_SLUG}
            target="_blank"
            rel="noreferrer"
            aria-label={translate('SR_OPEN_INTEGRITY_TAB')}
          >
            <TxtSmallBoldAccentResp>{translate('PERSONAL_INTEGRITY_DECLARATION')}</TxtSmallBoldAccentResp>
          </a>{' '}
        </TxtSmallMediumDarkResp>
      </p>
    </CheckboxLabelContainer>
  );
};
