import styled from 'styled-components';
import ArrowIcon from 'assets/svg/arrow-grey.svg';
import ArrowAccentIcon from 'assets/svg/arrow-down.svg';

type StyleProps = {
  width?: number;
  height?: number;
};

const DirectionIcon = styled.div`
  ${(_: StyleProps) => ''}
  width: ${(props) => (props.width ? `${props.width}rem` : '3.5rem')};
  height: ${(props) => (props.height ? `${props.height}rem` : '3.5rem')};
`;

export const DirectionUpIcon = styled(DirectionIcon)`
  ${(_: StyleProps) => ''}
  background: url(${ArrowIcon}) no-repeat center;
  transform: rotate(180deg);
  background-size: ${(props) => (props.width ? `${props.width}rem ${props.height}rem` : '3.5rem 3.5rem')};
`;

export const DirectionDownAccentIcon = styled(DirectionIcon)`
  background: url(${ArrowAccentIcon}) no-repeat center;
  background-size: ${(props) => (props.width ? `${props.width}rem ${props.height}rem` : '1.5rem 1.5rem')};
`;

export const DirectionRightAccentIcon = styled(DirectionIcon)`
  background: url(${ArrowAccentIcon}) no-repeat center;
  transform: rotate(-90deg);
  background-size: ${(props) => (props.width ? `${props.width}rem ${props.height}rem` : '1.5rem 1.5rem')};
`;
