import React, { useState } from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { GOADate } from 'utils/date/GOADate';
import { CmsCampaignType } from 'types/CmsTypes';
import { InfoBox } from 'components/new-design/cms/InfoBox';
import { CampaignPurchase } from './CampaignPurchase';
import { ParagraphText } from 'elements/new-design/Typography';
import { BorderButtonDark } from 'elements/new-design/buttons/text-buttons';
import { MarginTop } from 'elements/distance/Margins';

type CampaignBoxProps = {
  boxData: CmsCampaignType;
  position?: string;
  layout: string;
};

export const CampaignBox = ({ boxData, position, layout }: CampaignBoxProps): JSX.Element => {
  const { translate } = useI18n();
  const [modalOpen, setModalOpen] = useState(null);
  const saleStart = new GOADate(boxData.saleStartAt);
  const campaignEnd = new GOADate(boxData.endAtUtc);
  const isActiveCampaign = new GOADate().isBefore(campaignEnd) && new GOADate().isAfter(saleStart);

  return (
    <>
      <InfoBox position={position} layout={layout} boxData={boxData}>
        <div>
          {isActiveCampaign ? (
            <>
              <ParagraphText>{boxData.callToActionText}</ParagraphText>
              <MarginTop margin={0.8} />
            </>
          ) : (
            <ParagraphText>{translate('SORPASSET_SALE_IS_OVER')}</ParagraphText>
          )}
          <MarginTop margin={2.4} />
          <BorderButtonDark
            inFocus={false}
            disabled={!isActiveCampaign}
            big
            onClick={isActiveCampaign ? () => setModalOpen(!modalOpen) : null}
          >
            {translate('BUY_SORPASSET')}
          </BorderButtonDark>
        </div>
      </InfoBox>
      {modalOpen && <CampaignPurchase campaign={boxData} onModalClose={() => setModalOpen(null)} />}
    </>
  );
};
