import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module Deviation */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Add Deviation, or update existing based on situationId.
 * 
 * @param {object} options Optional options
 * @param {string} [options.Apikey] 
 * @param {string} [options.ClientId] 
 * @param {file} [options.file] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultString1>} Accepted
 */
export function Deviation_PostDeviation(options?: Deviation_PostDeviationOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultString1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      Apikey: options.Apikey,
      ClientId: options.ClientId
    },
    formData: {
      file: options.file
    }
  }
  return gateway.request(Deviation_PostDeviationOperation, parameters)
}

/**
 * Get critical deviations that are currently active.
 */
export function Deviation_GetCritical(): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultSimplifiedDeviation1>> {
  return gateway.request(Deviation_GetCriticalOperation)
}

/**
 */
export function Deviation_GetAll(): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCombinedDeviations1>> {
  return gateway.request(Deviation_GetAllOperation)
}

/**
 * @param {object} options Optional options
 * @param {string} [options.Apikey] 
 * @param {string} [options.ClientId] 
 * @param {module:types.GoAliveRestApi.Controllers.ControllerModels.TargetedDeviation} [options.body] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultString1>} Success
 */
export function Deviation_PostTargetedDeviation(options?: Deviation_PostTargetedDeviationOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultString1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      Apikey: options.Apikey,
      ClientId: options.ClientId
    },
    body: {
      body: options.body
    }
  }
  return gateway.request(Deviation_PostTargetedDeviationOperation, parameters)
}

export interface Deviation_PostDeviationOptions {
  Apikey?: string
  ClientId?: string
  file?: file
}

export interface Deviation_PostTargetedDeviationOptions {
  Apikey?: string
  ClientId?: string
  body?: api.GoAliveRestApi.Controllers.ControllerModels.TargetedDeviation
}

const Deviation_PostDeviationOperation: api.OperationInfo = {
  path: '/rest/Deviation/deviation',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Deviation_GetCriticalOperation: api.OperationInfo = {
  path: '/rest/Deviation/critical',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Deviation_GetAllOperation: api.OperationInfo = {
  path: '/rest/Deviation',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const Deviation_PostTargetedDeviationOperation: api.OperationInfo = {
  path: '/rest/Deviation/targeted',
  contentTypes: ['application/json-patch+json','application/json','text/json','application/*+json'],
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
