import { UserType } from 'types/CustomerTypes';
import { Order } from 'types/OrderTypes';
import {
  Customer_EditConsents,
  Customer_UpdateTempUserProfile,
  Customer_CreateTemporaryCustomerProfile,
  Customer_GetProfile,
  Customer_ForgotPassword,
  Customer_VerifyCustomerCredentials,
  Customer_RefreshToken,
  Customer_UpdatePassword,
  Customer_DeleteCustomer,
  Customer_GetConstents,
  Customer_EditProfile,
  Customer_GetAvailableConsents,
} from 'apiV1/Customer';
import { Customer_CreateCustomerProfileV2Async } from 'apiV2/Customer';

export const useCustomerApi = (token: string, user: UserType, order: Order) => {
  const getProfile = async (paramToken: string = null) =>
    await Customer_GetProfile(paramToken ? 'Bearer ' + paramToken : 'Bearer ' + token);

  const createProfile = async (body) => await Customer_CreateCustomerProfileV2Async({ body });

  const editProfile = async (body) =>
    await Customer_EditProfile({
      authorization: 'Bearer ' + token,
      body,
    });

  const verifyCustomerCredentials = async (userData: { email: string; password: string }) =>
    await Customer_VerifyCustomerCredentials({ body: userData });

  const refreshToken = async () => await Customer_RefreshToken({ token: token, refreshToken: user.refreshToken });

  const getConsents = async () => await Customer_GetConstents({ authorization: 'Bearer ' + token });

  const getAvailableConsents = async () => await Customer_GetAvailableConsents();

  const editConsents = async (consentData: { id: string; value: boolean }) => {
    const body = [
      {
        consentChoice: consentData.value,
        consentId: consentData.id,
      },
      { consents: user.consents },
    ];
    return (await Customer_EditConsents('Bearer ' + token, body)).data.result;
  };

  const createTemporaryCustomerProfile = async () => await Customer_CreateTemporaryCustomerProfile();

  const updateTempProfile = async () =>
    await Customer_UpdateTempUserProfile({
      authorization: 'Bearer ' + token,
      body: {
        firstName: order.contactInformation.firstName,
        surname: order.contactInformation.surname,
        email: order.contactInformation.email,
        countryCode: order.contactInformation.telephoneNumberNoCountryCode
          ? order.contactInformation.countryCode
          : null,
        telephoneNumberNoCountryCode: order.contactInformation.telephoneNumberNoCountryCode
          ? order.contactInformation.telephoneNumberNoCountryCode
          : null,
      },
    });

  const forgotPassword = async (email: string) => await Customer_ForgotPassword({ body: { email } });

  const updatePassword = async (data: { oldPwd: string; newPwd: string }) => {
    const body = {
      email: user.email,
      oldPassword: data.oldPwd,
      newPassword: data.newPwd,
    };
    return await Customer_UpdatePassword({ authorization: `Bearer ${token}`, body });
  };

  const deleteCustomer = async () => await Customer_DeleteCustomer({ authorization: 'Bearer ' + token });

  return {
    getProfile,
    updateTempProfile,
    editConsents,
    createTemporaryCustomerProfile,
    forgotPassword,
    verifyCustomerCredentials,
    refreshToken,
    updatePassword,
    deleteCustomer,
    getConsents,
    editProfile,
    createProfile,
    getAvailableConsents,
  };
};
