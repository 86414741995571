import sanityClient from '@sanity/client';
import urlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { pageQuery, landingPageQuery, partnerPageQuery } from './queries';
import { GOADate } from 'utils/date/GOADate';
import { Config } from 'utils/Config';

type useSanityApiProps = {
  slug?: string;
  preview?: boolean;
};

const CMS_CONFIG = {
  projectId: process.env.SANITY_ID,
  dataset: Config().cmsDataset,
  apiVersion: `${new GOADate().format('yyyy-MM-dd')}`,
};

const cmsClient = sanityClient(
  Object.assign(CMS_CONFIG, {
    useCdn: true,
    withCredentials: false,
  }),
);

const cmsClientForPreview = sanityClient(
  Object.assign(CMS_CONFIG, {
    useCdn: false,
    withCredentials: true,
  }),
);

const urlFor = (source: SanityImageSource) =>
  urlBuilder({
    projectId: CMS_CONFIG.projectId,
    dataset: CMS_CONFIG.dataset,
  }).image(source);

export const useCmsApi = () => {
  const fetchImageUrl = (data: { image: SanityImageSource; width: number }): string =>
    urlFor(data.image).quality(50).auto('format').width(data.width).url();

  const fetchLandingPages = async () =>
    await cmsClient.fetch(
      `*[_type == 'informationalLink' || _type == 'articleLandingPage' && !(_id in path('drafts.**'))]|order(orderRank){
      ${landingPageQuery}
    `,
    );

  const fetchLandingPage = async ({ slug }: useSanityApiProps) =>
    await cmsClient.fetch(
      `*[slug.current == $slug && !(_id in path('drafts.**'))]{
        ${landingPageQuery}`,
      { slug },
    );

  const fetchPagesWithSlug = async () =>
    await cmsClient.fetch(
      `*[_type == 'standardPage' || _type == 'articlePage' || _type == 'partnerPage' && !(_id in path('drafts.**'))]|order(orderRank){
      title,
      slug,
      apiId,}
    `,
    );

  const fetchMenuLink = async ({ slug }: useSanityApiProps) =>
    await cmsClient.fetch(
      `*[slug.current == $slug && !(_id in path('drafts.**'))]{
      title,
      slug,
      standardPage[]->{
        title,
        slug,
      },
      'locales': *[_type == "translation.metadata" && references(^._id)].translations[].value->{
        'locale': language, 
        'slug': slug.current 
      },
    }`,
      { slug },
    );

  const fetchCookies = async () =>
    await cmsClient.fetch(
      `*[_type in ["cookieSettingsSection", "cookieInformationSection"] && !(originalId in path('drafts.**'))]{

        _type == "cookieSettingsSection" => {
          "title": cookieSettingsTitle,
          "text": cookieSettingsText,
          "acceptText": acceptCookiesText,
          "saveText": saveSettingsText,
          "listOfCookiesTitle": cookieListTitle,
          "cookies": Cookies[] -> {
            "title": cookieTitle,
            "text": cookieText,
            "mandatory": mandatoryCookie,
            apiId,
            "language": coalesce(_lang, language),
          },
          apiId,
          "language": coalesce(_lang, language),
      },          
      _type == "cookieInformationSection" => {
        "title": cookieInformationTitle,
        "text": cookieInformationText,
        "linktText": readMoreLinkText,
        "link": readMoreLink -> {
          slug,
          link -> {
            slug,
          },
        },
        "goToSettingsText": goToSettingsText,
        "acceptText": acceptCookiesText,
        apiId,
        "language": coalesce(_lang, language),
      },          
    }`,
    );

  const fetchMainPage = async () =>
    await cmsClient.fetch(
      `*[_type in ["startPage"] && !(_id in path('drafts.**'))]{
        ${pageQuery}
      `,
    );

  const fetchMainPagePreview = async ({ preview }: useSanityApiProps) => {
    return await cmsClientForPreview.fetch(
      `*[_type in ["startPage"] && (_id in path('drafts.**'))]{
        ${pageQuery}`,
      { preview },
    );
  };

  const fetchPage = async ({ slug }: useSanityApiProps) =>
    await cmsClient.fetch(
      `*[slug.current == $slug && _type != "partnerPage" && !(_id in path('drafts.**'))]{
        ${pageQuery}`,
      { slug },
    );

  const fetchPartnerPage = async ({ slug }: useSanityApiProps) =>
    await cmsClient.fetch(
      `*[_type == "partnerPage" && slug.current == $slug && !(_id in path('drafts.**'))]{
        ${partnerPageQuery}`,
      { slug },
    );

  const fetchPagePreview = async ({ slug }: useSanityApiProps) => {
    return await cmsClientForPreview.fetch(
      `*[slug.current == $slug && (_id in path('drafts.**'))]{
        ${pageQuery}
      `,
      { slug },
    );
  };

  const fetchSystemMessages = async (data) => {
    const language = data.currentLanguage;
    return await cmsClient.fetch(
      `*[_type == "systemMessage" && language == $language && !(_id in path('drafts.**'))]{
        title,
        message,
        launchAtUtc,
        endAtUtc,
        language,
        'locales': *[_type == "translation.metadata" && references(^._id)].translations[].value->{
          'locale': language, 
          'slug': slug.current 
        },
      }`,
      { language },
    );
  };

  return {
    fetchImageUrl,
    fetchMainPage,
    fetchMainPagePreview,
    fetchLandingPages,
    fetchLandingPage,
    fetchMenuLink,
    fetchCookies,
    fetchPage,
    fetchPartnerPage,
    fetchPagePreview,
    fetchSystemMessages,
    fetchPagesWithSlug,
  };
};
