import React from 'react';
import { useI18n } from 'utils/i18n/usei18n';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { DialogActionContent } from 'components/new-design/dialogs/DialogActionContent';
import { CustomerServiceContactInfo } from 'elements/contacts/CustomerServiceContactInfo';
import { EmailCircleIcon } from 'elements/icon/symbol-icons';
import { MarginBottom } from 'elements/distance/Margins';

type AssistanceProps = {
  text: string;
  onModalClose: () => void;
};

export const DialogCustomerService = ({ text, onModalClose }: AssistanceProps) => {
  const { translate } = useI18n();
  return (
    <Dialog type="center" title={translate('CONTACT_CUSTOMER_SERVICE')} height={48} onModalClose={onModalClose}>
      <DialogActionContent
        headline={translate('CONTACT_CUSTOMER_SERVICE')}
        text={text}
        loading={false}
        Icon={<EmailCircleIcon />}
      >
        <MarginBottom margin={2.4} />
        <CustomerServiceContactInfo />
        <MarginBottom margin={2.4} />
      </DialogActionContent>
    </Dialog>
  );
};
