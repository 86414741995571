import React from 'react';
import styled from 'styled-components';
import { useApi } from 'hooks/use-api/useApi';
import { ImageSize } from 'components/new-design/cms/ImageSize';
import { CaptionContainer } from 'components/new-design/layouts/LayoutContainers';
import { fontSizeXSmallResp, fontFamilyRegular, TxtSmallRegularDarkResp } from 'elements/new-design/Typography';

type StandaloneImageProps = {
  imageData: any;
  width?: number;
  height?: number;
};

type StyleProps = {
  color?: string;
  height?: number;
};

const RelativeParent = styled.div`
  width: 100%;
  position: relative;
`;
const Image = styled.img`
  ${(_: StyleProps) => ''}
  width: 100%;
  max-width: 80rem;
  height: auto;
  object-fit: cover;

  @media ${(props) => props.theme.breakpoints.large} {
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  }
`;

const Figure = styled.figure`
  display: flex;
  flex-direction: column;
`;

const AccreditationText = styled.figcaption`
  ${(_: StyleProps) => ''}
  position: absolute;
  left: 2.4rem;
  bottom: 1.6rem;
  ${fontFamilyRegular}
  ${fontSizeXSmallResp}
  color: ${(props) => (props.color ? props.color : props.theme.newcolors.txtColLight)};
`;

export const StandaloneImage = ({ imageData, width, height }: StandaloneImageProps): JSX.Element => {
  const { requestImageUrl } = useApi();
  return (
    <Figure>
      <RelativeParent>
        <Image
          src={requestImageUrl({ image: imageData.url, width: width ? width : ImageSize() })}
          alt={imageData.alt}
          width={width}
          height={height}
        />
        <AccreditationText color={imageData.copyrightColor}>{imageData.copyright}</AccreditationText>
      </RelativeParent>

      {imageData.caption ? (
        <CaptionContainer>
          <figcaption>
            <TxtSmallRegularDarkResp>{imageData.caption}</TxtSmallRegularDarkResp>
          </figcaption>
        </CaptionContainer>
      ) : null}
    </Figure>
  );
};
