import { Constants } from 'utils/Constants';

export const MENU = 'menu';

const getProfileRoutes = (translate: (key: string) => void) => {
  //TODO: create a sanity schema and put all mine-sider there
  return [
    {
      title: translate('MY_JOURNEYS'),
      slug: Constants.ROUTE_MY_JOURNEYS,
    },
    {
      title: translate('CUSTOMER_INFORMATION'),
      slug: Constants.ROUTE_EDIT_PROFILE,
    },
    {
      title: translate('PAYMENT_CARD'),
      slug: Constants.ROUTE_MY_CARDS,
    },
    {
      title: translate('PASSWORD'),
      slug: Constants.ROUTE_CHANGE_PASSWORD,
    },
    {
      title: translate('CLIENT_DATA'),
      slug: Constants.ROUTE_PROFILE_DATA,
    },
    {
      title: translate('STRAWBERRY_BONUS'),
      slug: translate('STRAWBERRY_SLUG'),
    },
    {
      title: translate('LOGOUT'),
      slug: undefined,
    },
  ];
};

export const HeaderService = {
  getProfileLinks: (translate: (key: string) => void) => {
    const routes = getProfileRoutes(translate);
    return {
      title: translate('MY_PAGES'),
      slug: undefined,
      subRows: routes.map((route) => {
        return {
          title: route.title,
          slug: route.slug,
        };
      }),
    };
  },
  getFAQLinks: (faqLinks) => {
    return faqLinks.map((link) => {
      return {
        language: link.language,
        title: link.title,
        slug: link.slug.current,
        subRows: link.subPages.map((page) => {
          return {
            title: page.title,
            slug: page.slug.current,
          };
        }),
      };
    });
  },
};
