import React from 'react';
import styled from 'styled-components';
import { useDevice } from 'hooks/useDevice';
import { useI18n } from 'utils/i18n/usei18n';
import { isActionKey } from 'utils/Accessability';
import { Constants } from 'utils/Constants';
import { TransactionType } from 'types/HistoryTypes';
import { NumberOfTickets } from '../../NumberOfTickets';
import { DirectionRightAccentIcon } from 'elements/icon/arrow-icons';
import { CheckmarkAccent } from 'elements/icon/Icon';
import { TxtSmallBoldAccentResp } from 'elements/new-design/Typography';
import { ButtonBorderPrimary } from 'elements/buttons/Buttons';
import { MarginRight } from 'elements/distance/Margins';

type ReceiptProps = {
  transaction: TransactionType;
  receiptSent: boolean;
  onSendReceipt: () => void;
};

type StyleProps = {
  receiptSent: boolean;
};

const ReceiptContainer = styled.div`
  ${(_: StyleProps) => ''}
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: ${(props) => props.theme.constants.borderPrimary};

  @media ${(props) => props.theme.devices.large} {
    padding: 1rem 2.4rem;
  }
`;

export const SendReciept = ({ transaction, receiptSent, onSendReceipt }: ReceiptProps) => {
  const { device } = useDevice();
  const { translate } = useI18n();
  const isSmallScreen = device === Constants.DEVICE_SMALL;

  return (
    <ReceiptContainer receiptSent={receiptSent}>
      <NumberOfTickets
        aria-hidden={true}
        transaction={transaction}
        displayCompactVersion={isSmallScreen}
        displaySelectedColor={false}
      />
      <ButtonBorderPrimary
        onClick={onSendReceipt}
        onKeyDown={(e) => (isActionKey(e.key) ? onSendReceipt : null)}
        data-cy={'receiptBtnTxt'}
      >
        <MarginRight margin={1}>
          <TxtSmallBoldAccentResp id="receiptButtonText" aria-live="polite">
            {receiptSent ? translate('RECEIPT_SENT') : translate('SEND_RECEIPT_EMAIL')}
          </TxtSmallBoldAccentResp>
        </MarginRight>
        {receiptSent ? <CheckmarkAccent /> : <DirectionRightAccentIcon width={1.5} height={1.5} />}
      </ButtonBorderPrimary>
    </ReceiptContainer>
  );
};
