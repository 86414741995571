import { GOADate } from 'utils/date/GOADate';
import { LocationType } from './LocationTypes';
import { SelectedSeatListType } from './SeatingTypes';
import { SelectedJourneyType } from './JourneyTypes';
import { RecurringCardType } from './RecurringPaymentTypes';
import { Passengers, Supplements } from './PassengerTypes';

export type ContactInformation = {
  firstName: string;
  surname: string;
  email: string;
  countryCode?: string;
  telephoneNumberNoCountryCode?: string;
  sendSMS?: boolean;
};

// Page states
export type PlannerState = {
  type?: string;
  from?: LocationType;
  to?: LocationType;
  originalDepartureDate?: GOADate;
  originalReturnDate?: GOADate;
  departureDate?: GOADate;
  returnDate?: GOADate;
  passengers?: Passengers;
  supplements?: Supplements;
};

export type JourneyState = {
  departureDate?: GOADate;
  journey?: SelectedJourneyType;
};

export type ReturnState = {
  returnDate?: GOADate;
  returnJourney?: SelectedJourneyType;
};

export type ReviewState = {
  orderID?: string;
  selectedSeats?: SelectedSeatListType;
  reserveExpiration?: GOADate;
};

export type PaymentState = {
  contactInformation?: ContactInformation;
  paymentMethod?: string | number;
  deliveryMethod?: string;
  termsAccepted?: boolean;
  transactionId?: string;
  recurringCard?: RecurringCardType;
  returnToPath?: string;
};

export type Order = PlannerState & JourneyState & ReturnState & ReviewState & PaymentState;

export type OrderState = Order;

// Set Order
// =========================
export const SET_ORDER = 'SET_ORDER';

type SetOrderAction = {
  type: typeof SET_ORDER;
  payload: Order;
};

// Set Type
// =========================
export const SET_TYPE = 'SET_TYPE';

type SetTypeAction = {
  type: typeof SET_TYPE;
  payload: string;
};

// Set Journey
// =========================
export const SET_JOURNEY = 'SET_JOURNEY';

type SetJourneyAction = {
  type: typeof SET_JOURNEY;
  payload: SelectedJourneyType;
};

// Set Return
// =========================
export const SET_RETURN_JOURNEY = 'SET_RETURN_JOURNEY';

type SetReturnAction = {
  type: typeof SET_RETURN_JOURNEY;
  payload: SelectedJourneyType;
};

// Set Original Departure Date
// =========================
export const SET_ORIGINAL_DEPARTURE_DATE = 'SET_ORIGINAL_DEPARTURE_DATE';

type SetOriginalDepartureDateAction = {
  type: typeof SET_ORIGINAL_DEPARTURE_DATE;
  payload: GOADate;
};

// Set Original Return Date
// =========================
export const SET_ORIGINAL_RETURN_DATE = 'SET_ORIGINAL_RETURN_DATE';

type SetOriginalReturnDateAction = {
  type: typeof SET_ORIGINAL_RETURN_DATE;
  payload: GOADate;
};

// Set Departure Date
// =========================
export const SET_DEPARTURE_DATE = 'SET_DEPARTURE_DATE';

type SetDepartureDateAction = {
  type: typeof SET_DEPARTURE_DATE;
  payload: GOADate;
};

// Set Return Date
// =========================
export const SET_RETURN_DATE = 'SET_RETURN_DATE';

type SetReturnDateAction = {
  type: typeof SET_RETURN_DATE;
  payload: GOADate;
};

// Set From
// =========================
export const SET_FROM = 'SET_FROM';

type SetFromAction = {
  type: typeof SET_FROM;
  payload: LocationType;
};

// Set To
// =========================
export const SET_TO = 'SET_TO';

type SetToAction = {
  type: typeof SET_TO;
  payload: LocationType;
};

// Set Passengers
// =========================
export const SET_PASSENGERS = 'SET_PASSENGERS';

type SetPassengersAction = {
  type: typeof SET_PASSENGERS;
  payload: Passengers;
};

// Set Supplements
// =========================
export const SET_SUPPLEMENTS = 'SET_SUPPLEMENTS';

type SetSupplementsAction = {
  type: typeof SET_SUPPLEMENTS;
  payload: Supplements;
};

// Set Order ID
// =========================
export const SET_ORDER_ID = 'SET_ORDER_ID';

type SetOrderID = {
  type: typeof SET_ORDER_ID;
  payload: string;
};

// Set Reserve Expiration
// =========================
export const SET_RESERVE_EXPIRATION = 'SET_RESERVE_EXPIRATION';

type SetReserveExpiration = {
  type: typeof SET_RESERVE_EXPIRATION;
  payload: GOADate;
};

// Set Contact Information
// =========================
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

type SetPaymentMethod = {
  type: typeof SET_PAYMENT_METHOD;
  payload: string | number;
};

// Set Transaction ID
// =========================
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';

type SetTransactionID = {
  type: typeof SET_TRANSACTION_ID;
  payload: string;
};

// Set Delivery Method
// =========================
export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';

type SetDeliveryMethod = {
  type: typeof SET_DELIVERY_METHOD;
  payload: string;
};

// Set Contact Information
// =========================
export const SET_CONTACT_INFORMATION = 'SET_CONTACT_INFORMATION';

type SetContactInfomation = {
  type: typeof SET_CONTACT_INFORMATION;
  payload: ContactInformation;
};

// Set Terms Accepted
// =========================
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED';

type SetTermsAccepted = {
  type: typeof SET_TERMS_ACCEPTED;
  payload: boolean;
};

// Set Redirect To
// =========================
export const SET_RETURN_TO_PATH = 'SET_RETURN_TO_PATH';

type SetReturnToPath = {
  type: typeof SET_RETURN_TO_PATH;
  payload: string;
};

// Main Type
// =========================
export type OrderActionTypes =
  | SetOrderAction
  | SetTypeAction
  | SetOriginalDepartureDateAction
  | SetOriginalReturnDateAction
  | SetDepartureDateAction
  | SetReturnDateAction
  | SetFromAction
  | SetToAction
  | SetPassengersAction
  | SetSupplementsAction
  | SetOrderID
  | SetReserveExpiration
  | SetPaymentMethod
  | SetTransactionID
  | SetDeliveryMethod
  | SetContactInfomation
  | SetTermsAccepted
  | SetJourneyAction
  | SetReturnAction
  | SetReturnToPath;
