import { Constants } from 'utils/Constants';
import { formatShortPrice, getSuppTranslation, mapPassengerToGOAId } from 'utils/Helpers';

export const checkIsSeatSupplement = (id: string): boolean => {
  return id === Constants.GOA_SLEEPER || id === Constants.GOA_SLEEPER_BERTH || id === Constants.GOA_SLEEPER_CHILD;
};

export const isLuggageSupp = (id: string): boolean => {
  return (
    id === Constants.GOA_BICYCLE ||
    id === Constants.GOA_BICYCLE_NON_RESERVABLE ||
    id === Constants.GOA_SMALL_ANIMAL ||
    id === Constants.GOA_ANIMAL
  );
};

export const MyJourneyService = {
  cancelledAmount: (items: any): number => items.filter((p) => p.cancelled === true).length,

  validAmount: (items: any): number => items.filter((p) => p.cancelled != true).length,

  isPassengerSupplement: (id: string): boolean => isLuggageSupp(id),

  isSeatSupplement: (id: string): boolean => {
    return checkIsSeatSupplement(id);
  },

  getSuppWithPrice: (item: any) => {
    const id = item.product.id;
    const price = item.price.amount;

    const suppName = () => getSuppTranslation(id);

    return { name: suppName(), price: formatShortPrice(price) };
  },

  getPassWithPrice: (item: any, supplements) => {
    let totalSuppCost = 0;
    supplements.forEach((p) => {
      if (isLuggageSupp(p.product.id)) totalSuppCost += Number(p.price.amount);
    });
    const price = item.price.amount - totalSuppCost;

    return { name: mapPassengerToGOAId(item.userProfile.id), price: formatShortPrice(price) };
  },
  isLuggageSupp: (id: string) => isLuggageSupp(id),
  getSeats: (ticketDetails: any, hasSeats: boolean, translate: (key: string) => string): string => {
    const reservations = ticketDetails.reservations[0];
    const supplements = ticketDetails.supplementProducts[0];
    const isSleep =
      supplements &&
      (supplements.product.id === Constants.GOA_SLEEPER_BERTH ||
        supplements.product.id === Constants.GOA_SLEEPER_CHILD);

    if (!hasSeats || (!reservations && !isSleep)) return translate('NO_SEAT_RESERVATION');
    if (!reservations && isSleep) return translate('SHARED_SLEEP_COMPARTMENT');
    const seats =
      reservations &&
      ticketDetails.reservations[0].seats
        .filter((s) => s.seatNumber !== '0')
        .map((seat) => {
          return `${seat.seatNumber}, `;
        });
    if (seats[seats.length - 1]) seats[seats.length - 1] = seats[seats.length - 1].replace(',', '');
    return seats;
  },

  needsCustomerService: (detailsHistory) => {
    const amountOfPassengers = detailsHistory && detailsHistory.length;
    const sleeperCompartsments = detailsHistory.reduce((accumulator, currentValue) => {
      const supplements = currentValue.ticketDistributions[0].supplementProducts;
      if (supplements && supplements.find((p) => p.product.id === Constants.GOA_SLEEPER)) return accumulator + 1;
      return accumulator;
    }, 0);
    return sleeperCompartsments > 0 && sleeperCompartsments !== amountOfPassengers;
  },

  rebookInfant: (detailsHistory) => {
    const infants = detailsHistory.filter(
      (item) => item.ticketDistributions[0].userProfile.id === Constants.GOA_USERPROFILE_INFANT,
    );
    const cancelledTickets = detailsHistory && detailsHistory.filter((item) => item.cancelled);

    return {
      showMessage: infants.length > 0 && detailsHistory.length - infants.length - cancelledTickets.length === 1,
      infants: infants,
    };
  },
};
