import React from 'react';
import { FormFieldType } from 'types/FormTypes';
import { InputError } from './InputError';
import { InputLabel } from './InputLabel';
import { CheckboxLabelRight } from './Checkbox';
import { RadiobuttonLabelRight } from './Radiobutton';
import { FormGroupContainer } from './FormGroupContainer';
import { FlexRow } from 'elements/containers/Containers';

type FormGroupProps = {
  field: FormFieldType;
  onInputChange: (field: FormFieldType, value: string | number) => void;
};

export const FormGroup = ({ field, onInputChange }: FormGroupProps) => (
  <>
    <InputLabel fieldId={field.id} label={field.label} description={field.description} />
    <FlexRow>
      <FormGroupContainer field={field} error={Boolean(field.error)} column={true}>
        {field.options.map((option, index) => (
          <li key={index}>
            {option.type === 'checkbox' && (
              <CheckboxLabelRight
                id={option.id}
                name={option.id}
                checked={field.value.includes(option.value)}
                label={option.label}
                readOnly={option.readOnly}
                onToggle={() => onInputChange(field, option.value)}
              />
            )}
            {option.type === 'radio' && (
              <RadiobuttonLabelRight
                id={option.id}
                name={option.id}
                checked={field.value === option.value}
                label={option.label}
                onToggle={() => onInputChange(field, option.value)}
              />
            )}
          </li>
        ))}
      </FormGroupContainer>
    </FlexRow>
    <InputError fieldId={field.id} error={field.error} />
  </>
);
