import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { MenuLinksType } from 'types/CmsTypes';
import { HeaderLogo } from './components/HeaderLogo';
import { HeaderFloatingMenu } from './components/HeaderFloatingMenu';
import { LanguageSelector } from './components/LanguageSelector';
import { HeaderProfile } from './components/HeaderProfile';
import { FlexRow, FlexBetween } from 'elements/containers/Containers';
import { MarginRight } from 'elements/distance/Margins';

type LargeMenuProps = {
  faqLinks: MenuLinksType[];
  profileLinks: { title: string; slug: null; subRows: { title: string; slug: string | null }[] };
  expanded?: string;
  onToggleMenu?: (id: string) => void;
  onOpenLogin?: () => void;
  onMenuClick?: (slug: string) => void;
  onLogoutUser?: () => void;
};

const LargeHeaderContainer = styled(FlexBetween)`
  width: 100%;
  height: 12rem;
  padding: 0 5.6rem;
`;

const Container = styled(FlexRow)`
  flex: 1;
  padding: 0 1.6rem;
  justify-content: center;
`;

const LeftsideContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2.4rem;
  align-items: center;
  justify-content: end;
`;

export const LargeHeader = ({ ...props }: LargeMenuProps) => {
  const { faqLinks, profileLinks, expanded, onToggleMenu, onOpenLogin, onMenuClick, onLogoutUser } = props;
  const { translate } = useI18n();

  const handleRowClick = (parentTitle: string, title: string) => {
    if (title === translate('LOGOUT')) onLogoutUser();
    else {
      const menuLinks = [...faqLinks, profileLinks];
      const parent = menuLinks.find((link) => link.title === parentTitle);
      const slug = parent.subRows.find((row) => row.title === title)['slug'];
      onMenuClick(parent.slug ? `/${parent.slug}/${slug}` : slug);
    }
  };

  return (
    <LargeHeaderContainer>
      <HeaderLogo onLinkClick={onMenuClick} />
      <Container>
        {faqLinks &&
          faqLinks.map((faqLink, index) => (
            <MarginRight key={index} margin={index === faqLinks.length - 1 ? 0 : 4.8}>
              <HeaderFloatingMenu
                link={faqLink}
                margin="auto"
                expanded={expanded}
                onToggleMenu={onToggleMenu}
                onRowClick={handleRowClick}
              />
            </MarginRight>
          ))}
      </Container>
      <LeftsideContainer>
        <LanguageSelector expanded={expanded} onToggleMenu={onToggleMenu} />
        <HeaderProfile
          isPhoneDevice={false}
          profileLinks={profileLinks}
          expanded={expanded}
          onOpenLogin={onOpenLogin}
          onToggleMenu={onToggleMenu}
          onRowClick={handleRowClick}
        />
      </LeftsideContainer>
    </LargeHeaderContainer>
  );
};
