import React from 'react';
import styled from 'styled-components';
import { translate } from 'utils/i18n/i18n';
import { Constants } from 'utils/Constants';
import { MessageBox } from 'elements/new-design/messages/MessageBox';
import { SmallLoader } from 'elements/loader/Loader';
import {
  TxtSmallMediumDarkResp,
  TxtSmallMediumErrorResp,
  TxtSmallBoldAccentResp,
} from 'elements/new-design/Typography';
import { InfoIconAlert } from 'elements/new-design/icons/SymbolIcons';
import { ButtonBorderPrimary } from 'elements/buttons/Buttons';
import { FlexRow } from 'elements/containers/Containers';
import { MarginRight } from 'elements/distance/Margins';

type CampaignMessageProps = {
  name: string;
  buttonText: Record<string, string>;
  description: Record<string, string>;
  loading: boolean;
  error: boolean;
  onCampaignButtonClick: (name: string) => void;
};

type CancelledMessageProps = {
  text: string;
};

const ContentContainer = styled.div`
  padding: 0.8rem 1.6rem;
  display: grid;
  grid-gap: 1.6rem;
  grid-auto-flow: row;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.large} {
    grid-gap: 2.4rem;
    align-items: center;
    grid-auto-flow: column;
  }
`;

export const CampaignMessage = ({ ...props }: CampaignMessageProps): JSX.Element => (
  <MessageBox type={props.error ? Constants.WARNING_MESSAGE : Constants.INFO_MESSAGE}>
    <ContentContainer>
      <p>
        {props.error ? (
          <TxtSmallMediumErrorResp>{translate('TECHNICAL_ERROR_TEXT', 'NO')}</TxtSmallMediumErrorResp>
        ) : (
          <TxtSmallMediumDarkResp>{props.description.no}</TxtSmallMediumDarkResp>
        )}
      </p>
      <span>
        <ButtonBorderPrimary onClick={() => props.onCampaignButtonClick(props.name)}>
          {props.loading ? <SmallLoader /> : <TxtSmallBoldAccentResp>{props.buttonText.no}</TxtSmallBoldAccentResp>}
        </ButtonBorderPrimary>
      </span>
    </ContentContainer>
  </MessageBox>
);

export const CancelledMessage = ({ text }: CancelledMessageProps): JSX.Element => (
  <MessageBox type={Constants.WARNING_MESSAGE}>
    <ContentContainer>
      <FlexRow>
        <MarginRight margin={0.6}>
          <InfoIconAlert width={2} height={2} />
        </MarginRight>
        <span>
          <p>
            <TxtSmallMediumErrorResp>{text}</TxtSmallMediumErrorResp>
          </p>
        </span>
      </FlexRow>
    </ContentContainer>
  </MessageBox>
);
