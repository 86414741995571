import { api } from 'client/types';
/// <reference path="types.ts"/>
/** @module RecurringPayment */
// Auto-generated, edits will be overwritten
import * as gateway from './gateway'

/**
 * Returns the customer's recurring payments
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse2>} Success
 */
export function RecurringPayment_GetRecurringPayments(options?: RecurringPayment_GetRecurringPaymentsOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    }
  }
  return gateway.request(RecurringPayment_GetRecurringPaymentsOperation, parameters)
}

/**
 * Creates a new recurring payment and returns Nets terminal
 * 
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.nickname] Name of card
 * @param {string} [options.redirectUrl] Redirect url from Nets terminal
 * @param {boolean} [options.isPrimary] Should the card be primary (defaults to true)
 * @param {string} [options.lang=no_NO] Language of Nets terminal
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultCreateRecurringPaymentTerminalResponse1>} Success
 */
export function RecurringPayment_CreateRecurringPayment(options?: RecurringPayment_CreateRecurringPaymentOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultCreateRecurringPaymentTerminalResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    query: {
      nickname: options.nickname,
      redirectUrl: options.redirectUrl,
      isPrimary: options.isPrimary,
      lang: options.lang
    }
  }
  return gateway.request(RecurringPayment_CreateRecurringPaymentOperation, parameters)
}

/**
 * Deletes recurring payment
 * 
 * @param {number} recurringPaymentId Id of the recurring payment
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse1>} Success
 */
export function RecurringPayment_DeleteRecurringPayment(recurringPaymentId: number, options?: RecurringPayment_DeleteRecurringPaymentOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      recurringPaymentId
    }
  }
  return gateway.request(RecurringPayment_DeleteRecurringPaymentOperation, parameters)
}

/**
 * Updates a recurring payment
 * 
 * @param {number} recurringPaymentId Id of the recurring payment
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @param {string} [options.nickname] Name of card
 * @param {boolean} [options.isPrimary] Should the card be primary
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse2>} Success
 */
export function RecurringPayment_UpdateRecurringPayment(recurringPaymentId: number, options?: RecurringPayment_UpdateRecurringPaymentOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse2>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    query: {
      nickname: options.nickname,
      isPrimary: options.isPrimary
    },
    path: {
      recurringPaymentId
    }
  }
  return gateway.request(RecurringPayment_UpdateRecurringPaymentOperation, parameters)
}

/**
 * Authorizes recurring payment
 * 
 * @param {number} recurringPaymentId Id of the recurring payment
 * @param {object} options Optional options
 * @param {string} [options.authorization] 
 * @return {Promise<module:types.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse1>} Success
 */
export function RecurringPayment_AuthorizeRecurringPayment(recurringPaymentId: number, options?: RecurringPayment_AuthorizeRecurringPaymentOptions): Promise<api.Response<api.GoAliveRestApi.Controllers.ApiResultRecurringPaymentResponse1>> {
  if (!options) options = {}
  const parameters: api.OperationParamGroups = {
    header: {
      authorization: options.authorization
    },
    path: {
      recurringPaymentId
    }
  }
  return gateway.request(RecurringPayment_AuthorizeRecurringPaymentOperation, parameters)
}

export interface RecurringPayment_GetRecurringPaymentsOptions {
  authorization?: string
}

export interface RecurringPayment_CreateRecurringPaymentOptions {
  authorization?: string
  /**
   * Name of card
   */
  nickname?: string
  /**
   * Redirect url from Nets terminal
   */
  redirectUrl?: string
  /**
   * Should the card be primary (defaults to true)
   */
  isPrimary?: boolean
  /**
   * Language of Nets terminal
   */
  lang?: string
}

export interface RecurringPayment_DeleteRecurringPaymentOptions {
  authorization?: string
}

export interface RecurringPayment_UpdateRecurringPaymentOptions {
  authorization?: string
  /**
   * Name of card
   */
  nickname?: string
  /**
   * Should the card be primary
   */
  isPrimary?: boolean
}

export interface RecurringPayment_AuthorizeRecurringPaymentOptions {
  authorization?: string
}

const RecurringPayment_GetRecurringPaymentsOperation: api.OperationInfo = {
  path: '/rest/RecurringPayment/recurring-payments',
  method: 'get',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const RecurringPayment_CreateRecurringPaymentOperation: api.OperationInfo = {
  path: '/rest/RecurringPayment/recurring-payments',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const RecurringPayment_DeleteRecurringPaymentOperation: api.OperationInfo = {
  path: '/rest/RecurringPayment/recurring-payments/{recurringPaymentId}',
  method: 'delete',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const RecurringPayment_UpdateRecurringPaymentOperation: api.OperationInfo = {
  path: '/rest/RecurringPayment/recurring-payments/{recurringPaymentId}',
  method: 'put',
  security: [
    {
      id: 'Bearer'
    }
  ]
}

const RecurringPayment_AuthorizeRecurringPaymentOperation: api.OperationInfo = {
  path: '/rest/RecurringPayment/recurring-payments/{recurringPaymentId}/authorize',
  method: 'post',
  security: [
    {
      id: 'Bearer'
    }
  ]
}
