import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { CmsCampaignType } from 'types/CmsTypes';
import { FlexBetween } from 'elements/containers/Containers';
import {
  TxtMediumBoldDarkResp,
  TxtMediumMediumDarkResp,
  TxtSmallMediumMediumResp,
} from 'elements/new-design/Typography';
import { ButtonAccentPrimary } from 'elements/buttons/Buttons';

type CampaignFooterProps = {
  isValid: boolean;
  campaign: CmsCampaignType;
  onProceed: () => void;
};

type StyleProps = {
  valid: boolean;
};
const FooterContentContainer = styled(FlexBetween)`
  width: 100%;
`;

const SubmitButton = styled(ButtonAccentPrimary)`
  ${(_: StyleProps) => ''}
  opacity: ${(props) => (props.valid ? 1 : 0.5)};
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CampaignFooter = ({ isValid, campaign, onProceed }: CampaignFooterProps): JSX.Element => {
  const { translate } = useI18n();
  return (
    <FooterContentContainer>
      <FooterTextContainer>
        <TxtSmallMediumMediumResp>{campaign.title}</TxtSmallMediumMediumResp>
        <TxtMediumMediumDarkResp>{`${translate('VALID')} ${campaign.daysOfValidity} ${translate(
          'DAYS',
        )}`}</TxtMediumMediumDarkResp>
        <TxtMediumBoldDarkResp>{`kr ${campaign.price},-`}</TxtMediumBoldDarkResp>
      </FooterTextContainer>
      <SubmitButton big valid={isValid} onClick={onProceed}>
        {`${translate('PAY')} kr ${campaign.price},-`}
      </SubmitButton>
    </FooterContentContainer>
  );
};
