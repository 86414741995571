import React, { useEffect, useState } from 'react';
import { Constants } from 'utils/Constants';
import { usePersistentStorage } from 'hooks/usePersistentStorage';
import { useI18n } from 'utils/i18n/usei18n';
import { CookieOptionsType, CookieSection } from 'types/CmsTypes';
import { Dialog } from 'components/new-design/dialogs/Dialog';
import { CookieInformation } from './CookieInformation';
import { CookieSettingsView } from './CookieSettings';

type CookieConsentProps = {
  cookieSection: CookieSection;
};

const COOKIE_MAIN = 'COOKIEMAIN';
const COOKIE_SETTINGS = 'COOKIESETTINGS';

export const CookieConsent = ({ cookieSection }: CookieConsentProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(COOKIE_MAIN);
  const { getCachedItem, cacheItem } = usePersistentStorage();
  const { currentLanguage, BASE_LANGUAGE } = useI18n();
  const [cookieInfo, setCookieInfo] = useState(null);
  const [cookieSettings, setCookieSettings] = useState(null);
  const [savedConsents, setSavedConsents] = useState(null);

  const handleSaveChoices = (acceptedCookies: CookieOptionsType[]) => {
    const settings = getCachedItem(Constants.CACHE_SETTINGS);
    const updatedSettings = settings ? settings : {};
    cookieSettings.cookies.forEach((cookie: CookieOptionsType) => {
      if (acceptedCookies.find((c: CookieOptionsType) => c.apiId === cookie.apiId))
        updatedSettings[cookie.apiId] = Constants.ON;
      else updatedSettings[cookie.apiId] = Constants.OFF;
    });
    cacheItem(Constants.CACHE_SETTINGS, updatedSettings);
    setModalOpen(null);
  };

  const handleToggleConsent = (id: string) => {
    const isSaved = savedConsents.find((cookie: CookieOptionsType) => cookie.apiId === id);
    if (isSaved) setSavedConsents(savedConsents.filter((cookie: CookieOptionsType) => cookie.apiId !== id));
    else
      setSavedConsents([
        ...savedConsents,
        cookieSettings.cookies.find((cookie: CookieOptionsType) => cookie.apiId === id),
      ]);
  };

  useEffect(() => {
    if (currentLanguage) {
      const cookieSectionByLang = cookieSection.find((section) => section.language === currentLanguage)
        ? cookieSection.filter((section) => section.language === currentLanguage)
        : cookieSection.filter((section) => section.language === BASE_LANGUAGE);

      setCookieInfo(cookieSectionByLang[1]);
      setCookieSettings(cookieSectionByLang[0]);
      setSavedConsents(cookieSectionByLang[0]['cookies'].filter((cookie) => cookie.mandatory));
    }
  }, [currentLanguage]);

  if (cookieSection && modalOpen)
    return (
      <>
        {cookieInfo && cookieSettings && (
          <>
            {modalOpen === COOKIE_MAIN ? (
              <Dialog type="cookie" title={cookieInfo.cookieInformationTitle} onModalClose={null} focusDialog={true}>
                <CookieInformation
                  cookieInfo={cookieInfo}
                  onAcceptAll={() => handleSaveChoices(cookieSettings.cookies)}
                  onGoToSettings={() => setModalOpen(COOKIE_SETTINGS)}
                />
              </Dialog>
            ) : (
              <div role="alertDialog">
                <Dialog type="cookie" title={cookieSettings.title} onModalClose={null} focusDialog={true}>
                  <CookieSettingsView
                    cookieSettings={cookieSettings}
                    savedConsents={savedConsents}
                    onToggleConsent={handleToggleConsent}
                    onSaveChoice={() => handleSaveChoices(savedConsents)}
                    onAcceptAll={() => handleSaveChoices(cookieSettings.cookies)}
                  />
                </Dialog>
              </div>
            )}
          </>
        )}
      </>
    );
  else return null;
};
