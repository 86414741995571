import colors from './colors';

export default {
  borderDarkThin: `.1rem solid ${colors.borderDark}`,
  borderDarkThick: `.2rem solid ${colors.borderDark}`,
  borderDarkFat: `.4rem solid ${colors.borderDark}`,
  borderDarkHover: `.3rem solid ${colors.borderDark}`,
  borderReadonly: `.3rem solid ${colors.borderReadonly}`,
  borderLightThick: `.2rem solid ${colors.txtColLight}`,
  borderLightFat: `.4rem solid ${colors.txtColLight}`,
  borderErrorThin: `.1rem solid ${colors.borderError}`,
  borderErrorThick: `.2rem solid ${colors.borderError}`,
  borderTransparantThin: `.1rem solid transparent`,
  borderTransparantThick: `.2rem solid transparent`,
  borderTableThin: `.1rem solid ${colors.borderTable}`,
  borderInfoThin: `.1rem solid ${colors.borderInfo}`,
  keyNavFocus: `0 0 0 .2rem ${colors.borderLight}, 0 0 .3rem .5rem ${colors.focus}`,
  borderRadiusDefault: '3rem',
  borderRadiusSquare: '0.3rem',
  zIndexLow: 1,
  zIndexMedium: 50,
  zIndexHigh: 100,
  zIndexMega: 1000,
  zIndexSuper: 2000,
};
