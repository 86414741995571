import React, { useState, useEffect } from 'react';
import { AppState } from 'store/index';
import { useSelector } from 'react-redux';
import { useApi } from 'hooks/use-api/useApi';
import { useUpdateState } from 'hooks/useUpdateState';
import { useI18n } from 'utils/i18n/usei18n';
import { navigate } from 'hookrouter';
import { DeviationsPage } from './deviations-page/DeviationsPage';
import { SidebarPage } from './sidebar-page/SidebarPage';
import { ArticlePage } from 'pages/cms/ArticlePage';
import NotFoundPage from 'pages/404/NotFoundPage';
import { Constants } from 'utils/Constants';
import { SectionMessage } from 'elements/new-design/messages/SectionMessage';
import { Loader } from 'elements/loader/Loader';
import { PreviewNotAvailable } from './components/PreviewNotAvailable';
import { PartnerComponent } from './PartnerComponent';
import RealTimeView from 'components/real-time-view/RealTimeView';

type ContentPageProps = {
  slug: string;
  preview?: boolean;
  hideBreadCrumbs?: boolean;
};

const ContentPageState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

const ContentPage = ({ slug, preview, hideBreadCrumbs }: ContentPageProps): JSX.Element => {
  const { API_CALLS, requestCms } = useApi();
  const { setOrderFromStation, setOrderToStation, setCurrentLanguage, setTitle } = useUpdateState();
  const { currentLanguage, translate } = useI18n();
  const { currentDialog } = useSelector((store: AppState) => store.global);
  const [contentPageData, setContentPage] = useState(null);
  const [state, setState] = useState<typeof ContentPageState>(Constants.LOADING);
  const hasSidebar = contentPageData?.hasPageSectionNavigation;
  const { account } = useSelector((store: AppState) => store.user);

  const initContentPage = async (slug: string) => {
    setState(Constants.LOADING);
    try {
      const pageData = preview
        ? await requestCms(API_CALLS.CMS_GET_PAGE_PREVIEW, { slug })
        : await requestCms(API_CALLS.CMS_GET_PAGE, { slug });

      const partnerPageData = preview
        ? await requestCms(API_CALLS.CMS_GET_PAGE_PREVIEW, { slug })
        : await requestCms(API_CALLS.CMS_GET_PARTNER_PAGE, { slug });

      if (pageData.length) {
        setState(Constants.IDLE);
        if (pageData[0].language !== currentLanguage) setCurrentLanguage(pageData[0].language);
        setContentPage(pageData[0]);
        setTitle(pageData[0].title);
        //some pages are opended in dialogs, then don't navigate
        //navigation is used here to change url to match current language if
        //user switches language
        if (!currentDialog && !preview) {
          if (pageData[0].apiId !== Constants.SANITY.GOA_PARTNER_PAGE) {
            navigate(`/${pageData[0].parentLink.current}/${slug}`);
          } else {
            navigate(`/${slug}`);
          }
        }
      } else if (partnerPageData.length) {
        setState(Constants.IDLE);
        if (partnerPageData[0].language !== currentLanguage) setCurrentLanguage(partnerPageData[0].language);
        setContentPage(partnerPageData[0]);
        setTitle(
          partnerPageData[0].apiId == Constants.SANITY.GOA_PARTNER_PAGE
            ? account
              ? partnerPageData[0].loggedIn.title
              : partnerPageData[0].notLoggedIn.title
            : partnerPageData[0].title,
        );
      }
    } catch (error) {
      setState(Constants.ERROR);
    }
  };

  useEffect(() => {
    if (contentPageData?.body) {
      const stationsSearch = contentPageData.body.find((element) => element._type === 'stationSearch');
      if (stationsSearch) {
        stationsSearch.fromStation && setOrderFromStation(stationsSearch.fromStation);
        stationsSearch.toStation && setOrderToStation(stationsSearch.toStation);
      }
    }
  }, [contentPageData]);

  useEffect(() => {
    (async () => {
      if (!preview && contentPageData && contentPageData.language !== currentLanguage) {
        const newLang = contentPageData.locales.find((lang) => lang.locale === currentLanguage);
        if (newLang) await initContentPage(newLang.slug);
      }
    })();
  }, [currentLanguage]);

  useEffect(() => {
    initContentPage(slug);
  }, [slug]);

  return (
    <>
      {state === Constants.LOADING && <Loader />}
      {state === Constants.ERROR && (
        <SectionMessage
          type={Constants.ERROR_MESSAGE}
          title={translate('GENERAL_ERROR_HEADLINE')}
          text={translate('TECHNICAL_ERROR_TEXT')}
          buttonText={translate('TRY_AGAIN')}
          onButtonClick={() => window.location.reload()}
        />
      )}
      {state === Constants.IDLE && preview && !contentPageData && <PreviewNotAvailable />}
      {state === Constants.IDLE && !preview && !contentPageData && <NotFoundPage />}
      {state === Constants.IDLE && contentPageData && (
        <>
          {contentPageData.apiId === Constants.SANITY.GOA_DEVIATIONS_PAGE && (
            <DeviationsPage title={contentPageData.title} />
          )}
          {contentPageData.apiId === Constants.SANITY.GOA_PARTNER_PAGE && (
            <PartnerComponent pageData={contentPageData} />
          )}
          {(contentPageData.apiId === Constants.SANITY.GOA_ARTICLE_PAGE ||
            contentPageData.apiId === Constants.SANITY.GOA_INFORMATION_PAGE) && (
            <ArticlePage pageData={contentPageData} />
          )}
          {hasSidebar && <SidebarPage pageData={contentPageData} hideBreadCrumbs={hideBreadCrumbs} />}

          {!hasSidebar && contentPageData.apiId === Constants.SANITY.GOA_STANDARD_PAGE && (
            <ArticlePage pageData={contentPageData} />
          )}

          {(slug === 'rtv' || slug === 'rtv-en') && (
            <div>
              <RealTimeView />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default ContentPage;
