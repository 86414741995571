import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useUpdateState } from 'hooks/useUpdateState';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { BreadcrumbsPage } from 'components/new-design/layouts/BreadcrumbsPage';
import { SmallContainer } from 'components/new-design/layouts/LayoutContainers';
import { Premable } from 'elements/new-design/Typography';
import { LinkMediumRespUnderline } from 'elements/new-design/links/Links';
import BgImageSmall from 'assets/svg/new-design/404_small_2.svg';
import BgImageLarge from 'assets/svg/new-design/404_large_2.svg';

const Background = styled.div`
  width: 100vw;
  max-height: 100vh;
`;

const StyledSmallContainer = styled(SmallContainer)`
  z-index: ${(props) => props.theme.constants.zIndexLow};
`;

const BgImage = styled.div`
  position: absolute;
  top: 40rem;
  width: 100%;
  margin-top: 2rem;
  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: 0;
    top: 30rem;
  }
`;

const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    margin-top: 1.6rem;
  }
  @media ${(props) => props.theme.breakpoints.medium} {
    flex-direction: row;
    li {
      margin-right: 2.4rem;
    }
  }
`;

const NotFoundPage = (): JSX.Element => {
  const { track404 } = useGoogleAnalytics();
  const { setTitle } = useUpdateState();
  const { translate } = useI18n();
  const { device } = useBreakpoints();

  useEffect(() => {
    setTitle(translate('PAGE_TITLE_NOT_FOUND'));
    track404();
  }, []);

  return (
    <Background>
      <BreadcrumbsPage title={translate('NOT_FOUND_HEADLINE')} hideBreadCrumbs={true} loading={false}>
        <StyledSmallContainer>
          <Premable>{translate('NOT_FOUND_PREAMBLE')}</Premable>
          <LinksList>
            <li>
              <LinkMediumRespUnderline text={translate('ROUTE_HOME')} url={Constants.ROUTE_BASE} external={false} />
            </li>
            <li>
              <LinkMediumRespUnderline
                text={translate('ROUTE_DEVIATIONS')}
                url={Constants.ROUTE_DEVIATIONS}
                external={false}
              />
            </li>
            <li>
              <LinkMediumRespUnderline
                text={translate('ROUTE_ARTICLES')}
                url={Constants.ROUTE_CMS_ARTICLES}
                external={false}
              />
            </li>
          </LinksList>
        </StyledSmallContainer>
        <BgImage>
          {device === Constants.DEVICE_XLARGE ? (
            <img src={BgImageLarge} alt={'Train stopping in front of sign No Ahead'} />
          ) : (
            <img src={BgImageSmall} alt={'Train stopping in front of sign No Ahead'} />
          )}
        </BgImage>
      </BreadcrumbsPage>
    </Background>
  );
};

export default NotFoundPage;
