import React, { ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { isActionKey } from 'utils/Accessability';

type HiddenInputProps = {
  id: string | number;
  type: string;
  value?: string | number;
  checked?: boolean;
  readOnly?: boolean;
  onToggle: (e: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => void;
};

export const HiddenInputStyle = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const HiddenInput = ({ ...props }: HiddenInputProps) => (
  <HiddenInputStyle
    id={props.id.toString()}
    type={props.type}
    checked={props.checked}
    tabIndex={0}
    onKeyDown={(e) => (isActionKey(e.key) ? props.onToggle(e) : null)}
    value={props.value}
    aria-readonly={props.readOnly}
    readOnly={props.readOnly}
    disabled={props.readOnly}
    onChange={props.onToggle}
  />
);
