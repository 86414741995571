import styled from 'styled-components';

type StyleProps = {
  width?: number;
  height?: number;
};

export const Icon = styled.div`
  ${(_: StyleProps) => ''}
  width: ${(props) => (props.width ? `${props.width}rem` : '3.5rem')};
  height: ${(props) => (props.height ? `${props.height}rem` : '3.5rem')};
  flex-shrink: 0;
`;
