import React from 'react';
import styled from 'styled-components';
import ChevronDark from 'assets/svg/new-design/chevron-dark.svg';
import { Icon } from './Icon';

type ChevronProps = {
  expanded?: boolean;
  width?: number;
  height?: number;
};

export const ChevronDownDark = styled(Icon)`
  ${(_: ChevronProps) => ''}
  background: url(${ChevronDark}) no-repeat center;
  background-size: 100%;
`;

export const ChevronUpDark = styled(ChevronDownDark)`
  transform: rotate(180deg);
`;

export const ChevronRightDark = styled(ChevronDownDark)`
  transform: rotate(-90deg);
`;

export const ExpandIcon = ({ expanded, width, height }: ChevronProps) => (
  <>{expanded ? <ChevronUpDark width={width} height={height} /> : <ChevronDownDark width={width} height={height} />}</>
);
