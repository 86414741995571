import styled from 'styled-components';

export const BaseContainerLarger = styled.div`
  width: 100%;

  @media ${(props) => props.theme.devices.laptop} {
    width: 108.5rem;
  }
`;

export const BaseContainerSmaller = styled(BaseContainerLarger)`
  padding: 0 2rem;

  @media ${(props) => props.theme.devices.large} {
    padding: 0 4rem;
  }

  @media ${(props) => props.theme.devices.laptop} {
    padding: 0;
  }
`;

export const BaseContainerNarrow = styled.div`
  margin: 0 auto;
  padding: 0 3.7rem 0 3.6rem;

  @media ${(props) => props.theme.devices.medium} {
    padding: 0 13rem;
  }

  @media ${(props) => props.theme.devices.large} {
    padding: 0;
    max-width: 80.8rem;
  }
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const ContainerBorder = styled(BaseContainerLarger)`
  border: ${(props) => props.theme.constants.borderPrimary};
  background: ${(props) => props.theme.colors.bgSecondary};
`;

export const ContainerBorderResponsive = styled(ContainerBorder)`
  border-top: ${(props) => props.theme.constants.borderPrimary};
  border-bottom: ${(props) => props.theme.constants.borderPrimary};
  border-left: none;
  border-right: none;

  @media ${(props) => props.theme.devices.laptop} {
    border: ${(props) => props.theme.constants.borderPrimary};
  }
`;

export const ContainerBoxShadow = styled.div`
  background: ${(props) => props.theme.colors.bgSecondary};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexRowStart = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FlexRowEnd = styled.div`
  display: flex;
  align-items: baseline;
`;

export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FlexColumnEnd = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
`;

export const FlexColumnSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const FlexColumnNoCenter = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRowWithWrap = styled(FlexRow)`
  flex-wrap: wrap;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBetweenStart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FlexBetweenEnd = styled(FlexBetweenStart)`
  align-items: flex-end;
`;

export const FlexBetweenFullWidth = styled(FlexBetween)`
  width: 100%;
`;

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FlexBottom = styled.div`
  display: flex;
  align-items: baseline;
`;

export const FlexBottomSpaceBetween = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const FlexBetweenWithWrap = styled(FlexBetween)`
  flex-wrap: wrap;
`;

export const FlexCenterCenter = styled(FlexRow)`
  justify-content: center;
`;

export const FlexOne = styled.div`
  flex: 1;
`;

export const PageLoaderContainer = styled.div`
  margin-top: 4rem;
`;

export const BaseLoaderContainer = styled.div`
  margin-top: 15rem;
`;

export const BaseContainerFoldout = styled.div`
  padding: 0;

  @media ${(props) => props.theme.devices.medium} {
    padding: 0 2rem;
  }

  @media ${(props) => props.theme.devices.large} {
    padding: 0 4rem;
  }

  @media ${(props) => props.theme.devices.laptop} {
    padding: 0;
  }
`;

export const HiddenContainer = styled.div`
  display: none;
`;
