import React from 'react';
import styled from 'styled-components';
import { useI18n } from 'utils/i18n/usei18n';
import { FlexRow } from 'elements/containers/Containers';
import { TxtSmallBoldDarkResp } from 'elements/new-design/Typography';

const ListHeaderContainer = styled(FlexRow)`
  width: 100%;
  padding: 1rem 2.4rem;
  ${TxtSmallBoldDarkResp} {
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 10%;
      text-align: right;
    }
    &:nth-child(5) {
      width: 10%;
      text-align: right;
    }
    &:nth-child(6) {
      width: 5%;
    }
  }
`;

export const ListHeader = () => {
  const { translate } = useI18n();
  return (
    <ListHeaderContainer aria-hidden={true}>
      <TxtSmallBoldDarkResp>{translate('DATE_OF_PURCHASE')}</TxtSmallBoldDarkResp>
      <TxtSmallBoldDarkResp>{translate('JOURNEY')}</TxtSmallBoldDarkResp>
      <TxtSmallBoldDarkResp>{translate('PASSENGERS')}</TxtSmallBoldDarkResp>
      <TxtSmallBoldDarkResp>{translate('AMOUNT_PAYED')}</TxtSmallBoldDarkResp>
      <TxtSmallBoldDarkResp>{translate('TICKETS')}</TxtSmallBoldDarkResp>
      <TxtSmallBoldDarkResp></TxtSmallBoldDarkResp>
    </ListHeaderContainer>
  );
};
