import React, { useState } from 'react';
import { useApi } from 'hooks/use-api/useApi';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { TransactionOrderLineType } from 'types/HistoryTypes';
import { DialogCustomerService } from 'components/new-design/dialogs/DialogCustomerService';
import { CancelDialog } from './CancelDialog';
import { CancelErrorDialog } from './CancelErrorDialog';
import { ButtonBorderPrimary } from 'elements/buttons/Buttons';
import { TxtSmallBoldErrorResp } from 'elements/new-design/Typography';

type CancelProps = {
  orderLine: TransactionOrderLineType;
  onCancel: () => void;
};

const cancelState = `${Constants.LOADING} | ${Constants.IDLE} | ${Constants.ERROR}`;

export const Cancel = ({ orderLine, onCancel }: CancelProps) => {
  const [state, setState] = useState<typeof cancelState>(Constants.IDLE);
  const [modalOpen, setModalOpen] = useState(false);
  const { guardedRequest, API_CALLS } = useApi();
  const { translate } = useI18n();
  const hasDependentTickets = orderLine.refundOptions && orderLine.refundOptions.message;

  const handleCancel = async (orderLine: TransactionOrderLineType) => {
    setState(Constants.LOADING);
    try {
      await guardedRequest(API_CALLS.REFUND_TICKET, orderLine);
      setState(Constants.IDLE);
      setModalOpen(false);
      onCancel();
    } catch (err) {
      setState(Constants.ERROR);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setState(Constants.IDLE);
  };

  return (
    <>
      <ButtonBorderPrimary label={translate('CANCEL_TICKET')} onClick={() => setModalOpen(true)}>
        <TxtSmallBoldErrorResp>{translate('CANCEL_TICKET')}</TxtSmallBoldErrorResp>
      </ButtonBorderPrimary>

      {modalOpen && (
        <>
          {orderLine.refundOptions && orderLine.refundOptions.contactCustomerService ? (
            <DialogCustomerService text={orderLine.refundOptions.message.nb} onModalClose={handleClose} />
          ) : (
            <>
              {state === Constants.ERROR && (
                <CancelErrorDialog
                  onTryAgain={() => handleCancel(orderLine)}
                  onModalClose={handleClose}
                  loading={state === Constants.LOADING}
                />
              )}
              {(state === Constants.LOADING || state === Constants.IDLE) && (
                <CancelDialog
                  message={hasDependentTickets && orderLine.refundOptions.message.nb}
                  loading={state === Constants.LOADING}
                  onCancel={() => handleCancel(orderLine)}
                  onModalClose={handleClose}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
