import { SET_MESSAGES, REMOVE_MESSAGE, REMOVE_ALL_MESSAGES, MessagesState } from 'types/MessagesTypes';

const initialState: MessagesState = [];

export default function messagesReducer(state = initialState, action): MessagesState {
  switch (action.type) {
    case SET_MESSAGES:
      return [...state, action.payload];
    case REMOVE_MESSAGE:
      return state.length === 1 ? [] : state.filter((_, index) => index !== action.payload);
    case REMOVE_ALL_MESSAGES:
      return [];
    default:
      return state;
  }
}
