import React from 'react';
import styled from 'styled-components';
import { A } from 'hookrouter';
import { useI18n } from 'utils/i18n/usei18n';
import { Constants } from 'utils/Constants';
import { LogoGoAhead } from 'elements/new-design/Logos';

type HeaderLogoProps = {
  onLinkClick: (route: string) => void;
};

const LogoLink = styled(A)`
  border-bottom: ${(props) => props.theme.newconstants.borderTransparantThick};

  :focus-visible,
  :hover {
    box-shadow: none;
    border-bottom: ${(props) => props.theme.newconstants.borderDarkThick};
  }
`;

const Logo = styled(LogoGoAhead)`
  width: 12rem;
  height: 3.5rem;
  margin-bottom: 0.4rem;

  @media ${(props) => props.theme.breakpoints.xlarge} {
    width: 16.2rem;
    height: 4.8rem;
  }
`;

export const HeaderLogo = ({ onLinkClick }: HeaderLogoProps) => (
  <LogoLink
    href=""
    data-cy={'headerLogoCy'}
    onClick={() => onLinkClick(Constants.ROUTE_BASE)}
    aria-label={useI18n().translate('LABEL_NAVIGATE_TO_START_PAGE', 'NO')}
  >
    <Logo />
  </LogoLink>
);
